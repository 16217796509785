import React, { Fragment } from 'react';
import numeral from 'numeral';

const Information = (props) => {
    const handleChangeField = (field, event) => {
        props.setField(field, event.target.value);
    };

    let monthOptions = [];
    for (let i = 1; i <= 12; i++) {
        monthOptions.push(<option key={'month-' + i} value={numeral(i).format('00')}>{numeral(i).format('00')}</option>)
    }

    let yearOptions = [];
    for (let i = 22; i <= 40; i++) {
        yearOptions.push(<option key={'month-' + i} value={i}>20{i}</option>)
    }

    let absFieldsRequired = null;
    if (parseInt(props.user.abs_type) === 1) {
        absFieldsRequired = <Fragment> <span className="required">*</span></Fragment>;
    }

    return (
        <div className="information-container">
            <h2>Information</h2>
            <div className="information">
                <div className="field">
                    <div className="label">Name <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.name} onChange={handleChangeField.bind(this, 'name')} />
                </div>
                <div className="field">
                    <div className="label">Business Unit Number{absFieldsRequired}:</div>
                    <input type="text" value={props.fields.abs_business_unit_number} onChange={handleChangeField.bind(this, 'abs_business_unit_number')} />
                </div>
                <div className="field">
                    <div className="label">Cost Center Number{absFieldsRequired}:</div>
                    <input type="text" value={props.fields.abs_cost_center_number} onChange={handleChangeField.bind(this, 'abs_cost_center_number')} />
                </div>
            </div>
            <h2 className="mt-3">Password Change</h2>
            <div className="password">
                <div className="field">
                    <div className="label">New password (leave blank to leave unchanged):</div>
                    <input type="password" value={props.fields.new_password} onChange={handleChangeField.bind(this, 'new_password')} />
                </div>
                <div className="field">
                    <div className="label">Confirm new password (leave blank to leave unchanged):</div>
                    <input type="password" value={props.fields.confirm_password} onChange={handleChangeField.bind(this, 'confirm_password')} />
                </div>
            </div>
            <div className="button-container">
                <button onClick={props.saveAccount} disabled={props.savingAccount}>
                    {props.savingAccount ? <i className="fas fa-circle-notch fa-spin" /> : 'Save Changes'}
                </button>
            </div>
        </div>
    );
}

export default Information;
