import axios from 'axios';
import * as types from './AdminJobs.types';
import * as selectors from './AdminJobs.selectors';
import { adminValidateAuthInResponse, showNotification, empty } from './../../utils';
import * as dialog from './../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchElements = () => (dispatch, getState) => {
    const pagination = selectors.getPagination(getState());
    const sort = selectors.getSort(getState());
    const search = selectors.getSearch(getState());
    const searchType = selectors.getSearchType(getState());
    const customerId = selectors.getCustomerId(getState());

    let params = [];
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);
    params.push('sort-column=' + sort.column);
    params.push('sort-type=' + sort.type);
    params.push('search=' + search);
    params.push('search-type=' + searchType);

    if (customerId != null) {
        params.push('customer-id=' + customerId);
    }

    dispatch({ type: types.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrl + '/order-job/admin-index?' + params.join('&') + '&expand=artworks',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_ELEMENTS_ERROR));
        });
}

export const fetchElement = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ELEMENT, payload: null });
    axios.get(apiUrl + '/order-jobs/' + id + '?expand=billingAddress,jobs,jobs.artworks',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_ELEMENT_ERROR));
        });
}

export const saveElement = () => (dispatch, getState) => {
    const item = selectors.getItem(getState());
    const history = selectors.getHistory(getState());

    if (!validateSaveFields(item)) {
        return;
    }

    let endpoint = apiUrl + '/order-jobs';
    let method = 'post';
    if (item.id && item.id != null) {
        endpoint = apiUrl + '/order-jobs/' + item.id;
        method = 'put';
    }

    dispatch({ type: types.SAVE_ELEMENT, payload: item.id });
    axios({
        method: method,
        url: endpoint,
        data: item,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_ELEMENT_SUCCESS, payload: response.data });
        showNotification('Item Saved', 'The item has been saved successfully', 'success');
        history.push('/admin/jobs')
    }).catch((error) => {
        dispatch(adminValidateAuthInResponse(error, types.SAVE_ELEMENT_ERROR));
    });
}

export const deleteItem = () => (dispatch, getState) => {
    const idModal = selectors.getIdModal(getState());

    dispatch({ type: types.DELETE_ITEM, payload: null });
    axios.delete(apiUrl + '/order-jobs/' + idModal,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` } })
        .then((response) => {
            dispatch({ type: types.DELETE_ITEM_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('delete-modal'));
            showNotification('Item Deleted', 'The item has been deleted successfully', 'success');
            dispatch(fetchElements());
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.DELETE_ITEM_ERROR));
        });
}

export const validateSaveFields = (fields) => {
    if (empty(fields.title)) {
        showNotification('Complete Information', 'Enter the Title', 'info');
        return false;
    }
    if (empty(fields.content)) {
        showNotification('Complete Information', 'Enter the Content', 'info');
        return false;
    }

    return true;
}

export const createScTicket = (id) => (dispatch, getState) => {
    dispatch({ type: types.CREATE_SC_TICKET, payload: null });
    axios.post(apiUrl + '/order-job/create-sc-ticket', { id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` } })
        .then((response) => {
            dispatch({ type: types.CREATE_SC_TICKET_SUCCESS, payload: response.data });
            showNotification('SC Ticket Created', 'The SC Ticket has been created successfully', 'success');
            dispatch(dialog.actions.hideDialog('create-sc-ticket-modal'));
            dispatch(fetchElements());
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.CREATE_SC_TICKET_ERROR));
        });
}

export const fetchInformation = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/order-job/information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const changeJobStatus = (id, jobStatusId, index) => (dispatch, getState) => {
    dispatch({ type: types.CHANGE_JOB_STATUS, payload: { index, jobStatusId } });
    axios.post(apiUrl + '/order-job/change-job-status', { id, jobStatusId },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` } })
        .then((response) => {
            dispatch({ type: types.CHANGE_JOB_STATUS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.CHANGE_JOB_STATUS_ERROR));
        });
}

export const setActionType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_ACTION_TYPE,
        payload: value
    });
}

export const setActivePage = (value) => (dispatch, getState) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value,
        });
        resolve();
    }).then(() => {
        dispatch(fetchElements());
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: { column, type }
        });
        resolve();
    }).then(() => {
        dispatch(fetchElements());
    });
}

export const setSearch = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH,
        payload: value,
    });
}

export const setSearchType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH_TYPE,
        payload: value,
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setCustomerId = (id) => (dispatch) => {
    dispatch({
        type: types.SET_CUSTOMER_ID,
        payload: id,
    });
}

export const setHistory = (value) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: value,
    });
}

export const setIdModal = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_MODAL,
        payload: id
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}