import Immutable from 'immutable';
import * as types from './CustomQuote.types';

const initialState = {
    fields: {
        name: '',
        company: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        project_name: '',
        project_quantity: '',
        project_description: '',
    },
    sending: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SEND_CONTACT:
            return state.setIn(['sending'], true).toJS();

        case types.SEND_CONTACT_SUCCESS:
            return state.setIn(['sending'], false)
                .setIn(['fields'], {
                    name: '',
                    company: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    phone: '',
                    email: '',
                    project_name: '',
                    project_quantity: '',
                    project_description: '',
                }).toJS();

        case types.SEND_CONTACT_ERROR:
            return state.setIn(['sending'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}