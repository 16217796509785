import axios from 'axios';
import * as types from './LoyaltyPoints.types';
import * as selectors from './LoyaltyPoints.selectors';
import { validateAuthInResponse, showNotification, empty } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchPrizes = () => (dispatch, getState) => {
    const pagination = selectors.getPagination(getState());
    const sort = selectors.getSort(getState());
    const search = selectors.getSearch(getState());
    const searchType = selectors.getSearchType(getState());

    let params = [];
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);
    params.push('sort-column=' + sort.column);
    params.push('sort-type=' + sort.type);
    params.push('search=' + search);
    params.push('search-type=' + searchType);

    dispatch({ type: types.FETCH_PRIZES, payload: null });
    axios.get(apiUrl + '/prizes?' + params.join('&'),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_PRIZES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            // dispatch(validateAuthInResponse(error, types.FETCH_PRIZES_ERROR));
        });
}

export const fetchInformation = () => (dispatch, getState) => {
    const user = loginSelectors.getUser(getState());
    
    let customerId = 0;
    if (!empty(user)) {
        customerId = user.customer_id;
    }

    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/prize/information?customer-id=' + customerId,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            // dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const getPrize = () => (dispatch, getState) => {
    const idModal = selectors.getIdModal(getState());

    dispatch({ type: types.GET_PRIZE, payload: null });
    axios.post(apiUrl + '/prize/get-prize', { id: idModal },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.GET_PRIZE_SUCCESS, payload: response.data });
            showNotification('Prize Redeemed', 'Your prize has been redeemed successfully!', 'success', 8000);
            dispatch(fetchInformation());
            dispatch(dialog.actions.hideDialog('get-prize-modal'));
        })
        .catch((error) => {
            if (error.response && error.response.status === 406) {
                showNotification('Not enough points', 'We\'re Sorry! You do not have enough points for this prize.', 'info');
            } else {
                dispatch(validateAuthInResponse(error, types.GET_PRIZE_ERROR));
            }
        });
}

export const setActivePage = (value) => (dispatch, getState) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value,
        });
        resolve();
    }).then(() => {
        dispatch(fetchPrizes());
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: { column, type }
        });
        resolve();
    }).then(() => {
        dispatch(fetchPrizes());
    });
}

export const setSearch = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH,
        payload: value,
    });
}

export const setSearchType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH_TYPE,
        payload: value,
    });
}

export const setIdModal = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_MODAL,
        payload: id
    });
}

export const setHistory = (history) => dispatch => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}