import React, { Fragment } from 'react';
import { empty } from '../../../utils';
import BookletsForm from './BookletsForm';
import BookmarksForm from './BookmarksForm';
import BrochuresForm from './BrochuresForm';
import BusinessCardsForm from './BusinessCardsForm';
import CatalogsForm from './CatalogsForm';
import DoorHangersForm from './DoorHangersForm';
import FoldedCardsForm from './FoldedCardsForm';
import FlyersForm from './FlyersForm';
import HangTagsForm from './HangTagsForm';
import LetterheadsForm from './LetterheadsForm';
import NewslettersForm from './NewslettersForm';
import PocketFoldersForm from './PocketFoldersForm';
import PostcardsForm from './PostcardsForm';
import PostersForm from './PostersForm';
import RackCardsForm from './RackCardsForm';
import TableTentsForm from './TableTentsForm';

const Form = (props) => {
    const handleChangeField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);

        if (field === 'folder_type') {
            if (parseInt(props.fields.folder_type) === 2) {
                props.setField('pocket_style', 4);
                props.setField('card_slits', 1);
            } else {
                props.setField('pocket_style', 1);
                props.setField('card_slits', 1);
            }
        } else if (field === 'pocket_style') {
            props.setField('card_slits', 1);
        }

        if (!['project_name', 'purchase_order'].includes(field)) {
            props.calculatePrice();
        }
    };

    let viewTurnaroundButton = (
        <div className="view-button" onClick={props.setViewTurnaround.bind(this, true)}>View more <i className="fa-solid fa-angle-down"></i></div>
    );
    if (props.viewTurnaround) {
        viewTurnaroundButton = (
            <div className="view-button" onClick={props.setViewTurnaround.bind(this, false)}>View less <i className="fa-solid fa-angle-up"></i></div>
        );
    }

    const renderTurnaroundTable = () => {
        if (!props.viewTurnaround) {
            return null;
        }

        return (
            <Fragment>
                <p>
                    Turnaround time begins when the proof is approved. All times are based on normal business days
                    (Monday through Friday) excluding federal and state holidays. For all orders approved after 9:00 AM,
                    turnaround time will start the following business day. Please note that this does not include any
                    carrier's transit time.
                </p>
                <p>
                    For any questions or assistance, please call us at toll free at 1-800-386-3054.
                </p>
                <div className="image same-day-turnaround">
                    <img src="https://overnightcolor.s3.amazonaws.com/css/turnaround-same-day.png" alt="Turnaround Same Day" />
                </div>
                <p>
                    Same Day Order with "NO PROOF REQUIRED / RUN AS IS": All orders approved before 9:00 AM will ship at the
                    close of the same business day. All orders approved after 9:00 AM, turnaround time will start the following business day.
                </p>
                <div className="image">
                    <img src="https://overnightcolor.s3.amazonaws.com/css/turnaround-one-day.png" alt="Turnaround One Day" />
                </div>
                <p>
                    One Day Order with "NO PROOF REQUIRED / RUN AS IS": All orders approved before 9:00 AM will ship at the close of
                    the next business day. All orders approved after 9:00 AM, turnaround time will start the following business day.
                </p>
                <div className="image">
                    <img src="https://overnightcolor.s3.amazonaws.com/css/turnaround-seven-days.png" alt="Turnaround Seven Days" />
                </div>
                <p>
                    STANDARD TURNAROUND <br />
                    Standard turnaround time on all orders is 7 business days. However, if you need an order sooner, we provide quicker
                    turnaround times for your convenience.
                </p>
                <p>
                    Seven Day Order with "Online Electronic PDF Proof": All orders approved before 9:00 AM will ship at the close
                    of the seventh business day. All orders approved after 9:00 AM, turnaround time will begin the following business
                    day. This is considered standard production time without any additional cost.
                </p>
                <div className="image">
                    <img src="https://overnightcolor.s3.amazonaws.com/css/turnaround-five-days.png" alt="Turnaround Five Days" />
                </div>
                <p>
                    Five Day Order with "Online Electronic PDF Proof": All orders approved before 9:00 AM will ship at the close of the
                    fifth business day. All orders approved after 9:00 AM, turnaround time will start the following business day.
                </p>
                <div className="image">
                    <img src="https://overnightcolor.s3.amazonaws.com/css/turnaround-three-days.png" alt="Turnaround Three Days" />
                </div>
                <p>
                    Three Day Order with "Online Electronic PDF Proof": All orders approved before 9:00 AM will ship at the close of
                    the third business day. All orders approved after 9:00 AM, turnaround time will start the following business day.
                </p>
                <div className="image">
                    <img src="https://overnightcolor.s3.amazonaws.com/css/turnaround-two-days.png" alt="Turnaround Two Days" />
                </div>
                <p>
                    Two Day Order with "Online Electronic PDF Proof": All orders approved before 9:00 AM will ship at the close of
                    the second business day. All orders approved after 9:00 AM, turnaround time will start the following business day.
                </p>
            </Fragment>
        );
    };

    let shippingAddress = null;
    if (props.customer) {
        shippingAddress = props.customer.defaultShippingAddress;
    }

    let address = '';
    if (shippingAddress != null) {
        address = shippingAddress.address1;
        if (!empty(shippingAddress.address2)) {
            address += ' ' + shippingAddress.address2;
        }
        address += ', ' + shippingAddress.city + ', ' + shippingAddress.state + ' ' + shippingAddress.zip
    }

    const renderProductForm = () => {
        if (props.code === 'booklets') {
            return (
                <BookletsForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'bookmarks') {
            return (
                <BookmarksForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'brochures') {
            return (
                <BrochuresForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'business-cards') {
            return (
                <BusinessCardsForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'catalogs') {
            return (
                <CatalogsForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'door-hangers') {
            return (
                <DoorHangersForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'folded-cards') {
            return (
                <FoldedCardsForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'flyers') {
            return (
                <FlyersForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'hang-tags') {
            return (
                <HangTagsForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'letterheads') {
            return (
                <LetterheadsForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'newsletters') {
            return (
                <NewslettersForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'pocket-folders') {
            return (
                <PocketFoldersForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'postcards') {
            return (
                <PostcardsForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'posters') {
            return (
                <PostersForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'rack-cards') {
            return (
                <RackCardsForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        } else if (props.code === 'table-tents') {
            return (
                <TableTentsForm
                    fields={props.fields}
                    printingType={props.printingType}
                    calculatingPrice={props.calculatingPrice}
                    handleChangeField={handleChangeField}
                />
            );
        }

        return null;
    };

    let addressContainer = null;
    if (!empty(props.user)) {
        addressContainer = (
            <div className="address-container">
                <div className="address-field name">
                    <div className="label">Name</div>
                    <div className="value">{shippingAddress == null ? null : shippingAddress.first_name + ' ' + shippingAddress.last_name}</div>
                </div>
                <div className="address-field address">
                    <div className="label">Address</div>
                    <div className="value">{address}</div>
                </div>
                <div className="address-field company">
                    <div className="label">Company</div>
                    <div className="value">{shippingAddress == null ? null : shippingAddress.company}</div>
                </div>
                <div className="address-field phone">
                    <div className="label">Phone</div>
                    <div className="value">{shippingAddress == null ? null : shippingAddress.phone}</div>
                </div>
            </div>
        );
    }

    let digitalLegend = '';
    if (props.printingType === 'digital') {
        digitalLegend = '(Digital)';
    }

    return (
        <div className="form-container">
            <div className="product-title">{props.product.product} {digitalLegend}</div>
            <div className="features-container">
                <div className="project-name">
                    <div className="label">Project Name <span className="required">*</span>:</div>
                    <input type="text" placeholder="Project Name" value={props.fields.project_name} onChange={handleChangeField.bind(this, 'project_name')} />
                </div>
                <div className="purchase-order">
                    <div className="label">Purchase Order:</div>
                    <input type="text" placeholder="Purchase Order" value={props.fields.purchase_order} onChange={handleChangeField.bind(this, 'purchase_order')} />
                </div>
            </div>
            {renderProductForm()}
            <div className="features-container">
                <div className="turnaround-proof-container">
                    <div className="field">
                        <div className="label">Turnaround:</div>
                        <div className="select-field">
                            <select value={props.fields.turnaround} onChange={handleChangeField.bind(this, 'turnaround')} disabled={props.calculatingPrice}>
                                <option value="7">7 Business Days</option>
                                <option value="5">5 Business Days</option>
                                <option value="3">3 Business Days</option>
                                <option value="2">2 Business Days</option>
                                <option value="1">Next Day</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field proof-field">
                        <div className="label">Proof:</div>
                        <div className="select-field">
                            <select value={props.fields.proof} onChange={handleChangeField.bind(this, 'proof')} disabled={props.calculatingPrice}>
                                <option value="1">Electronic Proof (Free)</option>
                                <option value="2">Next Day Overnight Proof</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                </div>
                <div className="shipping-container">
                    <div className="field">
                        <div className="label">Shipping Method:</div>
                        <div className="select-field">
                            <select value={props.fields.shipping_method} onChange={handleChangeField.bind(this, 'shipping_method')} disabled={props.calculatingPrice}>
                                <option value="4">Ground</option>
                                <option value="3">3 Business Day</option>
                                <option value="2">2 Business Day</option>
                                <option value="1">Next Day</option>
                                <option value="5">Local Pickup</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="turnaround-table">
                <div className="title">
                    <div>EXPLANATION OF TURNAROUND TIME:</div>
                    {viewTurnaroundButton}
                </div>
                {renderTurnaroundTable()}
            </div>
            <div className="samples-container">
                <input type="checkbox" checked={props.fields.send_samples} onChange={handleChangeField.bind(this, 'send_samples')} /> Send me samples of this job
            </div>
            {addressContainer}
            <div className="job-comments">
                <textarea placeholder="Job Comments" />
            </div>
        </div>
    );
}

export default Form;
