import { createSelector } from 'reselect';

export const getComponentState = state => state.forgotPassword;

export const getSending = createSelector(
    getComponentState,
    (state) => state.sending
);
export const getForm = createSelector(
    getComponentState,
    (state) => state.form
);
export const getSent = createSelector(
    getComponentState,
    (state) => state.sent
);