import React from 'react';
import Pagination from './../../Pagination';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { dateToTimezone } from './../../../utils';

const Table = (props) => {
    if (props.actionType !== 'list') {
        return null;
    }

    const handleArtworksModal = (id) => {
        props.setIdModal(id);
        props.showArtworksModal();
    };

    const handleDeleteModal = (id) => {
        props.setIdModal(id);
        props.showDeleteModal();
    };

    const renderElements = () => {
        if (props.fetchingElements) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.items.length === 0) {
            return (
                <tr>
                    <td colSpan="10">No results</td>
                </tr>
            );
        }

        return props.items.map((item) => {
            let detailsIcon = (
                <div className="action-icon">
                    <Tooltip title="Job Details" position="bottom" arrow size="small" className="action-tooltip">
                        <Link to={'/admin/orders/details/' + item.order_id}><i className="fa-solid fa-rectangle-list"></i></Link>
                    </Tooltip>
                </div>
            );

            let artworksClass = 'fa-solid fa-images artworks-uploaded';
            if (item.artworks.length === 0) {
                artworksClass = 'fa-solid fa-images no-artworks';
            }

            let artworksIcon = (
                <div className="action-icon">
                    <Tooltip title="Artwork" position="bottom" arrow size="small" className="action-tooltip">
                        <i className={artworksClass} onClick={handleArtworksModal.bind(this, item.id)}></i>
                    </Tooltip>
                </div>
            );

            let statusDiv = <div>{item.job_status_name}</div>;
            if (parseInt(item.job_status_id) === 1) {
                statusDiv = <div className="no-artworks">{item.job_status_name}</div>;
            }

            let deleteIcon = (
                <div className="action-icon">
                    <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fa-solid fa-trash" onClick={handleDeleteModal.bind(this, item.id)}></i>
                    </Tooltip>
                </div>
            );

            return (
                <tr>
                    <td className="id-column desktop-column">{item.order_id}</td>
                    <td className="order-id-column main-column">
                        <div className="desktop-column">{item.id}</div>
                        <div className="mobile-container">
                            <div className="mobile-item">
                                <div className="label">Order #:</div>
                                <div className="value">{item.order_id}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Production #:</div>
                                <div className="value">{item.id}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Customer:</div>
                                <div className="value">{item.customer_name}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Ordered at:</div>
                                <div className="value">{dateToTimezone(item.ordered_date, 'MM/DD/YYYY')}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Status:</div>
                                <div className="value">{statusDiv}</div>
                            </div>
                            <div className="mobile-actions">
                                {detailsIcon}
                                {artworksIcon}
                                {deleteIcon}
                            </div>
                        </div>
                    </td>
                    <td className="name-column desktop-column">{item.name}</td>
                    <td className="customer-column desktop-column">{item.customer_name}</td>
                    <td className="ordered-at-column desktop-column">{dateToTimezone(item.ordered_date, 'MM/DD/YYYY')}</td>
                    <td className="status-column desktop-column">{statusDiv}</td>
                    <td className="actions-column desktop-column">
                        <div className="actions-container">
                            {detailsIcon}
                            {artworksIcon}
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const handleSort = (column, type) => {
        props.setSort(column, type, props.type);
    };

    const renderHeader = (label, field) => {
        let sortType = 'asc';
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === field) {
            if (props.sort.type === 'desc') {
                sortIcon = <i className="fas fa-sort-down" />;
                sortType = 'asc';
            } else {
                sortIcon = <i className="fas fa-sort-up" />;
                sortType = 'desc';
            }
        }

        return <div className="sort-item" onClick={handleSort.bind(this, field, sortType)}>{label} {sortIcon}</div>
    };

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr className="header-row">
                        <th className="order-id-column desktop-column">{renderHeader('Order #', 'order_id')}</th>
                        <th className="id-column desktop-column">{renderHeader('Production #', 'id')}</th>
                        <th className="name-column desktop-column">{renderHeader('Name', 'name')}</th>
                        <th className="customer-column desktop-column">{renderHeader('Customer', 'customer_name')}</th>
                        <th className="ordered-at-column desktop-column">{renderHeader('Ordered at', 'created_at')}</th>
                        <th className="status-column desktop-column">Status</th>
                        <th className="actions-column desktop-column"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderElements()}
                </tbody>
            </table>
            <Pagination
                pagination={props.pagination}
                setActivePage={props.setActivePage}
            />
        </div>
    );
}

export default Table;
