import React from 'react';
import { Dialog } from './../../common/dialog';

const SendResetPasswordModal = React.memo((props) => {
    const hideHandler = () => {
        props.setIdModal(null);
        props.hideSendResetPasswordModal();
    };

    const sendResetPasswordHandler = () => {
        props.sendResetPassword();
    };

    let label = 'Send';
    if(props.sendingResetPassword) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={sendResetPasswordHandler} disabled={props.sendingResetPassword}>{label}</button>
    ];
    return (
        <Dialog
            name="send-reset-password-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="admin-send-reset-password-dialog"
        >
            <div className="admin-send-reset-password-modal-container">
                <h1>Send Reset Password Email?</h1>
                <p className="notice">Confirm that you want send an email with instructions to reset the password to the Customer ID <span>#{props.idModal}</span>.</p>
            </div>
        </Dialog >
    );
});

export default SendResetPasswordModal;
