import React, { Fragment } from 'react';
import { empty } from './../../../utils';
import { Dialog } from './../../common/dialog';

const ShippingAddressModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideShippingAddressModal();
    };

    const handleAddAddress = (event) => {
        event.preventDefault();
        props.setFields({
            first_name: '',
            last_name: '',
            company: '',
            address1: '',
            address2: '',
            zip: '',
            country_id: '',
            state: '',
            city: '',
            phone: '',
            fax: '',
            cellphone: '',
        });
    };

    const handleSetSelectedAddress = (event) => {
        props.setSelectedAddress(event.target.value);

        for (const address of props.shippingAddresses) {
            if (parseInt(event.target.value) === parseInt(address.id)) {
                props.setFields(address);
                break;
            }
        }
    };

    const handleSetField = (field, event) => {
        props.setField(field, event.target.value);
    };

    let fieldsDisabled = false;
    let defaultField = (
        <div className="field">
            <div className="label">Make Default Address <span className="required">*</span>:</div>
            <select value={props.fields.make_default} onChange={handleSetField.bind(this, 'make_default')} disabled={fieldsDisabled}>
                <option value="">Select option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
            </select>
        </div>
    );
    if (!empty(props.fields.id)) {
        fieldsDisabled = true;
        defaultField = null;
    }

    let content = (
        <Fragment>
            <h1>Change Shipping Address</h1>
            <div className="select-address">
                Select an address
                <div className="select-field">
                    <select value={props.selectedAddress} onChange={handleSetSelectedAddress}>
                        {props.shippingAddresses.map((address) => <option value={address.id}>
                            {address.first_name} {address.last_name} - {address.city}, {address.state} {address.zip}
                        </option>)}
                    </select>
                    <i className="fa-solid fa-angle-down"></i>
                </div>
                or <a href="/" onClick={handleAddAddress}>Add a New Address</a>
            </div>
            <div className="form">
                <div className="field">
                    <div className="label">First Name <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.first_name} onChange={handleSetField.bind(this, 'first_name')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Last Name <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.last_name} onChange={handleSetField.bind(this, 'last_name')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Company <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.company} onChange={handleSetField.bind(this, 'company')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Address 1 <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.address1} onChange={handleSetField.bind(this, 'address1')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Address 2:</div>
                    <input type="text" value={props.fields.address2} onChange={handleSetField.bind(this, 'address2')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Zip Code <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.zip} onChange={handleSetField.bind(this, 'zip')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Country <span className="required">*</span>:</div>
                    <select value={props.fields.country_id} onChange={handleSetField.bind(this, 'country_id')} disabled={fieldsDisabled}>
                        <option value="">Select option</option>
                        <option value="230">United States</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">State <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.state} onChange={handleSetField.bind(this, 'state')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">City <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.city} onChange={handleSetField.bind(this, 'city')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Phone <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.phone} onChange={handleSetField.bind(this, 'phone')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Fax:</div>
                    <input type="text" value={props.fields.fax} onChange={handleSetField.bind(this, 'fax')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Cellphone:</div>
                    <input type="text" value={props.fields.cellphone} onChange={handleSetField.bind(this, 'cellphone')} disabled={fieldsDisabled} />
                </div>
                {defaultField}
            </div>
        </Fragment>
    );
    if (props.fetchingShippingAddresses) {
        content = <Fragment><i className="fas fa-circle-notch fa-spin" /> Loading data...</Fragment>;
    }

    let saveAddressLabel = 'Save';
    if (props.savingShippingAddress) {
        saveAddressLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
        <button key="save-button" className="btn btn-light btn-sm save-button" onClick={props.saveShippingAddress} disabled={props.savingShippingAddress}>{saveAddressLabel}</button>,
    ];
    return (
        <Dialog
            name="frontend-cart-shipping-address-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="frontend-cart-shipping-address-dialog"
        >
            <div className="frontend-cart-shipping-address-modal-container">
                {content}
            </div>
        </Dialog >
    );
});

export default ShippingAddressModal;
