import Immutable from 'immutable';
import * as types from './MyOrderDetails.types';

const initialState = {
    order: null,
    fetchingOrder: false,
    history: null,
    idModal: null,
    typeModal: null,
    artworks: [],
    uploadingArtworks: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ORDER:
            return state.setIn(['fetchingOrder'], true).toJS();

        case types.FETCH_ORDER_SUCCESS:
            return state.setIn(['fetchingOrder'], false)
                .setIn(['order'], action.payload)
                .toJS();

        case types.FETCH_ORDER_ERROR:
            return state.setIn(['fetchingOrder'], false).toJS();

        case types.UPLOAD_ARTWORKS:
            return state.setIn(['uploadingArtworks'], true).toJS();

        case types.UPLOAD_ARTWORKS_SUCCESS:
            return state.setIn(['uploadingArtworks'], false)
                .setIn(['artworks'], [])
                .toJS();

        case types.UPLOAD_ARTWORKS_ERROR:
            return state.setIn(['uploadingArtworks'], false).toJS();

        case types.SET_ARTWORKS:
            return state.setIn(['artworks'], action.payload).toJS();

        case types.ADD_ARTWORK:
            return state.updateIn(['artworks'], (artworks) => {
                return artworks.push(action.payload);
            }).toJS();

        case types.REMOVE_ARTWORK:
            return state.updateIn(['artworks'], (artworks) => {
                return artworks.delete(action.payload);
            }).toJS();

        case types.CLEAR_ARTWORKS:
            return state.updateIn(['artworks'], (artworks) => {
                return artworks.clear();
            }).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.SET_ID_MODAL:
            return state.setIn(['idModal'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}