import React from 'react';

const CatalogsTemplates = React.memo((props) => {
    const templates = [
        '9x12_catalog',
        '9x6_catalog',
        'catalog-8.5inx11in_saddle_stitch_front',
        'catalog_5.5inx8.5in_saddle_stitch_front',
    ];

    return templates.map((template) => {
        return (
            <tr>
                <td>{template}</td>
                <td className="actions">
                    <a href={'https://overnightcolor.s3.amazonaws.com/templates/' + template + '.pdf'} target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-file-arrow-down"></i> Download
                    </a>
                </td>
            </tr>
        );
    });
});

export default CatalogsTemplates;
