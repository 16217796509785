import React from 'react';
import { Dialog } from './../../common/dialog';
import { Link } from 'react-router-dom';

const NoAddressModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideNoAddressModal();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
    ];
    return (
        <Dialog
            name="frontend-product-no-address-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="frontend-product-no-address-dialog"
        >
            <div className="frontend-product-no-address-modal-container">
                <h1>Important Information</h1>
                <p className="notice">
                    Dear customer you need to add your default Billing and Shipping address before to continue. Add them clicking on 
                    this link <Link to="/my-address-book">My Address Book</Link>
                </p>
            </div>
        </Dialog >
    );
});

export default NoAddressModal;
