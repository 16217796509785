export const FETCH_INFORMATION = 'MY_INFORMATION/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'MY_INFORMATION/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'MY_INFORMATION/FETCH_INFORMATION_ERROR';
export const SAVE_INFORMATION = 'MY_INFORMATION/SAVE_INFORMATION';
export const SAVE_INFORMATION_SUCCESS = 'MY_INFORMATION/SAVE_INFORMATION_SUCCESS';
export const SAVE_INFORMATION_ERROR = 'MY_INFORMATION/SAVE_INFORMATION_ERROR';
export const SAVE_PASSWORD = 'MY_INFORMATION/SAVE_PASSWORD';
export const SAVE_PASSWORD_SUCCESS = 'MY_INFORMATION/SAVE_PASSWORD_SUCCESS';
export const SAVE_PASSWORD_ERROR = 'MY_INFORMATION/SAVE_PASSWORD_ERROR';
export const SET_FIELD = 'MY_INFORMATION/SET_FIELD';
export const SET_FILE = 'MY_INFORMATION/SET_FILE';
export const SET_HISTORY = 'MY_INFORMATION/SET_HISTORY';
export const RESET_STATE = 'MY_INFORMATION/RESET_STATE';