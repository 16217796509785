import { createSelector } from 'reselect';

export const getComponentState = state => state.activateAccount;

export const getActivating = createSelector(
    getComponentState,
    (state) => state.activating
);
export const getActivated = createSelector(
    getComponentState,
    (state) => state.activated
);