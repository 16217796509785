import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import Summary from './Summary';
import ShippingAddressModal from './ShippingAddressModal';
import { stringifyEqualityCheck } from './../../utils';
import * as selectors from './ShoppingCart.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import * as actions from './ShoppingCart.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import './ShoppingCart.scss';
import { Redirect } from 'react-router-dom';
import * as dialog from './../common/dialog';

const ShoppingCart = (props) => {
    /* State to props */
    const items = useSelector(selectors.getItems, stringifyEqualityCheck);
    const fetchingItems = useSelector(selectors.getFetchingItems);
    const calculatingPrice = useSelector(selectors.getCalculatingPrice);
    const calculatingPriceIndex = useSelector(selectors.getCalculatingPriceIndex);
    const savingShippingAddress = useSelector(selectors.getSavingShippingAddress);
    const shippingAddresses = useSelector(selectors.getShippingAddresses, stringifyEqualityCheck);
    const fetchingShippingAddresses = useSelector(selectors.getFetchingShippingAddresses, stringifyEqualityCheck);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const selectedItem = useSelector(selectors.getSelectedItem, stringifyEqualityCheck);
    const selectedAddress = useSelector(selectors.getSelectedAddress, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchItems = useCallback(() => dispatch(actions.fetchItems()), [dispatch]);
    const saveCheckout = useCallback((index, id, value) => dispatch(actions.saveCheckout(index, id, value)), [dispatch]);
    const deleteItem = useCallback((id) => dispatch(actions.deleteItem(id)), [dispatch]);
    const calculatePrice = useCallback((index, id, value) => dispatch(actions.calculatePrice(index, id, value)), [dispatch]);
    const setSelectedItem = useCallback((item) => dispatch(actions.setSelectedItem(item)), [dispatch]);
    const setSelectedAddress = useCallback((value) => dispatch(actions.setSelectedAddress(value)), [dispatch]);
    const fetchShippingAddresses = useCallback(() => dispatch(actions.fetchShippingAddresses()), [dispatch]);
    const setFields = useCallback((value) => dispatch(actions.setFields(value)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const saveShippingAddress = useCallback(() => dispatch(actions.saveShippingAddress()), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);  
    const showShippingAddressModal = useCallback(() => dispatch(dialog.actions.showDialog('frontend-cart-shipping-address-modal')), [dispatch]);
    const hideShippingAddressModal = useCallback(() => dispatch(dialog.actions.hideDialog('frontend-cart-shipping-address-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);
        fetchItems();

        return () => {
            resetState();
        };
    }, [setHistory, resetState, fetchItems, props.history]);

    if (user == null) {
        return <Redirect to="/" />;
    }

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>Shopping Cart - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="shopping-cart-container">
                <div className="content-container">
                    <Table
                        items={items}
                        fetchingItems={fetchingItems}
                        calculatingPrice={calculatingPrice}
                        calculatingPriceIndex={calculatingPriceIndex}
                        saveCheckout={saveCheckout}
                        deleteItem={deleteItem}
                        calculatePrice={calculatePrice}
                        setSelectedItem={setSelectedItem}
                        showShippingAddressModal={showShippingAddressModal}
                        fetchShippingAddresses={fetchShippingAddresses}
                        setSelectedAddress={setSelectedAddress}
                    />
                    <Summary
                        history={props.history}
                        items={items}
                        calculatingPrice={calculatingPrice}
                    />
                </div>
                <ShippingAddressModal
                    shippingAddresses={shippingAddresses}
                    fields={fields}
                    fetchingShippingAddresses={fetchingShippingAddresses}
                    selectedItem={selectedItem}
                    selectedAddress={selectedAddress}
                    savingShippingAddress={savingShippingAddress}
                    hideShippingAddressModal={hideShippingAddressModal}
                    setSelectedAddress={setSelectedAddress}
                    setField={setField}
                    setFields={setFields}
                    saveShippingAddress={saveShippingAddress}
                />
            </div>
        </Layout>
    );
};

export default ShoppingCart;
