import React from 'react';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

const Form = (props) => {
    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false,
        accept: 'image/*',
        onDrop: (files) => {
            props.setFile(files[0]);
        }
    });

    if (props.actionType === 'list') {
        return null;
    }

    if (props.fetchingElement) {
        return (
            <div className="form-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    let selectedFileContainer = null;
    if (props.file && props.file != null) {
        selectedFileContainer = (
            <div className="selected-file-container">
                <strong>Selected file:</strong> {props.file.name}
            </div>
        );
    }

    let previewImage = null;
    if (props.item.file_url != null) {
        previewImage = (
            <div className="preview-image">
                <div>Current image:</div>
                <img src={props.item.file_url} alt={props.item.name} />
            </div>
        );
    }

    return (
        <div className="form-container">
            <div className="fields">
                <div className="field">
                    <div className="label">Name <span className="required">*</span>:</div>
                    <input type="text" value={props.item.name} onChange={handleSetField.bind(this, 'name')} placeholder="Name" />
                </div>
                <div className="field">
                    <div className="label">Points <span className="required">*</span>:</div>
                    <input type="number" value={props.item.points} onChange={handleSetField.bind(this, 'points')} placeholder="Points" />
                </div>
                <div className="field">
                    <div className="label">Active <span className="required">*</span>:</div>
                    <select value={props.item.active} onChange={handleSetField.bind(this, 'active')}>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                {/* <div className="field description-field">
                    <div className="label">Description <span className="required">*</span>:</div>
                    <textarea value={props.item.description} onChange={handleSetField.bind(this, 'description')} />
                </div> */}
                <div className="field">
                    <div className="label">Image:</div>
                    <div {...getRootProps({ className: 'dropzone-container' })}>
                        <input {...getInputProps()} />
                        <button className="browse-files-button admin-button">Select File</button>
                    </div>
                    {selectedFileContainer}
                    {previewImage}
                </div>
            </div>
            <div className="buttons-container">
                <div className="button-container">
                    <Link to="/admin/prizes">
                        <button className="cancel-button">Cancel</button>
                    </Link>
                </div>
                <div className="button-container">
                    <button className="save-button admin-button" onClick={props.saveElement} disabled={props.saving}>
                        {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Form;
