import { createSelector } from 'reselect';

export const getComponentState = state => state.loyaltyPoints;

export const getPrizes = createSelector(
    getComponentState,
    (state) => state.prizes
);
export const getFetchingPrizes = createSelector(
    getComponentState,
    (state) => state.fetchingPrizes
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getSearch = createSelector(
    getComponentState,
    (state) => state.search
);
export const getSearchType = createSelector(
    getComponentState,
    (state) => state.searchType
);
export const getIdModal = createSelector(
    getComponentState,
    (state) => state.idModal
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getGettingPrize = createSelector(
    getComponentState,
    (state) => state.gettingPrize
);
