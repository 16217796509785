import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Form = (props) => {
    const setFieldHandler = event => {
        props.setField(event.target.name, event.target.value);
    }

    const keyPressHandler = (event) => {
        if (event.key === 'Enter') {
            props.register();
        }
    }

    const handleSignIn = () => {
        props.register();
    }

    let container = (
        <Fragment>
            <div className="title">Get professional results for all your printing projects with OvernightColor.</div>
            <div className="form-container">
                <div className="subtitle">Sign up</div>
                <div className="info">By signing up, I agree to the OvernightColor Privacy Policy and Terms of Service.</div>
                <div className="fields">
                    <div className="field">
                        {/* <div className="label">First Name</div> */}
                        <input type="text" name="first_name" value={props.form.first_name} onChange={setFieldHandler} onKeyPress={keyPressHandler} placeholder="First Name" />
                    </div>
                    <div className="field">
                        {/* <div className="label">Last Name</div> */}
                        <input type="text" name="last_name" value={props.form.last_name} onChange={setFieldHandler} onKeyPress={keyPressHandler} placeholder="Last Name" />
                    </div>
                    <div className="field">
                        {/* <div className="label">Email Address</div> */}
                        <input type="text" name="email" value={props.form.email} onChange={setFieldHandler} onKeyPress={keyPressHandler} placeholder="Email" />
                    </div>
                    <div className="field">
                        {/* <div className="label">Password</div> */}
                        <input type="password" name="password" value={props.form.password} onChange={setFieldHandler} onKeyPress={keyPressHandler} placeholder="Password" />
                    </div>
                    <div className="field">
                        {/* <div className="label">Confirm Password</div> */}
                        <input type="password" name="confirm_password" value={props.form.confirm_password} onChange={setFieldHandler} onKeyPress={keyPressHandler} placeholder="Confirm Password" />
                    </div>
                    <div className="button-container">
                        <button type="button" onClick={handleSignIn} disabled={props.registering}>{props.registering ? <i className="fas fa-circle-notch fa-spin"></i> : 'SIGN UP'}</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
    if (props.signedUp) {
        container = (
            <div className="form-container">
                <div className="subtitle">Thank you for signing up!</div>
                <div className="info">An email has been sent to your email address with instructions to activate your account.</div>
            </div>
        );
    }

    return (
        <div className="login-form-container">
            <div className="logo-container">
                <Link to="/">
                    <img src="https://overnightcolor.s3.amazonaws.com/css/logo.png" alt="OvernightColor" />
                </Link>
            </div>
            {container}
            <div className="support">
                <div className="phone">832-608-6400</div>
                <div className="email">info@overnightcolor.com</div>
            </div>
            <div className="copyright-container">
                <div className="copyright">COPYRIGHT © 2024 OVERNIGHTCOLOR.COM®, LLC ALL RIGHTS RESERVED</div>
                <div className="menu">
                    <div className="menu-items">
                        <Link to="/company/terms-conditions">TERMS</Link>
                        <div className="divider">|</div>
                        <Link to="/company/privacy-policy">PRIVACY</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;
