import { createSelector } from 'reselect';

export const getComponentState = state => state.myOrders;

export const getOrders = createSelector(
    getComponentState,
    (state) => state.orders
);
export const getFetchingOrders = createSelector(
    getComponentState,
    (state) => state.fetchingOrders
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getSearch = createSelector(
    getComponentState,
    (state) => state.search
);
export const getSearchType = createSelector(
    getComponentState,
    (state) => state.searchType
);
export const getArtworks = createSelector(
    getComponentState,
    (state) => state.artworks
);
export const getUploadingArtworks = createSelector(
    getComponentState,
    (state) => state.uploadingArtworks
);
export const getIdModal = createSelector(
    getComponentState,
    (state) => state.idModal
);
