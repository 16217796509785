import React from 'react';

const Description = (props) => {
    return (
        <div className="description-container content-container">
            <h2>
                Got questions? We've got answers! Our frequently asked questions (FAQ) section is designed to provide you with quick and easy access
                to the most common questions we receive.
            </h2>
            <div className="description">
                We understand that choosing a printing service provider can be overwhelming, and we want to make the process as easy as possible for you.
                That's why we've put together a list of the most commonly asked questions about our printing services. Whether you're wondering about
                file formats, turnaround times, or pricing, we've got you covered. Take a look at our FAQ below, and if you still have questions, don't
                hesitate to contact us. We're always here to help you achieve the best printing results.
            </div>
        </div>
    );
}

export default Description;
