import React from 'react';
import { useDropzone } from 'react-dropzone';
import { empty } from './../../../utils';

const Information = (props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false,
        onDrop: (files) => {
            props.setFile(files[0]);
        }
    });

    if (props.fetchingInformation || props.information == null) {
        return (
            <div className="information-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleSetField = (field, event) => {
        props.setField(field, event.target.value);
    };

    let selectedFile = null;
    if (props.file) {
        selectedFile = <div>Selected File: {props.file.name}</div>;
    }

    let logo = null;
    if (!empty(props.information.reseller_logo_file_url)) {
        logo = <div className="logo-image"><img src={props.information.reseller_logo_file_url} alt="Logo" /></div>
    }

    return (
        <div className="information-container">
            <div className="title">My Account Information</div>
            <div className="information-fields">
                <div className="subtitle">Name</div>
                <div className="field">
                    <div className="label">First Name:</div>
                    <input type="text" value={props.information.first_name} onChange={handleSetField.bind(this, 'first_name')} />
                </div>
                <div className="field">
                    <div className="label">Last Name:</div>
                    <input type="text" value={props.information.last_name} onChange={handleSetField.bind(this, 'last_name')} />
                </div>
                <div className="field">
                    <div className="label">Company:</div>
                    <input type="text" value={props.information.company} onChange={handleSetField.bind(this, 'company')} />
                </div>
                <div className="field"></div>
                <div className="field">
                    <div className="label">Reseller Logo (Recommended size: 280x178 px):</div>
                    <div className="file-button">
                        <div {...getRootProps({ className: 'dropzone-container' })}>
                            <input {...getInputProps()} />
                            <button>Choose File</button>
                        </div>
                    </div>
                    {selectedFile}
                    {logo}
                </div>
                <div className="button-container">
                    <button onClick={props.saveInformation} disabled={props.savingInformation}>
                        {props.savingInformation ? <i className="fas fa-circle-notch fa-spin" /> : 'SAVE'}
                    </button>
                </div>
            </div>
            <div className="password-fields">
                <div className="subtitle">Change Password</div>
                <div className="field">
                    <div className="label">Old Password:</div>
                    <input type="password" value={props.information.old_password} onChange={handleSetField.bind(this, 'old_password')} />
                </div>
                <div className="field">
                    <div className="label">New Password:</div>
                    <input type="password" value={props.information.new_password} onChange={handleSetField.bind(this, 'new_password')} />
                </div>
                <div className="field">
                    <div className="label">Confirm New Password:</div>
                    <input type="password" value={props.information.confirm_password} onChange={handleSetField.bind(this, 'confirm_password')} />
                </div>
                <div className="button-container">
                    <button onClick={props.savePassword} disabled={props.savingPassword}>
                        {props.savingPassword ? <i className="fas fa-circle-notch fa-spin" /> : 'CHANGE'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Information;
