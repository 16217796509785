import Immutable from 'immutable';
import * as types from './MyOrders.types';

const initialState = {
    orders: [],
    fetchingOrders: false,
    history: null,
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 15,
    },
    sort: {
        column: 'id',
        type: 'desc',
    },
    search: '',
    searchType: 'id',
    artworks: [],
    uploadingArtworks: false,
    idModal: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ORDERS:
            return state.setIn(['fetchingOrders'], true).toJS();

        case types.FETCH_ORDERS_SUCCESS:
            return state.setIn(['fetchingOrders'], false)
                .setIn(['orders'], action.payload.elements)
                .setIn(['pagination', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_ORDERS_ERROR:
            return state.setIn(['fetchingOrders'], false).toJS();

        case types.UPLOAD_ARTWORKS:
            return state.setIn(['uploadingArtworks'], true).toJS();

        case types.UPLOAD_ARTWORKS_SUCCESS:
            return state.setIn(['uploadingArtworks'], false)
                .setIn(['artworks'], [])
                .toJS();

        case types.UPLOAD_ARTWORKS_ERROR:
            return state.setIn(['uploadingArtworks'], false).toJS();

        case types.SET_ID_MODAL:
            return state.setIn(['idModal'], action.payload).toJS();

        case types.SET_ARTWORKS:
            return state.setIn(['artworks'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        case types.SET_SORT:
            return state.setIn(['sort', 'type'], action.payload.type)
                .setIn(['sort', 'column'], action.payload.column)
                .toJS();

        case types.ADD_ARTWORK:
            return state.updateIn(['artworks'], (artworks) => {
                return artworks.push(action.payload);
            }).toJS();

        case types.REMOVE_ARTWORK:
            return state.updateIn(['artworks'], (artworks) => {
                return artworks.delete(action.payload);
            }).toJS();

        case types.CLEAR_ARTWORKS:
            return state.updateIn(['artworks'], (artworks) => {
                return artworks.clear();
            }).toJS();

        case types.SET_SEARCH:
            return state.setIn(['search'], action.payload).toJS();

        case types.SET_SEARCH_TYPE:
            return state.setIn(['searchType'], action.payload).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}