import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import MyAccountMenu from './../MyAccountMenu';
import { stringifyEqualityCheck } from './../../utils';
import * as selectors from './MyAddressBook.selectors';
import * as actions from './MyAddressBook.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import './MyAddressBook.scss';

const MyAddressBook = (props) => {
    /* State to props */
    const billingAddresses = useSelector(selectors.getBillingAddresses, stringifyEqualityCheck);
    const fetchingBillingAddresses = useSelector(selectors.getFetchingBillingAddresses);
    const shippingAddresses = useSelector(selectors.getShippingAddresses, stringifyEqualityCheck);
    const fetchingShippingAddresses = useSelector(selectors.getFetchingShippingAddresses);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchBillingAddresses = useCallback(() => dispatch(actions.fetchBillingAddresses()), [dispatch]);
    const fetchShippingAddresses = useCallback(() => dispatch(actions.fetchShippingAddresses()), [dispatch]);
    const setActivePage = useCallback((type, value) => dispatch(actions.setActivePage(type, value)), [dispatch]);
    const setSort = useCallback((type, sortColumn, sortType) => dispatch(actions.setSort(type, sortColumn, sortType)), [dispatch]);
    const setAsDefault = useCallback((id, type) => dispatch(actions.setAsDefault(id, type)), [dispatch]);
    const deleteAddress = useCallback((id, type) => dispatch(actions.deleteAddress(id, type)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);
        fetchBillingAddresses();
        fetchShippingAddresses();

        return () => {
            resetState();
        };
    }, [fetchBillingAddresses, fetchShippingAddresses, setHistory, resetState, props.history]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>My Address Book - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="my-address-book-container">
                <div className="content-container">
                    <MyAccountMenu
                        history={props.history}
                    />
                    <Table
                        history={props.history}
                        billingAddresses={billingAddresses}
                        shippingAddresses={shippingAddresses}
                        fetchingBillingAddresses={fetchingBillingAddresses}
                        fetchingShippingAddresses={fetchingShippingAddresses}
                        pagination={pagination}
                        sort={sort}
                        fetchBillingAddresses={fetchBillingAddresses}
                        fetchShippingAddresses={fetchShippingAddresses}
                        setActivePage={setActivePage}
                        setSort={setSort}
                        setAsDefault={setAsDefault}
                        deleteAddress={deleteAddress}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default MyAddressBook;
