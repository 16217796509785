export const FETCH_ORDERS = 'MY_ORDERS/FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'MY_ORDERS/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'MY_ORDERS/FETCH_ORDERS_ERROR';
export const UPLOAD_ARTWORKS = 'MY_ORDERS/UPLOAD_ARTWORKS';
export const UPLOAD_ARTWORKS_SUCCESS = 'MY_ORDERS/UPLOAD_ARTWORKS_SUCCESS';
export const UPLOAD_ARTWORKS_ERROR = 'MY_ORDERS/UPLOAD_ARTWORKS_ERROR';
export const DELETE_ARTWORK = 'MY_ORDERS/DELETE_ARTWORK';
export const DELETE_ARTWORK_SUCCESS = 'MY_ORDERS/DELETE_ARTWORK_SUCCESS';
export const DELETE_ARTWORK_ERROR = 'MY_ORDERS/DELETE_ARTWORK_ERROR';
export const ADD_ARTWORK = 'MY_ORDERS/ADD_ARTWORK';
export const REMOVE_ARTWORK = 'MY_ORDERS/REMOVE_ARTWORK';
export const CLEAR_ARTWORKS = 'MY_ORDERS/CLEAR_ARTWORKS';
export const SET_ARTWORKS = 'MY_ORDERS/SET_ARTWORKS';
export const SET_ACTIVE_PAGE = 'MY_ORDERS/SET_ACTIVE_PAGE';
export const SET_SORT = 'MY_ORDERS/SET_SORT';
export const SET_SEARCH = 'MY_ORDERS/SET_SEARCH';
export const SET_SEARCH_TYPE = 'MY_ORDERS/SET_SEARCH_TYPE';
export const SET_ID_MODAL = 'MY_ORDERS/SET_ID_MODAL';
export const SET_HISTORY = 'MY_ORDERS/SET_HISTORY';
export const RESET_STATE = 'MY_ORDERS/RESET_STATE';