export const FETCH_JOBS = 'MY_JOBS/FETCH_JOBS';
export const FETCH_JOBS_SUCCESS = 'MY_JOBS/FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_ERROR = 'MY_JOBS/FETCH_JOBS_ERROR';
export const UPLOAD_ARTWORKS = 'MY_JOBS/UPLOAD_ARTWORKS';
export const UPLOAD_ARTWORKS_SUCCESS = 'MY_JOBS/UPLOAD_ARTWORKS_SUCCESS';
export const UPLOAD_ARTWORKS_ERROR = 'MY_JOBS/UPLOAD_ARTWORKS_ERROR';
export const DELETE_ARTWORK = 'MY_JOBS/DELETE_ARTWORK';
export const DELETE_ARTWORK_SUCCESS = 'MY_JOBS/DELETE_ARTWORK_SUCCESS';
export const DELETE_ARTWORK_ERROR = 'MY_JOBS/DELETE_ARTWORK_ERROR';
export const CHECK_MISSING_ARTWORKS = 'MY_JOBS/CHECK_MISSING_ARTWORKS';
export const CHECK_MISSING_ARTWORKS_SUCCESS = 'MY_JOBS/CHECK_MISSING_ARTWORKS_SUCCESS';
export const CHECK_MISSING_ARTWORKS_ERROR = 'MY_JOBS/CHECK_MISSING_ARTWORKS_ERROR';
export const ADD_ARTWORK = 'MY_JOBS/ADD_ARTWORK';
export const REMOVE_ARTWORK = 'MY_JOBS/REMOVE_ARTWORK';
export const CLEAR_ARTWORKS = 'MY_JOBS/CLEAR_ARTWORKS';
export const SET_ARTWORKS = 'MY_JOBS/SET_ARTWORKS';
export const SET_ACTIVE_PAGE = 'MY_JOBS/SET_ACTIVE_PAGE';
export const SET_SORT = 'MY_JOBS/SET_SORT';
export const SET_SEARCH = 'MY_JOBS/SET_SEARCH';
export const SET_SEARCH_TYPE = 'MY_JOBS/SET_SEARCH_TYPE';
export const SET_ID_MODAL = 'MY_JOBS/SET_ID_MODAL';
export const SET_HISTORY = 'MY_JOBS/SET_HISTORY';
export const RESET_STATE = 'MY_JOBS/RESET_STATE';