import React from 'react';

const Information = (props) => {
    const handleEcoFriendly = () => {
        props.history.push('/company/environmental-statement');
    };

    const handleRequestQuote = () => {
        props.history.push('/company/custom-quote');
    };

    return (
        <div className="information-container content-container">
            <h1>
                Below is a list of business partnerships held with OvernightColor.com. Please take time to visit
                these websites that focus on specific printing needs and specialized print services.
            </h1>
            <div className="partners">
                <div className="partner">
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/print-directory.jpg" alt="Print" />
                    </div>
                    <div className="description-container">
                        <div className="title">PRINT DIRECTORY</div>
                        <div className="description">
                            Directory of commercial printers, book printers, printing presses, digital presses, production and art printers.
                        </div>
                    </div>
                </div>
            </div>
            <div className="messages-container">
                <div className="message-main-container eco-friendly">
                    <div className="message-container">
                        <div className="title">ECO-FRIENDLY PRINTING</div>
                        <div className="message">
                            OvernightColor.com has taken the initiative and grown into an environmentally friendly company by taking significant
                            steps in our production of printed products.
                        </div>
                        <button onClick={handleEcoFriendly}>READ MORE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/eco-friendly.png" alt="Eco Friendly" />
                    </div>
                </div>
                <div className="message-main-container request-quote">
                    <div className="message-container">
                        <div className="title">REQUEST PRINT QUOTE</div>
                        <div className="message">
                            Request a quote for any print project by using our user-friendly online form. Submit the details of your print request
                            and we will provide you with an instant quote.
                        </div>
                        <button onClick={handleRequestQuote}>REQUEST A QUOTE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/request-quote.png" alt="Request Quote" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Information;
