export const FETCH_PRIZES = 'LOYALTY_POINTS/FETCH_PRIZES';
export const FETCH_PRIZES_SUCCESS = 'LOYALTY_POINTS/FETCH_PRIZES_SUCCESS';
export const FETCH_PRIZES_ERROR = 'LOYALTY_POINTS/FETCH_PRIZES_ERROR';
export const FETCH_INFORMATION = 'LOYALTY_POINTS/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'LOYALTY_POINTS/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'LOYALTY_POINTS/FETCH_INFORMATION_ERROR';
export const GET_PRIZE = 'LOYALTY_POINTS/GET_PRIZE';
export const GET_PRIZE_SUCCESS = 'LOYALTY_POINTS/GET_PRIZE_SUCCESS';
export const GET_PRIZE_ERROR = 'LOYALTY_POINTS/GET_PRIZE_ERROR';
export const SET_ACTIVE_PAGE = 'LOYALTY_POINTS/SET_ACTIVE_PAGE';
export const SET_SORT = 'LOYALTY_POINTS/SET_SORT';
export const SET_SEARCH = 'LOYALTY_POINTS/SET_SEARCH';
export const SET_SEARCH_TYPE = 'LOYALTY_POINTS/SET_SEARCH_TYPE';
export const SET_ID_MODAL = 'LOYALTY_POINTS/SET_ID_MODAL';
export const SET_HISTORY = 'LOYALTY_POINTS/SET_HISTORY';
export const RESET_STATE = 'LOYALTY_POINTS/RESET_STATE';