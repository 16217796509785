import React from 'react';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { dateToTimezone } from './../../../utils';

const Details = (props) => {
    if (props.fetchingOrder || props.order == null) {
        return (
            <div className="form-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleShowArtworksModal = (id) => {
        let job = null;
        for (let i = 0; i < props.order.jobs.length; i++) {
            if (parseInt(props.order.jobs[i].id) === parseInt(id)) {
                job = props.order.jobs[i];
                break;
            }
        }

        props.setArtworks(job.artworks);
        props.setIdModal(id);
        props.showArtworksModal();
    };

    const renderFeatures = (job) => {
        const features = JSON.parse(job.features_information);
        return features.map((feature) => {
            return (
                <div className="feature">
                    <div className="feature-name"><i className="fa-solid fa-circle"></i> {feature.feature}</div>
                    <div className="option-name">{feature.option}</div>
                </div>
            );
        });
    };

    const renderItems = () => {
        return props.order.jobs.map((job, index) => {
            let artworksButton = (
                <div>
                    <button className="artworks-button" onClick={handleShowArtworksModal.bind(this, job.id)}>
                        Artwork
                    </button>
                </div>
            );

            const productInformation = JSON.parse(job.product_information);

            let discount = 0;
            let discountRow = null;
            if (parseInt(props.order.is_first_order)) {
                discount = job.price * 0.2;
            } else {
                discount = job.coupons_discount;
            }

            if (discount > 0) {
                discountRow = (
                    <div className="price-row discount-row">
                        <div className="label">Discount:</div>
                        <div className="value">-{numeral(discount).format('$0,0.00')}</div>
                    </div>
                );
            }

            let totalPrice = parseFloat(job.price) + parseFloat(job.shipping_price) + parseFloat(job.tax) - discount;

            const shippingInformation = JSON.parse(job.shipping_information);

            let noArtworksNotice = null;
            let jobStatusClass = 'status-label';
            if (parseInt(job.job_status_id) === 1) {
                jobStatusClass = 'status-label no-artworks';
                noArtworksNotice = <div className="notice">Attention:  No artwork has been uploaded yet</div>;
            }

            return (
                <div className="item">
                    <div className="row1">
                        <div className="name-container">
                            <div class="name">{job.name}</div>
                            <div className="product-name"><b>{productInformation.product}</b></div>
                            <div className="job-number"><b>Job #:</b> {job.id}</div>
                            <div className="status-field">
                                <span className={jobStatusClass}><b>Status:</b> {job.job_status_name}</span>
                                {noArtworksNotice}
                            </div>
                            <div className="buttons-container">
                                {artworksButton}
                            </div>
                        </div>
                        <div className="image-container">
                            <div className="image">
                                <img src={job.product_image_url} alt="Product" />
                            </div>
                        </div>
                    </div>
                    <div className="row2">
                        <div className="printing-container">
                            <div className="subtitle"><strong>Printing Options:</strong></div>
                            <div className="features">
                                {renderFeatures(job)}
                            </div>
                        </div>
                        <div className="shipping-container">
                            <div className="subtitle"><strong>Shipping Address:</strong></div>
                            <div className="address-line shipping-method">
                                <span className="label">Shipping Method:</span> {job.shipping_method_name}
                            </div>
                            <div className="address-line">
                                <span className="label">Name:</span> {shippingInformation.first_name} {shippingInformation.last_name}
                            </div>
                            <div className="address-line">
                                <span className="label">Address:</span> {shippingInformation.address1} {shippingInformation.address2}
                            </div>
                            <div className="address-line">
                                <span className="label">City:</span> {shippingInformation.city}
                            </div>
                            <div className="address-line">
                                <span className="label">State:</span> {shippingInformation.state}
                            </div>
                            <div className="address-line">
                                <span className="label">Zip:</span> {shippingInformation.zip}
                            </div>
                            <div className="address-line">
                                <span className="label">Phone:</span> {shippingInformation.phone}
                            </div>
                            <div className="address-line">
                                <span className="label">Cellphone:</span> {shippingInformation.cellphone}
                            </div>
                            <div className="address-line">
                                <span className="label">Country:</span> {shippingInformation.country}
                            </div>
                        </div>
                        <div className="totals-container">
                            <div className="price-row quantity-row">
                                <div className="label">Quantity:</div>
                                <div className="value">{numeral(job.quantity).format('0,0')}</div>
                            </div>
                            <div className="price-row printing-price-row">
                                <div className="label">Printing:</div>
                                <div className="value">
                                    <div>{numeral(job.price).format('$0,0.00')}</div>
                                    <div className="each-price">(Each: {numeral(job.price / job.quantity).format('$0,0.00[00]')})</div>
                                </div>
                            </div>
                            {discountRow}
                            <div className="price-row shipping-price-row">
                                <div className="label">Shipping:</div>
                                <div className="value">{numeral(job.shipping_price).format('$0,0.00')}</div>
                            </div>
                            <div className="price-row tax-price-row">
                                <div className="label">Tax:</div>
                                <div className="value">{numeral(job.tax).format('$0,0.00')}</div>
                            </div>
                            <div className="price-row total-price-row">
                                <div className="label">Total:</div>
                                <div className="value">{numeral(totalPrice).format('$0,0.00')}</div>
                            </div>
                            <div className="price-row points-row">
                                <div className="label">Points:</div>
                                <div className="value">{numeral(Math.round(job.total_points)).format('0,0')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    const billingInformation = JSON.parse(props.order.jobs[0].billing_information);
    return (
        <div className="details-container">
            <div className="back-container">
                <Link to="/my-jobs"><i className="fa-solid fa-angle-left"></i> Back</Link>
            </div>
            <div className="top-container">
                <div className="order-id">Order #{props.order.id}</div>
                <div className="order-date">{dateToTimezone(props.order.created_at, 'MMMM Do, YYYY')}</div>
            </div>
            <div className="items-container">
                {renderItems()}
            </div>
            <div className="summary-container">
                <div className="addresses-container">
                    <div className="billing-address">
                        <div className="title">Order Information</div>
                        <div className="address-line">
                            <span className="label">Order #:</span> {props.order.id}
                        </div>
                        <div className="address-line">
                            <span className="label">Ordered By:</span> {props.order.customer_name}
                        </div>
                        <div className="address-line">
                            <span className="label">Order Date:</span> {dateToTimezone(props.order.created_at, 'MMMM Do, YYYY')}
                        </div>
                    </div>
                    <div className="billing-address">
                        <div className="title">Billing Address</div>
                        <div className="address-line">
                            <span className="label">Name:</span> {billingInformation.first_name} {billingInformation.last_name}
                        </div>
                        <div className="address-line">
                            <span className="label">Address:</span> {billingInformation.address1} {billingInformation.address2}
                        </div>
                        <div className="address-line">
                            <span className="label">City:</span> {billingInformation.city}
                        </div>
                        <div className="address-line">
                            <span className="label">State:</span> {billingInformation.state}
                        </div>
                        <div className="address-line">
                            <span className="label">Zip:</span> {billingInformation.zip}
                        </div>
                        <div className="address-line">
                            <span className="label">Phone:</span> {billingInformation.phone}
                        </div>
                        <div className="address-line">
                            <span className="label">Cellphone:</span> {billingInformation.cellphone}
                        </div>
                        <div className="address-line">
                            <span className="label">Country:</span> {billingInformation.country}
                        </div>
                        <div className="address-line">
                            <span className="label">Tax ID:</span> {billingInformation.tax_id}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Details;
