import React from "react";

const Products = (props) => {
    const handleEcoFriendly = () => {
        props.history.push("/company/environmental-statement");
    };

    const handleRequestQuote = () => {
        props.history.push("/company/custom-quote");
    };

    return (
        <div className="information-container content-container">
            <div className="info-container">
                <h1>Privacy Policy</h1>
                <div className="main-information">
                    <p>
                        <strong>Effective Date: June 25, 2024</strong>
                    </p>
                    <p>
                        <strong>Introduction</strong> You confirm that you have
                        read, understood, and agree to the OvernightColor.com
                        LLC Privacy Policy. The terms of this policy are
                        incorporated herein and deemed reasonable and
                        satisfactory to you. By using our services, you consent
                        to the use of your personal information by
                        OvernightColor.com LLC, its third-party providers, and
                        distributors in accordance with this policy. If you are
                        not a resident of the United States, please note that
                        your personal information submitted to the Site will be
                        transferred to the United States and processed by
                        OvernightColor.com LLC to provide our services or for
                        other purposes as set forth in this Privacy Policy.
                    </p>
                    <p>
                        <strong>Collection of Your Personal Information</strong>
                        OvernightColor.com LLC is committed to protecting your
                        privacy. You can visit most pages on our site without
                        providing any information about yourself. However,
                        certain areas require registration and/or a password for
                        access, such as when making purchases. Information
                        obtained from registered users may be used for
                        OvernightColor.com LLC marketing purposes, and cookies
                        may be used as described in this policy.
                    </p>
                    <p>
                        We collect personal information when you register, make
                        a purchase, enter a contest, subscribe to newsletters,
                        join limited-access areas, sign up for events or
                        training, or otherwise interact with our services.
                        Personal information collected may include your email
                        address, language, country, and other contact details
                        necessary for providing the requested service. For
                        example, if you make a purchase, we will collect billing
                        and shipping information, including your name, address,
                        telephone number, and credit card number.
                    </p>
                    <p>
                        We also collect information about your computer hardware
                        and software, such as your IP address, browser type,
                        operating system, domain name, access times, and
                        referring website addresses. This information is used to
                        operate the service, maintain quality, and provide
                        general statistics regarding the use of
                        OvernightColor.com LLC. Site visitation data is
                        identified by a unique ID number and never linked with
                        personal information.
                    </p>
                    <p>
                        <strong>Use of Your Personal Information</strong> The
                        information collected through our site helps us identify
                        the content our customers value, allowing us to market
                        and improve our website and services. We use your
                        information to send email notifications about updates to
                        the website, contact customers for marketing purposes,
                        and provide personalized content. Our policy is not to
                        give, sell, or otherwise distribute the information
                        collected to third parties outside OvernightColor.com
                        LLC and its subsidiaries, except as required by law or
                        with your consent. We may use suppliers to assist in
                        collecting and processing this information. For example,
                        we use an outside shipping company to ship orders and a
                        credit card processing company to bill users for goods
                        and services.
                    </p>
                    <p>
                        We may merge site-visitation data with anonymous
                        demographic information for research purposes and use
                        this information in aggregate to provide more relevant
                        content. In some areas, with your approval, we may
                        combine site-visitation data with your personal
                        information to provide personalized content. We
                        occasionally hire other companies to provide limited
                        services on our behalf, such as packaging, mailing,
                        delivering purchases, answering customer questions,
                        sending postal mail, and processing event registration.
                        These companies are provided only the information needed
                        to deliver the service and are prohibited from using it
                        for any other purpose.
                    </p>
                    <p>
                        <strong>Control of Your Personal Information</strong>{" "}
                        When you register online or provide personal
                        information, OvernightColor.com LLC will not share that
                        information with third parties without your written
                        permission, except for the limited exceptions listed. It
                        will only be used for the purposes stated in this
                        Privacy Policy. OvernightColor.com LLC may send periodic
                        emails about products or services you have requested,
                        order status confirmations, or promotional discounts.
                        You cannot unsubscribe from these mailings as they are
                        considered essential to the service you have chosen.
                    </p>
                    <p>
                        <strong>Access to Your Personal Information</strong>{" "}
                        OvernightColor.com LLC provides you with the means to
                        ensure your personal information is correct and current.
                        If you register on our site, you can review and edit
                        your personal information, check your current order, and
                        review past orders on the "My Account" page. You will
                        need your username and password to view your personal
                        information and can retrieve lost or forgotten passwords
                        once registered.
                    </p>
                    <p>
                        <strong>Security of Your Personal Information</strong>{" "}
                        We are committed to safeguarding your personal data or
                        other information. We use a variety of security
                        technologies and procedures to help protect your
                        personal information from unauthorized access, use, or
                        disclosure. For example, we store the personal
                        information you provide on computer servers with limited
                        access located in controlled facilities. When
                        transmitting sensitive information (such as a credit
                        card number) over the Internet, we use encryption, such
                        as the Secure Socket Layer (SSL) protocol. However, no
                        system is infallible, and absolute confidentiality or
                        security cannot be guaranteed.
                    </p>
                    <p>
                        <strong>Use of Cookies</strong> When you visit our site,
                        a cookie is placed on your machine (if accepted) or read
                        if you have visited the site previously. Cookies assist
                        in collecting site visitation statistics as described
                        above. We are not responsible for the privacy practices
                        of other sites. We encourage users to be aware of this
                        when they leave our site and read the privacy statements
                        of every website that collects personally identifiable
                        information. This privacy statement applies solely to
                        information collected by our website.
                    </p>
                    <p>
                        <strong>Indemnification</strong> You agree to defend,
                        indemnify, and hold harmless OvernightColor.com LLC and
                        all its officers, directors, employees, shareholders,
                        agents, affiliates, subsidiaries, suppliers, and
                        representatives from any claims, damages, losses, costs,
                        and expenses (including attorneys' fees) arising out of
                        or related to your breach of any provision of the Site
                        Terms, any suit, claim, or demand regarding an
                        allegation that Customer Content provided by you
                        infringes upon the rights of a third party, or any of
                        your activities conducted in connection with this
                        website, your use thereof, and the products and services
                        ordered through this website.
                    </p>
                    <p>
                        <strong>Termination</strong> User access to all or part
                        of this site may be terminated or suspended at any time,
                        without notice and for any reason. Your account or your
                        right to utilize the services made available through
                        this website may be terminated if OvernightColor.com LLC
                        determines or has reasonable grounds to suspect that you
                        are in violation of the Site Terms and Conditions.
                        OvernightColor.com LLC reserves the right to terminate
                        your right to use this website for any reason or no
                        reason at all and to block or prevent future access. If
                        your right to use the website is terminated, these Site
                        Terms and Conditions still apply. If your account is
                        terminated/deactivated, it will not affect your
                        responsibility for payment(s) of job(s) already
                        completed and/or in production.
                    </p>
                    <p>
                        <strong>Changes to This Statement</strong> As privacy
                        laws change, OvernightColor.com LLC reserves the right
                        to amend this Privacy Policy at any time with or without
                        notice. When changes are made, we will revise the
                        "Effective Date" at the top of the privacy statement.
                        Please check back frequently for updates.
                    </p>
                    <p>
                        You confirm that you have read, understood, and agree to
                        the OvernightColor.com LLC Privacy Policy. You consent
                        to the use of your personal information by
                        OvernightColor.com LLC, its third-party providers, and
                        distributors in accordance with this policy.
                    </p>
                </div>
            </div>
            <div className="messages-container">
                <div className="message-main-container eco-friendly">
                    <div className="message-container">
                        <div className="title">ECO-FRIENDLY PRINTING</div>
                        <div className="message">
                            OvernightColor.com has taken the initiative and
                            grown into an environmentally friendly company by
                            taking significant steps in our production of
                            printed products.
                        </div>
                        <button onClick={handleEcoFriendly}>READ MORE</button>
                    </div>
                    <div className="image">
                        <img
                            src="https://overnightcolor.s3.amazonaws.com/css/eco-friendly.png"
                            alt="Eco Friendly"
                        />
                    </div>
                </div>
                <div className="message-main-container request-quote">
                    <div className="message-container">
                        <div className="title">REQUEST PRINT QUOTE</div>
                        <div className="message">
                            Request a quote for any print project by using our
                            user-friendly online form. Submit the details of
                            your print request and we will provide you with an
                            instant quote.
                        </div>
                        <button onClick={handleRequestQuote}>
                            REQUEST A QUOTE
                        </button>
                    </div>
                    <div className="image">
                        <img
                            src="https://overnightcolor.s3.amazonaws.com/css/request-quote.png"
                            alt="Request Quote"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;
