import React from 'react';

const BrochuresTemplates = React.memo((props) => {
    const templates = [
        'brochure_dpfold_8.5x14_back',
        'brochure_dpfold_8.5x14_front',
        'brochure_halffold_11x17_back',
        'brochure_halffold_11x17_front',
        'brochure_halffold_11x25.5_back',
        'brochure_halffold_11x25.5_front',
        'brochure_halffold_8.5x11_back',
        'brochure_halffold_8.5x11_front',
        'brochure_halffold_8.5x14_back',
        'brochure_halffold_8.5x14_front',
        'brochure_halffold_9x12_back',
        'brochure_halffold_9x12_front',
        'brochure_halfold_back_8.5x5.5',
        'brochure_halfold_front_8.5x5.5',
        'brochure_letterfold_8.5x11_back',
        'brochure_rtangfold_11x17_hback',
        'brochure_rtangfold_11x17_hfront',
        'brochure_rtangfold_11x17_vback',
        'brochure_rtangfold_11x17_vfront',
        'brochure_trifold_11x17_back',
        'brochure_trifold_11x17_front',
        'brochure_trifold_11x25.5_back',
        'brochure_trifold_11x25.5_front',
        'brochure_trifold_8.5x14_back',
        'brochure_trifold_8.5x14_front',
        'brochure_trifold_9x12_back',
        'brochure_trifold_9x12_front',
    ];

    return templates.map((template) => {
        return (
            <tr>
                <td>{template}</td>
                <td className="actions">
                    <a href={'https://overnightcolor.s3.amazonaws.com/templates/' + template + '.pdf'} target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-file-arrow-down"></i> Download
                    </a>
                </td>
            </tr>
        );
    });
});

export default BrochuresTemplates;
