import React from 'react';

const BookletsTemplates = React.memo((props) => {
    const templates = [
        '8.5x11_booklet',
        '8.5x5.5_booklet',
        '9x12_booklet',
        '9x6_booklet',
    ];

    return templates.map((template) => {
        return (
            <tr>
                <td>{template}</td>
                <td className="actions">
                    <a href={'https://overnightcolor.s3.amazonaws.com/templates/' + template + '.pdf'} target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-file-arrow-down"></i> Download
                    </a>
                </td>
            </tr>
        );
    });
});

export default BookletsTemplates;
