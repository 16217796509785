import React from 'react';

const Products = (props) => {
    const handleEcoFriendly = () => {
        props.history.push('/company/environmental-statement');
    };

    const handleRequestQuote = () => {
        props.history.push('/company/custom-quote');
    };

    return (
        <div className="information-container content-container">
            <div className="info-container">
                <div className="award">
                    <div className="icon-container fsc">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/award-forest.jpg" alt="FOREST STEWARDSHIP COUNCIL" />
                    </div>
                    <div className="title">FOREST STEWARDSHIP COUNCIL® (FSC®)</div>
                    <div className="description">
                        The FSC® is a global organization which sets standards for guiding and coordinating the development of responsible
                        forest management throughout the different biogeographic regions of the world. The FSC® is the standard-setter
                        globally in responsible forest management. Chain-of-Custody certification guarantees that wood comes from certified,
                        responsibly managed forestlands and is tracked throughout the supply chain, from the forest to the consumer.
                    </div>
                </div>
                <div className="award">
                    <div className="icon-container">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/award-rainforest.jpg" alt="RAINFOREST ALLIANCE" />
                    </div>
                    <div className="title">RAINFOREST ALLIANCE</div>
                    <div className="description">
                        The Rainforest Alliance works to conserve biodiversity and ensure sustainable livelihoods in more than 60 countries
                        around the world by striving to employ standards that are socially and environmentally responsible, as well as economically
                        viable. Participants in the Rainforest Alliance's programs meet rigorous standards and the Rainforest Alliance certified
                        seal of approval assures consumers that they are buying a sustainable product. (currently applying)
                    </div>
                </div>
                <div className="award">
                    <div className="icon-container">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/award-pigc.jpg" alt="RAINFOREST ALLIANCE" />
                    </div>
                    <div className="title">PRINTING INDUSTRIES OF GULF COAST (PIGC)</div>
                    <div className="description">
                        Membership in PIGC is available to graphic communications firms in the Texas-Louisiana Gulf Coast area. The purpose of
                        the Association is to serve as the official representative of the graphic arts industry in the area and further the cause
                        of the industry in the areas of business, government and to the general public. Established in 1918 by a group of Houston
                        printers as the Printer's Education and Publicity Bureau, PIGC is one of the state's oldest still existing trade associations.
                        In 1920, Houston printers joined with Galveston's printing industry to form the Houston-Galveston Typothetae and affiliated
                        with the United Typothetae of America (the original name for Printing Industries of America). PIGC is a non-profit corporation
                        in the State of Texas. (PIGC website)
                    </div>
                </div>
                <div className="award">
                    <div className="icon-container">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/award-hub.jpg" alt="STATE OF TEXAS (HUB)" />
                    </div>
                    <div className="title">STATE OF TEXAS (HUB)</div>
                    <div className="description">
                        Administered by the Texas Building & Procurement Commission (TBPC), Historically Underutilized Business certification acknowledges
                        businesses that have been historically underutilized due to their identification as members of certain groups. These groups include
                        African Americans, Hispanic Americans, Asian Americans, Native Indian Americans, and women. The purpose of a HUB certificate is to
                        promote a business and encourage other businesses to utilize its services. The purpose of this program is to promote full and equal
                        business opportunities for all businesses in state contracting in accordance with the goals specified in the State of Texas Disparity
                        Study. 34 Texas Administrative Code Part 1, Chapter 20, Subchapter B, Sections 20.11-20.28
                    </div>
                </div>
                <div className="award">
                    <div className="icon-container">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/award-hmbc.jpg" alt="HOUSTON MINORITY BUSINESS COUNCIL (HMBC)" />
                    </div>
                    <div className="title">HOUSTON MINORITY BUSINESS COUNCIL (HMBC)</div>
                    <div className="description">
                        The mission of the HMBC is "to actively involve its members in efforts that will increase and expand business opportunities and business
                        growth for minority business enterprises." The HMBC strives to connect major corporations interested in establishing business relationships
                        with minority entrepreneurs and in helping them to successfully grow their businesses.
                    </div>
                </div>
                <div className="award">
                    <div className="icon-container">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/award-cohc.jpg" alt="CITY OF HOUSTON CERTIFICATION (COHC)" />
                    </div>
                    <div className="title">CITY OF HOUSTON CERTIFICATION (COHC)</div>
                    <div className="description">
                        The Certification section implements City Ordinance Numbers 84-1309, 95-336, 98-1213, and 99-893, approved by City Council for the purpose
                        of equalizing the opportunity of minority, women, small, persons with disabilities, and disadvantaged business owners to compete for City contracts.
                        The Minority, Woman, Small, and Persons with Disabilities Business Enterprise certification program and Disadvantaged Business Enterprise
                        certification program assures the legitimacy of ownership, management, and control of firms applying for M/W/S/PDBE and DBE status. Only
                        firms certified by the City of Houston can be used to satisfy the respective goal requirements.
                    </div>
                </div>
                <div className="award">
                    <div className="icon-container metro">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/award-metro.jpg" alt="METRO TRANSIT" />
                    </div>
                    <div className="title">METRO TRANSIT</div>
                    <div className="description">
                        Metro Small Business Certification recognizes all (SBE) Small Business Enterprise and (DBE) Disadvantage Business Enterprise in the Houston
                        area. The Disadvantaged Business Enterprise Program requires that any individual or group claiming ownership of a disadvantaged business
                        enterprise must own at least 51% of the organization to be classified as eligible to participate. Our certification number is 8112051634.
                    </div>
                </div>
                <div className="award-description">
                    <div className="title">
                        Our efforts through the years have earned us some of the industry's most prestigious awards. These include: Award of Excellence and multiple 
                        Best of Catategory in the Graphic Excellence Competition sponsored by the Printing Industries of the Gulf Coast.
                    </div>
                    <ul>
                        <li>In 2010, Metro Transit Authority Recoginzed OvernightColor.com's commitment to customer service with the Excellent Service Award.</li>
                        <li>In 2011, OvernightColor.com received the Printing Industry of the Gulf Coast award for:</li>
                    </ul>
                    <div className="bullet">- Best of Category (2 Awards)</div>
                    <div className="bullet">- Award of Excellence</div>
                </div>
            </div>
            <div className="messages-container">
                <div className="message-main-container eco-friendly">
                    <div className="message-container">
                        <div className="title">ECO-FRIENDLY PRINTING</div>
                        <div className="message">
                            OvernightColor.com has taken the initiative and grown into an environmentally friendly company by taking significant
                            steps in our production of printed products.
                        </div>
                        <button onClick={handleEcoFriendly}>READ MORE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/eco-friendly.png" alt="Eco Friendly" />
                    </div>
                </div>
                <div className="message-main-container request-quote">
                    <div className="message-container">
                        <div className="title">REQUEST PRINT QUOTE</div>
                        <div className="message">
                            Request a quote for any print project by using our user-friendly online form. Submit the details of your print request
                            and we will provide you with an instant quote.
                        </div>
                        <button onClick={handleRequestQuote}>REQUEST A QUOTE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/request-quote.png" alt="Request Quote" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
