import React from 'react';

const Form = (props) => {
    const handleSetField = (field, event) => {
        props.setField(field, event.target.value)
    };

    return (
        <div className="form-container content-container">
            <h2>
                If you have a print project that you would like to receive a FREE quote for, you can either call us directly at 800-386-3054 or
                complete the online form below with the project details. We look forward to hearing from you and providing all that you need to
                complete your print project!
            </h2>
            <div className="contact-form-container">
                <h3>General Information</h3>
                <div className="contact-form">
                    <div className="field">
                        <div className="label">Full Name <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.name} onChange={handleSetField.bind(this, 'name')} placeholder="Full Name" />
                    </div>
                    <div className="field">
                        <div className="label">Company Name:</div>
                        <input type="text" value={props.fields.company} onChange={handleSetField.bind(this, 'company')} placeholder="Company Name" />
                    </div>
                    <div className="field">
                        <div className="label">Address 1:</div>
                        <input type="text" value={props.fields.address1} onChange={handleSetField.bind(this, 'address1')} placeholder="Address 1" />
                    </div>
                    <div className="field">
                        <div className="label">Address 2:</div>
                        <input type="text" value={props.fields.address2} onChange={handleSetField.bind(this, 'address2')} placeholder="Address 2" />
                    </div>
                    <div className="field">
                        <div className="label">City:</div>
                        <input type="text" value={props.fields.city} onChange={handleSetField.bind(this, 'city')} placeholder="City" />
                    </div>
                    <div className="field">
                        <div className="label">State:</div>
                        <input type="text" value={props.fields.state} onChange={handleSetField.bind(this, 'state')} placeholder="State" />
                    </div>
                    <div className="field">
                        <div className="label">Zip Code:</div>
                        <input type="text" value={props.fields.zip} onChange={handleSetField.bind(this, 'zip')} placeholder="Zip Code" />
                    </div>
                    <div className="field">
                        <div className="label">Phone <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.phone} onChange={handleSetField.bind(this, 'phone')} placeholder="Phone" />
                    </div>
                    <div className="field">
                        <div className="label">Email <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.email} onChange={handleSetField.bind(this, 'email')} placeholder="Email" />
                    </div>
                </div>
                <h3>Project Information</h3>
                <div className="contact-form">
                    <div className="field">
                        <div className="label">Project Name:</div>
                        <input type="text" value={props.fields.project_name} onChange={handleSetField.bind(this, 'project_name')} placeholder="Project Name" />
                    </div>
                    <div className="field">
                        <div className="label">Quantity:</div>
                        <input type="text" value={props.fields.project_quantity} onChange={handleSetField.bind(this, 'project_quantity')} placeholder="Quantity" />
                    </div>
                    <div className="comments-field">
                        <div className="label">Project Description <span className="required">*</span>:</div>
                        <textarea value={props.fields.project_description} onChange={handleSetField.bind(this, 'project_description')} placeholder="Project Description" />
                    </div>
                </div>
                <div className="button-container">
                    <button onClick={props.sendCustomQuote} disabled={props.sending}>
                        {props.sending ? <i className="fas fa-circle-notch fa-spin" /> : 'Send'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Form;
