import Immutable from 'immutable';
import * as types from './LoyaltyPoints.types';

const initialState = {
    prizes: [],
    fetchingPrizes: false,
    history: null,
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 15,
    },
    sort: {
        column: 'name',
        type: 'desc',
    },
    search: '',
    searchType: 'name',
    gettingPrize: false,
    idModal: null,
    information: {
        points: 0,
    },
    fetchingInformation: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_PRIZES:
            return state.setIn(['fetchingPrizes'], true).toJS();

        case types.FETCH_PRIZES_SUCCESS:
            return state.setIn(['fetchingPrizes'], false)
                .setIn(['prizes'], action.payload.elements)
                .setIn(['pagination', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_PRIZES_ERROR:
            return state.setIn(['fetchingPrizes'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'points'], action.payload.points)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS()

        case types.GET_PRIZE:
            return state.setIn(['gettingPrize'], true).toJS();

        case types.GET_PRIZE_SUCCESS:
            return state.setIn(['gettingPrize'], false).toJS();

        case types.GET_PRIZE_ERROR:
            return state.setIn(['gettingPrize'], false).toJS();

        case types.SET_ID_MODAL:
            return state.setIn(['idModal'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        case types.SET_SORT:
            return state.setIn(['sort', 'type'], action.payload.type)
                .setIn(['sort', 'column'], action.payload.column)
                .toJS();

        case types.SET_SEARCH:
            return state.setIn(['search'], action.payload).toJS();

        case types.SET_SEARCH_TYPE:
            return state.setIn(['searchType'], action.payload).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}