import React from 'react';
import numeral from 'numeral';
import { dateToTimezone, empty } from './../../../utils';

const Details = (props) => {
    if (props.actionType === 'list') {
        return null;
    }

    if (props.fetchingElement) {
        return (
            <div className="form-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleShowCreateScTicketModal = (id) => {
        props.setIdModal(id);
        props.showCreateScTicketModal();
    };

    const handleShowArtworksModal = (id) => {
        props.setIdModal(id);
        props.showArtworksModal();
    };

    const renderFeatures = (job) => {
        const features = JSON.parse(job.features_information);
        return features.map((feature) => {
            return (
                <div className="feature">
                    <div className="feature-name"><i className="fa-solid fa-circle"></i> {feature.feature}</div>
                    <div className="option-name">{feature.option}</div>
                </div>
            );
        });
    };

    const handleChangeJobStatus = (id, index, event) => {
        props.changeJobStatus(id, event.target.value, index);
    };

    const renderItems = () => {
        return props.item.jobs.map((job, index) => {
            let artworksButton = (
                <div>
                    <button className="artworks-button" onClick={handleShowArtworksModal.bind(this, job.id)}>
                        Artwork
                    </button>
                </div>
            );
            let createScTicketButton = (
                <div>
                    <button className="create-sc-ticket-button" onClick={handleShowCreateScTicketModal.bind(this, job.id)}>
                        SC Ticket
                    </button>
                </div>
            );
            let deleteButton = (
                <div>
                    <button className="delete-button" onClick={props.deleteItem.bind(this, job.id)}>
                        {props.isDeleting ? <i className="fas fa-circle-notch fa-spin" /> : 'Delete'}
                    </button>
                </div>
            );

            const productInformation = JSON.parse(job.product_information);

            let discount = 0;
            let discountRow = null;
            if (parseInt(props.item.is_first_order)) {
                discount = job.price * 0.2;
            } else {
                discount = job.coupons_discount;
            }

            if (discount > 0) {
                discountRow = (
                    <div className="price-row shipping-price-row">
                        <div className="label">Discount:</div>
                        <div className="value">-{numeral(discount).format('$0,0.00')}</div>
                    </div>
                );
            }

            let totalPrice = parseFloat(job.price) + parseFloat(job.shipping_price) + parseFloat(job.tax) - discount;

            const shippingInformation = JSON.parse(job.shipping_information);

            let scTicketField = null;
            if (!empty(job.sc_ticket_id)) {
                scTicketField = (
                    <div className="sc-number">
                        SC #: <a href={'https://www.stagecoachview.com/tracking/' + job.sc_ticket_id} target="_blank" rel="noopener noreferrer">{job.sc_ticket_id}</a>
                    </div>
                );
            }

            return (
                <div className="item">
                    <div className="image-column">
                        <div className="pdf">
                            <img src={job.product_image_url} alt="Product" />
                        </div>
                        <div className="job-number">Job #: {job.id}</div>
                        {scTicketField}
                        <div className="buttons-container">
                            {artworksButton}
                            {createScTicketButton}
                            {deleteButton}
                        </div>
                    </div>
                    <div className="description-column">
                        <div class="name">{job.name}</div>
                        <div className="card-title">{productInformation.product}</div>
                        <div className="status-field">
                            <span className="status-label">Status:</span>
                            <select value={job.job_status_id} onChange={handleChangeJobStatus.bind(this, job.id, index)}>
                                <option value="">Select status</option>
                                {props.information.statuses.map((status) => {
                                    return <option value={status.id}>{status.name}</option>;
                                })}
                            </select>
                        </div>
                        <div className="features-title mt-2"><strong>Printing Options:</strong></div>
                        <div className="features">
                            {renderFeatures(job)}
                        </div>
                    </div>
                    <div className="shipping-address-column">
                        <div className="card-title"><strong>Shipping Address:</strong></div>
                        <div className="address-line shipping-method">
                            <span className="label">Shipping Method:</span> {job.shipping_method_name}
                        </div>
                        <div className="address-line">
                            <span className="label">Name:</span> {shippingInformation.first_name} {shippingInformation.last_name}
                        </div>
                        <div className="address-line">
                            <span className="label">Address:</span> {shippingInformation.address1} {shippingInformation.address2}
                        </div>
                        <div className="address-line">
                            <span className="label">City:</span> {shippingInformation.city}
                        </div>
                        <div className="address-line">
                            <span className="label">State:</span> {shippingInformation.state}
                        </div>
                        <div className="address-line">
                            <span className="label">Zip:</span> {shippingInformation.zip}
                        </div>
                        <div className="address-line">
                            <span className="label">Phone:</span> {shippingInformation.phone}
                        </div>
                        <div className="address-line">
                            <span className="label">Cellphone:</span> {shippingInformation.cellphone}
                        </div>
                        <div className="address-line">
                            <span className="label">Country:</span> {shippingInformation.country}
                        </div>
                    </div>
                    <div className="quantity-column">
                        <div className="price-row quantity-row">
                            <div className="label">Quantity:</div>
                            <div className="value">{numeral(job.quantity).format('0,0')}</div>
                        </div>
                        <div className="price-row printing-price-row">
                            <div className="label">Printing:</div>
                            <div className="value">
                                <div>{numeral(job.price).format('$0,0.00')}</div>
                                <div className="each-price">(Each: {numeral(job.price / job.quantity).format('$0,0.00[00]')})</div>
                            </div>
                        </div>
                        {discountRow}
                        <div className="price-row shipping-price-row">
                            <div className="label">Shipping:</div>
                            <div className="value">{numeral(job.shipping_price).format('$0,0.00')}</div>
                        </div>
                        <div className="price-row tax-price-row">
                            <div className="label">Tax:</div>
                            <div className="value">{numeral(job.tax).format('$0,0.00')}</div>
                        </div>
                        <div className="price-row total-price-row">
                            <div className="label">Total:</div>
                            <div className="value">{numeral(totalPrice).format('$0,0.00')}</div>
                        </div>
                        <div className="price-row points-row">
                            <div className="label">Points:</div>
                            <div className="value">{numeral(Math.round(job.total_points)).format('0,0')}</div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    let scTicket = null;
    if (parseInt(props.item.status) === 2 && props.user && [2, 4].includes(parseInt(props.user.abs_type)) && !empty(props.item.pqt_tracking_id)) {
        scTicket = (
            <div className="sc-ticket">
                SC #: <a href={'https://www.stagecoachview.com/tracking/' + props.item.pqt_tracking_id} target="_blank" rel="noopener noreferrer">{props.item.pqt_tracking_id}</a>
            </div>
        );
    }

    const billingInformation = JSON.parse(props.item.jobs[0].billing_information);

    return (
        <div className="details-container">
            <div className="top-container">
                <div className="order-id-status-container">
                    <div className="order-id">Order #{props.item.id}</div>
                    {scTicket}
                </div>
                <div className="order-date">{dateToTimezone(props.item.date, 'MMMM Do, YYYY')}</div>
            </div>
            <div className="items-container">
                {renderItems()}
            </div>
            <div className="summary-container">
                <div className="addresses-container">
                    <div className="billing-address">
                        <div className="title">Order Information</div>
                        <div className="address-line">
                            <span className="label">Order #:</span> {props.item.id}
                        </div>
                        <div className="address-line">
                            <span className="label">Ordered By:</span> {props.item.customer_name}
                        </div>
                        <div className="address-line">
                            <span className="label">Order Date:</span> {dateToTimezone(props.item.date, 'MMMM Do, YYYY')}
                        </div>
                    </div>
                    <div className="billing-address">
                        <div className="title">Billing Address</div>
                        <div className="address-line">
                            <span className="label">Name:</span> {billingInformation.first_name} {billingInformation.last_name}
                        </div>
                        <div className="address-line">
                            <span className="label">Address:</span> {billingInformation.address1} {billingInformation.address2}
                        </div>
                        <div className="address-line">
                            <span className="label">City:</span> {billingInformation.city}
                        </div>
                        <div className="address-line">
                            <span className="label">State:</span> {billingInformation.state}
                        </div>
                        <div className="address-line">
                            <span className="label">Zip:</span> {billingInformation.zip}
                        </div>
                        <div className="address-line">
                            <span className="label">Phone:</span> {billingInformation.phone}
                        </div>
                        <div className="address-line">
                            <span className="label">Cellphone:</span> {billingInformation.cellphone}
                        </div>
                        <div className="address-line">
                            <span className="label">Country:</span> {billingInformation.country}
                        </div>
                        <div className="address-line">
                            <span className="label">Tax ID:</span> {billingInformation.tax_id}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Details;
