import React from 'react';

const Items = (props) => {
    return (
        <div className="items-container">
            <div className="items content-container">
                <h3>What Clients are saying</h3>
                <div className="testimonials">
                    <div className="testimonial">
                        <div className="photo-container">
                            <div className="photo" style={{ backgroundImage: 'url(https://overnightcolor.s3.amazonaws.com/css/carthy.png)' }}></div>
                        </div>
                        <div className="name">Carthy</div>
                        {/* <div className="position">AMERICAN MEDICAL ID</div> */}
                        <div className="description">
                            Alonso was a pleasure to work with and he really understood my vision. Thanks to him expertise, my brand has never looked better!
                        </div>
                        <div className="stars">
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                        </div>
                    </div>
                    <div className="testimonial">
                        <div className="photo-container">
                            <div className="photo" style={{ backgroundImage: 'url(https://overnightcolor.s3.amazonaws.com/css/jane.png)' }}></div>
                        </div>
                        <div className="name">Jane</div>
                        {/* <div className="position">AMERICAN MEDICAL ID</div> */}
                        <div className="description">
                            It was a fantastic experience. From start to finish, they were responsive, professional, and went above and beyond to ensure that
                            I was happy with the final product. I would highly recommend them to anyone looking for high-quality design work." -
                        </div>
                        <div className="stars">
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                        </div>
                    </div>
                    <div className="testimonial">
                        <div className="photo-container">
                            <div className="photo" style={{ backgroundImage: 'url(https://overnightcolor.s3.amazonaws.com/css/tom.png)' }}></div>
                        </div>
                        <div className="name">Tom</div>
                        {/* <div className="position">AMERICAN MEDICAL ID</div> */}
                        <div className="description">
                            I've worked with a lot of design firms in the past, but OvernightColor really stands out. Their attention to detail is second to none
                            and they really took the time to understand my needs. I'm thrilled with the final result and would definitely work with them again.
                        </div>
                        <div className="stars">
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Items;
