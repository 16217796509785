import React from 'react';
// import { Link } from 'react-router-dom';

const RelatedProducts = (props) => {
    const handleShopNow = (product) => {
        props.history.push('/product/' + product.code);
    };

    const renderRelatedProducts = () => {
        return props.relatedProducts.map((product) => {
            return (
                <div className="related-product-container" onClick={handleShopNow.bind(this, product)}>
                    <div className="related-product" style={{ backgroundImage: 'url("' + product.image_url + '")' }}></div>
                    <button onClick={handleShopNow.bind(this, product)}>SHOP NOW!</button>
                    <div className="title">{product.product}</div>
                </div>
            );
        });
    };

    return (
        <div className="related-products-container">
            <h1>Related Products</h1>
            <div className="related-products">
                {renderRelatedProducts()}
            </div>
        </div>
    );
}

export default RelatedProducts;
