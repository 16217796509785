import React from 'react';

const PostersTemplates = React.memo((props) => {
    const templates = [
        'poster_11x17',
        'poster_12x18',
        'poster_18x24',
        'poster_24x36',
    ];

    return templates.map((template) => {
        return (
            <tr>
                <td>{template}</td>
                <td className="actions">
                    <a href={'https://overnightcolor.s3.amazonaws.com/templates/' + template + '.pdf'} target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-file-arrow-down"></i> Download
                    </a>
                </td>
            </tr>
        );
    });
});

export default PostersTemplates;
