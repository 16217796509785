import React from 'react';

const Products = (props) => {
    const handleEcoFriendly = () => {
        props.history.push('/company/environmental-statement');
    };

    const handleRequestQuote = () => {
        props.history.push('/company/custom-quote');
    };

    return (
        <div className="information-container content-container">
            <div className="info-container">
                <div className="years">
                    <div className="year">1982-2000</div>
                    <div className="line"></div>
                    <div className="year">2001</div>
                    <div className="line"></div>
                    <div className="year">2002</div>
                    <div className="line"></div>
                    <div className="year">2003</div>
                    <div className="line"></div>
                    <div className="year">2004</div>
                    <div className="line"></div>
                    <div className="year">2005</div>
                    <div className="line"></div>
                    <div className="year">2006</div>
                    <div className="line"></div>
                    <div className="year">2007</div>
                    <div className="line"></div>
                    <div className="year">2008</div>
                    <div className="line"></div>
                    <div className="year">2009</div>
                    <div className="line"></div>
                    <div className="year">2009-2010</div>
                    <div className="line"></div>
                    <div className="year">2010</div>
                    <div className="line"></div>
                    <div className="year">2011</div>
                    <div className="line"></div>
                    <div className="year">2019</div>
                </div>
                <div className="descriptions">
                    <div className="description">Hugh and Christy worked at a family printing business in Houston, Texas.</div>
                    <div className="description">OvernightColor.com was founded by Hugh and Christy Nguyen.</div>
                    <div className="description">OvernightColor.com purchased its first 5-color Komori Lithrone 40 inch printing press.</div>
                    <div className="description">OvernightColor.com purchased a 5-color Komori Sprint 26 inch with inline coating.</div>
                    <div className="description">OvernightColor.com added the first off-line 40 inch UV coater in Houston.</div>
                    <div className="description">OvernightColor.com added the first Heidelberg QM-DI Pro/Spot digital press in Houston.</div>
                    <div className="description">OvernightColor.com added the first Sakurai 5-Color with inter-deck coater in Houston.</div>
                    <div className="description">OvernightColor.com outgrew its original location and moved to a new 30,000 square feet facility at 5810 Windfern Road.</div>
                    <div className="description">OvernightColor.com purchased an additional 12,000 square feet building to add future mailing and fulfillment services.</div>
                    <div className="description">OvernightColor.com acquired a heat set web printing facility in Pasadena, Texas.</div>
                    <div className="description">OvernightColor.com also added a Kodak Nexpress to our digital lineup.</div>
                    <div className="description">OvernightColor.com is the first to add a direct to substrate large format printer to service our unique clientele.</div>
                    <div className="description">OvernightColor.com is the first to purchase a brand new Komori Lithrone S40/Perfector 10 Color 40 inch printing press with an inline aqueous coater.</div>
                    <div className="description">OvernightColor.com acquired PIMS, a move to bring significant benefits in terms of business expansion and market share consolidation.</div>
                </div>
            </div>
            <div className="messages-container">
                <div className="message-main-container eco-friendly">
                    <div className="message-container">
                        <div className="title">ECO-FRIENDLY PRINTING</div>
                        <div className="message">
                            OvernightColor.com has taken the initiative and grown into an environmentally friendly company by taking significant
                            steps in our production of printed products.
                        </div>
                        <button onClick={handleEcoFriendly}>READ MORE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/eco-friendly.png" alt="Eco Friendly" />
                    </div>
                </div>
                <div className="message-main-container request-quote">
                    <div className="message-container">
                        <div className="title">REQUEST PRINT QUOTE</div>
                        <div className="message">
                            Request a quote for any print project by using our user-friendly online form. Submit the details of your print request
                            and we will provide you with an instant quote.
                        </div>
                        <button onClick={handleRequestQuote}>REQUEST A QUOTE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/request-quote.png" alt="Request Quote" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
