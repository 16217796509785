import React from 'react';

const ShippingForm = (props) => {
    if (props.fetchingAddress) {
        return (
            <div className="shipping-form-container">
                <div className="title">Shipping Address</div>
                <div className="form">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleCancel = () => {
        props.history.push('/my-address-book');
    };

    const handleSetField = (field, event) => {
        props.setField(field, event.target.value);
    };

    return (
        <div className="shipping-form-container">
            <div className="title">Shipping Address</div>
            <div className="form">
                <div className="field">
                    <div className="label">First Name <span className="required">*</span>:</div>
                    <input type="text" value={props.address.first_name} onChange={handleSetField.bind(this, 'first_name')} />
                </div>
                <div className="field">
                    <div className="label">Last Name <span className="required">*</span>:</div>
                    <input type="text" value={props.address.last_name} onChange={handleSetField.bind(this, 'last_name')} />
                </div>
                <div className="field">
                    <div className="label">Company <span className="required">*</span>:</div>
                    <input type="text" value={props.address.company} onChange={handleSetField.bind(this, 'company')} />
                </div>
                <div className="field">
                    <div className="label">Address 1 <span className="required">*</span>:</div>
                    <input type="text" value={props.address.address1} onChange={handleSetField.bind(this, 'address1')} />
                </div>
                <div className="field">
                    <div className="label">Address 2:</div>
                    <input type="text" value={props.address.address2} onChange={handleSetField.bind(this, 'address2')} />
                </div>
                <div className="field">
                    <div className="label">Zip Code <span className="required">*</span>:</div>
                    <input type="text" value={props.address.zip} onChange={handleSetField.bind(this, 'zip')} />
                </div>
                <div className="field">
                    <div className="label">Country <span className="required">*</span>:</div>
                    <select value={props.address.country_id} onChange={handleSetField.bind(this, 'country_id')}>
                        <option value="">Select option</option>
                        <option value="230">United States</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">State <span className="required">*</span>:</div>
                    <select value={props.address.state} onChange={handleSetField.bind(this, 'state')}>
                        <option value="">Select option</option>
                        <option value="Alabama">Alabama</option>
                        <option value="Alaska">Alaska</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Arizona">Arizona</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="California">California</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="Delaware">Delaware</option>
                        <option value="District of Columbia">District of Columbia</option>
                        <option value="Florida">Florida</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Guam">Guam</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="Maine">Maine</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Montana">Montana</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="New York">New York</option>
                        <option value="North Carolina">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Northern Mariana Isla">Northern Mariana Isla</option>nds
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Other Links">Other Links</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Texas">Texas</option>
                        <option value="Tribal Governments">Tribal Governments</option>
                        <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                        <option value="Utah">Utah</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Washington">Washington</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="Wyoming">Wyoming</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">City <span className="required">*</span>:</div>
                    <input type="text" value={props.address.city} onChange={handleSetField.bind(this, 'city')} />
                </div>
                <div className="field">
                    <div className="label">Phone <span className="required">*</span>:</div>
                    <input type="text" value={props.address.phone} onChange={handleSetField.bind(this, 'phone')} />
                </div>
                <div className="field">
                    <div className="label">Fax:</div>
                    <input type="text" value={props.address.fax} onChange={handleSetField.bind(this, 'fax')} />
                </div>
                <div className="field">
                    <div className="label">Cellphone:</div>
                    <input type="text" value={props.address.cellphone} onChange={handleSetField.bind(this, 'cellphone')} />
                </div>
                <div className="buttons-container">
                    <button onClick={handleCancel}>CANCEL</button>
                    <button disabled={props.savingAddress} onClick={props.saveAddress}>
                        {props.savingAddress ? <i className="fas fa-circle-notch fa-spin" /> : 'SAVE'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ShippingForm;
