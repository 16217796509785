import axios from 'axios';
import * as types from './Checkout.types';
import * as selectors from './Checkout.selectors';
import { empty, showNotification, validateAuthInResponse } from './../../utils';
import * as dialog from './../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchItems = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ITEMS, payload: null });
    axios.get(apiUrl + '/shopping-cart-items',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ITEMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ITEMS_ERROR));
        });
}

export const fetchBillingAddresses = () => (dispatch, getState) => {
    const items = selectors.getItems(getState());

    dispatch({ type: types.FETCH_BILLING_ADDRESSES, payload: null });
    axios.get(apiUrl + '/customer/billing-addresses',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_BILLING_ADDRESSES_SUCCESS, payload: response.data });

            for (const address of response.data) {
                if (parseInt(items[0].billing_address_id) === parseInt(address.id)) {
                    dispatch(setAddressFields(address));
                    break;
                }
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_BILLING_ADDRESSES_ERROR));
        });
}

export const fetchInformation = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/shopping-cart-item/information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const validateCoupon = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());

    dispatch({ type: types.VALIDATE_COUPON, payload: null });
    axios.post(apiUrl + '/coupon/validate', { code: fields.coupon },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.VALIDATE_COUPON_SUCCESS, payload: response.data });

            if (empty(response.data)) {
                showNotification('Invalid Coupon', 'The coupon code you entered is invalid', 'info');
            } else {
                showNotification('Valid Coupon', 'The coupon has been applied sucessfully', 'success');
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.VALIDATE_COUPON_ERROR));
        });
}

export const placeOrder = (paypalId = null) => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const history = selectors.getHistory(getState());
    const paymentMethod = selectors.getPaymentMethod(getState());

    if (paymentMethod === 'CreditCard') {
        if (empty(fields.cardNumber)) {
            return showNotification('Complete Information', 'Enter the Card Number', 'info');
        }
        if (empty(fields.expirationMonth)) {
            return showNotification('Complete Information', 'Enter the Expiration Month', 'info');
        }
        if (empty(fields.expirationYear)) {
            return showNotification('Complete Information', 'Enter the Expiration Year', 'info');
        }
        if (empty(fields.nameOnCard)) {
            return showNotification('Complete Information', 'Enter the Name on Card', 'info');
        }
        if (empty(fields.securityNumber)) {
            return showNotification('Complete Information', 'Enter the Security Number', 'info');
        }
    }

    dispatch({ type: types.PLACE_ORDER, payload: null });
    axios.post(apiUrl + '/shopping-cart-item/place-order', { fields, paymentMethod, paypalId },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.PLACE_ORDER_SUCCESS, payload: response.data });
            showNotification('Order Placed', 'Your order has been placed successfully', 'success');
            history.push('/my-orders/details/' + response.data.id + '/1');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.PLACE_ORDER_ERROR));
        });
}

export const saveBillingAddress = () => (dispatch, getState) => {
    const fields = selectors.getAddressFields(getState());

    if (!validateAddress(fields)) {
        return;
    }

    dispatch({ type: types.SAVE_BILLING_ADDRESS, payload: null });
    axios.post(apiUrl + '/shopping-cart-item/save-billing-address', fields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_BILLING_ADDRESS_SUCCESS, payload: response.data });
            dispatch(fetchItems());
            dispatch(dialog.actions.hideDialog('frontend-checkout-billing-address-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_BILLING_ADDRESS_ERROR));
        });
}

export const validateAddress = (fields) => {
    if (empty(fields.first_name)) {
        showNotification('Complete Information', 'Enter the First Name', 'info');
        return false;
    }
    if (empty(fields.last_name)) {
        showNotification('Complete Information', 'Enter the Last Name', 'info');
        return false;
    }
    if (empty(fields.company)) {
        showNotification('Complete Information', 'Enter the Company', 'info');
        return false;
    }
    if (empty(fields.is_reseller)) {
        showNotification('Complete Information', 'Select if is a Reseller', 'info');
        return false;
    }
    if (parseInt(fields.is_reseller) && empty(fields.tax_id)) {
        showNotification('Complete Information', 'Enter the Tax ID', 'info');
        return false;
    }
    if (empty(fields.zip)) {
        showNotification('Complete Information', 'Enter the Zip Code', 'info');
        return false;
    }
    if (empty(fields.country_id)) {
        showNotification('Complete Information', 'Select the Country', 'info');
        return false;
    }
    if (empty(fields.state)) {
        showNotification('Complete Information', 'Enter the State', 'info');
        return false;
    }
    if (empty(fields.city)) {
        showNotification('Complete Information', 'Enter the City', 'info');
        return false;
    }
    if (empty(fields.phone)) {
        showNotification('Complete Information', 'Enter the Phone', 'info');
        return false;
    }
    if (empty(fields.id) && empty(fields.make_default)) {
        showNotification('Complete Information', 'Select if this will be the Default Address or not', 'info');
        return false;
    }

    return true;
}

export const setSelectedAddress = (value) => dispatch => {
    dispatch({
        type: types.SET_SELECTED_ADDRESS,
        payload: value,
    });
}

export const setAddressFields = (value) => dispatch => {
    dispatch({
        type: types.SET_ADDRESS_FIELDS,
        payload: value,
    });
}

export const setAddressField = (field, value) => dispatch => {
    dispatch({
        type: types.SET_ADDRESS_FIELD,
        payload: { field, value },
    });
}

export const setField = (field, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setPaymentMethod = (value) => dispatch => {
    dispatch({
        type: types.SET_PAYMENT_METHOD,
        payload: value,
    });
}

export const setOrderId = (value) => dispatch => {
    dispatch({
        type: types.SET_ORDER_ID,
        payload: value,
    });
}

export const setHistory = (history) => dispatch => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}