import Immutable from 'immutable';
import * as types from './AdminOrders.types';

const initialState = {
    actionType: 'list',
    items: [],
    item: {},
    fetchingElements: false,
    fetchingElement: false,
    saving: false,
    deleting: false,
    idModal: null,
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 15,
    },
    sort: {
        column: 'id',
        type: 'desc',
    },
    search: '',
    searchType: 'id',
    creatingScTicket: false,
    information: {
        statuses: [],
    },
    fetchingInformation: false,
    savingJobStatus: false,
    customerId: null,
    orderId: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ELEMENTS:
            return state.setIn(['fetchingElements'], true).toJS();

        case types.FETCH_ELEMENTS_SUCCESS:
            return state.setIn(['fetchingElements'], false)
                .setIn(['items'], action.payload.elements)
                .setIn(['pagination', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_ELEMENTS_ERROR:
            return state.setIn(['fetchingElements'], false).toJS();

        case types.FETCH_ELEMENT:
            return state.setIn(['fetchingElement'], true).toJS();

        case types.FETCH_ELEMENT_SUCCESS:
            return state.setIn(['fetchingElement'], false)
                .setIn(['item'], action.payload)
                .toJS();

        case types.FETCH_ELEMENT_ERROR:
            return state.setIn(['fetchingElement'], false).toJS();

        case types.SAVE_ELEMENT:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_ELEMENT_SUCCESS:
            return state.setIn(['saving'], false)
                .setIn(['item'], {})
                .toJS();

        case types.SAVE_ELEMENT_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.DELETE_ITEM:
            return state.setIn(['deleting'], true).toJS();

        case types.DELETE_ITEM_SUCCESS:
            return state.setIn(['deleting'], false)
                .setIn(['idModal'], null)
                .toJS();

        case types.DELETE_ITEM_ERROR:
            return state.setIn(['deleting'], false).toJS();

        case types.CREATE_SC_TICKET:
            return state.setIn(['creatingScTicket'], true).toJS();

        case types.CREATE_SC_TICKET_SUCCESS:
            return state.setIn(['creatingScTicket'], false).toJS();

        case types.CREATE_SC_TICKET_ERROR:
            return state.setIn(['creatingScTicket'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'statuses'], action.payload.statuses)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS();

        case types.CHANGE_JOB_STATUS:
            return state.setIn(['savingJobStatus'], true)
                .updateIn(['item', 'jobs'], (jobs) => {
                    const newJobs = jobs.toJS();
                    newJobs[action.payload.index].job_status_id = action.payload.jobStatusId;
                    return newJobs;
                }).toJS();

        case types.CHANGE_JOB_STATUS_SUCCESS:
            return state.setIn(['savingJobStatus'], false).toJS();

        case types.CHANGE_JOB_STATUS_ERROR:
            return state.setIn(['savingJobStatus'], false).toJS();

        case types.SET_ACTION_TYPE:
            return state.setIn(['actionType'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        case types.SET_SORT:
            return state.setIn(['sort', 'type'], action.payload.type)
                .setIn(['sort', 'column'], action.payload.column)
                .toJS();

        case types.SET_SEARCH:
            return state.setIn(['search'], action.payload).toJS();

        case types.SET_SEARCH_TYPE:
            return state.setIn(['searchType'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['item', action.payload.field], action.payload.value).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.SET_ID_MODAL:
            return state.setIn(['idModal'], action.payload).toJS();

        case types.SET_CUSTOMER_ID:
            return state.setIn(['customerId'], action.payload).toJS();

        case types.SET_ORDER_ID:
            return state.setIn(['orderId'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}