import React from 'react';

const Information = (props) => {
    return (
        <div className="information-container content-container">
            <div className="description-container">
                <div className="description">
                    OvernightColor.com has partnered up with Hightail.com to allow our customers to transfer large files safely and securely to our prepress server.
                    With this partnership, our clients can send files up to 2 GB without compromising the potential loss of data. OvernightColor.com realizes that
                    traditional formats for transferring large files are often unreliable. Email has file size limits, CDs can be lost or damaged and FTP systems
                    are often tricky. OvernightColor.com understands that receiving mission-critical files is vital to getting our client's print jobs out on time.
                    <br />
                    Uploading files to OvernightColor.com is a breeze. Simply click on the Hightail icon and follow the simple instructions provided below. Or you
                    can view the upload video tutorial at the bottom of this page.
                </div>
                <div className="icon">
                    <i className="fa-solid fa-cloud-arrow-up"></i>
                </div>
            </div>
            <div className="button-container">
                <a href="https://spaces.hightail.com/uplink/AbsoluteColor" target="_blank" rel="noopener noreferrer">CLICK HERE TO UPLOAD YOUR ARTWORK</a>
            </div>
        </div>
    );
}

export default Information;
