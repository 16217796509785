import React from 'react';
import Pagination from './../../Pagination';
import { Tooltip } from 'react-tippy';
import { Link } from 'react-router-dom';

const Table = (props) => {
    const handleSetAsDefault = (id, type) => {
        props.setAsDefault(id, type);
    };

    const handleDeleteAddress = (id, type) => {
        props.deleteAddress(id, type);
    };

    const renderBillingAddresses = () => {
        if (props.fetchingBillingAddresses) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.billingAddresses.length === 0) {
            return (
                <tr>
                    <td colSpan="10">No results</td>
                </tr>
            );
        }

        return props.billingAddresses.map((address) => {
            let editIcon = (
                <div className="action-icon">
                    <Tooltip title="Edit address" position="bottom" arrow size="small" className="action-tooltip">
                        <Link to={'/billing/address/edit/' + address.id}><i className="fa-solid fa-pen-to-square"></i></Link>
                    </Tooltip>
                </div>
            );

            let deleteIcon = null;
            let deleteButton = null;
            if (!address.is_default) {
                deleteIcon = (
                    <div className="action-icon">
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fa-solid fa-trash" onClick={handleDeleteAddress.bind(this, address.id, 'billing')}></i>
                        </Tooltip>
                    </div>
                );
                deleteButton = <button className="delete-button" onClick={handleDeleteAddress.bind(this, address.id, 'billing')}>Delete</button>;
            }

            let defaultIcon = (
                <div className="action-icon default-icon">
                    <Tooltip title="Set as default address" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fa-solid fa-circle-minus" onClick={handleSetAsDefault.bind(this, address.id, 'billing')}></i>
                    </Tooltip>
                </div>
            );
            if (address.is_default) {
                defaultIcon = (
                    <div className="action-icon default-icon">
                        <Tooltip title="This is the default address" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fa-solid fa-circle-check"></i>
                        </Tooltip>
                    </div>
                );
            }

            return (
                <tr>
                    <td className="main-column">
                        <div className="desktop-value">{address.first_name} {address.last_name}</div>
                        <div className="mobile-container">
                            <div className="mobile-item">
                                <div className="label">Name:</div>
                                <div className="value">{address.first_name} {address.last_name}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Address:</div>
                                <div className="value">{address.full_address}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Default:</div>
                                <div className="value">{address.is_default ? 'Yes' : 'No'}</div>
                            </div>
                            <div className="mobile-actions">
                                <Link to={'/billing/address/edit/' + address.id}><button>Edit</button></Link>
                                <button onClick={handleSetAsDefault.bind(this, address.id, 'billing')}>Default</button>
                                {deleteButton}
                            </div>
                        </div>
                    </td>
                    <td className="address-column desktop-column">{address.full_address}</td>
                    <td className="default-column desktop-column">{defaultIcon}</td>
                    <td className="actions-column desktop-column">
                        <div className="actions-container">
                            {editIcon}
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const renderShippingAddresses = () => {
        if (props.fetchingShippingAddresses) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.shippingAddresses.length === 0) {
            return (
                <tr>
                    <td colSpan="10">No results</td>
                </tr>
            );
        }

        return props.shippingAddresses.map((address) => {
            let editIcon = (
                <div className="action-icon">
                    <Tooltip title="Edit address" position="bottom" arrow size="small" className="action-tooltip">
                        <Link to={'/shipping/address/edit/' + address.id}><i className="fa-solid fa-pen-to-square"></i></Link>
                    </Tooltip>
                </div>
            );

            let deleteIcon = null;
            let deleteButton = null;
            if (!address.is_default) {
                deleteIcon = (
                    <div className="action-icon">
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fa-solid fa-trash" onClick={handleDeleteAddress.bind(this, address.id, 'shipping')}></i>
                        </Tooltip>
                    </div>
                );
                deleteButton = <button className="delete-button" onClick={handleDeleteAddress.bind(this, address.id, 'shipping')}>Delete</button>;
            }

            let defaultIcon = (
                <div className="action-icon default-icon">
                    <Tooltip title="Set as default address" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fa-solid fa-circle-minus" onClick={handleSetAsDefault.bind(this, address.id, 'shipping')}></i>
                    </Tooltip>
                </div>
            );
            if (address.is_default) {
                defaultIcon = (
                    <div className="action-icon default-icon">
                        <Tooltip title="This is the default address" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fa-solid fa-circle-check"></i>
                        </Tooltip>
                    </div>
                );
            }

            return (
                <tr>
                    <td className="main-column">
                        <div className="desktop-value">{address.first_name} {address.last_name}</div>
                        <div className="mobile-container">
                            <div className="mobile-item">
                                <div className="label">Name:</div>
                                <div className="value">{address.first_name} {address.last_name}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Address:</div>
                                <div className="value">{address.full_address}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Default:</div>
                                <div className="value">{address.is_default ? 'Yes' : 'No'}</div>
                            </div>
                            <div className="mobile-actions">
                                <Link to={'/shipping/address/edit/' + address.id}><button>Edit</button></Link>
                                <button onClick={handleSetAsDefault.bind(this, address.id, 'shipping')}>Default</button>
                                {deleteButton}
                            </div>
                        </div>
                    </td>
                    <td className="address-column desktop-column">{address.full_address}</td>
                    <td className="default-column desktop-column">{defaultIcon}</td>
                    <td className="actions-column desktop-column">
                        <div className="actions-container">
                            {editIcon}
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    // const handleSort = (column, type) => {
    //     props.setSort(column, type, props.type);
    // };

    const renderHeader = (label, field) => {
        return <div className="sort-item">{label}</div>;

        // TODO: Enable sort

        // let sortType = 'asc';
        // let sortIcon = <i className="fas fa-sort" />;
        // if (props.sort.column === field) {
        //     if (props.sort.type === 'desc') {
        //         sortIcon = <i className="fas fa-sort-down" />;
        //         sortType = 'asc';
        //     } else {
        //         sortIcon = <i className="fas fa-sort-up" />;
        //         sortType = 'desc';
        //     }
        // }

        // return <div className="sort-item" onClick={handleSort.bind(this, field, sortType)}>{label} {sortIcon}</div>;
    };

    const handleBillingSetActivePage = (page) => {
        props.setActivePage('billing', page);
    };

    const handleShippingSetActivePage = (page) => {
        props.setActivePage('shipping', page);
    };

    const handleNewBilling = () => {
        props.history.push('/billing/address/new');
    };

    const handleNewShipping = () => {
        props.history.push('/shipping/address/new');
    };

    return (
        <div className="table-container">
            <div className="title">My Address Book</div>
            <div className="subtitle">
                <div>Billing Address</div>
                <button onClick={handleNewBilling}>NEW BILLING ADDRESS</button>
            </div>
            <table>
                <thead>
                    <tr className="header-row">
                        <th className="title-column desktop-column">{renderHeader('Name', 'name')}</th>
                        <th className="address-column desktop-column">{renderHeader('Address', 'full_address')}</th>
                        <th className="default-column desktop-column">{renderHeader('Default', 'is_default')}</th>
                        <th className="actions-column desktop-column"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderBillingAddresses()}
                </tbody>
            </table>
            <Pagination
                pagination={props.pagination.billing}
                setActivePage={handleBillingSetActivePage}
            />
            <div className="subtitle">
                <div>Shipping Address</div>
                <button onClick={handleNewShipping}>NEW SHIPPING ADDRESS</button>
            </div>
            <table>
                <thead>
                    <tr className="header-row">
                        <th className="title-column desktop-column">{renderHeader('Name', 'name')}</th>
                        <th className="address-column desktop-column">{renderHeader('Address', 'full_address')}</th>
                        <th className="default-column desktop-column">{renderHeader('Default', 'is_default')}</th>
                        <th className="actions-column desktop-column"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderShippingAddresses()}
                </tbody>
            </table>
            <Pagination
                pagination={props.pagination.shipping}
                setActivePage={handleShippingSetActivePage}
            />
        </div>
    );
}

export default Table;
