import React from "react";

const Products = (props) => {
    const handleEcoFriendly = () => {
        props.history.push("/company/environmental-statement");
    };

    const handleRequestQuote = () => {
        props.history.push("/company/custom-quote");
    };

    return (
        <div className="information-container content-container">
            <div className="info-container">
                <h1>Terms and Conditions</h1>
                <div className="main-information">
                    <p>
                        <strong>Effective Date: June 25, 2024</strong>
                    </p>
                    <p>
                        <strong>Introduction</strong> This Terms of Use
                        Agreement sets forth the standards of use between you
                        and OvernightColor.com LLC. PLEASE READ THESE TERMS AND
                        CONDITIONS CAREFULLY. If you do not agree to the terms
                        and conditions of this agreement, you should immediately
                        stop all use of this website. OvernightColor.com LLC
                        reserves the right to change, modify, or amend these
                        terms and conditions without any notice. Your continual
                        use of this website constitutes an acknowledgment and
                        acceptance of all modifications. This Agreement may not
                        be amended by users at any time. This website is
                        provided by OvernightColor.com LLC as a service to our
                        customers. Please review the following basic rules that
                        govern your use of the OvernightColor.com LLC Website.
                    </p>
                    <p>
                        Your use of the OvernightColor.com LLC site (the "Site")
                        constitutes your unconditional agreement to follow and
                        be bound by these Terms and Conditions. Although you may
                        "bookmark" a particular portion of this Site and thereby
                        bypass this Agreement, your use of this Site still binds
                        you to these Terms and Conditions. OvernightColor.com
                        LLC reserves the right to update or modify these Terms
                        and Conditions at any time without prior notice to you,
                        and we encourage you to review these Terms and
                        Conditions whenever you use this Site.
                    </p>
                    <p>
                        <strong>Use Restriction</strong> You may only use this
                        Site to make legitimate requests to purchase the
                        products or services offered (each, a "Request"), and
                        shall not use this Site to make any speculative, false,
                        or fraudulent Requests. You may not use robots or other
                        automated means to access this Site unless specifically
                        permitted by OvernightColor.com LLC. You represent that
                        you are of sufficient legal age to create binding and
                        legal obligations for any liability you may incur as a
                        result of your use of this Site. You agree to promptly
                        update your membership information (if applicable) to
                        keep it current, complete, and accurate.
                    </p>
                    <p>
                        It is a violation of the law to place a request in a
                        false name or with an invalid or stolen credit card.
                        Please be aware that even if you do not give us your
                        real name, your web browser transmits a unique Internet
                        address to us that can be used by law enforcement
                        officials to identify you. Fraudulent users may be
                        prosecuted to the fullest extent allowed by the law.
                    </p>
                    <p>
                        OvernightColor.com LLC may terminate this license and
                        your use of or access to this Website if you make or
                        permit any unauthorized use of this Website or the
                        information, Site Content, services, products, and
                        materials contained in or offered through this Website.
                        Such actions by you may also violate applicable laws
                        including, without limitation, copyright laws, trademark
                        laws (including trade dress), and communications
                        regulations and statutes. We reserve the right to have
                        all violators prosecuted to the fullest extent of the
                        law.
                    </p>
                    <p>
                        <strong>Communication</strong> Your use of this Website
                        constitutes your agreement and consent that
                        OvernightColor.com LLC may communicate with you
                        electronically for all purposes, including legal and
                        notice requirements. All electronic communications may
                        take the form of postings to this Website and/or emails
                        forwarded to the email address you provide when you
                        register with OvernightColor.com LLC. You may withdraw
                        your consent to receive electronic communication at any
                        time, but if you withdraw your consent, it will only be
                        effective prospectively (not for jobs already ordered or
                        in production). If you do not consent to the electronic
                        communication method your account will be deactivated,
                        and you will no longer be eligible to use this Website
                        to order further products or services from
                        OvernightColor.com LLC. If your account is deactivated,
                        you will remain responsible for payment of print jobs
                        already completed or in production.
                    </p>
                    <p>
                        This electronic document, together with any other
                        electronic documents, policies, and guidelines
                        incorporated herein, as well as any email communication
                        with you, will be deemed for all purposes to be a
                        "writing," "in writing," "yes, I agreed" and shall
                        comply with all statutory, contractual, and other legal
                        requirements for a written agreement. All such
                        electronic documents shall be legally enforceable and
                        legally bound as a signed writing as against the parties
                        subject to the electronic documents. All such electronic
                        documents shall be deemed as an "original" when printed
                        from electronic records that have been established and
                        maintained in the ordinary course of business. These
                        electronic documents and any communication that are
                        established and maintained in the ordinary course of
                        business shall be admissible in judicial, arbitration,
                        mediation, or administrative proceedings to the same
                        extent as other records in written form that are
                        similarly established and maintained in the ordinary
                        course of business.
                    </p>
                    <p>
                        <strong>Payment</strong> All prices and amounts shown on
                        this Site are in U.S. Dollars (USD), unless otherwise
                        noted. If a User submits a request on the Site to
                        purchase printing products, mailing services, design
                        services, or other services, the User agrees that all
                        charges, taxes, and shipping and processing fees will
                        automatically be charged to the credit card or paid by
                        User with an approved payment method. Such amounts will
                        be posted on this Site.
                    </p>
                    <p>
                        All purchase requests must be submitted with an approved
                        payment method unless otherwise expressly stated.
                        Approved payment methods include any of the following
                        methods:
                    </p>
                    <p>
                        <ul>
                            <li>
                                <strong>Pay by credit card:</strong> This is
                                simple and safe. Most of our customers pay this
                                way. Our secure server scrambles your payment
                                data so that only our computers can decode it.
                            </li>
                            <li>
                                <strong>Pay by pre-pay, cash, or check:</strong>{" "}
                                Choose this option and finish your order. After
                                you're done, we will instruct you on how to
                                submit cash or mail your check to us.
                            </li>
                            <li>
                                <strong>Pay on account:</strong> Available ONLY
                                to government and educational institutions
                                unless otherwise stated.
                            </li>
                        </ul>
                    </p>
                    <p>
                        Unless OvernightColor.com LLC agrees otherwise, all
                        product and service orders must be paid for at the time
                        of the order and accompanied by an authorization to
                        charge a credit card on file with OvernightColor.com LLC
                        or to charge a valid, authentic, and current credit
                        card, which you are authorized to use, that you provide
                        at the time of the order. If a charge against a credit
                        card you provide is not authorized or accepted, or is
                        subsequently refused or rejected, we reserve the right
                        to cancel your order without notice or liability.
                    </p>
                    <p>
                        In the event that OvernightColor.com LLC and you have
                        agreed upon an alternative method of payment and the
                        payment is not received within 5 business days of the
                        date on which payment was due, you hereby irrevocably
                        agree that OvernightColor.com LLC may charge one or more
                        of the credit cards(s) you have on file for the full
                        amount of your outstanding account balance. A financial
                        charge of 12% will be applied to your balance after 30
                        days.
                    </p>
                    <p>
                        Please note that no work will proceed on any print job
                        in your order until full payment is received by
                        OvernightColor.com LLC.
                    </p>
                    <p>
                        <strong>Sales Tax Policy</strong> OvernightColor.com LLC
                        is required by law to collect sales tax on purchases in
                        the States of Texas. Sales tax is calculated based on
                        the printing and finishing product subtotal. If tax was
                        charged on your order and you would like to claim
                        tax-exempt status, you can request an adjustment for the
                        amount of sales tax charged by sending in documentation
                        of your tax-exempt status. Please fax a copy of your
                        tax-exempt certificate along with your order number to
                        the attention of the Credit department on (713)
                        254-6776. After receiving and verifying your
                        certification, we will send you a confirmation email of
                        your changed account status.
                    </p>
                    <p>
                        <strong>
                            Refunds, Reprints, or Order Cancellations
                        </strong>{" "}
                        All sales are final. No refunds or credit are available
                        unless artwork files have NOT been uploaded to our FTP
                        site. For orders that have been uploaded but not gone
                        into production, a $35 processing fee will be charged
                        for each job cancellation plus any labor hours or
                        administrative fees (credit card fees, etc.) will be
                        subtracted from your total and the balance will be
                        issued as credit toward your next order. If an order is
                        canceled after we have sent you a hard color proof, the
                        charges for the hard copy proof are non-refundable, and
                        the balance of the order will be issued as credit toward
                        the next order. No cash refunds are offered.
                    </p>
                    <p>
                        Since each order is unique to each customer and has no
                        resale value to OvernightColor.com LLC, All Sales Are
                        Final. Customers must notify OvernightColor.com LLC
                        within 5 business days of receiving the printed
                        product(s) to report any defects discovered. Please note
                        that (100%) of the product MUST be returned to
                        OvernightColor.com LLC at the customer's expense within
                        7 business days from the date of delivery.
                        OvernightColor.com LLC QC staff will inspect the
                        returned product(s) prior to issuing a "reprint" notice.
                        Should the claim be approved, a reprint will be
                        processed and shipped to the customer using the same
                        shipping method chosen at NO cost to the customer. All
                        charges related to the canceled order such as (Rush,
                        Hardcopy proof, Shipping) are NON-Refundable nor are
                        they credited toward future orders.
                    </p>
                    <p>
                        Each order made by OvernightColor.com LLC is a contract
                        between you, as the customer making the order, and
                        OvernightColor.com LLC. The fact that you have placed an
                        order on behalf of a third party does not absolve you of
                        liability to OvernightColor.com LLC. As such, any
                        decision by the third party not to accept the particular
                        items printed or to cancel the order with you does not
                        affect your liability to OvernightColor.com LLC.
                    </p>
                    <p>
                        We stand 100% behind all our work, but this guarantee
                        does not cover customer-caused errors such as poor image
                        resolution, using the wrong color mode, poor scanning of
                        photographic materials, spelling, grammar, and
                        punctuation mistakes, or incorrect copy contents.
                    </p>
                    <p>
                        <strong>Change Order</strong> All jobs that have been
                        approved CANNOT be altered or changed due to the
                        automation workflow of OvernightColor.com LLC. Any
                        changes will result in a delay of up to 4 additional
                        working days. The result will also be an additional
                        $150.00 to be added to your print order. Changes made
                        before the digital proof are $15.00. A change order fee
                        of $45.00 for any additional digital proof after the
                        first was made will be applied to the order.
                    </p>
                    <p>
                        <strong>Submitting / Uploading Artwork Files</strong>{" "}
                        Our prepress department checks most artwork files before
                        printing, but you are still 100% responsible for the
                        accuracy of your print-ready artwork files, and we
                        encourage you to proofread all files carefully before
                        submitting them to OvernightColor.com LLC. As postal
                        regulations are subject to change, you are also 100%
                        responsible for complying with current mailing
                        restrictions for your layouts. Check with your local
                        Post Office. However, we also offer file preparation
                        support services for an additional cost to assist you in
                        preparing your files for printing.
                    </p>
                    <p>
                        You certify that you have the right to use the image(s)
                        in your artwork files. DO NOT send any "one-of-a-kind"
                        transparencies, prints, or artwork. Although we take
                        every precaution to safeguard your materials, we are NOT
                        responsible for loss or damage of images or artwork.
                    </p>
                    <p>
                        <strong>Uploading Requirements:</strong>
                    </p>
                    <p>
                        <ul>
                            <li>
                                A minimum of 300 DPI at 1:1 ratio (or 100%).
                                Content that does not meet this standard may
                                print out as images that are fuzzy, pixelated,
                                or otherwise distorted for which
                                OvernightColor.com LLC does not accept any
                                responsibility. We reserve the right to reject
                                content that does not meet this standard, but we
                                do not assume any obligation to verify that the
                                content meets this criterion.
                            </li>
                            <li>
                                Designed in CMYK color mode and uploaded files
                                to OvernightColor.com LLC in CMYK with no
                                embedded color profile. (You may upload in RGB
                                or some other standard if you choose, however,
                                the color will be converted to CMYK and
                                OvernightColor.com LLC is not responsible for
                                any inaccuracy or color shift that occurs as a
                                result of the conversion).
                            </li>
                        </ul>
                    </p>
                    <p>
                        Under these Terms and Conditions, you agree that you
                        will NOT upload any artwork files consisting of the
                        following material: any material that could give rise to
                        any civil or criminal liability under applicable law;
                        and any material that could infringe rights of privacy,
                        publicity, copyrights, or other intellectual property
                        rights without the permission of the owner of these
                        rights and the persons who are shown in the material if
                        applicable.
                    </p>
                    <p>
                        <strong>Color Accuracy OvernightColor.com</strong> LLC
                        guarantees that its printing will meet CMYK print
                        industry standards. We cannot guarantee a "match-print"
                        color fidelity and cannot prevent a slight color shift
                        throughout an order. OvernightColor.com LLC will
                        reproduce color from submitted print-ready files as
                        closely as possible but cannot exactly match color and
                        density (as viewed in a 5000K light booth) because of
                        limitations in the printing process, as well as
                        neighboring image ink requirements. Because we cannot
                        control the color reproduction of a customer's monitor
                        or inkjet printer, we cannot guarantee that the actual
                        print color will precisely match any preview images that
                        appear on your monitor or your printer.
                        OvernightColor.com LLC is not responsible for any color
                        shift that occurs in conversions from RGB to CMYK color
                        space or PMS Pantone color to CMYK color values with
                        black, near black, or gray tone. Digital proof does not
                        guarantee black, rich black, near black, or gray tone
                        final printed output. For close results, always work in
                        CMYK color space when working on your artwork.
                        OvernightColor.com LLC is not liable for color matching
                        or ink density on screen proofs approved by customers.
                        OvernightColor.com LLC will not be responsible for color
                        integrity with "post print options" such as UV Coating,
                        Lamination, Aqueous Coating, and varnishing may affect
                        or change the appearance of the final printed color.
                    </p>
                    <p>
                        The accuracy of each color reproduction (of the final
                        printed product) is guaranteed to be within 90% of the
                        final proof you approved. Please note that
                        OvernightColor.com LLC cannot guarantee color
                        reproduction for your print-ready files if you: (a) did
                        not request a hardcopy proof; or (b) you did not follow
                        OvernightColor.com LLC's guidelines. Also, note that we
                        accept no responsibility for color variations between
                        submitted print-ready images and the actual artwork or
                        product they represent. By placing an order with
                        OvernightColor.com LLC, you agree to all mentioned
                        limitations.
                    </p>
                    <p>
                        <strong>Artwork Resolution</strong> All artwork/images
                        must be at a minimum 300 dpi high-resolution images
                        suited for commercial printing. OvernightColor.com LLC
                        is not liable for images printing as bitmaps, fuzzy,
                        distorted, pixelated, and low-resolution as the result
                        due to customer-provided artwork.
                    </p>
                    <p>
                        <strong>Rich Black/Black</strong> OvernightColor.com LLC
                        prints all orders in process color (e.g., CMYK). CMYK
                        jobs that contain large solid areas of black ink should
                        have the black value set at (60, 20, 20, 100).
                        OvernightColor.com LLC will not be liable for printing a
                        consistent, solid, and rich black if the artwork is not
                        set up to this specification. (Do not use Rich Black on
                        text and strokes).
                    </p>
                    <p>
                        <strong>Proofing & Hard Copy/Digital</strong> If you
                        submit a job and do not request a hard copy color proof,
                        your job(s) will be printed AS-IS. OvernightColor.com
                        LLC will not be liable for color variation from the soft
                        proof (e.g., monitor). OvernightColor.com LLC will make
                        every effort to match the colors of your submitted
                        artwork, whether your job is color critical or not. It
                        is the customer's responsibility to determine if the job
                        is color critical.
                    </p>
                    <p>
                        A hard copy color proof does not guarantee a reasonable
                        likeness to the final printed pieces. Hard copy proof is
                        printed on a substrate that does not match the final
                        paper stocks used in our printing process. The hard copy
                        color proof is color-matched to a four-color process on
                        coated paper and does NOT simulate other stocks such as
                        uncoated paper. While a hard copy color proof will
                        visually show color, variation between the hard copy
                        color proof and the final printed piece is to be
                        expected. If a variation occurs due to these
                        circumstances, the resulting printed job is considered
                        an acceptable print performance.
                    </p>
                    <p>
                        OvernightColor.com LLC assumes no obligation to prove or
                        otherwise review the content or layout of your order.
                        Even if an OvernightColor.com LLC customer service
                        representative has inquired as to the attributes of one
                        of your prior orders, you are not entitled nor should
                        you assume that OvernightColor.com LLC will review any
                        other order you place thereafter. Orders are printed in
                        their "as submitted" form, and the customer is fully
                        responsible for final proof and layout verification and
                        approval prior to submission to the print process.
                        OvernightColor.com LLC DOES NOT make any changes to
                        customer files. Once you submit an order to the
                        automated printing process, you agree that you have/are
                        fully satisfied with the document layout and content,
                        and you accept full responsibility for any errors
                        therein. OvernightColor.com LLC will assume that you
                        have/and verified the spelling, grammar, content, and
                        layout, etc., are/is all correct and it will not accept
                        any liability for errors such as misspelling, grammar,
                        damaged fonts, punctuation, transparency, overprint,
                        improper layout, bleeding, erroneous cut or fold lines,
                        die lines or crop marks, sizing, etc.
                    </p>
                    <p>
                        OvernightColor.com LLC does not provide proof of any
                        order unless the customer requests it as part of the
                        order process, and it is an option available for each
                        product(s). If you request proof, then it is your
                        responsibility to verify the proof against the original
                        (and to correct any spelling or grammatical errors,
                        etc.).
                    </p>
                    <p>
                        If proof is requested, then your job will not be
                        processed or deemed production ready until you have
                        approved the proof in writing. Please be aware that this
                        may impact the job turnaround time.
                    </p>
                    <p>
                        If you order “Next Day Hardcopy" proof, your order must
                        be placed, all files uploaded, and all files approved by
                        1:00 P.M. CST to promise next-day delivery of your
                        proof. If you order a "Next Day Hardcopy" Proof and you
                        order booklets, catalogs, newsletters, or calendar
                        products, our cut-off time is 11:00 A.M. CST for these
                        products. If you want more than one Hardcopy proof per
                        job, we reserve the right to charge an additional $5 per
                        page (for booklets and other multi-page products) or an
                        additional $20 per proof (for all other products).
                    </p>
                    <p>
                        OvernightColor.com LLC recommends that if your order is
                        "color critical" please order a digital "Hardcopy
                        Proof". Depending on the size of your order
                        OvernightColor.com LLC may require that you receive a
                        "Hardcopy Proof" in which case there will NOT be any
                        charge to your order.
                    </p>
                    <p>
                        <strong>Over/Under-Runs</strong> OvernightColor.com LLC
                        reserves the right to over or underrun not to exceed 5%
                        of all orders. If a job is short more than 5%,
                        OvernightColor.com LLC will credit the percentage short
                        toward future orders or, at OvernightColor.com LLC's
                        discretion, will reprint the under amount for jobs
                        shortage of more than 10% of the ordered quantity. We
                        suggest when ordering to account for this over/under
                        percentage. If an exact count is critical, please
                        contact our representative. Additional fees may apply if
                        an absolute quantity is requested.
                    </p>
                    <p>
                        <strong>Printing Turnaround Time (PTT)</strong>{" "}
                        Turnaround times do not include shipping time. The
                        estimated date you will receive your order is a
                        combination of the selected turnaround time and shipping
                        time. Shipping time is based on the shipping method that
                        was chosen during your checkout. Due to our automated
                        tracking, once your order is placed, your turnaround
                        time generally cannot be changed, and billing charges
                        will not be reversed.
                    </p>
                    <p>
                        PTT begins once your order has been placed, your
                        print-ready files have been uploaded with your order and
                        are attached to your printing job and approved for
                        printing. If you order a next-day hardcopy proof or
                        standard hardcopy proof, PTT begins when we receive
                        approval for your proof. Please note that if we are
                        unable to print your files because they do not conform
                        to our file requirements, your job status will return to
                        "Need Artwork," and your printing turnaround time will
                        be reset. For printing jobs that do not have complete
                        digital source files provided, PTT begins when we have
                        your print-ready file(s) for your print job, not from
                        when the order is first submitted. If your job or proof
                        is approved by 6:00 P.M. CST (printing turnaround time
                        begins the next following business day). For example: a
                        job for the three-day printing turnaround that you
                        approved by 6:00 P.M. CST on Tuesday will be shipped to
                        you by end of business Friday. Please note that
                        OvernightColor.com LLC offices are closed Saturdays,
                        Sundays, and Holidays. As a result, these days are not
                        considered when calculating printing turnaround time. In
                        addition, printing turnaround time does not include
                        shipping transit time, and you should allow additional
                        business days for delivery based on the shipping method
                        you selected.
                    </p>
                    <p>
                        For your convenience, depending on the product ordered,
                        OvernightColor.com LLC offers a variety of different
                        production turnaround times. Not all of the following
                        turnaround times are offered on every product; the
                        available turnaround times will be listed with the
                        product as part of the order process.
                    </p>
                    <p>
                        <ul>
                            <li>
                                <strong>Next Business Day:</strong> All
                                production-ready jobs received and confirmed by
                                no later than 10:00 a.m. will be ready by the
                                end of the next business day. Production-ready
                                jobs not placed and confirmed by 10:00 a.m. will
                                be treated as having been received before 10:00
                                a.m. the following business day.
                            </li>
                            <li>
                                <strong>Next Day + 1:</strong> All
                                production-ready jobs received and confirmed by
                                no later than 1:00 p.m. will be ready by the end
                                of the second business day after receipt.
                                Production-ready jobs not placed and confirmed
                                by 1:00 p.m. will be treated as having been
                                received before 1:00 p.m. the following business
                                day.
                            </li>
                            <li>
                                <strong>2-4 Day:</strong> All production-ready
                                jobs received and confirmed by no later than
                                1:00 p.m. will be ready between 1:00 p.m. the
                                second business day after receipt and 1:00 p.m.
                                on the fourth business day after receipt.
                            </li>
                            <li>
                                <strong>5-7 Day:</strong> All production-ready
                                jobs received and confirmed by no later than
                                1:00 p.m. will be ready between 1:00 p.m. the
                                fifth day after receipt and 1:00 p.m. on the
                                seventh day after receipt.
                            </li>
                        </ul>
                    </p>
                    <p>
                        Please note: Turnaround times for jobs that
                        OvernightColor.com LLC does not deem production ready
                        (e.g., those with problems or that require a proof) will
                        not begin until the job is approved and ready for
                        production. To qualify for same-day PTT for selected
                        products, your order must be placed, all files uploaded,
                        and all files approved by 6:00 A.M. CST. If an order
                        qualifies for the same-day printing turnaround, it will
                        be shipped or ready for pick-up between 5:00 & 9:00 P.M.
                        CST, the same business day. Orders with the same-day
                        printing turnaround placed on Saturdays, Sundays, or
                        Holidays will be processed the next business day.
                    </p>
                    <p>
                        Printing turnaround time for jobs with approved payment
                        and accepted print-ready files is guaranteed. The remedy
                        for failing to meet a deadline is limited to a refund of
                        any rush charges or a courtesy rush on your next order.
                        Cancellation of an order based on printing turnaround
                        time is only allowed if the customer indicates in
                        writing during the order (e.g., note posted to order)
                        when placing the original order that a job in the order
                        is "time sensitive" and must be shipped by target date
                        based on the printing turnaround option selected.
                    </p>
                    <p>
                        The ONLY other restriction to PTT would be if your order
                        is held up in accounting due to Non-sufficient funds,
                        payment method declined, or any other reasons beyond the
                        control of the production department.
                    </p>
                    <p>
                        <strong>Shipping</strong> Unless you choose Pick-Up or
                        Mailing Services, you need to select one of the shipping
                        options presented to you on the Site.
                    </p>
                    <p>
                        All shipping except Special COD is currently done via
                        UPS. When choosing a shipping option, please remember
                        that the estimated shipping transit time is based on the
                        number of business days in transit and does not include
                        weekends, holidays, or the day the package is picked up
                        by UPS. For instance, a product shipped 2nd Day Air and
                        picked up on a Thursday would be delivered by the end of
                        the day Monday.
                    </p>
                    <p>
                        For Special COD delivery (i.e., Air Cargo, Greyhound, or
                        other shipping methods), there is a processing fee
                        payable to OvernightColor.com LLC at the time of
                        purchase for making all necessary arrangements with the
                        carrier. In addition, the customer pays the shipping
                        charge at the time of delivery. The Special COD shipping
                        charge is set by the carrier, and orders shipped by COD
                        must be picked up at the carrier's location. Delivery to
                        your door is not included or available when you select
                        Special COD shipping options.
                    </p>
                    <p>
                        Shipping transit times vary, and OvernightColor.com LLC
                        assumes no responsibility for delays caused by shipping
                        carriers, weather, or any damage resulting from the
                        failure to receive a job on time. Your order may arrive
                        late due to unforeseen delays in the delivery service,
                        the breakdown of equipment, illness, etc. Pick-Up orders
                        will be held for 30 days at our facility. After 30 days,
                        we will recycle your order.
                    </p>
                    <p>
                        You agree that shipping terms for all OvernightColor.com
                        LLC products are shipped "Freight On Board Shipping
                        Point" or "F.O.B. Shipping Point" - Title and Risk of
                        loss transfers to you at the time of shipment or pickup.
                    </p>
                    <p>
                        Lost or damaged claims can only be filed for shipments
                        over $50.00 in value and can only be done after 30 days
                        from package shipment. OvernightColor.com LLC is not
                        responsible for 3rd party shipping errors, omissions, or
                        damaged shipments. OvernightColor.com LLC's
                        responsibility is limited to preparing your printing
                        order for shipping. OvernightColor.com LLC is not liable
                        for damages which occur in or during shipping.
                    </p>
                    <p>
                        <strong>Copyright Notice</strong> You, the User,
                        acknowledge that all content included on this Site,
                        including, without limitation, the information, data,
                        software, photographs, graphs, typefaces, graphics,
                        images, illustrations, maps, designs, icons, written and
                        other material and compilations (collectively, "Site
                        Content") are intellectual property and copyrighted
                        works of OvernightColor.com LLC (DBA OvernightColor.com
                        LLC and/or various third-party providers, "Providers").
                        Reproductions or storage of Content retrieved from this
                        Site, in all forms, media, and technologies now existing
                        or hereafter developed, is subject to the U.S. Copyright
                        Act of 1976, Title 17 of the United States Code. Your
                        use of this Website and/or any of its Site Content does
                        not convey to you or any other person an interest of any
                        kind written or implied to the Site Content.
                    </p>
                    <p>
                        <strong>
                            Corporate Identification and Trademarks/Copyrights
                        </strong>{" "}
                        Any and all other marks appearing on this Site are
                        trademarks of OvernightColor.com LLC (DBA
                        OvernightColor.com LLC in the United States and other
                        jurisdictions ("Trademarks"). You may not use, copy,
                        reproduce, republish, upload, post, transmit,
                        distribute, or modify the Trademarks in any way,
                        including in advertising or publicity pertaining to the
                        distribution of materials on this Site, without
                        OvernightColor.com's prior written consent. The use of
                        Trademarks on any other website or network computer
                        environment is prohibited. OvernightColor.com LLC
                        prohibits the use of Trademarks as a "hot" link on, or
                        to, any other website unless the establishment of such a
                        link is pre-approved by OvernightColor.com LLC in
                        writing. All other Trademarks, product names, and
                        company names and/or logos mentioned on this Website are
                        the property of their respective owners.
                    </p>
                    <p>
                        <strong>
                            Limitation of Liability / Disclaimer of Warranty
                        </strong>{" "}
                        THIS WEBSITE AND THE INFORMATION, SITE CONTENT,
                        SERVICES, PRODUCTS, AND MATERIALS CONTAINED IN OR
                        OFFERED THROUGH THIS WEBSITE, ARE PROVIDED ON AN "AS IS"
                        BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                        IMPLIED. TO THE MAXIMUM EXTENT PERMITTED BY LAW,
                        OVERNIGHTCOLOR.COM LLC DISCLAIMS ALL WARRANTIES, EXPRESS
                        OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM COMPUTER
                        VIRUSES, AND IMPLIED WARRANTIES ARISING FROM COURSE OF
                        DEALING OR COURSE OF PERFORMANCE, WITH RESPECT TO THIS
                        WEBSITE AND THE INFORMATION, SITE CONTENT, SERVICES,
                        PRODUCTS, AND MATERIALS CONTAINED IN OR OFFERED THROUGH
                        THIS WEBSITE. OVERNIGHTCOLOR.COM LLC DOES NOT REPRESENT
                        OR WARRANT THAT THE INFORMATION, SITE CONTENT, SERVICES,
                        PRODUCTS, AND MATERIALS CONTAINED IN OR OFFERED THROUGH
                        THIS WEBSITE ARE ACCURATE, COMPLETE, RELIABLE, CURRENT,
                        OR ERROR-FREE, THAT THE SERVICES AND PRODUCTS OFFERED
                        WILL MEET THE USER'S REQUIREMENTS, OR THAT THE SERVICES
                        AND PRODUCTS WILL BE PROVIDED IN A TIMELY,
                        UNINTERRUPTED, OR SECURE MANNER. OVERNIGHTCOLOR.COM LLC
                        IS NOT RESPONSIBLE FOR TYPOGRAPHICAL ERRORS OR OMISSIONS
                        RELATING TO PRICING, TEXT, OR PHOTOGRAPHY.
                        OVERNIGHTCOLOR.COM LLC DOES NOT REPRESENT OR WARRANT
                        THAT THIS WEBSITE OR ITS SERVER(S) ARE FREE OF VIRUSES,
                        TROJANS, MALWARE, OR OTHER HARMFUL OR MALICIOUS
                        COMPONENTS, EVEN IF OVERNIGHTCOLOR.COM LLC OR ITS
                        AUTHORIZED REPRESENTATIVES HAVE BEEN ADVISED OF SUCH
                        POSSIBILITY. YOU EXPRESSLY AGREE THAT THE USE OF THIS
                        SITE, INCLUDING ALL CONTENT, DATA OR SOFTWARE
                        DISTRIBUTED BY, DOWNLOADED OR ACCESSED FROM OR THROUGH
                        THIS SITE, IS AT YOUR SOLE RISK.
                    </p>
                    <p>
                        IN NO EVENT SHALL OVERNIGHTCOLOR.COM LLC, OR ITS
                        SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
                        AFFILIATES, OR SUBSIDIARIES, BE LIABLE (JOINTLY OR
                        SEVERALLY) TO ANY PERSON OR ENTITY FOR ANY SPECIAL,
                        INCIDENTAL, INDIRECT, CONSEQUENTIAL, PUNITIVE, OR OTHER
                        INDIRECT DAMAGES OF ANY KIND OR NATURE, UNDER ANY LEGAL
                        THEORY, INCLUDING DAMAGES RESULTING FROM LOSS OF USE,
                        LOSS OF PROFITS AND/OR LOSS OF DATA, WHETHER IN AN
                        ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO
                        NEGLIGENCE), OR OTHERWISE, AND WHETHER OR NOT
                        OVERNIGHTCOLOR.COM LLC HAS BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN
                        CONNECTION WITH: (1) THE USE OR PERFORMANCE OF THIS
                        WEBSITE; (2) ERRORS OR FAULTS IN THE INFORMATION, SITE
                        CONTENT, SERVICES, PRODUCTS, OR MATERIALS CONTAINED IN
                        OR OFFERED THROUGH THIS WEBSITE; (3) THE USE OF
                        INFORMATION, SITE CONTENT, SERVICES, PRODUCTS, OR
                        MATERIALS PROVIDED THROUGH THIS WEBSITE; (4) THE
                        INABILITY TO USE THIS WEBSITE, ANY PART OF THIS WEBSITE,
                        OR ANY FUNCTION OFFERED BY THE WEBSITE; (5) A FAILURE TO
                        PROVIDE PRODUCTS OR SERVICES ORDERED FROM
                        OVERNIGHTCOLOR.COM LLC (OR ANY AFFILIATE THEREOF) FOR
                        ANY REASON INCLUDING ANY LOST, CORRUPTED, OR DAMAGED
                        CUSTOMER DATA, NON-DELIVERY, MIS-DELIVERY, LATE
                        DELIVERY, OR LOST OR DAMAGED SHIPMENTS; (6) MISTAKE,
                        OMISSION, DELAY, OR INTERRUPTION OF SERVICE; AND/OR (7)
                        CONTAMINATION BY VIRUS, TROJAN, OR MALWARE OF ANY KIND.
                    </p>
                    <p>
                        SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED
                        WARRANTIES, OR THE EXCLUSION OR LIMITATION OF INCIDENTAL
                        OR CONSEQUENTIAL DAMAGES. IF THESE LAWS APPLY, SOME OR
                        ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS
                        MAY NOT APPLY TO YOU, AND YOU MAY HAVE RIGHTS ADDITIONAL
                        TO THOSE CONTAINED HEREIN.
                    </p>
                    <p>
                        OVERNIGHTCOLOR.COM LLC, TOGETHER WITH ITS SHAREHOLDERS,
                        DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, AFFILIATES, OR
                        SUBSIDIARIES, SHALL NOT BE RESPONSIBLE FOR ANY DIRECT
                        DAMAGES, AND SHALL NOT PROVIDE ANY CREDIT, REFUND, OR
                        ADJUSTMENT OF ANY KIND OR NATURE THAT IS IN EXCESS OF
                        THE AMOUNT ACTUALLY PAID TO OVERNIGHTCOLOR.COM LLC
                        (AFTER ACCOUNTING FOR AND DEDUCTING, AS APPLICABLE,
                        SPECIAL OFFERS, DISCOUNTS, REWARDS POINTS, ETC.) FOR THE
                        PRINTED PRODUCT (AND SHIPPING COSTS IF APPLICABLE) AT
                        ISSUE.
                    </p>
                    <p>
                        UNDER NO CIRCUMSTANCES WILL OVERNIGHTCOLOR.COM LLC BE
                        LIABLE TO YOU IN ANY WAY FOR ANY SITE CONTENT YOU MAY BE
                        EXPOSED TO YOU THAT YOU MAY FIND OFFENSIVE, INDECENT, OR
                        OBJECTIONABLE.
                    </p>
                    <p>
                        <strong>Governing Law</strong> The internal laws of the
                        state of Texas shall govern the performance of these
                        Terms and Conditions, without regard to such state's
                        conflicts of laws principles. You consent to the
                        exclusive jurisdiction and venue of the courts located
                        in Houston, Texas, for all disputes arising out of, or
                        relating to, the Terms and Conditions and use of this
                        Site. Furthermore, regardless of where you access this
                        site, you agree that any action at law or in equity
                        arising out of or relating to these Site Terms and/or
                        your use of this Website shall be filed and adjudicated
                        only in the federal or state courts located in Houston,
                        Texas, and you hereby irrevocably and unconditionally
                        consent and submit to the exclusive jurisdiction and
                        venue of such courts over any suit, action, or
                        proceeding arising out of or under these Site Terms
                        and/or your use of this Website. This Agreement is not
                        governed by the United Nations Convention of Contracts
                        for the International Sale of Goods, the application of
                        which is expressly excluded. We make NO representation
                        that our websites or services are appropriate, legal, or
                        available for use in other locations. If you choose to
                        access our website, you absolutely agree to do so
                        subject to this governing law set forth.
                    </p>
                    <p>
                        <strong>Termination of Usage</strong> User access to all
                        or part of this Site may be terminated or suspended at
                        any time, without notice and for any reason. Your
                        account and/or your right to utilize the services made
                        available through this Website in the event that it
                        determines or/and has reasonable grounds to suspect that
                        you are in violation of the Site Terms and Conditions.
                        Notwithstanding any of these Site Terms and Conditions
                        that may suggest otherwise, OvernightColor.com LLC
                        reserves the right, without notice and in its sole
                        discretion, to terminate your right to use this Website
                        for any reason or no reason at all, and to block or
                        prevent future access to and use of this Website by you.
                        In the event your right to use this website is
                        terminated, these Site Terms and Conditions shall still
                        apply. If your account is deemed terminated/deactivated,
                        this will not affect your responsibility for payment(s)
                        of job(s) already completed and/or in production.
                    </p>
                    <p>
                        <strong>Indemnification</strong> You agree that you
                        shall defend, indemnify and hold harmless
                        OvernightColor.com LLC and ALL its officers, directors,
                        employees, shareholders, agents, affiliates,
                        subsidiaries, suppliers, and representatives, from and
                        against any and all claims, damages, losses, costs, and
                        expenses (including but not limited to attorneys' fees)
                        that, directly or indirectly, arise out of or are
                        related to: (i) your breach of any provision of the Site
                        Terms; (ii) any suit, claim, or demand arising from or
                        relating to an allegation that any Customer Content
                        provided by You, or any other text, photograph, image,
                        graphic or other material you incorporated into products
                        or services ordered from OvernightColor.com LLC, that
                        was not part of the standard Site Contents, infringes
                        upon the rights of a third party (iii) and/or (c) any of
                        your activities conducted in connection with this
                        Website, your use thereof, and/or the products and
                        services ordered through this Website. You also certify
                        that you own rights to or have a license to use the
                        image(s) being reproduced as part of your order.
                    </p>
                    <p>
                        <strong>Third Parties</strong> If you use this Site to
                        submit Requests for or on behalf of a third party
                        ("Third Party"), you are ULTIMATELY responsible for any
                        error in the accuracy of information provided in
                        connection with such use. In addition, you must inform
                        the Third Party of all Terms and Conditions applicable
                        to all products or services acquired through this Site,
                        including all rules and restrictions applicable thereto.
                    </p>
                    <p>
                        Each User using this Site for, or on behalf of, a Third
                        Party agrees to indemnify and hold each Covered Party
                        harmless from, and against, any and all liabilities,
                        losses, damages, suits, and claims (including the costs
                        of defense), relating to the Third Party's or the User's
                        failure to fulfill any of its obligations as described
                        (above). The User is directly responsible for any
                        Request submitted, including related fees, charges, and
                        performance obligations. The fact that you have made an
                        order on behalf of a third party DOES NOT absolve you of
                        liability and obligation to OvernightColor.com LLC. As
                        such, any decision by the third party not to accept
                        any/all particular items produced or cancel with you
                        DOES NOT affect your liability and obligation to
                        OvernightColor.com LLC.
                    </p>
                    <p>
                        <strong>Links to Other Web Sites and Services</strong>{" "}
                        To the extent this Site contains links to outside
                        services and resources, any concerns regarding such
                        services or resources should be directed to the
                        particular outside service or resource provider. None of
                        the Covered Parties guarantees or warrants the accuracy
                        or completeness of the information or content included
                        on the websites of these outside services and resources.
                        The outside services and resources are in no way related
                        to OvernightColor.com LLC and are neither its employees
                        nor agents.
                    </p>
                    <p>
                        <strong>Privacy</strong> You confirm that you have read,
                        understood, and agree to the OvernightColor.com LLC
                        Privacy Policy, the terms of which are incorporated
                        herein, and agree that the terms of this policy are
                        reasonable and satisfactory to you. You consent to the
                        use of your personal information by OvernightColor.com
                        LLC, its third-party providers, and/or distributors in
                        accordance with the terms of and for the purposes set
                        forth in the OvernightColor.com LLC Privacy Policy.
                    </p>
                    <p>
                        <strong>Terms Applicable to All Prize(s):</strong> The
                        odds of claiming any Prize(s) depend on the number of
                        orders and the dollar amount placed under your Account.
                        No transfer, substitution, or cash equivalent for
                        Prize(s) is allowed, except at OvernightColor.com's sole
                        discretion. OvernightColor.com reserves the right to
                        substitute any Prize(s), in whole or in part, of equal
                        or greater monetary value if a Prize(s) cannot be
                        claimed, in whole or in part, as described for any
                        reason, including export laws. Value is subject to
                        market conditions, which can fluctuate, and any
                        difference between actual market value and ARV (Average
                        Retail Value) will not be awarded or matched. The
                        Prize(s) may be subject to restrictions and/or licenses.
                        OvernightColor.com has not made and OvernightColor.com
                        is not responsible in any manner for any warranties,
                        representations, or guarantees, express or implied, in
                        fact or law, relating to the Prize(s), regarding the
                        use, value, or enjoyment of the Prize(s), including,
                        without limitation, its quality, mechanical condition,
                        merchantability, or fitness for a particular purpose,
                        with the exception of any standard manufacturer's
                        warranty that may apply to the Prize(s) or any
                        components thereto.
                    </p>
                    <p>
                        <strong>Taxes:</strong> Payments to potential winners
                        are subject to the express requirement that they submit
                        to OvernightColor.com all documentation requested by
                        OvernightColor.com to permit it to comply with all
                        applicable federal, state, provincial, local, and
                        foreign tax reporting and withholding requirements. All
                        Prize(s) will be net of any taxes OvernightColor.com is
                        required by law to withhold. All taxes imposed on
                        Prize(s) are the sole responsibility of the winners. To
                        receive a Prize(s), potential winners must submit the
                        tax documentation requested by OvernightColor.com or
                        otherwise required by applicable law, to
                        OvernightColor.com or the relevant tax authority, all as
                        determined by applicable law, including, where relevant,
                        the law of the Prize(s) recipient(s) country of
                        residence. The potential Prize(s) recipient(s) is
                        responsible for ensuring that they comply with all the
                        applicable tax laws and filing requirements. If a
                        Prize(s) recipient(s) fails to provide such
                        documentation or comply with such laws, the Prize(s) may
                        be forfeited and OvernightColor.com may, in its sole
                        discretion, relist the Prize(s) back on the Prize(s)
                        list.
                    </p>
                    <p>
                        <strong>General Conditions:</strong> All federal, state,
                        provincial, and local laws and regulations apply.
                        OvernightColor.com reserves the right to disqualify any
                        Customer(s) from the Prize(s) if, in
                        OvernightColor.com's sole discretion, it reasonably
                        believes that the Customer(s) has attempted to undermine
                        the legitimate operation of the Prize(s) by cheating,
                        deception, or other unfair playing practices or annoys,
                        abuses, threatens, or harasses any other Customer(s),
                        Participant(s), Overnight Color, or the Staff.
                    </p>
                    <p>
                        <strong>Publicity:</strong> By accepting a Prize(s),
                        Customer(s) agrees to OvernightColor.com and its
                        agencies' use of his or her name and/or likeness and
                        Photographs for advertising and promotional purposes
                        without additional compensation, unless prohibited by
                        law.
                    </p>
                    <p>
                        <strong>Arbitration:</strong> By entering the
                        Customer(s), you agree that exclusive jurisdiction for
                        any dispute, claim, or demand related in any way to the
                        Prize(s) will be decided by binding arbitration. All
                        disputes between you and OvernightColor.com of
                        whatsoever kind or nature arising out of these Rules
                        shall be submitted to Judicial Arbitration and Mediation
                        Services, Inc. ("JAMS") for binding arbitration under
                        its rules then in effect in the Houston, Texas, USA
                        area, before one arbitrator to be mutually agreed upon
                        by both parties. The Customer(s) agrees to ALL
                        arbitration costs incurred.
                    </p>
                </div>
            </div>
            <div className="messages-container">
                <div className="message-main-container eco-friendly">
                    <div className="message-container">
                        <div className="title">ECO-FRIENDLY PRINTING</div>
                        <div className="message">
                            OvernightColor.com has taken the initiative and
                            grown into an environmentally friendly company by
                            taking significant steps in our production of
                            printed products.
                        </div>
                        <button onClick={handleEcoFriendly}>READ MORE</button>
                    </div>
                    <div className="image">
                        <img
                            src="https://overnightcolor.s3.amazonaws.com/css/eco-friendly.png"
                            alt="Eco Friendly"
                        />
                    </div>
                </div>
                <div className="message-main-container request-quote">
                    <div className="message-container">
                        <div className="title">REQUEST PRINT QUOTE</div>
                        <div className="message">
                            Request a quote for any print project by using our
                            user-friendly online form. Submit the details of
                            your print request and we will provide you with an
                            instant quote.
                        </div>
                        <button onClick={handleRequestQuote}>
                            REQUEST A QUOTE
                        </button>
                    </div>
                    <div className="image">
                        <img
                            src="https://overnightcolor.s3.amazonaws.com/css/request-quote.png"
                            alt="Request Quote"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;
