import React from 'react';
import Pagination from './../../Pagination';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { dateToTimezone } from './../../../utils';
import numeral from 'numeral';

const Table = (props) => {
    if (props.actionType !== 'list') {
        return null;
    }

    const handleDeleteModal = (id) => {
        props.setIdModal(id);
        props.showDeleteModal();
    };

    const renderElements = () => {
        if (props.fetchingElements) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.items.length === 0) {
            return (
                <tr>
                    <td colSpan="10">No results</td>
                </tr>
            );
        }

        return props.items.map((item) => {
            let deleteIcon = (
                <div className="action-icon">
                    <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fa-solid fa-trash" onClick={handleDeleteModal.bind(this, item.id)}></i>
                    </Tooltip>
                </div>
            );

            const pricing = JSON.parse(item.pricing);

            const optionsHtml = [];
            for (const feature of item.features) {
                if (feature.feature === 'productId') {
                    continue;
                }
                optionsHtml.push(<div><span className="feature-name">{feature.feature}:</span> {feature.option}</div>);
            }

            return (
                <tr>
                    <td className="project-name-column desktop-column">{item.project_name}</td>
                    <td className="order-id-column main-column">
                        <div className="desktop-column">{item.product_name}</div>
                        <div className="mobile-container">
                            <div className="mobile-item">
                                <div className="label">Project Name:</div>
                                <div className="value">{item.project_name}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Product Name:</div>
                                <div className="value">{item.product_name}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Quantity:</div>
                                <div className="value">{numeral(item.quantity).format('0,0')}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Customer:</div>
                                <div className="value">{item.customer_name}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Created at:</div>
                                <div className="value">{dateToTimezone(item.created_at, 'MM/DD/YYYY')}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Total Price:</div>
                                <div className="value"></div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Features:</div>
                                <div className="value">{optionsHtml}</div>
                            </div>
                            <div className="mobile-actions">
                                {deleteIcon}
                            </div>
                        </div>
                    </td>
                    <td className="quantity-column desktop-column">{numeral(item.quantity).format('0,0')}</td>
                    <td className="customer-column desktop-column">{item.customer_name}</td>
                    <td className="ordered-at-column desktop-column">{dateToTimezone(item.created_at, 'MM/DD/YYYY')}</td>
                    <td className="price-column desktop-column">{numeral(pricing.total).format('$0,0.00')}</td>
                    <td className="features-column desktop-column">{optionsHtml}</td>
                    <td className="actions-column desktop-column">
                        <div className="actions-container">
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const handleSort = (column, type) => {
        props.setSort(column, type, props.type);
    };

    const renderHeader = (label, field, sortEnabled = true) => {
        let sortType = 'asc';
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === field) {
            if (props.sort.type === 'desc') {
                sortIcon = <i className="fas fa-sort-down" />;
                sortType = 'asc';
            } else {
                sortIcon = <i className="fas fa-sort-up" />;
                sortType = 'desc';
            }
        }

        if (!sortEnabled) {
            return <div className="sort-item">{label}</div>
        }

        return <div className="sort-item" onClick={handleSort.bind(this, field, sortType)}>{label} {sortIcon}</div>
    };

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr className="header-row">
                        <th className="project-column desktop-column">{renderHeader('Project Name', 'project_name', false)}</th>
                        <th className="product-column desktop-column">{renderHeader('Product Name', 'product_name')}</th>
                        <th className="quantity-column desktop-column">{renderHeader('Quantity', 'quantity')}</th>
                        <th className="customer-column desktop-column">{renderHeader('Customer', 'customer_name')}</th>
                        <th className="ordered-at-column desktop-column">{renderHeader('Created at', 'created_at')}</th>
                        <th className="total-price-column desktop-column">{renderHeader('Total Price', 'total_price', false)}</th>
                        <th className="configuration-column desktop-column">{renderHeader('Configuration', 'features', false)}</th>
                        <th className="actions-column desktop-column"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderElements()}
                </tbody>
            </table>
            <Pagination
                pagination={props.pagination}
                setActivePage={props.setActivePage}
            />
        </div>
    );
}

export default Table;
