import React from 'react';
import { Link } from 'react-router-dom';

const Banner = (props) => {
    return (
        <div className="banner-container">
            <div className="content-container">
                <div className="title">Are you ready to get serious about your business?</div>
                <div className="subtitle">Make sure you have the professional edge with our superior-quality business cards.</div>
                <div className="shop-button">
                    <Link to="/sign-up">SIGN UP NOW!</Link>
                </div>
            </div>
        </div>
    );
}

export default Banner;
