import React from 'react';
import { Dialog } from './../../common/dialog';

const CreateScTicketModal = React.memo((props) => {
    const hideHandler = () => {
        props.setIdModal(null);
        props.hideCreateScTicketModal();
    };

    let buttonLabel = 'Create SC Ticket';
    if (props.creatingScTicket) {
        buttonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="generate-button" className="btn btn-light btn-sm admin-button" onClick={props.createScTicket.bind(this, props.idModal)}>{buttonLabel}</button>,
    ];
    return (
        <Dialog
            name="create-sc-ticket-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="admin-orders-create-sc-ticket-dialog"
        >
            <div className="admin-orders-create-sc-ticket-modal-container">
                <h1>Create SC Ticket</h1>
                <p>Click on the "Create SC Ticket" button if you want to create a new SC Ticket for the Job <strong>#{props.idModal}</strong>.</p>
            </div>
        </Dialog >
    );
});

export default CreateScTicketModal;
