import axios from 'axios';
import * as types from './MyAddressBook.types';
import * as selectors from './MyAddressBook.selectors';
import { validateAuthInResponse, showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchBillingAddresses = () => (dispatch, getState) => {
    const pagination = selectors.getPagination(getState());
    const sort = selectors.getSort(getState());

    let params = [];
    params.push('active-page=' + pagination.billing.activePage);
    params.push('elements-per-page=' + pagination.billing.elementsPerPage);
    params.push('sort-column=' + sort.billing.column);
    params.push('sort-type=' + sort.billing.type);

    dispatch({ type: types.FETCH_BILLING_ADDRESSES, payload: null });
    axios.get(apiUrl + '/billing-addresses?' + params.join('&'),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_BILLING_ADDRESSES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_BILLING_ADDRESSES_ERROR));
        });
}

export const fetchShippingAddresses = () => (dispatch, getState) => {
    const pagination = selectors.getPagination(getState());
    const sort = selectors.getSort(getState());

    let params = [];
    params.push('active-page=' + pagination.billing.activePage);
    params.push('elements-per-page=' + pagination.billing.elementsPerPage);
    params.push('sort-column=' + sort.billing.column);
    params.push('sort-type=' + sort.billing.type);

    dispatch({ type: types.FETCH_SHIPPING_ADDRESSES, payload: null });
    axios.get(apiUrl + '/shipping-addresses?' + params.join('&'),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_SHIPPING_ADDRESSES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_SHIPPING_ADDRESSES_ERROR));
        });
}

export const setAsDefault = (id, type) => (dispatch, getState) => {
    dispatch({ type: types.SET_AS_DEFAULT, payload: null });
    axios.post(apiUrl + '/' + type + '-address/set-as-default', { id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SET_AS_DEFAULT_SUCCESS, payload: response.data });

            if (type === 'billing') {
                dispatch(fetchBillingAddresses());
            } else {
                dispatch(fetchShippingAddresses());
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SET_AS_DEFAULT_ERROR));
        });
}

export const deleteAddress = (id, type) => (dispatch, getState) => {
    dispatch({ type: types.DELETE_ADDRESS, payload: null });
    axios.delete(apiUrl + '/' + type + '-addresses/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.DELETE_ADDRESS_SUCCESS, payload: response.data });
            showNotification('Address Deleted', 'The address has been deleted successfully', 'success');

            if (type === 'billing') {
                dispatch(fetchBillingAddresses());
            } else {
                dispatch(fetchShippingAddresses());
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DELETE_ADDRESS_ERROR));
        });
}

export const setActivePage = (type, value) => (dispatch, getState) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: { type, value },
        });
        resolve();
    }).then(() => {
        if (type === 'billing') {
            dispatch(fetchBillingAddresses());
        } else {
            dispatch(fetchShippingAddresses());
        }
    });
}

export const setSort = (type, sortColumn, sortType) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: { type, sortColumn, sortType }
        });
        resolve();
    }).then(() => {
        if (type === 'billing') {
            dispatch(fetchBillingAddresses());
        } else {
            dispatch(fetchShippingAddresses());
        }
    });
}

export const setHistory = (history) => dispatch => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}