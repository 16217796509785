import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import numeral from "numeral";
import { showNotification } from "./../../../utils";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
const apiUrl = process.env.REACT_APP_API_URL;

const PaymentForm = (props) => {
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [cardNumberEmpty, setCardNumberEmpty] = useState(true);
    const [expiryEmpty, setExpiryEmpty] = useState(true);
    const [cvcEmpty, setCvcEmpty] = useState(true);
    const [createdOrderId, setCreatedOrderId] = useState(null);

    const stripe = useStripe();
    const elements = useElements();

    const createPaymentIntent = async (total, id) => {
        if (total > 0) {
            try {
                const response = await axios.post(
                    apiUrl + "/shopping-cart-item/create-payment-intent",
                    { amount: numeral(total).format("0.00") * 100, id },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );

                return response.data.client_secret;
            } catch (error) {
                return "none";
            }
        }

        return "none";
    };

    const handleChange = async (event) => {
        setError(event.error ? event.error.message : "");

        if (event.elementType === "cardNumber") {
            setCardNumberEmpty(event.empty);
        }
        if (event.elementType === "cardExpiry") {
            setExpiryEmpty(event.empty);
        }
        if (event.elementType === "cardCvc") {
            setCvcEmpty(event.empty);
        }
    };

    const confirmOrder = (id, paymentPayload) => {
        axios
            .post(
                apiUrl + "/shopping-cart-item/confirm-order",
                { id, paymentPayload, paymentMethod: 'CreditCard' },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setError(null);
                setProcessing(false);
                props.setOrderId(id);

                showNotification('Order Placed', 'Your order has been placed successfully', 'success');
                props.history.push('/my-orders/details/' + response.data.id + '/1');
            })
            .catch((error) => {
                console.log(error);
                showNotification(
                    "Error",
                    "An error has occurred. Please, contact our customer support.",
                    "danger"
                );
            });
    };

    const processPayment = async (orderId) => {
        setCreatedOrderId(orderId);

        const clientSecret = await createPaymentIntent(props.total, orderId);
        if (!clientSecret || clientSecret === "none") {
            setError("Payment could not be processed. Please try again later.");
            setProcessing(false);
            return;
        }

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    email: props.user.email,
                },
            },
        });

        if (payload.error) {
            setError(`Payment failed: ${payload.error.message}`);
            setProcessing(false);
            showNotification(
                "Payment failed",
                payload.error.message,
                "warning"
            );
        } else {
            confirmOrder(orderId, payload);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (cardNumberEmpty) {
            return showNotification(
                "Complete Information",
                "Enter the Card Number",
                "info"
            );
        }
        if (expiryEmpty) {
            return showNotification(
                "Complete Information",
                "Enter the Expiry",
                "info"
            );
        }
        if (cvcEmpty) {
            return showNotification(
                "Complete Information",
                "Enter the CVC",
                "info"
            );
        }

        if (props.items === 0) {
            return showNotification(
                "Cart empty",
                "Your shopping cart is empty",
                "info"
            );
        }

        setProcessing(true);
        if (createdOrderId == null) {
            axios
                .post(
                    apiUrl + "/shopping-cart-item/place-order",
                    { fields: props.fields, paymentMethod: "CreditCard" },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then(async (response) => {
                    processPayment(response.data.id);
                })
                .catch((error) => {
                    console.log(error);
                    if (
                        error.response &&
                        (error.response.status === 401 ||
                            error.response.status === 403)
                    ) {
                        setProcessing(false);
                        props.logout();
                    } else {
                        showNotification(
                            "Error",
                            "An error has occurred.",
                            "danger"
                        );
                    }
                });
        } else {
            processPayment(createdOrderId);
        }
    };

    if (props.paymentMethod === "CreditCard") {
        return (
            <form id="payment-form" onSubmit={handleSubmit}>
                <div id="payment-fields-container">
                    <div>
                        <div>
                            Card Number <span className="required">*</span>
                        </div>
                        <CardNumberElement
                            id="card-number-element"
                            className="mt-0"
                            onChange={handleChange}
                        />
                    </div>
                    <div id="expiration-cvc-container" className="mt-3">
                        <div id="expiration-container">
                            <div>
                                Expiration Date{" "}
                                <span className="required">*</span>
                            </div>
                            <CardExpiryElement
                                id="card-expiry-element"
                                className="mt-0"
                                onChange={handleChange}
                            />
                        </div>
                        <div id="cvc-container">
                            <div>
                                CVC <span className="required">*</span>
                            </div>
                            <CardCvcElement
                                id="card-cvc-element"
                                className="mt-0"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <button
                    disabled={processing}
                    id="submit"
                    className="checkout-button"
                >
                    <span id="button-text">
                        {processing ? (
                            <div className="spinner" id="spinner"></div>
                        ) : (
                            "PLACE ORDER"
                        )}
                    </span>
                </button>
                {error && (
                    <div className="card-error" role="alert">
                        {error}
                    </div>
                )}
            </form>
        );
    }

    return null;
};

const StripePayment = (props) => {
    return (
        <Elements stripe={stripePromise} options={{ locale: "en" }}>
            <PaymentForm
                paymentMethod={props.paymentMethod}
                total={props.total}
                user={props.user}
                setOrderId={props.setOrderId}
                history={props.history}
            />
        </Elements>
    );
};

export default StripePayment;
