import React from "react";
import numeral from "numeral";
import { showNotification } from "./../../../utils";

const Table = (props) => {
    let totalPrinting = 0;
    let totalShipping = 0;
    let count = 0;

    for (const item of props.items) {
        if (!item.checkout) {
            continue;
        }

        count++;
        const pricing = JSON.parse(item.pricing);

        totalPrinting += pricing.printing;
        totalShipping += pricing.shipping;
    }

    let billingAddress = null;
    if (props.items.length > 0) {
        billingAddress = props.items[0].billingAddress;
    }

    let tax = totalPrinting * 0.0825;
    if (billingAddress != null && billingAddress.is_reseller) {
        tax = 0;
    }

    let total = totalPrinting + tax;
    if (totalShipping > 0) {
        total += totalShipping;
    }

    const handleCheckout = () => {
        if (count === 0) {
            return showNotification(
                "No items selected",
                "Select at least one item to Checkout",
                "info"
            );
        }
        if (totalShipping < 0) {
            return showNotification(
                "Invalid Shipping Method",
                "One or more items has an invalid Shipping Method, please choose another one before continue.",
                "info",
                6000
            );
        }

        props.history.push("/checkout");
    };

    const renderPrice = (content) => {
        if (props.calculatingPrice) {
            return <i className="fas fa-circle-notch fa-spin" />;
        }

        return content;
    };

    let shippingHtml = numeral(totalShipping).format("$0,0.00");
    if (totalShipping < 0) {
        shippingHtml = "N/A";
    }

    return (
        <div className="summary-container">
            <div className="cart-summary">
                <div className="title">Cart Summary</div>
                <div className="details">
                    <div className="price-row">
                        <div className="label">Total of Jobs:</div>
                        <div className="value">{count}</div>
                    </div>
                    <div className="price-row">
                        <div className="label">Total of Printing:</div>
                        <div className="value">
                            {renderPrice(
                                numeral(totalPrinting).format("$0,0.00")
                            )}
                        </div>
                    </div>
                    <div className="price-row">
                        <div className="label">Total of Shipping:</div>
                        <div className="value">{renderPrice(shippingHtml)}</div>
                    </div>
                    <div className="price-row">
                        <div className="label">Total of Tax:</div>
                        <div className="value">
                            {renderPrice(numeral(tax).format("$0,0.00"))}
                        </div>
                    </div>
                    <div className="price-row grand-total-row">
                        <div className="label">Grand Total:</div>
                        <div className="value">
                            {renderPrice(numeral(total).format("$0,0.00"))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="checkout-button">
                <button
                    onClick={handleCheckout}
                    disabled={props.calculatingPrice}
                >
                    {props.calculatingPrice ? (
                        <i className="fas fa-circle-notch fa-spin" />
                    ) : (
                        "PROCEED TO CHECKOUT"
                    )}
                </button>
            </div>
        </div>
    );
};

export default Table;
