import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Banner from './Banner';
import Information from './Information';
import Instructions from './Instructions';
import Video from './Video';
import './FileUpload.scss';
import * as actions from './FileUpload.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';

const FileUpload = (props) => {
    /* Dispatch to props */
    const dispatch = useDispatch();
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        return () => {
            resetState();
        };
    }, [resetState]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>File Upload - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="file-upload-container">
                <Banner />
                <Information />
                <Instructions />
                <Video
                    history={props.history}
                />
            </div>
        </Layout>
    );
};

export default FileUpload;
