import React from 'react';

const Products = (props) => {
    const handleEcoFriendly = () => {
        props.history.push('/company/environmental-statement');
    };

    const handleRequestQuote = () => {
        props.history.push('/company/custom-quote');
    };

    return (
        <div className="information-container content-container">
            <div className="info-container">
                <h1>About OvernightColor.com</h1>
                <div className="main-information">
                    <p>
                        OvernightColor.com is a family-owned commercial printing company in Houston, Texas. We believe that all jobs begin with building a strong 
                        relationship with our customers and because of this approach, you will understand why OvernightColor.com has been the best kept secret in 
                        Houston. Rather than hoarding every print job available, OvernightColor.com prefers to treat each one of its customers as part of our close 
                        knit family.
                    </p>
                    <p>
                        Paying attention to detail in every aspect of the production line insures total customer satisfaction from beginning to end. OvernightColor.com 
                        is more than just a printing company, we are a one-stop-shop for all your printing, fulfillment and signage needs - big or small, we are here 
                        to serve and make you feel like family.
                    </p>
                </div>
            </div>
            <div className="messages-container">
                <div className="message-main-container eco-friendly">
                    <div className="message-container">
                        <div className="title">ECO-FRIENDLY PRINTING</div>
                        <div className="message">
                            OvernightColor.com has taken the initiative and grown into an environmentally friendly company by taking significant
                            steps in our production of printed products.
                        </div>
                        <button onClick={handleEcoFriendly}>READ MORE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/eco-friendly.png" alt="Eco Friendly" />
                    </div>
                </div>
                <div className="message-main-container request-quote">
                    <div className="message-container">
                        <div className="title">REQUEST PRINT QUOTE</div>
                        <div className="message">
                            Request a quote for any print project by using our user-friendly online form. Submit the details of your print request
                            and we will provide you with an instant quote.
                        </div>
                        <button onClick={handleRequestQuote}>REQUEST A QUOTE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/request-quote.png" alt="Request Quote" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
