import React from 'react';

const Items = (props) => {
    const handleSetShowItem = (index) => {
        props.setShowItem(index);
    }

    const renderItems = () => {
        return props.items.map((item, index) => {
            let activeClass = '';
            if (item.show) {
                activeClass = ' active';
            }

            return (
                <div className="item">
                    <div className={'question' + activeClass} onClick={handleSetShowItem.bind(this, index)}>
                        <div>{item.title}</div>
                        <div><i className="fa-solid fa-angle-down"></i></div>
                    </div>
                    <div className={'answer' + activeClass}>
                        <div dangerouslySetInnerHTML={{ __html: item.description }}  ></div>
                    </div>
                </div>
            )
        });
    };

    return (
        <div className="items-container">
            <div className="items content-container">
                <h3>Positions</h3>
                <div className="list">
                    {renderItems()}
                </div>
            </div>
        </div>
    );
}

export default Items;
