import React from 'react';

const Banner = (props) => {
    return (
        <div className="banner2-container">
            <div className="content-container">
                <div className="layer"></div>
                <h1>
                    We have built our reputation by delivering quality printing that businesses need for over 30 years, with our
                    commitment to Quality, Service and Cost effective pricing plus a 100% guarantee to back up our statement of turnaround.
                </h1>
            </div>
        </div>
    );
}

export default Banner;
