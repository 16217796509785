import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Dialog } from './../../common/dialog';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const ArtworksModal = React.memo((props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: true,
        onDrop: (files) => {
            for (let i = 0; i < files.length; i++) {
                props.addArtwork(files[i]);
            }
        }
    });

    const hideHandler = () => {
        props.clearArtworks();
        props.setIdModal(null);
        props.hideArtworksModal();
    };

    const handleDeleteArtwork = (artwork, index) => {
        if (artwork.id) {
            props.deleteArtwork(artwork.id);
        }
        props.removeArtwork(index);
    };

    const renderArtworks = () => {
        if (props.artworks.length === 0) {
            return (
                <tr>
                    <td colSpan="2">No artwork</td>
                </tr>
            );
        }

        return props.artworks.map((file, index) => {
            let downloadIcon = null;
            let fileName = file.name;
            if (file.id) {
                downloadIcon = (
                    <a href={file.file_url} target="_blank" rel="noopener noreferrer">
                        <Tooltip title="Download File" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fa-solid fa-download"></i>
                        </Tooltip>
                    </a>
                );
                fileName = file.filename;
            }

            return (
                <tr>
                    <td>{fileName}</td>
                    <td className="text-center actions">
                        {downloadIcon}
                        <Tooltip title="Remove File" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={handleDeleteArtwork.bind(this, file, index)} />
                        </Tooltip>
                    </td>
                </tr>
            );
        });
    };

    let uploadingArtworks = null;
    let uploadButtonLabel = 'Upload Artwork';
    if (props.uploadingArtworks && props.artworks.length > 0) {
        uploadingArtworks = <div className="uploading-artworks"><i className="fas fa-circle-notch fa-spin" /> Your files are uploading. It may take a few seconds depending on the size of the file.</div>;
        uploadButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
        <button key="save-button" className="btn btn-light btn-sm save-button" onClick={props.uploadArtworks} disabled={props.uploadingArtworks}>{uploadButtonLabel}</button>,
    ];
    return (
        <Dialog
            name="frontend-my-orders-artworks-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="frontend-my-orders-artworks-dialog"
        >
            <div className="frontend-my-orders-artworks-modal-container">
                <h1>Artwork (Job #{props.idModal})</h1>
                <div className="files-container">
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th>Filename</th>
                                <th className="actions"></th>
                            </tr>
                        </thead>
                        <tbody>{renderArtworks()}</tbody>
                    </table>
                    <div className="add-artworks">
                        <div {...getRootProps({ className: 'dropzone-container' })}>
                            <input {...getInputProps()} />
                            <button>Choose Files</button>
                        </div>
                    </div>
                    {uploadingArtworks}
                </div>
            </div>
        </Dialog >
    );
});

export default ArtworksModal;
