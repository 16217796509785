import React from 'react';
import { Link } from 'react-router-dom';

const Form = (props) => {
    if (props.actionType === 'list') {
        return null;
    }

    if (props.fetchingElement) {
        return (
            <div className="form-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    return (
        <div className="form-container">
            <div className="fields">
                <div className="field">
                    <div className="label">First Name <span className="required">*</span>:</div>
                    <input type="text" value={props.item.first_name} onChange={handleSetField.bind(this, 'first_name')} placeholder="First Name" />
                </div>
                <div className="field">
                    <div className="label">Last Name <span className="required">*</span>:</div>
                    <input type="text" value={props.item.last_name} onChange={handleSetField.bind(this, 'last_name')} placeholder="Last Name" />
                </div>
                <div className="field">
                    <div className="label">Type <span className="required">*</span>:</div>
                    <select value={props.item.type} onChange={handleSetField.bind(this, 'type')}>
                        <option value="">Select option</option>
                        <option value="1">Super Admin</option>
                        <option value="2">Admin</option>
                        <option value="4">CSR</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">Email <span className="required">*</span>:</div>
                    <input type="text" value={props.item.email} onChange={handleSetField.bind(this, 'email')} placeholder="Email" />
                </div>
                <div className="field">
                    <div className="label">Active <span className="required">*</span>:</div>
                    <select value={props.item.is_active} onChange={handleSetField.bind(this, 'is_active')}>
                        <option value="">Select option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">New password (leave blank to leave unchanged){props.item.id ? null : <span className="ml-1 required">*</span>}:</div>
                    <input type="password" value={props.item.new_password} onChange={handleSetField.bind(this, 'new_password')} placeholder="Password" />
                </div>
                <div className="field">
                    <div className="label">Confirm new password (leave blank to leave unchanged){props.item.id ? null : <span className="ml-1 required">*</span>}:</div>
                    <input type="password" value={props.item.confirm_password} onChange={handleSetField.bind(this, 'confirm_password')} placeholder="Password" />
                </div>
            </div>
            <div className="buttons-container">
                <div className="button-container">
                    <Link to="/admin/users">
                        <button className="cancel-button">Cancel</button>
                    </Link>
                </div>
                <div className="button-container">
                    <button className="save-button admin-button" onClick={props.saveElement} disabled={props.saving}>
                        {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Form;
