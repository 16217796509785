import React from 'react';
import Pagination from './../../Pagination';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const Table = (props) => {
    if (props.actionType !== 'list') {
        return null;
    }

    const handleDeleteModal = (id) => {
        props.setIdModal(id);
        props.showDeleteModal();
    };

    const renderElements = () => {
        if (props.fetchingElements) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.items.length === 0) {
            return (
                <tr>
                    <td colSpan="10">No results</td>
                </tr>
            );
        }

        return props.items.map((item) => {
            let editIcon = (
                <div className="action-icon">
                    <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                        <Link to={'/admin/faqs/edit/' + item.id}><i className="fa-solid fa-pen-to-square"></i></Link>
                    </Tooltip>
                </div>
            );
            
            let deleteIcon = (
                <div className="action-icon">
                    <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fa-solid fa-trash" onClick={handleDeleteModal.bind(this, item.id)}></i>
                    </Tooltip>
                </div>
            );

            return (
                <tr>
                    <td className="id-column desktop-column">{item.id}</td>
                    <td className="name-column main-column">
                        <div className="desktop-column">{item.question}</div>
                        <div className="mobile-container">
                            <div className="mobile-item">
                                <div className="label">ID:</div>
                                <div className="value">{item.id}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Question:</div>
                                <div className="value">{item.question}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Active:</div>
                                <div className="value">{item.active ? 'Yes' : 'No'}</div>
                            </div>
                            <div className="mobile-actions">
                                {editIcon}
                                {deleteIcon}
                            </div>
                        </div>
                    </td>
                    <td className="active-column desktop-column">{item.active ? 'Yes' : 'No'}</td>
                    <td className="actions-column desktop-column">
                        <div className="actions-container">
                            {editIcon}
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const handleSort = (column, type) => {
        props.setSort(column, type, props.type);
    };

    const renderHeader = (label, field) => {
        let sortType = 'asc';
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === field) {
            if (props.sort.type === 'desc') {
                sortIcon = <i className="fas fa-sort-down" />;
                sortType = 'asc';
            } else {
                sortIcon = <i className="fas fa-sort-up" />;
                sortType = 'desc';
            }
        }

        return <div className="sort-item" onClick={handleSort.bind(this, field, sortType)}>{label} {sortIcon}</div>
    };

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr className="header-row">
                        <th className="id-column desktop-column">{renderHeader('ID', 'id')}</th>
                        <th className="name-column desktop-column">{renderHeader('Question', 'question')}</th>
                        <th className="parent-column desktop-column">{renderHeader('Active', 'active')}</th>
                        <th className="actions-column desktop-column"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderElements()}
                </tbody>
            </table>
            <Pagination
                pagination={props.pagination}
                setActivePage={props.setActivePage}
            />
        </div>
    );
}

export default Table;
