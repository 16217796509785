import axios from 'axios';
import * as types from './MyInformation.types';
import * as selectors from './MyInformation.selectors';
import { empty, showNotification, validateAuthInResponse } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchInformation = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/customer/my-information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const saveInformation = () => (dispatch, getState) => {
    const information = selectors.getInformation(getState());
    const file = selectors.getFile(getState());

    if (empty(information.first_name)) {
        return showNotification('Complete Information', 'Enter the First Name', 'info');
    }
    if (empty(information.last_name)) {
        return showNotification('Complete Information', 'Enter the Last Name', 'info');
    }

    var formData = new FormData();
    formData.append('first_name', information.first_name);
    formData.append('last_name', information.last_name);
    formData.append('company', information.company);
    formData.append('file', file);

    dispatch({ type: types.SAVE_INFORMATION, payload: null });
    axios.post(apiUrl + '/customer/save-information', formData,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_INFORMATION_SUCCESS, payload: response.data });
            showNotification('Information Saved', 'Your information has been saved successfully', 'success');
            dispatch(fetchInformation());
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_INFORMATION_ERROR));
        });
}

export const savePassword = () => (dispatch, getState) => {
    const information = selectors.getInformation(getState());

    if (empty(information.old_password)) {
        return showNotification('Complete Information', 'Enter the Old Password', 'info');
    }
    if (empty(information.new_password)) {
        return showNotification('Complete Information', 'Enter the New Password', 'info');
    }
    if (empty(information.confirm_password)) {
        return showNotification('Complete Information', 'Enter the Confirm New Password', 'info');
    }
    if (information.new_password !== information.confirm_password) {
        return showNotification('Complete Information', 'The New Password and Confirm New Password do not match', 'info');
    }

    dispatch({ type: types.SAVE_PASSWORD, payload: null });
    axios.post(apiUrl + '/customer/save-password', information,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_PASSWORD_SUCCESS, payload: response.data });
            showNotification('Password Saved', 'Your new password has been saved successfully', 'success');
        })
        .catch((error) => {
            dispatch({ type: types.SAVE_PASSWORD_ERROR, payload: null });
            if (error.response && error.response.status === 406) {
                showNotification('Old Password does not match', 'The Old Password entered does not match with the one we have in database.', 'info');
            } else {
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const setField = (field, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setFile = (file) => dispatch => {
    dispatch({
        type: types.SET_FILE,
        payload: file,
    });
}

export const setHistory = (history) => dispatch => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}