export const FETCH_ELEMENTS = 'ADMIN_SHOPPING_CART_JOBS/FETCH_ELEMENTS';
export const FETCH_ELEMENTS_SUCCESS = 'ADMIN_SHOPPING_CART_JOBS/FETCH_ELEMENTS_SUCCESS';
export const FETCH_ELEMENTS_ERROR = 'ADMIN_SHOPPING_CART_JOBS/FETCH_ELEMENTS_ERROR';
export const FETCH_ELEMENT = 'ADMIN_SHOPPING_CART_JOBS/FETCH_ELEMENT';
export const FETCH_ELEMENT_SUCCESS = 'ADMIN_SHOPPING_CART_JOBS/FETCH_ELEMENT_SUCCESS';
export const FETCH_ELEMENT_ERROR = 'ADMIN_SHOPPING_CART_JOBS/FETCH_ELEMENT_ERROR';
export const SAVE_ELEMENT = 'ADMIN_SHOPPING_CART_JOBS/SAVE_ELEMENT';
export const SAVE_ELEMENT_SUCCESS = 'ADMIN_SHOPPING_CART_JOBS/SAVE_ELEMENT_SUCCESS';
export const SAVE_ELEMENT_ERROR = 'ADMIN_SHOPPING_CART_JOBS/SAVE_ELEMENT_ERROR';
export const DELETE_ITEM = 'ADMIN_SHOPPING_CART_JOBS/DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'ADMIN_SHOPPING_CART_JOBS/DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_ERROR = 'ADMIN_SHOPPING_CART_JOBS/DELETE_ITEM_ERROR';
export const FETCH_INFORMATION = 'ADMIN_SHOPPING_CART_JOBS/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'ADMIN_SHOPPING_CART_JOBS/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'ADMIN_SHOPPING_CART_JOBS/FETCH_INFORMATION_ERROR';
export const SET_FIELD = 'ADMIN_SHOPPING_CART_JOBS/SET_FIELD';
export const SET_ACTION_TYPE = 'ADMIN_SHOPPING_CART_JOBS/SET_ACTION_TYPE';
export const SET_ACTIVE_PAGE = 'ADMIN_SHOPPING_CART_JOBS/SET_ACTIVE_PAGE';
export const SET_SORT = 'ADMIN_SHOPPING_CART_JOBS/SET_SORT';
export const SET_SEARCH = 'ADMIN_SHOPPING_CART_JOBS/SET_SEARCH';
export const SET_SEARCH_TYPE = 'ADMIN_SHOPPING_CART_JOBS/SET_SEARCH_TYPE';
export const SET_CUSTOMER_ID = 'ADMIN_SHOPPING_CART_JOBS/SET_CUSTOMER_ID';
export const SET_HISTORY = 'ADMIN_SHOPPING_CART_JOBS/SET_HISTORY';
export const SET_ID_MODAL = 'ADMIN_SHOPPING_CART_JOBS/SET_ID_MODAL';
export const RESET_STATE = 'ADMIN_SHOPPING_CART_JOBS/RESET_STATE';