import React from 'react';

const Instructions = (props) => {
    return (
        <div className="instructions-container ">
            <div className="content-container">
                <h2>SIMPLE INSTRUCTIONS:</h2>
                <ul>
                    <li>Full Name:  (This is your name)</li>
                    <li>Email: (This is your email so we know how to contact you)</li>
                    <li>Subject: (This can be your job title, PO, Order number, etc.)</li>
                    <li>Message: (This can be a quick note or special request to the recipient of your upload. Remember also to include your phone number.)</li>
                    <li>Select File: (Here you can use the 'Shift' key to select multiple files to be uploaded)</li>
                </ul>
                <div className="message">
                    If you have any issues or difficulty with uploading your files, please contact us directly and we will help resolve the 
                    problem. Or call our customer service representatives at 713-996-0202.
                </div>
            </div>
        </div>
    );
}

export default Instructions;
