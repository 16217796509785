import Immutable from 'immutable';
import * as types from './Checkout.types';

const initialState = {
    items: [],
    fetchingItems: false,
    placingOrder: false,
    history: null,
    fields: {
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        nameOnCard: '',
        securityNumber: '',
        coupon: '',
    },
    billingAddresses: [],
    fetchingBillingAddresses: false,
    addressFields: {
        first_name: '',
        last_name: '',
        company: '',
        website: '',
        industry_id: '',
        role_id: '',
        address1: '',
        address2: '',
        zip: '',
        country_id: '',
        state: '',
        city: '',
        phone: '',
        fax: '',
        cellphone: '',
        is_reseller: '',
        tax_id: '',
    },
    selectedAddress: null,
    savingBillingAddress: false,
    fetchingInformation: false,
    information: {
        industries: [],
        roles: [],
    },
    paymentMethod: 'CreditCard',
    validatingCoupon: false,
    coupon: null,
    orderId: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ITEMS:
            return state.setIn(['fetchingItems'], true).toJS();

        case types.FETCH_ITEMS_SUCCESS:
            return state.setIn(['fetchingItems'], false)
                .setIn(['items'], action.payload)
                .toJS();

        case types.FETCH_ITEMS_ERROR:
            return state.setIn(['fetchingItems'], false).toJS();

        case types.PLACE_ORDER:
            return state.setIn(['placingOrder'], true).toJS();

        case types.PLACE_ORDER_SUCCESS:
            return state.setIn(['placingOrder'], false).toJS();

        case types.PLACE_ORDER_ERROR:
            return state.setIn(['placingOrder'], false).toJS();

        case types.FETCH_BILLING_ADDRESSES:
            return state.setIn(['fetchingBillingAddresses'], true).toJS();

        case types.FETCH_BILLING_ADDRESSES_SUCCESS:
            return state.setIn(['fetchingBillingAddresses'], false)
                .setIn(['billingAddresses'], action.payload)
                .toJS();

        case types.FETCH_BILLING_ADDRESSES_ERROR:
            return state.setIn(['fetchingBillingAddresses'], false).toJS();

        case types.SAVE_BILLING_ADDRESS:
            return state.setIn(['savingBillingAddress'], true).toJS();

        case types.SAVE_BILLING_ADDRESS_SUCCESS:
            return state.setIn(['savingBillingAddress'], false)
                .setIn(['selectedItem'], null)
                .setIn(['selectedAddress'], null)
                .setIn(['addressFields'], {
                    first_name: '',
                    last_name: '',
                    company: '',
                    website: '',
                    industry_id: '',
                    role_id: '',
                    address1: '',
                    address2: '',
                    zip: '',
                    country_id: '',
                    state: '',
                    city: '',
                    phone: '',
                    fax: '',
                    cellphone: '',
                    is_reseller: '',
                    tax_id: '',
                }).toJS();

        case types.SAVE_BILLING_ADDRESS_ERROR:
            return state.setIn(['savingBillingAddress'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'industries'], action.payload.industries)
                .setIn(['information', 'roles'], action.payload.roles)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS();

        case types.VALIDATE_COUPON:
            return state.setIn(['validatingCoupon'], true).toJS();

        case types.VALIDATE_COUPON_SUCCESS:
            return state.setIn(['validatingCoupon'], false)
                .setIn(['coupon'], action.payload)
                .toJS();

        case types.VALIDATE_COUPON_ERROR:
            return state.setIn(['validatingCoupon'], false).toJS();

        case types.SET_SELECTED_ADDRESS:
            return state.setIn(['selectedAddress'], action.payload).toJS();

        case types.SET_ADDRESS_FIELD:
            return state.setIn(['addressFields', action.payload.field], action.payload.value).toJS();

        case types.SET_ADDRESS_FIELDS:
            return state.setIn(['addressFields'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.SET_PAYMENT_METHOD:
            return state.setIn(['paymentMethod'], action.payload).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();
            
        case types.SET_ORDER_ID:
            return state.setIn(['orderId'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}