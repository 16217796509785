import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from './Layout.selectors';
import * as actions from './Layout.actions';
import * as loginActions from './../Login/Login.actions';
import * as loginSelectors from './../Login/Login.selectors';
import Favicon from 'react-favicon';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { showNotification, stringifyEqualityCheck } from './../../utils';
import './Layout.scss';

const Layout = (props) => {
    /* State to props */
    const showSidebarMenu = useSelector(selectors.getShowSidebarMenu);
    const customer = useSelector(selectors.getCustomer);
    const fetchingCustomer = useSelector(selectors.getFetchingCustomer);
    const cartItems = useSelector(selectors.getCartItems);
    const showAllProducts = useSelector(selectors.getShowAllProducts);
    const isAuthenticated = useSelector(loginSelectors.getIsAuthenticated);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setShowSidebarMenu = useCallback(() => dispatch(actions.setShowSidebarMenu()), [dispatch]);
    const fetchCustomer = useCallback((id) => dispatch(actions.fetchCustomer(id)), [dispatch]);
    const checkCartItems = useCallback(() => dispatch(actions.checkCartItems()), [dispatch]);
    const setShowAllProducts = useCallback((value) => dispatch(actions.setShowAllProducts(value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const logout = useCallback((history) => dispatch(loginActions.logout(history)), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);

        if (user != null) {
            fetchCustomer(user.customer_id);
            checkCartItems();
        }

        const path = props.history.location.pathname;
        if (
            user == null && (['/my-information', '/my-address-book', '/my-jobs'].includes(path) ||
            path.includes('/my-orders/') || path.includes('/shopping-cart/') || path.includes('/checkout/'))
        ) {
            showNotification('Login required', 'You need to login to see this section', 'warning');
            props.history.replace('/')
        }

        return () => {
            resetState();
        };
    }, [fetchCustomer, checkCartItems, resetState, user, props.history]);

    const logoutHandler = (event) => {
        event.preventDefault();
        logout(props.history);
    }

    return (
        <div className="layout-container">
            <Favicon url="https://overnightcolor.s3.amazonaws.com/favicon.ico" />
            <Helmet>
                <title>OvernightColor</title>
            </Helmet>
            <div className="content-area">
                <Header
                    user={user}
                    isAuthenticated={isAuthenticated}
                    showSidebarMenu={showSidebarMenu}
                    customer={customer}
                    fetchingCustomer={fetchingCustomer}
                    cartItems={cartItems}
                    showAllProducts={showAllProducts}
                    history={props.history}
                    logoutHandler={logoutHandler}
                    setShowSidebarMenu={setShowSidebarMenu}
                    setShowAllProducts={setShowAllProducts}
                />
                <div className="layout-content">
                    {props.children}
                </div>
                <Footer
                    logoutHandler={logoutHandler}
                />
            </div>
        </div>
    );
};

export default Layout;
