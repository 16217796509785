import { createSelector } from 'reselect';

export const getComponentState = state => state.layout;

export const getShowSidebarMenu = createSelector(
    getComponentState,
    (state) => state.showSidebarMenu
);
export const getCustomer = createSelector(
    getComponentState,
    (state) => state.customer
);
export const getFetchingCustomer = createSelector(
    getComponentState,
    (state) => state.fetchingCustomer
);
export const getScrollPosition = createSelector(
    getComponentState,
    (state) => state.scrollPosition
);
export const getCartItems = createSelector(
    getComponentState,
    (state) => state.cartItems
);
export const getShowAllProducts = createSelector(
    getComponentState,
    (state) => state.showAllProducts
);