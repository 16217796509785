export const FETCH_ADDRESS = 'MY_ADDRESS/FETCH_ADDRESS';
export const FETCH_ADDRESS_SUCCESS = 'MY_ADDRESS/FETCH_ADDRESS_SUCCESS';
export const FETCH_ADDRESS_ERROR = 'MY_ADDRESS/FETCH_ADDRESS_ERROR';
export const FETCH_INFORMATION = 'MY_ADDRESS/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'MY_ADDRESS/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'MY_ADDRESS/FETCH_INFORMATION_ERROR';
export const SAVE_ADDRESS = 'MY_ADDRESS/SAVE_ADDRESS';
export const SAVE_ADDRESS_SUCCESS = 'MY_ADDRESS/SAVE_ADDRESS_SUCCESS';
export const SAVE_ADDRESS_ERROR = 'MY_ADDRESS/SAVE_ADDRESS_ERROR';
export const SET_ID = 'MY_ADDRESS/SET_ID';
export const SET_TYPE = 'MY_ADDRESS/SET_TYPE';
export const SET_ACTION = 'MY_ADDRESS/SET_ACTION';
export const SET_FIELD = 'MY_ADDRESS/SET_FIELD';
export const SET_FILE = 'MY_ADDRESS/SET_FILE';
export const SET_HISTORY = 'MY_ADDRESS/SET_HISTORY';
export const RESET_STATE = 'MY_ADDRESS/RESET_STATE';