import React from 'react';

const BookmarksTemplates = React.memo((props) => {
    const templates = [
        '10.5x2_bookmark_vertical',
        '7.25x2vertical',
        '9x2bookmark_vertical',
    ];

    return templates.map((template) => {
        return (
            <tr>
                <td>{template}</td>
                <td className="actions">
                    <a href={'https://overnightcolor.s3.amazonaws.com/templates/' + template + '.pdf'} target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-file-arrow-down"></i> Download
                    </a>
                </td>
            </tr>
        );
    });
});

export default BookmarksTemplates;
