import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { dateToTimezone } from './../../../utils';

const Header = (props) => {
    let sidebarMenuClass = 'sidebar-menu';
    let overlayClass = 'overlay';
    if (props.showSidebarMenu) {
        sidebarMenuClass = 'sidebar-menu show-menu';
        overlayClass = 'overlay show-menu';
    }

    const handleLogout = (event) => {
        props.logoutHandler(event);
        props.setShowSidebarMenu();
    };

    let cartItemsBullet = null;
    if (props.cartItems > 0) {
        cartItemsBullet = <div className="cart-items">{props.cartItems}</div>;
    }

    let signUpMenu = <Link to="/sign-up">Sign Up</Link>;
    let loginOutMenu = (
        <Link to="/login">Log In</Link>
    );
    let signUpMobileMenu = <li><Link to="/sign-up" onClick={props.setShowSidebarMenu}>Sign Up</Link></li>;
    let loginOutMobileMenu = <li><Link to="/login" onClick={props.setShowSidebarMenu}>Log In</Link></li>;
    let loginMobileHeader = (
        <div className="login">
            <Link to="/login">Log In</Link>
        </div>
    );
    if (props.user != null) {
        signUpMenu = <Link to="/my-jobs">Account</Link>;
        loginOutMenu = <Link to="#" onClick={props.logoutHandler}>Log Out</Link>;

        signUpMobileMenu = <li><Link to="/my-jobs" onClick={props.setShowSidebarMenu}>Account</Link></li>;
        loginOutMobileMenu = <li><Link to="#" onClick={handleLogout}>Log Out</Link></li>;

        loginMobileHeader = (
            <div className="shopping-cart">
                <Link to="/shopping-cart"><i className="fa-solid fa-cart-shopping"></i></Link>
                {cartItemsBullet}
            </div>
        );
    }

    let mobileProducts = null;
    let mobileProductsArrow = <div><i className="fa-solid fa-angle-down"></i></div>;
    if (props.showAllProducts) {
        mobileProducts = (
            <Fragment>
                <li className="product-item">
                    <Link to="/product/booklets">Booklets</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/bookmarks">Bookmarks</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/brochures">Brochures</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/business-cards">Business Cards</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/catalogs">Catalogs</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/door-hangers">Door Hangers</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/envelopes">Envelopes</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/flyers">Flyers</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/folded-cards">Folded Cards</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/hang-tags">Hang Tags</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/letterheads">Lettherheads</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/newsletters">Newsletters</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/pocket-folders">Pocket Folders</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/postcards">Postcards</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/posters">Posters</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/rack-cards">Rack Cards</Link>
                </li>
                <li className="product-item">
                    <Link to="/product/table-tents">Table Tents</Link>
                </li>
            </Fragment>
        );
        mobileProductsArrow = <div><i className="fa-solid fa-angle-up"></i></div>;
    }

    let lastLogin = null;
    if (props.user) {
        lastLogin = (
            <div className="last-login">
                Welcome back <span>{props.user.first_name}</span> <br /> (Last Login: {dateToTimezone(props.user.last_login, 'MM/DD/YYYY h:mm a')})
            </div>
        );
    }

    return (
        <div className="header-container">
            <div className={sidebarMenuClass}>
                <div className="sidebar-content">
                    <div className="close-icon">
                        <i className="fa-solid fa-xmark" onClick={props.setShowSidebarMenu}></i>
                    </div>
                    <div className="menu-items">
                        <ul>
                            <li><Link to="/" onClick={props.setShowSidebarMenu}>Home</Link></li>
                            {signUpMobileMenu}
                            <li className="view-all-products" onClick={props.setShowAllProducts}>
                                <div>View All Products</div>
                                {mobileProductsArrow}
                            </li>
                            {mobileProducts}
                            <li><Link to="/company/custom-quote" onClick={props.setShowSidebarMenu}>Custom Quote</Link></li>
                            <li><Link to="/company/contact-us" onClick={props.setShowSidebarMenu}>Contact Us</Link></li>
                            {loginOutMobileMenu}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={overlayClass}></div>
            <div className="menu">
                {/* <div className="black-header"></div> */}
                <div className="main-header content-container">
                    <div className="logo-container">
                        <Link to="/"><img src="https://overnightcolor.s3.amazonaws.com/css/logo.png" alt="Overnightcolor" /></Link>
                    </div>
                    <div className="actions-container">
                        <div className="menu-items">
                            <Link to="/company/custom-quote">Custom Quote</Link>
                            <Link to="/company/contact-us">Contact Us</Link>
                            {/* <div className="search">
                                <input type="text" placeholder="Search for product or service" />
                                <div className="icon">
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                </div>
                            </div> */}
                        </div>
                        <div className="sign-container">
                            <div className="login">
                                {loginOutMenu}
                            </div>
                            <div className="separator">|</div>
                            <div className="register">
                                {signUpMenu}
                            </div>
                            <div className="shopping-cart">
                                <Link to="/shopping-cart"><i className="fa-solid fa-cart-shopping"></i></Link>
                                {cartItemsBullet}
                            </div>
                            {lastLogin}
                        </div>
                    </div>
                </div>
                <div className="products-header">
                    <div className="content-container">
                        <div class="menu-item-container">
                            <div class="menu-item-title">View All Products</div>
                            <div class="menu-item-content">
                                <div className="menu-content">
                                    <div className="menu-item-content-container">
                                        <div className="four-color">
                                            <div className="menu-title">Four Color</div>
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="menu-link">
                                                        <Link to="/product/booklets">Booklets</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/bookmarks">Bookmarks</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/brochures">Brochures</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/business-cards">Business Cards</Link>
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div className="menu-link">
                                                        <Link to="/product/catalogs">Catalogs</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/door-hangers">Door Hangers</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/flyers">Flyers</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/rack-cards">Rack Cards</Link>
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div className="menu-link">
                                                        <Link to="/product/folded-cards">Folded Cards</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/hang-tags">Hang Tags</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/letterheads">Lettherheads</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/newsletters">Newsletters</Link>
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div className="menu-link">
                                                        <Link to="/product/pocket-folders">Pocket Folders</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/postcards">Postcards</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/posters">Posters</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/table-tents">Table Tents</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="short-run">
                                            <div className="menu-title">Short Run Digital</div>
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="menu-link">
                                                        <Link to="/product/booklets/digital">Booklets (Digital)</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/brochures/digital">Brochures (Digital)</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/flyers/digital">Flyers (Digital)</Link>
                                                    </div>
                                                    <div className="menu-link">
                                                        <Link to="/product/business-cards/digital">Business Cards (Digital)</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="menu-item-container">
                            <div class="menu-item-title">
                                <Link to="/product/business-cards">Business Cards</Link>
                            </div>
                        </div>
                        <div class="menu-item-container">
                            <div class="menu-item-title">
                                <Link to="/product/brochures">Brochures</Link>
                            </div>
                        </div>
                        <div class="menu-item-container">
                            <div class="menu-item-title">
                                <Link to="/product/postcards">Postcards</Link>
                            </div>
                        </div>
                        <div class="menu-item-container">
                            <div class="menu-item-title">
                                <Link to="/product/booklets">Booklets</Link>
                            </div>
                        </div>
                        <div class="menu-item-container">
                            <div class="menu-item-title tools">Tools</div>
                            <div class="menu-item-content tools">
                                <div className="menu-content">
                                    <div className="menu-item-content-container">
                                        <div className="columns">
                                            <div className="column">
                                                <div className="menu-link">
                                                    <Link to="/tools/file-upload">File Upload</Link>
                                                </div>
                                                <div className="menu-link">
                                                    <a href="https://www.stagecoachview.com/make-payment" target="_blank" rel="noopener noreferrer">
                                                        Make Payment Online
                                                    </a>
                                                </div>
                                                <div className="menu-link">
                                                    <Link to="/tools/credit-application">Credit Application</Link>
                                                </div>
                                                <div className="menu-link">
                                                    <a href="https://overnightcolor.s3.amazonaws.com/downloads/w-9.pdf" target="_blank" rel="noopener noreferrer">
                                                        W-9 Form
                                                    </a>
                                                </div>
                                                <div className="menu-link">
                                                    <a href="https://overnightcolor.s3.amazonaws.com/downloads/customer-resale-tax-form.pdf" target="_blank" rel="noopener noreferrer">
                                                        Customer Resale Tax Form
                                                    </a>
                                                </div>
                                                <div className="menu-link">
                                                    <a href="https://overnightcolor.s3.amazonaws.com/downloads/vendor-resale-tax-form.pdf" target="_blank" rel="noopener noreferrer">
                                                        Vendor Resale Tax Form
                                                    </a>
                                                </div>
                                                <div className="menu-link">
                                                    <a href="https://overnightcolor.s3.amazonaws.com/downloads/Texas_Sales_Use_Tax_Exemption_Certification.pdf" target="_blank" rel="noopener noreferrer">
                                                        Tax Exemption Certification
                                                    </a>
                                                </div>
                                                <div className="menu-link">
                                                    <a href="https://overnightcolor.s3.amazonaws.com/downloads/Customer_information_form.pdf" target="_blank" rel="noopener noreferrer">
                                                        Customer Information Form
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="menu-item-container">
                            <div class="menu-item-title faqs">FAQs</div>
                            <div class="menu-item-content faqs">
                                <div className="menu-content">
                                    <div className="menu-item-content-container">
                                        <div className="columns">
                                            <div className="column">
                                                <div className="menu-link">
                                                    <Link to="/company/faqs">FAQs</Link>
                                                </div>
                                                <div className="menu-link">
                                                    <Link to="/company/custom-design-services">Custom Design Services</Link>
                                                </div>
                                                <div className="menu-link">
                                                    <Link to="/company/careers">Careers</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-menu">
                <div className="menu-icon" onClick={props.setShowSidebarMenu}>
                    <i className="fa-solid fa-bars"></i>
                </div>
                <div className="logo">
                    <Link to="/"><img src="https://overnightcolor.s3.amazonaws.com/css/logo.png" alt="OvernightColor" /></Link>
                </div>
                <div className="sign-container">
                    {loginMobileHeader}
                </div>
            </div>
        </div>
    );
}

export default Header;
