import Immutable from 'immutable';
import * as types from './ForgotPassword.types';

const initialState = {
    form: {
        email: '',
    },
    sending: false,
    sent: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SEND_EMAIL:
            return state.setIn(['sending'], true).toJS();

        case types.SEND_EMAIL_SUCCESS:
            return state.setIn(['sending'], false)
                .setIn(['form'], { email: '', password: '' })
                .setIn(['sent'], true)
                .toJS();

        case types.SEND_EMAIL_ERROR:
            return state.setIn(['sending'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['form', action.payload.name], action.payload.value).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}