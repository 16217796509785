export const FETCH_ELEMENTS = 'ADMIN_FAQS/FETCH_ELEMENTS';
export const FETCH_ELEMENTS_SUCCESS = 'ADMIN_FAQS/FETCH_ELEMENTS_SUCCESS';
export const FETCH_ELEMENTS_ERROR = 'ADMIN_FAQS/FETCH_ELEMENTS_ERROR';
export const FETCH_ELEMENT = 'ADMIN_FAQS/FETCH_ELEMENT';
export const FETCH_ELEMENT_SUCCESS = 'ADMIN_FAQS/FETCH_ELEMENT_SUCCESS';
export const FETCH_ELEMENT_ERROR = 'ADMIN_FAQS/FETCH_ELEMENT_ERROR';
export const SAVE_ELEMENT = 'ADMIN_FAQS/SAVE_ELEMENT';
export const SAVE_ELEMENT_SUCCESS = 'ADMIN_FAQS/SAVE_ELEMENT_SUCCESS';
export const SAVE_ELEMENT_ERROR = 'ADMIN_FAQS/SAVE_ELEMENT_ERROR';
export const DELETE_ITEM = 'ADMIN_FAQS/DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'ADMIN_FAQS/DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_ERROR = 'ADMIN_FAQS/DELETE_ITEM_ERROR';
export const SET_FIELD = 'ADMIN_FAQS/SET_FIELD';
export const SET_ACTION_TYPE = 'ADMIN_FAQS/SET_ACTION_TYPE';
export const SET_ACTIVE_PAGE = 'ADMIN_FAQS/SET_ACTIVE_PAGE';
export const SET_SORT = 'ADMIN_FAQS/SET_SORT';
export const SET_SEARCH = 'ADMIN_FAQS/SET_SEARCH';
export const SET_SEARCH_TYPE = 'ADMIN_FAQS/SET_SEARCH_TYPE';
export const SET_HISTORY = 'ADMIN_FAQS/SET_HISTORY';
export const SET_ID_MODAL = 'ADMIN_FAQS/SET_ID_MODAL';
export const RESET_STATE = 'ADMIN_FAQS/RESET_STATE';