import React from 'react';
import { Link } from 'react-router-dom';
import { dateToTimezone } from './../../../utils';

const Header = (props) => {
    const handleLogoClick = () => {
        props.history.push('/')
    };

    let sidebarMenuClass = 'sidebar-menu';
    let overlayClass = 'overlay';
    if (props.showSidebarMenu) {
        sidebarMenuClass = 'sidebar-menu show-menu';
        overlayClass = 'overlay show-menu';
    }

    let couponsMenu = null;
    let testimonialsMenu = null;
    let prizesMenu = null;
    let customersMenu = null;
    let usersMenu = null;
    let faqsMenu = null;
    let careersMenu = null;

    if (parseInt(props.user.type) === 1) {  // Super Admin
        customersMenu = <li><Link to="/admin/customers" onClick={props.setShowSidebarMenu}>Customers</Link></li>;
        couponsMenu = <li><Link to="/admin/coupons" onClick={props.setShowSidebarMenu}>Coupons</Link></li>;
        testimonialsMenu = <li><Link to="/admin/testimonials" onClick={props.setShowSidebarMenu}>Testimonials</Link></li>;
        prizesMenu = <li><Link to="/admin/prizes" onClick={props.setShowSidebarMenu}>Prizes</Link></li>;
        usersMenu = <li><Link to="/admin/users" onClick={props.setShowSidebarMenu}>Users</Link></li>;
        faqsMenu = <li><Link to="/admin/faqs" onClick={props.setShowSidebarMenu}>FAQs</Link></li>;
        careersMenu = <li><Link to="/admin/careers" onClick={props.setShowSidebarMenu}>Careers</Link></li>;
    } else if (parseInt(props.user.type) === 2) {  // Admin
        customersMenu = <li><Link to="/admin/customers" onClick={props.setShowSidebarMenu}>Customers</Link></li>;
    }

    return (
        <div className="header-container">
            <div className={sidebarMenuClass}>
                <div className="sidebar-content">
                    <div className="close-icon">
                        <i className="fa-solid fa-xmark" onClick={props.setShowSidebarMenu}></i>
                    </div>
                    <div className="menu-items">
                        <ul>
                            <li><Link to="/admin/jobs" onClick={props.setShowSidebarMenu}>Jobs</Link></li>
                            {customersMenu}
                            {couponsMenu}
                            {testimonialsMenu}
                            {prizesMenu}
                            {faqsMenu}
                            {careersMenu}
                            {usersMenu}
                            <li onClick={props.logoutHandler}>Log Out</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={overlayClass}></div>
            <div className="menu">
                <div className="content">
                    <div className="logo" onClick={handleLogoClick}>
                        <img src="https://overnightcolor.s3.amazonaws.com/css/logo.png" alt="OvernightColor" />
                    </div>
                    <div className="menu-items">
                        <div className="last-login">
                            Welcome back <span>{props.user.first_name}</span> <br /> (Last Login: {dateToTimezone(props.user.last_login, 'MM/DD/YYYY h:mm a')})
                        </div>
                        <div className="logout-link" onClick={props.logoutHandler}><i className="fas fa-sign-out-alt" /> Log Out</div>
                    </div>
                </div>
            </div>
            <div className="mobile-menu">
                <div className="menu-icon" onClick={props.setShowSidebarMenu}>
                    <i className="fa-solid fa-bars"></i>
                </div>
                <div className="logo" onClick={handleLogoClick}>
                    <img src="https://overnightcolor.s3.amazonaws.com/css/logo.png" alt="OvernightColor" />
                </div>
                <div className="cart-icon"></div>
            </div>
        </div>
    );
}

export default Header;
