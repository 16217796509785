import axios from 'axios';
import * as types from './CustomDesignServices.types';
import * as selectors from './CustomDesignServices.selectors';
import { empty, validateAuthInResponse, showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const sendContact = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());

    if (empty(fields.first_name)) {
        return showNotification('Complete Information', 'Enter the First Name', 'info');
    }
    if (empty(fields.last_name)) {
        return showNotification('Complete Information', 'Enter the Last Name', 'info');
    }
    if (empty(fields.email)) {
        return showNotification('Complete Information', 'Enter the Email', 'info');
    }
    if (empty(fields.phone)) {
        return showNotification('Complete Information', 'Enter the Phone', 'info');
    }
    if (empty(fields.message)) {
        return showNotification('Complete Information', 'Enter the Project Description', 'info');
    }

    dispatch({ type: types.SEND_CONTACT, payload: null });
    axios.post(apiUrl + '/user/send-custom-design-services', fields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SEND_CONTACT_SUCCESS, payload: response.data });
            showNotification('Message Sent', 'Your contact message has been sent successfully, our Customer Service will contact you very soon', 'success', 8000);
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SEND_CONTACT_ERROR));
        });
}

export const setField = (field, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}