import axios from 'axios';
import * as types from './Product.types';
import * as selectors from './Product.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import { empty, showNotification, validateAuthInResponse } from './../../utils';
import fileDownload from 'js-file-download';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchProduct = (callCalculatePrice, callSetFields = true, printingType = 'press') => (dispatch, getState) => {
    const name = selectors.getName(getState());

    dispatch({ type: types.FETCH_PRODUCT, payload: null });
    axios.get(apiUrl + '/product/get-product?name=' + name + '&printing-type=' + printingType,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_PRODUCT_SUCCESS, payload: response.data });

            if (callSetFields) {
                dispatch(setFields(response.data.fields));
            }

            if (callCalculatePrice) {
                dispatch(calculatePrice());
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_PRODUCT_ERROR));
        });
}

export const fetchItem = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ITEM, payload: null });
    axios.get(apiUrl + '/shopping-cart-items/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ITEM_SUCCESS, payload: response.data });
            dispatch(fetchProduct(true, false, response.data.printing_type));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ITEM_ERROR));
        });
}

export const calculatePrice = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const product = selectors.getProduct(getState());
    const user = loginSelectors.getUser(getState());

    dispatch({ type: types.CALCULATE_PRICE, payload: null });
    axios.post(apiUrl + '/product/calculate-price', { ...fields, productId: product.id, user },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.CALCULATE_PRICE_SUCCESS, payload: response.data });

            if (parseFloat(response.data.printing) === 0) {
                return showNotification('Invalid Configuration', 'This product configuration is invalid, please choose another option', 'info');
            }
            if (parseFloat(response.data.shipping) === -1) {
                return showNotification('Invalid Shipping Method', 'The Shipping Method is not available for your address, please select another one.', 'info', 6000);
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.CALCULATE_PRICE_ERROR));
        });
}

export const addToCart = () => (dispatch, getState) => {
    const product = selectors.getProduct(getState());
    const fields = selectors.getFields(getState());
    const history = selectors.getHistory(getState());
    const price = selectors.getPrice(getState());
    const calculatingPrice = selectors.getCalculatingPrice(getState());
    const printingType = selectors.getPrintingType(getState());

    if (calculatingPrice) {
        return showNotification('Loading Price', 'The price is still loading, please wait', 'info');
    }

    if (parseFloat(price.printing) === 0) {
        return showNotification('Invalid Configuration', 'This product configuration is invalid, please choose another option', 'info');
    }
    if (parseFloat(price.shipping) === -1) {
        return showNotification('Invalid Shipping Method', 'The Shipping Method is not available for your address, please select another one', 'info', 6000);
    }

    if (empty(fields.project_name)) {
        return showNotification('Complete Information', 'Enter the Project Name', 'info');
    }

    dispatch({ type: types.ADD_TO_CART, payload: null });
    axios.post(apiUrl + '/product/add-to-cart', { fields, productId: product.id, pricing: price, printingType },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.ADD_TO_CART_SUCCESS, payload: response.data });
            showNotification('Product Added to the Cart', 'Your product has been added to the cart successfully', 'success');
            history.push('/shopping-cart');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.ADD_TO_CART_ERROR));
        });
}

export const save = () => (dispatch, getState) => {
    const product = selectors.getProduct(getState());
    const fields = selectors.getFields(getState());
    const history = selectors.getHistory(getState());
    const price = selectors.getPrice(getState());
    const item = selectors.getItem(getState());
    const calculatingPrice = selectors.getCalculatingPrice(getState());

    if (calculatingPrice) {
        return showNotification('Loading Price', 'The price is still loading, please wait', 'info');
    }

    if (parseFloat(price.printing) === 0) {
        return showNotification('Invalid Configuration', 'This product configuration is invalid, please choose another option', 'info');
    }

    if (empty(fields.project_name)) {
        return showNotification('Complete Information', 'Enter the Project Name', 'info');
    }

    dispatch({ type: types.SAVE, payload: null });
    axios.put(apiUrl + '/shopping-cart-items/' + item.id, { fields, productId: product.id, pricing: price },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_SUCCESS, payload: response.data });
            showNotification('Item Saved', 'Your item has been saved successfully', 'success');
            history.push('/shopping-cart');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_ERROR));
        });
}

export const downloadRetail = () => (dispatch, getState) => {
    const price = selectors.getPrice(getState());
    const retailMarkup = selectors.getRetailMarkup(getState());
    const retailCustomerName = selectors.getRetailCustomerName(getState());
    const product = selectors.getProduct(getState());
    const fields = selectors.getFields(getState());
    const user = loginSelectors.getUser(getState());

    if (empty(fields.project_name)) {
        return showNotification('Complete Information', 'Enter the Project Name', 'info');
    }
    if (retailCustomerName === '') {
        return showNotification('Complete Information', 'Enter the Customer Name', 'info');
    }

    let customerId = null;
    if (user) {
        customerId = user.customer_id;
    }

    dispatch({ type: types.DOWNLOAD_RETAIL, payload: null });
    axios.post(apiUrl + '/product/retail-pdf',
        {
            jobPrice: price.total,
            netRetail: price.total * retailMarkup / 100,
            retailPrice: price.total + price.total * (retailMarkup / 100),
            fields,
            productId: product.id,
            productName: product.product,
            customerId: customerId,
            retailCustomerName: retailCustomerName,
            projectName: fields.project_name,
        },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.DOWNLOAD_RETAIL_SUCCESS, payload: null });
            fileDownload(response.data, 'Estimate for ' + retailCustomerName + '.pdf');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DOWNLOAD_RETAIL_ERROR));
        });
}

export const setName = (value) => dispatch => {
    dispatch({
        type: types.SET_NAME,
        payload: value,
    });
}

export const setField = (field, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setViewTurnaround = (value) => dispatch => {
    dispatch({
        type: types.SET_VIEW_TURNAROUND,
        payload: value,
    });
}

export const setViewRetail = () => dispatch => {
    dispatch({
        type: types.SET_VIEW_RETAIL,
        payload: null,
    });
}

export const setRetailMarkup = (value) => dispatch => {
    dispatch({
        type: types.SET_RETAIL_MARKUP,
        payload: value,
    });
}

export const setRetailCustomerName = (value) => dispatch => {
    dispatch({
        type: types.SET_RETAIL_CUSTOMER_NAME,
        payload: value,
    });
}

export const setIsEdit = (value) => dispatch => {
    dispatch({
        type: types.SET_IS_EDIT,
        payload: value,
    });
}

export const setFields = (value) => dispatch => {
    dispatch({
        type: types.SET_FIELDS,
        payload: value,
    });
}

export const setPrintingType = (value) => dispatch => {
    dispatch({
        type: types.SET_PRINTING_TYPE,
        payload: value,
    });
}

export const setHistory = (history) => dispatch => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}