import React from 'react';
// import { Link } from 'react-router-dom';

const Testimonials = (props) => {
    if (props.fetchingInformation) {
        return null;
    }

    const renderTestimonials = () => {
        return props.information.testimonials.map((testimonial) => {
            return (
                <div className="testimonial">
                    <div className="photo-container">
                        <div className="photo" style={{ backgroundImage: 'url("' + testimonial.file_url + '")' }}></div>
                    </div>
                    <div className="name">{testimonial.name}</div>
                    <div className="position">{testimonial.position}</div>
                    <div className="description">{testimonial.comment}</div>
                    <div className="stars">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="testimonials-container content-container">
            <h1>What Clients are saying</h1>
            <div className="testimonials">
                {renderTestimonials()}
            </div>
        </div>
    );
}

export default Testimonials;
