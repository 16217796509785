import React from 'react';

const Table = (props) => {
    // const handleChangeField = (field, event) => {
    //     props.setField(field, event.target.value);
    // };

    const handleShowBillingAddressModal = (event) => {
        event.preventDefault();
        props.setSelectedAddress(props.items[0].billing_address_id);
        props.fetchBillingAddresses();
        props.showBillingAddressModal();
    };

    let billingAddress = {};
    if (props.items.length > 0) {
        billingAddress = props.items[0].billingAddress;
    }

    // let paymentForm = null;
    // if (props.paymentMethod === 'CreditCard') {
    //     paymentForm = (
    //         <div className="payment-form">
    //             <div className="field">
    //                 <div className="label">Card Number <span className="required">*</span>:</div>
    //                 <input type="text" value={props.fields.cardNumber} onChange={handleChangeField.bind(this, 'cardNumber')} />
    //             </div>
    //             <div className="field expiration-date">
    //                 <div className="label">Expiration Date <span className="required">*</span>:</div>
    //                 <select className="expiration-month" value={props.fields.expirationMonth} onChange={handleChangeField.bind(this, 'expirationMonth')}>
    //                     <option value="">Month</option>
    //                     <option value="01">01</option>
    //                     <option value="02">02</option>
    //                     <option value="03">03</option>
    //                     <option value="04">04</option>
    //                     <option value="05">05</option>
    //                     <option value="06">06</option>
    //                     <option value="07">07</option>
    //                     <option value="08">08</option>
    //                     <option value="09">09</option>
    //                     <option value="10">10</option>
    //                     <option value="11">11</option>
    //                     <option value="12">12</option>
    //                 </select>
    //                 <select className="expiration-year" value={props.fields.expirationYear} onChange={handleChangeField.bind(this, 'expirationYear')}>
    //                     <option value="">Year</option>
    //                     <option value="2023">2023</option>
    //                     <option value="2024">2024</option>
    //                     <option value="2025">2025</option>
    //                     <option value="2026">2026</option>
    //                     <option value="2027">2027</option>
    //                     <option value="2028">2028</option>
    //                     <option value="2029">2029</option>
    //                     <option value="2030">2030</option>
    //                     <option value="2031">2031</option>
    //                     <option value="2032">2032</option>
    //                     <option value="2033">2033</option>
    //                     <option value="2034">2034</option>
    //                     <option value="2035">2035</option>
    //                     <option value="2036">2036</option>
    //                     <option value="2037">2037</option>
    //                     <option value="2038">2038</option>
    //                     <option value="2039">2039</option>
    //                     <option value="2040">2040</option>
    //                 </select>
    //             </div>
    //             <div className="field">
    //                 <div className="label">Name on card <span className="required">*</span>:</div>
    //                 <input type="text" value={props.fields.nameOnCard} onChange={handleChangeField.bind(this, 'nameOnCard')} />
    //             </div>
    //             <div className="field">
    //                 <div className="label">Security Number <span className="required">*</span>:</div>
    //                 <input type="text" value={props.fields.securityNumber} onChange={handleChangeField.bind(this, 'securityNumber')} />
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <div className="table-container">
            <div className="title">Checkout</div>
            <div className="addresses">
                <div className="column2">
                    <div className="subtitle mb-2">Billing Address</div>
                    <div>{billingAddress.first_name} {billingAddress.last_name}</div>
                    <div>{billingAddress.address1} {billingAddress.address2}</div>
                    <div>{billingAddress.city}, {billingAddress.state} {billingAddress.zip}</div>
                </div>
                <div className="column1">
                    <button onClick={handleShowBillingAddressModal}>Change Billing Address</button>
                </div>
            </div>
            <div className="payment-method">
                <div className="subtitle payment-subtitle">
                    <div>Payment Method</div>
                    <div>
                        <img src="https://overnightcolor.s3.amazonaws.com/css/cards.png" alt="Cards" />
                    </div>
                </div>
                <div className="payment-methods">
                    <div className="selection">
                        <div>
                            <input type="radio" checked={props.paymentMethod === 'CreditCard'} onClick={props.setPaymentMethod.bind(this, 'CreditCard')} /> Credit Card
                        </div>
                        <div className="paypal-option">
                            <input type="radio" checked={props.paymentMethod === 'Paypal'} onClick={props.setPaymentMethod.bind(this, 'Paypal')} /> Paypal
                        </div>
                    </div>
                    {/* {paymentForm} */}
                </div>
            </div>
        </div>
    );
}

export default Table;
