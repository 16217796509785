import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { dateToTimezone, empty } from './../../../utils';

const Form = (props) => {
    if (props.actionType === 'list') {
        return null;
    }

    if (props.fetchingElement) {
        return (
            <div className="form-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    let customerSummary = null;
    if (props.item.id) {
        let taxIdField = null;
        let resellerCertificateField = null;
        if (!empty(props.item.defaultBillingAddress) && props.item.defaultBillingAddress.is_reseller) {
            taxIdField = (
                <div className="summary-item">
                    <div className="label">Tax ID:</div>
                    <div className="value">
                        {props.item.defaultBillingAddress.tax_id}
                    </div>
                </div>
            );
            resellerCertificateField = (
                <div className="summary-item">
                    <div className="label">Reseller Certificate:</div>
                    <div className="value">
                        <a href={props.item.defaultBillingAddress.reseller_certificate_file_url} target="_blank" without rel="noopener noreferrer">{props.item.defaultBillingAddress.reseller_certificate_filename}</a>
                    </div>
                </div>
            );
        }

        let defaultBilling = null;
        if (!empty(props.item.defaultBillingAddress)) {
            defaultBilling = (
                <Fragment>
                    <h2 className="mt-4">Default Billing Address</h2>
                    <div className="summary-item">
                        <div className="label">Name:</div>
                        <div className="value">
                            {props.item.defaultBillingAddress.first_name} {props.item.defaultBillingAddress.last_name}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">Company:</div>
                        <div className="value">
                            {props.item.defaultBillingAddress.company}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">Website:</div>
                        <div className="value">
                            {props.item.defaultBillingAddress.website}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">Address:</div>
                        <div className="value">
                            {props.item.defaultBillingAddress.address1} {props.item.defaultBillingAddress.address2}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">City:</div>
                        <div className="value">
                            {props.item.defaultBillingAddress.city}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">State:</div>
                        <div className="value">
                            {props.item.defaultBillingAddress.state}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">Phone:</div>
                        <div className="value">
                            {props.item.defaultBillingAddress.phone}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">Cellphone:</div>
                        <div className="value">
                            {props.item.defaultBillingAddress.cellphone}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">Fax:</div>
                        <div className="value">
                            {props.item.defaultBillingAddress.fax}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">Reseller:</div>
                        <div className="value">
                            {props.item.defaultBillingAddress.is_reseller ? 'Yes' : 'No'}
                        </div>
                    </div>
                    {taxIdField}
                    {resellerCertificateField}
                </Fragment>
            );
        }

        let defaultShipping = null;
        if (!empty(props.item.defaultShippingAddress)) {
            defaultShipping = (
                <Fragment>
                    <h2 className="mt-4">Default Shipping Address</h2>
                    <div className="summary-item">
                        <div className="label">Name:</div>
                        <div className="value">
                            {props.item.defaultShippingAddress.first_name} {props.item.defaultShippingAddress.last_name}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">Company:</div>
                        <div className="value">
                            {props.item.defaultShippingAddress.company}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">Address:</div>
                        <div className="value">
                            {props.item.defaultShippingAddress.address1} {props.item.defaultShippingAddress.address2}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">City:</div>
                        <div className="value">
                            {props.item.defaultShippingAddress.city}
                        </div>
                    </div>
                    <div className="summary-item">
                        <div className="label">State:</div>
                        <div className="value">
                            {props.item.defaultShippingAddress.state}
                        </div>
                    </div>
                </Fragment>
            );
        }

        customerSummary = (
            <div className="customer-summary">
                <h2 className="mt-4">Customer Information</h2>
                <div className="summary-item">
                    <div className="label">Created At:</div>
                    <div className="value">{dateToTimezone(props.item.creation_date, 'MMMM Do, YYYY')}</div>
                </div>
                <div className="summary-item">
                    <div className="label">Total of Orders:</div>
                    <div className="value">{props.item.total_orders}</div>
                </div>
                {defaultBilling}
                {defaultShipping}
            </div>
        );
    }

    return (
        <div className="form-container">
            <div className="fields">
                <div className="field">
                    <div className="label">First Name <span className="required">*</span>:</div>
                    <input type="text" value={props.item.first_name} onChange={handleSetField.bind(this, 'first_name')} placeholder="First Name" />
                </div>
                <div className="field">
                    <div className="label">Last Name <span className="required">*</span>:</div>
                    <input type="text" value={props.item.last_name} onChange={handleSetField.bind(this, 'last_name')} placeholder="Last Name" />
                </div>
                <div className="field">
                    <div className="label">Email <span className="required">*</span>:</div>
                    <input type="text" value={props.item.email} onChange={handleSetField.bind(this, 'email')} placeholder="Email" />
                </div>
                <div className="field">
                    <div className="label">Active <span className="required">*</span>:</div>
                    <select value={props.item.is_active} onChange={handleSetField.bind(this, 'is_active')}>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">New password (leave blank to leave unchanged){props.item.id ? null : <span className="ml-1 required">*</span>}:</div>
                    <input type="password" value={props.item.new_password} onChange={handleSetField.bind(this, 'new_password')} placeholder="Password" />
                </div>
                <div className="field">
                    <div className="label">Confirm new password (leave blank to leave unchanged){props.item.id ? null : <span className="ml-1 required">*</span>}:</div>
                    <input type="password" value={props.item.confirm_password} onChange={handleSetField.bind(this, 'confirm_password')} placeholder="Password" />
                </div>
            </div>
            <div className="buttons-container">
                <div className="button-container">
                    <Link to="/admin/customers">
                        <button className="cancel-button">Cancel</button>
                    </Link>
                </div>
                <div className="button-container">
                    <button className="save-button admin-button" onClick={props.saveElement} disabled={props.saving}>
                        {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
                    </button>
                </div>
            </div>
            {customerSummary}
        </div>
    );
}

export default Form;
