import React from 'react';

const Form = (props) => {
    const handleSetField = (field, event) => {
        props.setField(field, event.target.value)
    };

    return (
        <div className="form-container content-container">
            <h2>We would love to hear from you! Whether you have a question, suggestion, or just want to say hello, our team is here and ready to help. </h2>
            <div className="contact-form">
                <div className="field">
                    <div className="label">First Name <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.first_name} onChange={handleSetField.bind(this, 'first_name')} placeholder="First Name" />
                </div>
                <div className="field">
                    <div className="label">Last Name <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.last_name} onChange={handleSetField.bind(this, 'last_name')} placeholder="Last Name" />
                </div>
                <div className="field">
                    <div className="label">Email <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.email} onChange={handleSetField.bind(this, 'email')} placeholder="Email" />
                </div>
                <div className="field">
                    <div className="label">Phone <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.phone} onChange={handleSetField.bind(this, 'phone')} placeholder="Phone" />
                </div>
                <div className="field">
                    <div className="label">Contact Reason <span className="required">*</span>:</div>
                    <div className="select-field">
                        <select value={props.fields.reason} onChange={handleSetField.bind(this, 'reason')}>
                            <option value="">Please Select</option>
                            <option value="My Account Question">My Account Question</option>
                            <option value="My Estimate">My Estimate</option>
                            <option value="My Job Status">My Job Status</option>
                            <option value="Accounting Department">Accounting Department</option>
                            <option value="Other">Other</option>
                        </select>
                        <i className="fa-solid fa-angle-down"></i>
                    </div>
                </div>
                <div className="comments-field">
                    <div className="label">Message <span className="required">*</span>:</div>
                    <textarea value={props.fields.message} onChange={handleSetField.bind(this, 'message')} placeholder="Message" />
                </div>
            </div>
            <div className="button-container">
                <button onClick={props.sendContact} disabled={props.sending}>
                    {props.sending ? <i className="fas fa-circle-notch fa-spin" /> : 'Send'}
                </button>
            </div>
            <div className="contact-info">
                <div>OvernightColor. 11101 Ella Blvd.</div>
                <div>Houston, Texas 77067</div>
                <div>Phone: 832-608-6400 / Fax: 832-608-6420</div>
            </div>
        </div>
    );
}

export default Form;
