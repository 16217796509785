import React from 'react';
import { Link } from 'react-router-dom';

const Footer = React.memo((props) => {
    return (
        <div className="footer-container">
            <div className="footer content-container">
                <div className="main-menu">
                    <div className="menu about-us-menu">
                        <h2>ABOUT US</h2>
                        <Link to="/company/online-printer">The Company</Link>
                        <Link to="/company/overview">Overview</Link>
                        <Link to="/company/profile-and-vision">Profile and Vision</Link>
                        <Link to="/company/meet-the-owners">Meet The Owners</Link>
                        <Link to="/company/our-history">Our History</Link>
                        <a href="https://overnightcolor.s3.amazonaws.com/downloads/AbsoluteColor_Digital_Brochure.pdf" target="_blank" rel="noopener noreferrer">
                            Digital Brochure (pdf)
                        </a>
                        <Link to="/loyalty-points">Customer Appreciation</Link>
                        <Link to="/company/certification-and-awards">Certifications and Awards</Link>
                        <Link to="/company/partners">Partnerships</Link>
                    </div>
                    <div className="menu tools-menu">
                        <h2>TOOLS</h2>
                        <Link to="/tools/file-upload">File Upload</Link>
                        <a href="https://www.stagecoachview.com/make-payment" target="_blank" rel="noopener noreferrer">
                            Make Payment Online
                        </a>
                        <Link to="/tools/credit-application">Credit Application</Link>
                        <a href="https://overnightcolor.s3.amazonaws.com/downloads/w-9.pdf" target="_blank" rel="noopener noreferrer">
                            W-9 Form
                        </a>
                        <a href="https://overnightcolor.s3.amazonaws.com/downloads/customer-resale-tax-form.pdf" target="_blank" rel="noopener noreferrer">
                            Customer Resale Tax Form
                        </a>
                        <a href="https://overnightcolor.s3.amazonaws.com/downloads/vendor-resale-tax-form.pdf" target="_blank" rel="noopener noreferrer">
                            Vendor Resale Tax Form
                        </a>
                        <a href="https://overnightcolor.s3.amazonaws.com/downloads/Texas_Sales_Use_Tax_Exemption_Certification.pdf" target="_blank" rel="noopener noreferrer">
                            Tax Exemption Certification
                        </a>
                        <a href="https://overnightcolor.s3.amazonaws.com/downloads/Customer_information_form.pdf" target="_blank" rel="noopener noreferrer">
                            Customer Information Form
                        </a>
                    </div>
                    <div className="menu news-media-menu">
                        <h2>NEWS & MEDIA</h2>
                        {/* <Link to="/">OvernightColor Blog</Link> */}
                        <a href="https://www.youtube.com/user/absolutecolorprint" target="_blank" rel="noopener noreferrer">
                            OvernightColor Videos
                        </a>
                        <a href="https://www.facebook.com/AbsoluteColorPrinting" target="_blank" rel="noopener noreferrer">
                            OvernightColor Facebook
                        </a>
                        <a href="https://twitter.com/absolutecolor" target="_blank" rel="noopener noreferrer">
                            OvernightColor Twitter
                        </a>
                        <a href="https://www.linkedin.com/in/absolutecolor" target="_blank" rel="noopener noreferrer">
                            OvernightColor LinkedIn
                        </a>
                    </div>
                    <div className="social-media-menu">
                        <h2>FOLLOW US</h2>
                        <div className="icons">
                            <a href="https://www.youtube.com/user/absolutecolorprint" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-youtube"></i>
                            </a>
                            <a href="https://www.facebook.com/AbsoluteColorPrinting" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-facebook-f"></i>
                            </a>
                            <a href="https://twitter.com/absolutecolor" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-twitter"></i>
                            </a>
                            <a href="https://www.linkedin.com/in/absolutecolor" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-linkedin"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="information-menu">
                    <Link to="/company/environmental-statement">Environmental Statement</Link>
                    <Link to="/company/terms-conditions">Terms & Conditions</Link>
                    <Link to="/company/privacy-policy">Privacy Policy</Link>
                    <a href="https://overnightcolor.s3.amazonaws.com/downloads/w-9.pdf" target="_blank" rel="noopener noreferrer" className="w9-form">
                        W-9 Form
                    </a>
                    <a href="https://overnightcolor.s3.amazonaws.com/downloads/customer-resale-tax-form.pdf" target="_blank" rel="noopener noreferrer" className="customer-resale-tax-form">
                        Customer Resale Tax Form
                    </a>
                </div>
                {/* <div className="partners">
                    <img src="https://overnightcolor.s3.amazonaws.com/css/partners.jpg" alt="Partners" />
                </div> */}
                <div className="address-container">
                    <div>Copyright © OvernightColor.com | Wholesale Online Print Services. All Rights Reserved.</div>
                    <div>(p) 832-608-6400</div>
                </div>
            </div>
        </div>
    );
});

export default Footer;
