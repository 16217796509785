import axios from 'axios';
import * as types from './CustomQuote.types';
import * as selectors from './CustomQuote.selectors';
import { empty, validateAuthInResponse, showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const sendCustomQuote = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());

    if (empty(fields.name)) {
        return showNotification('Complete Information', 'Enter the Full Name', 'info');
    }
    if (empty(fields.phone)) {
        return showNotification('Complete Information', 'Enter the Phone', 'info');
    }
    if (empty(fields.email)) {
        return showNotification('Complete Information', 'Enter the Email', 'info');
    }
    if (empty(fields.project_description)) {
        return showNotification('Complete Information', 'Enter the Project Description', 'info');
    }

    dispatch({ type: types.SEND_CONTACT, payload: null });
    axios.post(apiUrl + '/user/send-custom-quote', fields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SEND_CONTACT_SUCCESS, payload: response.data });
            showNotification('Message Sent', 'Your message has been sent successfully, our Customer Service will contact you very soon', 'success', 8000);
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SEND_CONTACT_ERROR));
        });
}

export const setField = (field, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}