import axios from 'axios';
import * as types from './MyOrders.types';
import * as selectors from './MyOrders.selectors';
import { validateAuthInResponse, showNotification } from './../../utils';
import * as dialog from '../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchOrders = () => (dispatch, getState) => {
    const pagination = selectors.getPagination(getState());
    const sort = selectors.getSort(getState());
    const search = selectors.getSearch(getState());
    const searchType = selectors.getSearchType(getState());

    let params = [];
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);
    params.push('sort-column=' + sort.column);
    params.push('sort-type=' + sort.type);
    params.push('search=' + search);
    params.push('search-type=' + searchType);

    dispatch({ type: types.FETCH_ORDERS, payload: null });
    axios.get(apiUrl + '/orders?' + params.join('&'),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ORDERS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ORDERS_ERROR));
        });
}

export const uploadArtworks = () => (dispatch, getState) => {
    const artworks = selectors.getArtworks(getState());
    const idModal = selectors.getIdModal(getState());

    if (artworks.length === 0) {
        return showNotification('No files selected', 'Select at least one file to upload', 'info');
    }

    var formData = new FormData();
    formData.append('id', idModal);
    for (let i = 1; i <= artworks.length; i++) {
        formData.append('file' + i, artworks[i - 1]);
    }
    formData.append('filesLength', artworks.length);

    dispatch({ type: types.UPLOAD_ARTWORKS, payload: null });
    axios.post(apiUrl + '/order/upload-artworks', formData,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.UPLOAD_ARTWORKS_SUCCESS, payload: response.data });
            showNotification('Files uploaded', 'The files have been uploaded successfully', 'success');
            dispatch(dialog.actions.hideDialog('artworks-modal'));
            dispatch(clearArtworks());
            dispatch(fetchOrders());
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.UPLOAD_ARTWORKS_ERROR));
        });
}

export const deleteArtwork = (id) => (dispatch, getState) => {
    dispatch({ type: types.DELETE_ARTWORK, payload: null });
    axios.delete(apiUrl + '/order/delete-artwork?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.DELETE_ARTWORK_SUCCESS, payload: response.data });
            showNotification('Artwork Deleted', 'Your artwork has been deleted successfully', 'success');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DELETE_ARTWORK_ERROR));
        });
}

export const setActivePage = (value) => (dispatch, getState) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value,
        });
        resolve();
    }).then(() => {
        dispatch(fetchOrders());
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: { column, type }
        });
        resolve();
    }).then(() => {
        dispatch(fetchOrders());
    });
}

export const setSearch = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH,
        payload: value,
    });
}

export const setSearchType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH_TYPE,
        payload: value,
    });
}

export const setIdModal = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_MODAL,
        payload: id
    });
}

export const addArtwork = (file) => (dispatch) => {
    dispatch({
        type: types.ADD_ARTWORK,
        payload: file,
    });
}

export const removeArtwork = (index) => (dispatch) => {
    dispatch({
        type: types.REMOVE_ARTWORK,
        payload: index,
    });
}

export const clearArtworks = (index) => (dispatch) => {
    dispatch({
        type: types.CLEAR_ARTWORKS,
        payload: index,
    });
}

export const setArtworks = (artworks) => (dispatch) => {
    dispatch({
        type: types.SET_ARTWORKS,
        payload: artworks,
    });
}

export const setHistory = (history) => dispatch => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}