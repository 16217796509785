import React from 'react';
import { Link } from 'react-router-dom';

const Menu = (props) => {
    const pathname = props.history.location.pathname;

    return (
        <div className="menu-container">
            <Link to="/my-information">
                <div className={'menu-item ' + (pathname === '/my-information' ? 'active' : '')}>
                    <div className="icon"><i className="fa-solid fa-user" /></div>
                    <div className="label">Your account</div>
                </div>
            </Link>
            <Link to="/my-address-book">
                <div className={'menu-item ' + (pathname === '/my-address-book' || pathname.includes('/billing/address') || pathname.includes('/shipping/address') ? 'active' : '')}>
                    <div className="icon"><i className="fa-solid fa-location-dot" /></div>
                    <div className="label">Address book</div>
                </div>
            </Link>
            <Link to="/my-jobs">
                <div className={'menu-item ' + (pathname === '/my-jobs' ? 'active' : '')}>
                    <div className="icon"><i className="fa-solid fa-cart-shopping" /></div>
                    <div className="label">Jobs</div>
                </div>
            </Link>
            <Link to="/loyalty-points">
                <div className={'menu-item ' + (pathname === '/loyalty-points' ? 'active' : '')}>
                    <div className="icon"><i className="fa-solid fa-paper-plane" /></div>
                    <div className="label">Loyalty Points</div>
                </div>
            </Link>
        </div>
    );
}

export default Menu;
