import Immutable from 'immutable';
import * as types from './Layout.types';

const initialState = {
    showSidebarMenu: false,
    customer: null,
    fetchingCustomer: false,
    cartItems: 0,
    fetchingCartItems: false,
    showAllProducts: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_CUSTOMER:
            return state.setIn(['fetchingCustomer'], true).toJS();

        case types.FETCH_CUSTOMER_SUCCESS:
            return state.setIn(['fetchingCustomer'], false)
                .setIn(['customer'], action.payload)
                .toJS();

        case types.FETCH_CUSTOMER_ERROR:
            return state.setIn(['fetchingCustomer'], false).toJS();

        case types.CHECK_CART_ITEMS:
            return state.setIn(['fetchingCartItems'], true).toJS();

        case types.CHECK_CART_ITEMS_SUCCESS:
            return state.setIn(['fetchingCartItems'], false)
                .setIn(['cartItems'], action.payload)
                .toJS();

        case types.CHECK_CART_ITEMS_ERROR:
            return state.setIn(['fetchingCartItems'], false).toJS();

        case types.SET_SHOW_SIDEBAR_MENU:
            return state.setIn(['showSidebarMenu'], action.payload).toJS();

        case types.SET_SHOW_ALL_PRODUCTS:
            return state.updateIn(['showAllProducts'], (showAllProducts) => {
                return !showAllProducts;
            }).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}