import React, { Fragment } from 'react';

const BusinessCardsForm = (props) => {
    const renderQuantities = () => {
        if (props.printingType === 'digital') {
            return (
                <select value={props.fields.quantity} onChange={props.handleChangeField.bind(this, 'quantity')} disabled={props.calculatingPrice}>
                    <option className="digital-option" value="DIGITAL" disabled>DIGITAL</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                    <option value="250">250</option>
                    <option value="300">300</option>
                    <option value="400">400</option>
                    <option value="500">500</option>
                    <option value="750">750</option>
                    <option value="1000">1,000</option>
                    <option value="1500">1,500</option>
                    <option value="2000">2,000</option>
                    <option value="2500">2,500</option>
                    <option value="3000">3,000</option>
                    <option value="3500">3,500</option>
                    <option value="4000">4,000</option>
                    <option value="4500">4,500</option>
                    <option value="5000">5,000</option>
                    <option value="5500">5,500</option>
                    <option value="6000">6,000</option>
                    <option value="6500">6,500</option>
                    <option value="7000">7,000</option>
                    <option value="7500">7,500</option>
                    <option value="8000">8,000</option>
                    <option value="8500">8,500</option>
                    <option value="9000">9,000</option>
                    <option value="9500">9,500</option>
                    <option value="10000">10,000</option>
                    <option value="12500">12,500</option>
                    <option value="15000">15,000</option>
                    <option value="17500">17,500</option>
                    <option value="20000">20,000</option>
                    <option value="22500">22,500</option>
                    <option value="25000">25,000</option>
                    <option value="27500">27,500</option>
                    <option value="30000">30,000</option>
                    <option value="32500">32,500</option>
                    <option value="35000">35,000</option>
                    <option value="37500">37,500</option>
                    <option value="40000">40,000</option>
                    <option value="42500">42,500</option>
                    <option value="45000">45,000</option>
                    <option value="47500">47,500</option>
                    <option value="50000">50,000</option>
                </select>
            );
        }

        return (
            <select value={props.fields.quantity} onChange={props.handleChangeField.bind(this, 'quantity')} disabled={props.calculatingPrice}>
                <option className="digital-option" value="DIGITAL" disabled>DIGITAL</option>
                <option value="250">250</option>
                <option value="500">500</option>
                <option value="1000">1,000</option>
                <option value="1500">1,500</option>
                <option value="2000">2,000</option>
                <option value="2500">2,500</option>
                <option value="3000">3,000</option>
                <option value="3500">3,500</option>
                <option value="4000">4,000</option>
                <option value="4500">4,500</option>
                <option value="5000">5,000</option>
                <option value="5500">5,500</option>
                <option value="6000">6,000</option>
                <option value="6500">6,500</option>
                <option value="7000">7,000</option>
                <option value="7500">7,500</option>
                <option value="8000">8,000</option>
                <option value="8500">8,500</option>
                <option value="9000">9,000</option>
                <option value="9500">9,500</option>
                <option value="10000">10,000</option>
                <option value="12500">12,500</option>
                <option value="15000">15,000</option>
                <option value="17500">17,500</option>
                <option value="20000">20,000</option>
                <option value="22500">22,500</option>
                <option value="25000">25,000</option>
                <option value="27500">27,500</option>
                <option value="30000">30,000</option>
                <option value="32500">32,500</option>
                <option value="35000">35,000</option>
                <option value="37500">37,500</option>
                <option value="40000">40,000</option>
                <option value="42500">42,500</option>
                <option value="45000">45,000</option>
                <option value="47500">47,500</option>
                <option value="50000">50,000</option>
            </select>
        );
    };

    return (
        <Fragment>
            <div className="features-container">
                <div className="title">Specs</div>
                <div className="features">
                    <div className="field">
                        <div className="label">Quantity:</div>
                        <div className="select-field">
                            {renderQuantities()}
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Printing Ink:</div>
                        <div className="select-field">
                            <select value={props.fields.ink} onChange={props.handleChangeField.bind(this, 'ink')} disabled={props.calculatingPrice}>
                                <option value="4/0">4/0 - Color Front Only</option>
                                <option value="4/1">4/1 - Color Front | Black Back</option>
                                <option value="4/4">4/4 - Color Both Side</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Size:</div>
                        <div className="select-field">
                            <select value={props.fields.size} onChange={props.handleChangeField.bind(this, 'size')} disabled={props.calculatingPrice}>
                                <option value="3.5x2">3.5x2</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Paper:</div>
                        <div className="select-field">
                            <select value={props.fields.paper_stock} onChange={props.handleChangeField.bind(this, 'paper_stock')} disabled={props.calculatingPrice}>
                                <option value="100-glossy-cover">100# Glossy, Cover</option>
                                <option value="12pt-glossy-cover-2sides">12pt. Glossy, Cover 2 sides</option>
                                <option value="14pt-glossy-cover-2sides">14pt. Glossy, Cover 2 sides</option>
                                <option value="100-uncoated-cover">100# Uncoated, Cover</option>
                                <option value="120-uncoated-cover">120# Uncoated, Cover</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-container">
                <div className="title">Finishing</div>
                <div className="features">
                    <div className="field">
                        <div className="label">Coating Front:</div>
                        <div className="select-field">
                            <select value={props.fields.coating_front} onChange={props.handleChangeField.bind(this, 'coating_front')} disabled={props.calculatingPrice}>
                                <option value="0">No Coating</option>
                                <option value="1">Aqueous Coating</option>
                                <option value="2">High Gloss UV Coating</option>
                                <option value="3">Matte UV Coating</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Coating Back:</div>
                        <div className="select-field">
                            <select value={props.fields.coating_back} onChange={props.handleChangeField.bind(this, 'coating_back')} disabled={props.calculatingPrice}>
                                <option value="0">No Coating</option>
                                <option value="1">Aqueous Coating</option>
                                <option value="2">High Gloss UV Coating</option>
                                <option value="3">Matte UV Coating</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Round Corner:</div>
                        <div className="select-field">
                            <select value={props.fields.round_corner} onChange={props.handleChangeField.bind(this, 'round_corner')} disabled={props.calculatingPrice}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default BusinessCardsForm;
