import Immutable from 'immutable';
import * as types from './Account.types';

const initialState = {
    element: null,
    fetchingElement: false,
    savingAccount: '',
    savingAddresses: '',
    fields: {
        name: '',
        abs_business_unit_number: '',
        abs_cost_center_number: '',
        new_password: '',
        confirm_password: '',
    },
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ELEMENT:
            return state.setIn(['fetchingElement'], true).toJS();

        case types.FETCH_ELEMENT_SUCCESS:
            return state.setIn(['fetchingElement'], false)
                .setIn(['element'], action.payload)
                .setIn(['fields', 'name'], action.payload.name)
                .setIn(['fields', 'abs_business_unit_number'], action.payload.abs_business_unit_number)
                .setIn(['fields', 'abs_cost_center_number'], action.payload.abs_cost_center_number)
                .toJS();

        case types.FETCH_ELEMENT_ERROR:
            return state.setIn(['fetchingElement'], false).toJS();

        case types.SAVE_ACCOUNT:
            return state.setIn(['savingAccount'], true).toJS();

        case types.SAVE_ACCOUNT_SUCCESS:
            return state.setIn(['savingAccount'], false)
                .setIn(['fields', 'new_password'], '')
                .setIn(['fields', 'confirm_password'], '')
                .toJS();

        case types.SAVE_ACCOUNT_ERROR:
            return state.setIn(['savingAccount'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}