import Immutable from 'immutable';
import * as types from './Product.types';

const initialState = {
    name: null,
    product: null,
    item: null,
    fetchingProduct: false,
    calculatingPrice: false,
    price: {
        printing: 0,
        shipping: 0,
        total: 0,
    },
    history: null,
    fields: {},
    viewTurnaround: false,
    viewRetail: false,
    addingToCart: false,
    fetchingItem: false,
    saving: false,
    isEdit: false,
    retailMarkup: 0,
    retailCustomerName: '',
    downloadingRetail: false,
    relatedProducts: [],
    printingType: 'press',
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_PRODUCT:
            return state.setIn(['fetchingProduct'], true).toJS();

        case types.FETCH_PRODUCT_SUCCESS:
            return state.setIn(['fetchingProduct'], false)
                .setIn(['product'], action.payload.product)
                .setIn(['relatedProducts'], action.payload.relatedProducts)
                // .setIn(['fields'], action.payload.fields)
                .toJS();

        case types.FETCH_PRODUCT_ERROR:
            return state.setIn(['fetchingProduct'], false).toJS();

        case types.CALCULATE_PRICE:
            return state.setIn(['calculatingPrice'], true).toJS();

        case types.CALCULATE_PRICE_SUCCESS:
            return state.setIn(['calculatingPrice'], false)
                .setIn(['price'], action.payload)
                .toJS();

        case types.CALCULATE_PRICE_ERROR:
            return state.setIn(['calculatingPrice'], false).toJS();

        case types.FETCH_ITEM:
            return state.setIn(['fetchingItem'], true).toJS();

        case types.FETCH_ITEM_SUCCESS:
            return state.setIn(['fetchingItem'], false)
                .setIn(['item'], action.payload)
                .setIn(['fields'], JSON.parse(action.payload.options))
                .toJS();

        case types.FETCH_ITEM_ERROR:
            return state.setIn(['fetchingItem'], false).toJS();

        case types.ADD_TO_CART:
            return state.setIn(['addingToCart'], true).toJS();

        case types.ADD_TO_CART_SUCCESS:
            return state.setIn(['addingToCart'], false).toJS();

        case types.ADD_TO_CART_ERROR:
            return state.setIn(['addingToCart'], false).toJS();

        case types.DOWNLOAD_RETAIL:
            return state.setIn(['downloadingRetail'], true).toJS();

        case types.DOWNLOAD_RETAIL_SUCCESS:
            return state.setIn(['downloadingRetail'], false).toJS();

        case types.DOWNLOAD_RETAIL_ERROR:
            return state.setIn(['downloadingRetail'], false).toJS();

        case types.SAVE:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_SUCCESS:
            return state.setIn(['saving'], false).toJS();

        case types.SAVE_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.SET_NAME:
            return state.setIn(['name'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.SET_VIEW_TURNAROUND:
            return state.setIn(['viewTurnaround'], action.payload).toJS();

        case types.SET_VIEW_RETAIL:
            return state.updateIn(['viewRetail'], (viewRetail) => !viewRetail).toJS();

        case types.SET_RETAIL_MARKUP:
            return state.setIn(['retailMarkup'], action.payload).toJS();

        case types.SET_RETAIL_CUSTOMER_NAME:
            return state.setIn(['retailCustomerName'], action.payload).toJS();

        case types.SET_IS_EDIT:
            return state.setIn(['isEdit'], action.payload).toJS();

        case types.SET_FIELDS:
            return state.setIn(['fields'], action.payload).toJS();

        case types.SET_PRINTING_TYPE:
            return state.setIn(['printingType'], action.payload).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}