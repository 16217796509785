export const FETCH_ORDER = 'MY_ORDER_DETAILS/FETCH_ORDER';
export const FETCH_ORDER_SUCCESS = 'MY_ORDER_DETAILS/FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_ERROR = 'MY_ORDER_DETAILS/FETCH_ORDER_ERROR';
export const UPLOAD_ARTWORKS = 'MY_JOBS/UPLOAD_ARTWORKS';
export const UPLOAD_ARTWORKS_SUCCESS = 'MY_JOBS/UPLOAD_ARTWORKS_SUCCESS';
export const UPLOAD_ARTWORKS_ERROR = 'MY_JOBS/UPLOAD_ARTWORKS_ERROR';
export const DELETE_ARTWORK = 'MY_JOBS/DELETE_ARTWORK';
export const DELETE_ARTWORK_SUCCESS = 'MY_JOBS/DELETE_ARTWORK_SUCCESS';
export const DELETE_ARTWORK_ERROR = 'MY_JOBS/DELETE_ARTWORK_ERROR';
export const ADD_ARTWORK = 'MY_JOBS/ADD_ARTWORK';
export const REMOVE_ARTWORK = 'MY_JOBS/REMOVE_ARTWORK';
export const CLEAR_ARTWORKS = 'MY_JOBS/CLEAR_ARTWORKS';
export const SET_ARTWORKS = 'MY_JOBS/SET_ARTWORKS';
export const SET_ID_MODAL = 'MY_ORDER_DETAILS/SET_ID_MODAL';
export const SET_HISTORY = 'MY_ORDER_DETAILS/SET_HISTORY';
export const RESET_STATE = 'MY_ORDER_DETAILS/RESET_STATE';