import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import MyAccountMenu from './../MyAccountMenu';
import ArtworksModal from './ArtworksModal';
import ArtworksWarningModal from './ArtworksWarningModal';
import './MyJobs.scss';
import { stringifyEqualityCheck } from './../../utils';
import * as selectors from './MyJobs.selectors';
import * as actions from './MyJobs.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import * as dialog from './../common/dialog';

const MyJobsContainer = (props) => {
    /* State to props */
    const jobs = useSelector(selectors.getJobs, stringifyEqualityCheck);
    const fetchingJobs = useSelector(selectors.getFetchingJobs);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const search = useSelector(selectors.getSearch);
    const searchType = useSelector(selectors.getSearchType);
    const artworks = useSelector(selectors.getArtworks, stringifyEqualityCheck);
    const missingArtworksJobs = useSelector(selectors.getMissingArtworksJobs, stringifyEqualityCheck);
    const uploadingArtworks = useSelector(selectors.getUploadingArtworks);
    const idModal = useSelector(selectors.getIdModal);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchJobs = useCallback(() => dispatch(actions.fetchJobs()), [dispatch]);
    const setActivePage = useCallback((value) => dispatch(actions.setActivePage(value)), [dispatch]);
    const setSort = useCallback((column, type, orderType) => dispatch(actions.setSort(column, type, orderType)), [dispatch]);
    const setSearch = useCallback((value) => dispatch(actions.setSearch(value)), [dispatch]);
    const setSearchType = useCallback((value) => dispatch(actions.setSearchType(value)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setIdModal = useCallback((id) => dispatch(actions.setIdModal(id)), [dispatch]);
    const addArtwork = useCallback((file) => dispatch(actions.addArtwork(file)), [dispatch]);
    const removeArtwork = useCallback((index) => dispatch(actions.removeArtwork(index)), [dispatch]);
    const clearArtworks = useCallback((index) => dispatch(actions.clearArtworks(index)), [dispatch]);
    const uploadArtworks = useCallback(() => dispatch(actions.uploadArtworks()), [dispatch]);
    const setArtworks = useCallback((artworks) => dispatch(actions.setArtworks(artworks)), [dispatch]);
    const deleteArtwork = useCallback((id) => dispatch(actions.deleteArtwork(id)), [dispatch]);
    const checkMissingArtworks = useCallback(() => dispatch(actions.checkMissingArtworks()), [dispatch]);
    const showArtworksModal = useCallback(() => dispatch(dialog.actions.showDialog('frontend-my-jobs-artworks-modal')), [dispatch]);
    const hideArtworksModal = useCallback(() => dispatch(dialog.actions.hideDialog('frontend-my-jobs-artworks-modal')), [dispatch]);
    // const showArtworksWarningModal = useCallback(() => dispatch(dialog.actions.showDialog('frontend-artworks-warning-modal')), [dispatch]);
    const hideArtworksWarningModal = useCallback(() => dispatch(dialog.actions.hideDialog('frontend-artworks-warning-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);
        fetchJobs();
        checkMissingArtworks();

        return () => {
            resetState();
        };
    }, [fetchJobs, setHistory, checkMissingArtworks, resetState, props.history]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>My Jobs - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="my-jobs-container">
                <div className="content-container">
                    <MyAccountMenu
                        history={props.history}
                    />
                    <Table
                        jobs={jobs}
                        fetchingJobs={fetchingJobs}
                        pagination={pagination}
                        sort={sort}
                        search={search}
                        searchType={searchType}
                        setActivePage={setActivePage}
                        setSort={setSort}
                        setSearch={setSearch}
                        setSearchType={setSearchType}
                        showArtworksModal={showArtworksModal}
                        setIdModal={setIdModal}
                        setArtworks={setArtworks}
                    />
                </div>
                <ArtworksModal
                    jobs={jobs}
                    idModal={idModal}
                    artworks={artworks}
                    uploadingArtworks={uploadingArtworks}
                    hideArtworksModal={hideArtworksModal}
                    setIdModal={setIdModal}
                    addArtwork={addArtwork}
                    removeArtwork={removeArtwork}
                    clearArtworks={clearArtworks}
                    uploadArtworks={uploadArtworks}
                    deleteArtwork={deleteArtwork}
                />
                <ArtworksWarningModal
                    missingArtworksJobs={missingArtworksJobs}
                    hideArtworksWarningModal={hideArtworksWarningModal}
                />
            </div>
        </Layout>
    );
};

export default MyJobsContainer;
