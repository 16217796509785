import React from 'react';
import { Dialog } from './../../common/dialog';
import BookletsTemplates from './BookletsTemplates';
import BookmarksTemplates from './BookmarksTemplates';
import BrochuresTemplates from './BrochuresTemplates';
import BusinessCardsTemplates from './BusinessCardsTemplates';
import CatalogsTemplates from './CatalogsTemplates';
import DoorHangersTemplates from './DoorHangersTemplates';
import FlyersTemplates from './FlyersTemplates';
import FoldedCardsTemplates from './FoldedCardsTemplates';
import HangTagsTemplates from './HangTagsTemplates';
import LetterheadsTemplates from './LetterheadsTemplates';
import NewslettersTemplates from './NewslettersTemplates';
import PocketFoldersTemplates from './PocketFoldersTemplates';
import PostcardsTemplates from './PostcardsTemplates';
import PostersTemplates from './PostersTemplates';
import TableTentsTemplates from './TableTentsTemplates';
import RackCardsTemplates from './RackCardsTemplates';

const TemplatesModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideTemplatesModal();
    };

    const renderTemplatesTable = () => {
        if (props.code === 'booklets') {
            return <BookletsTemplates />;
        } else if (props.code === 'bookmarks') {
            return <BookmarksTemplates />;
        } else if (props.code === 'brochures') {
            return <BrochuresTemplates />;
        } else if (props.code === 'business-cards') {
            return <BusinessCardsTemplates />;
        } else if (props.code === 'catalogs') {
            return <CatalogsTemplates />;
        } else if (props.code === 'door-hangers') {
            return <DoorHangersTemplates />;
        } else if (props.code === 'flyers') {
            return <FlyersTemplates />;
        } else if (props.code === 'folded-cards') {
            return <FoldedCardsTemplates />;
        } else if (props.code === 'hang-tags') {
            return <HangTagsTemplates />;
        } else if (props.code === 'letterheads') {
            return <LetterheadsTemplates />;
        } else if (props.code === 'newsletters') {
            return <NewslettersTemplates />;
        } else if (props.code === 'pocket-folders') {
            return <PocketFoldersTemplates />;
        } else if (props.code === 'postcards') {
            return <PostcardsTemplates />;
        } else if (props.code === 'posters') {
            return <PostersTemplates />;
        } else if (props.code === 'rack-cards') {
            return <RackCardsTemplates />;
        } else if (props.code === 'table-tents') {
            return <TableTentsTemplates />;
        }

        return null;
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
    ];
    return (
        <Dialog
            name="frontend-product-templates-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="frontend-product-templates-dialog"
        >
            <div className="frontend-product-templates-modal-container">
                <h1>Templates</h1>
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th className="actions"></th>
                        </tr>
                    </thead>
                    <tbody>{renderTemplatesTable()}</tbody>
                </table>
            </div>
        </Dialog >
    );
});

export default TemplatesModal;
