import React, { useCallback, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './AdminUsers.scss';
import Table from './Table';
import Form from './Form';
import DeleteModal from './DeleteModal';
import SendResetPasswordModal from './SendResetPasswordModal';
import AdminLayout from './../AdminLayout';
import * as actions from './AdminUsers.actions';
import * as selectors from './AdminUsers.selectors';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import { Link } from 'react-router-dom';
import * as dialog from './../common/dialog';

const AdminUsers = (props) => {
    /* State to props */
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const actionType = useSelector(selectors.getActionType);
    const items = useSelector(selectors.getItems, stringifyEqualityCheck);
    const item = useSelector(selectors.getItem, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const search = useSelector(selectors.getSearch);
    const searchType = useSelector(selectors.getSearchType);
    const fetchingElements = useSelector(selectors.getFetchingElements);
    const fetchingElement = useSelector(selectors.getFetchingElement);
    const saving = useSelector(selectors.getSaving);
    const deleting = useSelector(selectors.getDeleting);
    const sendingResetPassword = useSelector(selectors.getSendingResetPassword);
    const idModal = useSelector(selectors.getIdModal);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setActionType = useCallback((value) => dispatch(actions.setActionType(value)), [dispatch]);
    const setActivePage = useCallback((value) => dispatch(actions.setActivePage(value)), [dispatch]);
    const fetchElements = useCallback(() => dispatch(actions.fetchElements()), [dispatch]);
    const fetchElement = useCallback((id) => dispatch(actions.fetchElement(id)), [dispatch]);
    const saveElement = useCallback(() => dispatch(actions.saveElement()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const setHistory = useCallback((value) => dispatch(actions.setHistory(value)), [dispatch]);
    const setSort = useCallback((column, type, orderType) => dispatch(actions.setSort(column, type, orderType)), [dispatch]);
    const setSearch = useCallback((value) => dispatch(actions.setSearch(value)), [dispatch]);
    const setSearchType = useCallback((value) => dispatch(actions.setSearchType(value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setIdModal = useCallback((id) => dispatch(actions.setIdModal(id)), [dispatch]);
    const deleteItem = useCallback(() => dispatch(actions.deleteItem()), [dispatch]);
    const sendResetPassword = useCallback(() => dispatch(actions.sendResetPassword()), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showSendResetPasswordModal = useCallback(() => dispatch(dialog.actions.showDialog('send-reset-password-modal')), [dispatch]);
    const hideSendResetPasswordModal = useCallback(() => dispatch(dialog.actions.hideDialog('send-reset-password-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        setHistory(props.history);
        
        if (props.match.params.action) {
            setActionType(props.match.params.action);

            if (props.match.params.action === 'edit') {
                fetchElement(props.match.params.id);
            }
        } else {
            setActionType('list');
            fetchElements();
        }

        return () => {
            resetState();
        };
    }, [setActionType, resetState, fetchElements, fetchElement, setHistory,
        props.history, props.match.params.action, props.match.params.id]);

    const handleSearch = () => {
        setActivePage(1);
        fetchElements();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setActivePage(1);
            fetchElements();
        }
    };

    const handleSetSearch = (event) => {
        setSearch(event.target.value);
    };

    let titleContainer = (
        <Fragment>
            <h1>Users</h1>
            <div className="header-actions-container">
                <div className="search-field">
                    <input type="text" value={search} onChange={handleSetSearch} onKeyPress={handleKeyPress} />
                    <button className="search-button admin-button" onClick={handleSearch}>Search</button>
                </div>
                <div className="new-button-container">
                    <Link to="/admin/users/new"><button className="admin-button">Add New</button></Link>
                </div>
            </div>
        </Fragment>
    );
    if (props.match.params.action === 'new') {
        titleContainer = (
            <h1>New User</h1>
        );
    } else if (props.match.params.action === 'edit') {
        titleContainer = (
            <h1>Edit User</h1>
        );
    }

    return (
        <AdminLayout history={props.history}>
            <Helmet>
                <title>Admin Users - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="admin-users-container">
                {titleContainer}
                <Table
                    pagination={pagination}
                    actionType={actionType}
                    sort={sort}
                    search={search}
                    searchType={searchType}
                    fetchingElements={fetchingElements}
                    items={items}
                    setActivePage={setActivePage}
                    setSort={setSort}
                    setSearch={setSearch}
                    setSearchType={setSearchType}
                    showDeleteModal={showDeleteModal}
                    setIdModal={setIdModal}
                    showSendResetPasswordModal={showSendResetPasswordModal}
                />
                <Form
                    actionType={actionType}
                    item={item}
                    saving={saving}
                    fetchingElement={fetchingElement}
                    setField={setField}
                    saveElement={saveElement}
                />
            </div>
            <DeleteModal
                idModal={idModal}
                deleting={deleting}
                deleteItem={deleteItem}
                hideDeleteModal={hideDeleteModal}
                setIdModal={setIdModal}
            />
            <SendResetPasswordModal
                idModal={idModal}
                sendingResetPassword={sendingResetPassword}
                sendResetPassword={sendResetPassword}
                hideSendResetPasswordModal={hideSendResetPasswordModal}
                setIdModal={setIdModal}
            />
        </AdminLayout>
    );
};

export default AdminUsers;
