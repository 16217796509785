import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import GetPrizeModal from './GetPrizeModal';
import './LoyaltyPoints.scss';
import { stringifyEqualityCheck } from './../../utils';
import * as selectors from './LoyaltyPoints.selectors';
import * as actions from './LoyaltyPoints.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import * as dialog from './../common/dialog';

const LoyaltyPointsContainer = (props) => {
    /* State to props */
    const prizes = useSelector(selectors.getPrizes, stringifyEqualityCheck);
    const fetchingPrizes = useSelector(selectors.getFetchingPrizes);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const search = useSelector(selectors.getSearch);
    const searchType = useSelector(selectors.getSearchType);
    const idModal = useSelector(selectors.getIdModal);
    const fetchingInformation = useSelector(selectors.getFetchingInformation);
    const gettingPrize = useSelector(selectors.getGettingPrize);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchPrizes = useCallback(() => dispatch(actions.fetchPrizes()), [dispatch]);
    const setActivePage = useCallback((value) => dispatch(actions.setActivePage(value)), [dispatch]);
    const setSort = useCallback((column, type, orderType) => dispatch(actions.setSort(column, type, orderType)), [dispatch]);
    const setSearch = useCallback((value) => dispatch(actions.setSearch(value)), [dispatch]);
    const setSearchType = useCallback((value) => dispatch(actions.setSearchType(value)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setIdModal = useCallback((id) => dispatch(actions.setIdModal(id)), [dispatch]);
    const getPrize = useCallback(() => dispatch(actions.getPrize()), [dispatch]);
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const showGetPrizeModal = useCallback(() => dispatch(dialog.actions.showDialog('get-prize-modal')), [dispatch]);
    const hideGetPrizeModal = useCallback(() => dispatch(dialog.actions.hideDialog('get-prize-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);
        fetchInformation();
        fetchPrizes();

        return () => {
            resetState();
        };
    }, [fetchPrizes, setHistory, resetState, fetchInformation, props.history]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>Loyalty Points - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="loyalty-points-container">
                <div className="content-container">
                    <Table
                        prizes={prizes}
                        fetchingPrizes={fetchingPrizes}
                        pagination={pagination}
                        sort={sort}
                        search={search}
                        searchType={searchType}
                        fetchingInformation={fetchingInformation}
                        information={information}
                        setActivePage={setActivePage}
                        setSort={setSort}
                        setSearch={setSearch}
                        setSearchType={setSearchType}
                        showGetPrizeModal={showGetPrizeModal}
                        setIdModal={setIdModal}
                    />
                </div>
                <GetPrizeModal
                    prizes={prizes}
                    idModal={idModal}
                    gettingPrize={gettingPrize}
                    hideGetPrizeModal={hideGetPrizeModal}
                    setIdModal={setIdModal}
                    getPrize={getPrize}
                />
            </div>
        </Layout>
    );
};

export default LoyaltyPointsContainer;
