import { createSelector } from 'reselect';

export const getComponentState = state => state.myOrderDetails;

export const getOrder = createSelector(
    getComponentState,
    (state) => state.order
);
export const getFetchingOrder = createSelector(
    getComponentState,
    (state) => state.fetchingOrder
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getIdModal = createSelector(
    getComponentState,
    (state) => state.idModal
);
export const getTypeModal = createSelector(
    getComponentState,
    (state) => state.typeModal
);
export const getArtworks = createSelector(
    getComponentState,
    (state) => state.artworks
);
export const getUploadingArtworks = createSelector(
    getComponentState,
    (state) => state.uploadingArtworks
);