import React from 'react';
import { Dialog } from './../../common/dialog';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const ArtworksModal = React.memo((props) => {
    const hideHandler = () => {
        props.setIdModal(null);
        props.hideArtworksModal();
    };

    if (props.item == null || !props.item.jobs || props.idModal == null) {
        return null;
    }

    const renderArtworks = () => {
        let job = null;
        for (let i = 0; i < props.item.jobs.length; i++) {
            if (parseInt(props.item.jobs[i].id) === parseInt(props.idModal)) {
                job = props.item.jobs[i];
                break;
            }
        }

        if (job.artworks.length === 0) {
            return (
                <tr>
                    <td colSpan="2">No artwork</td>
                </tr>
            );
        }

        return job.artworks.map((file, index) => {
            return (
                <tr>
                    <td>{file.filename}</td>
                    <td className="text-center actions">
                        <a href={file.file_url} target="_blank" rel="noopener noreferrer">
                            <Tooltip title="Download File" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fa-solid fa-download"></i>
                            </Tooltip>
                        </a>
                    </td>
                </tr>
            );
        });
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
    ];
    return (
        <Dialog
            name="artworks-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="admin-orders-artworks-dialog"
        >
            <div className="admin-orders-artworks-modal-container">
                <h1>Artwork (Job #{props.idModal})</h1>
                <div className="files-container">
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th>Filename</th>
                                <th className="actions"></th>
                            </tr>
                        </thead>
                        <tbody>{renderArtworks()}</tbody>
                    </table>
                </div>
            </div>
        </Dialog >
    );
});

export default ArtworksModal;
