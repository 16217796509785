import React from 'react';

const DoorHangersTemplates = React.memo((props) => {
    const templates = [
        '3.5x8.5door_hanger',
        '4x11door_hanger_large',
        '4x14door_hanger_large',
    ];

    return templates.map((template) => {
        return (
            <tr>
                <td>{template}</td>
                <td className="actions">
                    <a href={'https://overnightcolor.s3.amazonaws.com/templates/' + template + '.pdf'} target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-file-arrow-down"></i> Download
                    </a>
                </td>
            </tr>
        );
    });
});

export default DoorHangersTemplates;
