import React from 'react';

const Banner = (props) => {
    return (
        <div className="banner-container">
            <div className="content-container">
                <h1>Credit Application</h1>
            </div>
        </div>
    );
}

export default Banner;
