// import axios from 'axios';
import * as types from './AdminLayout.types';
import * as selectors from './AdminLayout.selectors';
// import { adminValidateAuthInResponse } from './../../utils';

// const apiUrl = process.env.REACT_APP_API_URL;

export const setShowSidebarMenu = () => (dispatch, getState) => {
    const showSidebarMenu = selectors.getShowSidebarMenu(getState());

    if (!showSidebarMenu) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }

    dispatch({
        type: types.SET_SHOW_SIDEBAR_MENU,
        payload: !showSidebarMenu,
    })
}