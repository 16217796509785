import axios from 'axios';
import * as types from './Account.types';
import * as selectors from './Account.selectors';
import * as loginSelectors from './../AdminLogin/AdminLogin.selectors';
import { validateAuthInResponse, empty, showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchElement = () => (dispatch) => {
    dispatch({ type: types.FETCH_ELEMENT, payload: null });
    axios.get(apiUrl + '/abs-order/user',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ELEMENT_ERROR));
        });
}

export const saveAccount = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const user = loginSelectors.getUser(getState());

    if (!validateAccountFields(fields, user)) {
        return;
    }

    dispatch({ type: types.SAVE_ACCOUNT, payload: null });
    axios.post(apiUrl + '/abs-order/save-account', fields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_ACCOUNT_SUCCESS, payload: response.data });
            showNotification('Data Saved', 'The information has been saved successfully', 'success');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_ACCOUNT_ERROR));
        });
}

export const validateAccountFields = (fields, user) => {
    if (empty(fields.name)) {
        showNotification('Complete Information', 'Enter the Name', 'info');
        return false;
    }
    if (parseInt(user.abs_type) === 1 && empty(fields.abs_business_unit_number)) {
        showNotification('Complete Information', 'Enter the Business Unit Number', 'info');
        return false;
    }
    if (parseInt(user.abs_type) === 1 &&empty(fields.abs_cost_center_number)) {
        showNotification('Complete Information', 'Enter the Cost Center Number', 'info');
        return false;
    }

    if (!empty(fields.new_password) || !empty(fields.confirm_password)) {
        if (empty(fields.new_password)) {
            showNotification('Complete Information', 'Enter the New Password', 'info');
            return false;
        }
        if (empty(fields.confirm_password)) {
            showNotification('Complete Information', 'Enter the Confirm New Password', 'info');
            return false;
        }
        if (fields.new_password !== fields.confirm_password) {
            showNotification('Complete Information', 'The New Password and Confirm New Password are different', 'info');
            return false;
        }
    }

    return true;
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    })
}
export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}