import React from 'react';
// import { Link } from 'react-router-dom';

const Satisfaction = (props) => {
    return (
        <div className="satisfaction-container">
            <div className="content-container">
                <div className="guarantee-container">
                    <div className="column1">
                        <h1>100% Satisfaction Guarantee</h1>
                        <div className="description">
                            OvernightColor.com is a wholesale business to business printing company based in Houston, Texas.
                            Our Fortune 500 company customers know us for our superior quality printing, low discounted pricing
                            and exceptional service. We offer the best online pricing calculator in the industry to ensure that
                            you are saving the most money on all your printing and signage needs
                        </div>
                    </div>
                    <div className="column2">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/guarantee.png" alt="Guarantee Satisfaction" />
                    </div>
                    <div className="column3">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/satisfaction.png" alt="Guarantee Satisfaction" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Satisfaction;
