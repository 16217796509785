import Immutable from 'immutable';
import * as types from './CustomDesignServices.types';

const initialState = {
    fields: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        description: '',
    },
    sending: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SEND_CONTACT:
            return state.setIn(['sending'], true).toJS();

        case types.SEND_CONTACT_SUCCESS:
            return state.setIn(['sending'], false)
                .setIn(['fields'], {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    description: '',
                }).toJS();

        case types.SEND_CONTACT_ERROR:
            return state.setIn(['sending'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}