import { createSelector } from 'reselect';

export const getComponentState = state => state.checkout;

export const getItems = createSelector(
    getComponentState,
    (state) => state.items
);
export const getFetchingItems = createSelector(
    getComponentState,
    (state) => state.fetchingItems
);
export const getPlacingOrder = createSelector(
    getComponentState,
    (state) => state.placingOrder
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getBillingAddresses = createSelector(
    getComponentState,
    (state) => state.billingAddresses
);
export const getFetchingBillingAddresses = createSelector(
    getComponentState,
    (state) => state.fetchingBillingAddresses
);
export const getAddressFields = createSelector(
    getComponentState,
    (state) => state.addressFields
);
export const getSelectedAddress = createSelector(
    getComponentState,
    (state) => state.selectedAddress
);
export const getSavingBillingAddress = createSelector(
    getComponentState,
    (state) => state.savingBillingAddress
);
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);
export const getPaymentMethod = createSelector(
    getComponentState,
    (state) => state.paymentMethod
);
export const getValidatingCoupon = createSelector(
    getComponentState,
    (state) => state.validatingCoupon
);
export const getCoupon = createSelector(
    getComponentState,
    (state) => state.coupon
);
export const getOrderId = createSelector(
    getComponentState,
    (state) => state.orderId
);