import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Banner from './Banner';
import Description from './Description';
import Items from './Items';
import Contact from './Contact';
import './CustomDesignServices.scss';
import * as selectors from './CustomDesignServices.selectors';
import * as actions from './CustomDesignServices.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';

const CustomDesignServices = (props) => {
    /* State to props */
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const sending = useSelector(selectors.getSending);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const sendContact = useCallback(() => dispatch(actions.sendContact()), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        return () => {
            resetState();
        };
    }, [resetState]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>Custom Design Services - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="custom-design-services-container">
                <Banner />
                <Description />
                <Items />
                <Contact
                    history={props.history}
                    fields={fields}
                    sending={sending}
                    setField={setField}
                    sendContact={sendContact}
                />
            </div>
        </Layout>
    );
};

export default CustomDesignServices;
