import React from 'react';
import Pagination from './../../Pagination';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import { dateToTimezone } from './../../../utils';

const Table = (props) => {
    const renderElements = () => {
        if (props.fetchingOrders) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.orders.length === 0) {
            return (
                <tr>
                    <td colSpan="10">No results</td>
                </tr>
            );
        }

        return props.orders.map((order) => {
            let detailsIcon = (
                <div className="action-icon">
                    <Tooltip title="Order Details" position="bottom" arrow size="small" className="action-tooltip">
                        <Link to={'/my-orders/details/' + order.id}><i className="fa-solid fa-circle-info"></i></Link>
                    </Tooltip>
                </div>
            );

            return (
                <tr>
                    <td className="id-column desktop-column">{order.id}</td>
                    <td className="customer-column main-column">
                        <div className="desktop-value">{order.customer_name}</div>
                        <div className="mobile-container">
                            <div className="mobile-item">
                                <div className="label">Order #:</div>
                                <div className="value">{order.id}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Customer:</div>
                                <div className="value">{order.customer_name}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Ordered at:</div>
                                <div className="value">{dateToTimezone(order.date, 'MM/DD/YYYY')}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Total Jobs:</div>
                                <div className="value">{order.total_jobs}</div>
                            </div>
                            <div className="mobile-actions">
                                <Link to={'/my-orders/details/' + order.id}><button>Details</button></Link>
                            </div>
                        </div>
                    </td>
                    <td className="ordered-at-column desktop-column">{dateToTimezone(order.date, 'MM/DD/YYYY')}</td>
                    <td className="total-jobs-column desktop-column">{order.total_jobs}</td>
                    <td className="actions-column desktop-column">
                        <div className="actions-container">
                            {detailsIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    // const handleSort = (column, type) => {
    //     props.setSort(column, type, props.type);
    // };

    const renderHeader = (label, field) => {
        return <div className="sort-item">{label}</div>;

        // TODO: Enable sort

        // let sortType = 'asc';
        // let sortIcon = <i className="fas fa-sort" />;
        // if (props.sort.column === field) {
        //     if (props.sort.type === 'desc') {
        //         sortIcon = <i className="fas fa-sort-down" />;
        //         sortType = 'asc';
        //     } else {
        //         sortIcon = <i className="fas fa-sort-up" />;
        //         sortType = 'desc';
        //     }
        // }

        // return <div className="sort-item" onClick={handleSort.bind(this, field, sortType)}>{label} {sortIcon}</div>;
    };

    return (
        <div className="table-container">
            <div className="title">My Orders</div>
            <table>
                <thead>
                    <tr className="header-row">
                        <th className="id-column desktop-column">{renderHeader('Order #', 'id')}</th>
                        <th className="customer-column desktop-column">{renderHeader('Customer', 'customer_name')}</th>
                        <th className="ordered-at-column desktop-column">{renderHeader('Ordered at', 'date')}</th>
                        <th className="total-jobs-column desktop-column">Total Jobs</th>
                        <th className="actions-column desktop-column"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderElements()}
                </tbody>
            </table>
            <Pagination
                pagination={props.pagination}
                setActivePage={props.setActivePage}
            />
        </div>
    );
}

export default Table;
