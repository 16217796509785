import { combineReducers } from 'redux';
import * as dialog from './components/common/dialog';
import AccountReducer from './components/Account/Account.reducer';
import ActivateAccountReducer from './components/ActivateAccount/ActivateAccount.reducer';
import AdminLayoutReducer from './components/AdminLayout/AdminLayout.reducer';
import AdminCareersReducer from './components/AdminCareers/AdminCareers.reducer';
import AdminConfigurationReducer from './components/AdminConfiguration/AdminConfiguration.reducer';
import AdminCouponsReducer from './components/AdminCoupons/AdminCoupons.reducer';
import AdminCustomersReducer from './components/AdminCustomers/AdminCustomers.reducer';
import AdminJobsReducer from './components/AdminJobs/AdminJobs.reducer';
import AdminFaqsReducer from './components/AdminFaqs/AdminFaqs.reducer';
import AdminLoginReducer from './components/AdminLogin/AdminLogin.reducer';
import AdminOrdersReducer from './components/AdminOrders/AdminOrders.reducer';
import AdminPointsReducer from './components/AdminPoints/AdminPoints.reducer';
import AdminPrizesReducer from './components/AdminPrizes/AdminPrizes.reducer';
import AdminTestimonialsReducer from './components/AdminTestimonials/AdminTestimonials.reducer';
import AdminShoppingCartItemsReducer from './components/AdminShoppingCartItems/AdminShoppingCartItems.reducer';
import AdminUsersReducer from './components/AdminUsers/AdminUsers.reducer';
import CareersReducer from './components/Careers/Careers.reducer';
import CertificationAndAwardsReducer from './components/CertificationAndAwards/CertificationAndAwards.reducer';
import CheckoutReducer from './components/Checkout/Checkout.reducer';
import CompanyReducer from './components/Company/Company.reducer';
import ContactReducer from './components/Contact/Contact.reducer';
import CreditApplicationReducer from './components/CreditApplication/CreditApplication.reducer';
import CustomDesignServicesReducer from './components/CustomDesignServices/CustomDesignServices.reducer';
import CustomQuoteReducer from './components/CustomQuote/CustomQuote.reducer';
import EnvironmentalStatementReducer from './components/EnvironmentalStatement/EnvironmentalStatement.reducer';
import FaqsReducer from './components/Faqs/Faqs.reducer';
import FileUploadReducer from './components/FileUpload/FileUpload.reducer';
import ForgotPasswordReducer from './components/ForgotPassword/ForgotPassword.reducer';
import LandingReducer from './components/Landing/Landing.reducer';
import LayoutReducer from './components/Layout/Layout.reducer';
import LoginReducer from './components/Login/Login.reducer';
import LoyaltyPointsReducer from './components/LoyaltyPoints/LoyaltyPoints.reducer';
import MeetOwnersReducer from './components/MeetOwners/MeetOwners.reducer';
import MyAddressReducer from './components/MyAddress/MyAddress.reducer';
import MyAddressBookReducer from './components/MyAddressBook/MyAddressBook.reducer';
import MyInformationReducer from './components/MyInformation/MyInformation.reducer';
import MyJobsReducer from './components/MyJobs/MyJobs.reducer';
import MyOrderDetailsReducer from './components/MyOrderDetails/MyOrderDetails.reducer';
import MyOrdersReducer from './components/MyOrders/MyOrders.reducer';
import OurHistoryReducer from './components/OurHistory/OurHistory.reducer';
import OverviewReducer from './components/Overview/Overview.reducer';
import PartnersReducer from './components/Partners/Partners.reducer';
import PrivacyReducer from './components/Privacy/Privacy.reducer';
import ProductReducer from './components/Product/Product.reducer';
import ProfileAndVisionReducer from './components/ProfileAndVision/ProfileAndVision.reducer';
import ResetPasswordReducer from './components/ResetPassword/ResetPassword.reducer';
import ShoppingCartReducer from './components/ShoppingCart/ShoppingCart.reducer';
import SignUpReducer from './components/SignUp/SignUp.reducer';
import TermsReducer from './components/Terms/Terms.reducer';

const reducer = combineReducers({
    account: AccountReducer,
    activateAccount: ActivateAccountReducer,
    adminLayout: AdminLayoutReducer,
    adminCareers: AdminCareersReducer,
    adminConfiguration: AdminConfigurationReducer,
    adminCoupons: AdminCouponsReducer,
    adminCustomers: AdminCustomersReducer,
    adminJobs: AdminJobsReducer,
    adminFaqs: AdminFaqsReducer,
    adminLogin: AdminLoginReducer,
    adminOrders: AdminOrdersReducer,
    adminPoints: AdminPointsReducer,
    adminPrizes: AdminPrizesReducer,
    adminTestimonials: AdminTestimonialsReducer,
    adminShoppingCartItems: AdminShoppingCartItemsReducer,
    adminUsers: AdminUsersReducer,
    careers: CareersReducer,
    certificationAndAwards: CertificationAndAwardsReducer,
    checkout: CheckoutReducer,
    company: CompanyReducer,
    contact: ContactReducer,
    creditApplication: CreditApplicationReducer,
    customDesignServices: CustomDesignServicesReducer,
    customQuote: CustomQuoteReducer,
    environmentalStatement: EnvironmentalStatementReducer,
    faqs: FaqsReducer,
    fileUpload: FileUploadReducer,
    forgotPassword: ForgotPasswordReducer,
    dialog: dialog.reducer,
    landing: LandingReducer,
    layout: LayoutReducer,
    login: LoginReducer,
    loyaltyPoints: LoyaltyPointsReducer,
    meetOwners: MeetOwnersReducer,
    myAddress: MyAddressReducer,
    myAddressBook: MyAddressBookReducer,
    myInformation: MyInformationReducer,
    myJobs: MyJobsReducer,
    myOrderDetails: MyOrderDetailsReducer,
    myOrders: MyOrdersReducer,
    ourHistory: OurHistoryReducer,
    overview: OverviewReducer,
    partners: PartnersReducer,
    privacy: PrivacyReducer,
    product: ProductReducer,
    profileAndVision: ProfileAndVisionReducer,
    resetPassword: ResetPasswordReducer,
    shoppingCart: ShoppingCartReducer,
    signUp: SignUpReducer,
    terms: TermsReducer,
});

export default reducer;
