import { createSelector } from 'reselect';

export const getComponentState = state => state.product;

export const getName = createSelector(
    getComponentState,
    (state) => state.name
);
export const getProduct = createSelector(
    getComponentState,
    (state) => state.product
);
export const getFetchingProduct = createSelector(
    getComponentState,
    (state) => state.fetchingProduct
);
export const getPrice = createSelector(
    getComponentState,
    (state) => state.price
);
export const getCalculatingPrice = createSelector(
    getComponentState,
    (state) => state.calculatingPrice
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getViewTurnaround = createSelector(
    getComponentState,
    (state) => state.viewTurnaround
);
export const getViewRetail = createSelector(
    getComponentState,
    (state) => state.viewRetail
);
export const getAddingToCart = createSelector(
    getComponentState,
    (state) => state.addingToCart
);
export const getFetchingItem = createSelector(
    getComponentState,
    (state) => state.fetchingItem
);
export const getIsEdit = createSelector(
    getComponentState,
    (state) => state.isEdit
);
export const getItem = createSelector(
    getComponentState,
    (state) => state.item
);
export const getSaving = createSelector(
    getComponentState,
    (state) => state.saving
);
export const getRetailMarkup = createSelector(
    getComponentState,
    (state) => state.retailMarkup
);
export const getRetailCustomerName = createSelector(
    getComponentState,
    (state) => state.retailCustomerName
);
export const getDownloadingRetail = createSelector(
    getComponentState,
    (state) => state.downloadingRetail
);
export const getRelatedProducts = createSelector(
    getComponentState,
    (state) => state.relatedProducts
);
export const getPrintingType = createSelector(
    getComponentState,
    (state) => state.printingType
);