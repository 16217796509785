import { createSelector } from 'reselect';

export const getComponentState = state => state.customQuote

export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getSending = createSelector(
    getComponentState,
    (state) => state.sending
);