import React from 'react';

const Form = (props) => {
    const handleLogin = () => {
        props.history.push('/login');
    }

    let container = null;
    if (props.activating) {
        container = (
            <div className="activating-container">
                Thank you for signing up! We are activating your account, please wait. <i className="fas fa-circle-notch fa-spin" />
            </div>
        );
    } else {
        if (props.activated) {
            container = (
                <div className="activating-container">
                    <p>Your account has been activated successfully. Please, click on the button below to sign in.</p>
                    <div className="text-center">
                        <button onClick={handleLogin}>Log In</button>
                    </div>
                </div>
            );
        } else {
            container = (
                <div className="activating-container">
                    <p>We had problems activating your account. Please, contact our support team at <a href="mailto:support@overnightcolor.com">support@overnightcolor.com</a> for more information.</p>
                </div>
            );
        }
    }

    return (
        <div className="form-container">
            <div className="logo-container">
                <img src="https://overnightcolor.s3.amazonaws.com/css/logo.png" alt="OvernightColor" />
            </div>
            {container}
        </div>
    );
}

export default Form;
