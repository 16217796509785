import Immutable from 'immutable';
import * as types from './ResetPassword.types';

const initialState = {
    form: {
        password: '',
        confirm_password: '',
    },
    resetting: false,
    reset: false,
    userType: null,
    id: null,
    token: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.RESET_PASSWORD:
            return state.setIn(['resetting'], true).toJS();

        case types.RESET_PASSWORD_SUCCESS:
            return state.setIn(['resetting'], false)
                .setIn(['reset'], true)
                .setIn(['userType'], action.payload.abs_type)
                .toJS();

        case types.RESET_PASSWORD_ERROR:
            return state.setIn(['resetting'], false).toJS();

        case types.SET_ID:
            return state.setIn(['id'], action.payload).toJS();

        case types.SET_TOKEN:
            return state.setIn(['token'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['form', action.payload.name], action.payload.value).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}