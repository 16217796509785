import { createSelector } from 'reselect';

export const getComponentState = state => state.myAddressBook;

export const getBillingAddresses = createSelector(
    getComponentState,
    (state) => state.billingAddresses
);
export const getFetchingBillingAddresses = createSelector(
    getComponentState,
    (state) => state.fetchingBillingAddresses
);
export const getShippingAddresses = createSelector(
    getComponentState,
    (state) => state.shippingAddresses
);
export const getFetchingShippingAddresses = createSelector(
    getComponentState,
    (state) => state.fetchingShippingAddresses
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);