import React from 'react';

const Form = (props) => {
    const handleSetField = (field, event) => {
        props.setField(field, event.target.value)
    };

    return (
        <div className="form-container content-container">
            <div className="link">
                <a href="https://overnightcolor.s3.amazonaws.com/downloads/absolute-color-credit-application.pdf" target="_blank" rel="noopener noreferrer">
                    Click Here</a> for a downloadable version (pdf)
            </div>
            <h2>Please complete the credit application form below to apply for a new account:</h2>
            <div className="contact-form-container">
                <h3>General Information</h3>
                <div className="contact-form">
                    <div className="field">
                        <div className="label">Company Name <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.company_name} onChange={handleSetField.bind(this, 'company_name')} />
                    </div>
                    <div className="field">
                        <div className="label">Trade Name:</div>
                        <input type="text" value={props.fields.trade_name} onChange={handleSetField.bind(this, 'trade_name')} />
                    </div>
                    <div className="field">
                        <div className="label">Address <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.address} onChange={handleSetField.bind(this, 'address')} />
                    </div>
                    <div className="field">
                        <div className="label">City <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.city} onChange={handleSetField.bind(this, 'city')} />
                    </div>
                    <div className="field">
                        <div className="label">State <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.state} onChange={handleSetField.bind(this, 'state')} />
                    </div>
                    <div className="field">
                        <div className="label">Zip Code <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.zip} onChange={handleSetField.bind(this, 'zip')} />
                    </div>
                    <div className="field">
                        <div className="label">Phone <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.phone} onChange={handleSetField.bind(this, 'phone')} />
                    </div>
                    <div className="field">
                        <div className="label">Fax:</div>
                        <input type="text" value={props.fields.fax} onChange={handleSetField.bind(this, 'fax')} />
                    </div>
                    <div className="field">
                        <div className="label">Website:</div>
                        <input type="text" value={props.fields.website} onChange={handleSetField.bind(this, 'website')} />
                    </div>
                </div>
                <h3>Accounts Payable Contact Information</h3>
                <div className="contact-form">
                    <div className="field">
                        <div className="label">Contact Name <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.contact_name} onChange={handleSetField.bind(this, 'contact_name')} />
                    </div>
                    <div className="field">
                        <div className="label">Contact Phone <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.contact_phone} onChange={handleSetField.bind(this, 'contact_phone')} />
                    </div>
                    <div className="field">
                        <div className="label">Contact Email <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.contact_email} onChange={handleSetField.bind(this, 'contact_email')} />
                    </div>
                    <div className="field">
                        <div className="label">Contact Address <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.contact_address} onChange={handleSetField.bind(this, 'contact_address')} />
                    </div>
                    <div className="field">
                        <div className="label">Contact City <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.contact_city} onChange={handleSetField.bind(this, 'contact_city')} />
                    </div>
                    <div className="field">
                        <div className="label">Contact State <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.contact_state} onChange={handleSetField.bind(this, 'contact_state')} />
                    </div>
                    <div className="field">
                        <div className="label">Contact Zip <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.contact_zip} onChange={handleSetField.bind(this, 'contact_zip')} />
                    </div>
                </div>
                <h3>Business Information</h3>
                <div className="contact-form">
                    <div className="field">
                        <div className="label">Business Entity <span className="required">*</span>:</div>
                        <div className="select-field">
                            <select value={props.fields.business_entity} onChange={handleSetField.bind(this, 'business_entity')}>
                                <option value="">Please Select</option>
                                <option value="Corporation">Corporation</option>
                                <option value="Partnership">Partnership</option>
                                <option value="Individual">Individual</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Fed ID # (SSN for Individual) <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.fed_id} onChange={handleSetField.bind(this, 'fed_id')} />
                    </div>
                    <div className="field">
                        <div className="label">Type of Business <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.business_type} onChange={handleSetField.bind(this, 'business_type')} />
                    </div>
                    <div className="field">
                        <div className="label">Est. Annual Print Purchases ($) <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.estimated_annual_print} onChange={handleSetField.bind(this, 'estimated_annual_print')} />
                    </div>
                    <div className="field">
                        <div className="label">Is the Company Tax Exempt? <span className="required">*</span>:</div>
                        <div className="select-field">
                            <select value={props.fields.tax_exempt} onChange={handleSetField.bind(this, 'tax_exempt')}>
                                <option value="">Please Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Applicable Sales Tax Rate (%) <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.sales_tax_rate} onChange={handleSetField.bind(this, 'sales_tax_rate')} />
                    </div>
                    <div className="field">
                        <div className="label">County <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.county} onChange={handleSetField.bind(this, 'county')} />
                    </div>
                </div>
                <h3>Banking Information</h3>
                <div className="contact-form">
                    <div className="field">
                        <div className="label">Bank Name <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.bank_name} onChange={handleSetField.bind(this, 'bank_name')} />
                    </div>
                    <div className="field">
                        <div className="label">Account Number <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.account_number} onChange={handleSetField.bind(this, 'account_number')} />
                    </div>
                    <div className="field">
                        <div className="label">Bank Officer Name <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.bank_officer_name} onChange={handleSetField.bind(this, 'bank_officer_name')} />
                    </div>
                    <div className="field">
                        <div className="label">Bank Phone Number <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.bank_phone_number} onChange={handleSetField.bind(this, 'bank_phone_number')} />
                    </div>
                </div>
                <h3>Trade References (3 required)</h3>
                <div className="contact-form">
                    <div className="field">
                        <div className="label">Reference 1 Name <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference1_name} onChange={handleSetField.bind(this, 'reference1_name')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 1 Address <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference1_address} onChange={handleSetField.bind(this, 'reference1_address')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 1 City <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference1_city} onChange={handleSetField.bind(this, 'reference1_city')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 1 State <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference1_state} onChange={handleSetField.bind(this, 'reference1_state')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 1 Zip <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference1_zip} onChange={handleSetField.bind(this, 'reference1_zip')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 1 Phone <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference1_phone} onChange={handleSetField.bind(this, 'reference1_phone')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 1 Fax:</div>
                        <input type="text" value={props.fields.reference1_fax} onChange={handleSetField.bind(this, 'reference1_fax')} />
                    </div>
                    <hr />
                    <div className="field">
                        <div className="label">Reference 2 Name <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference2_name} onChange={handleSetField.bind(this, 'reference2_name')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 2 Address <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference2_address} onChange={handleSetField.bind(this, 'reference2_address')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 2 City <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference2_city} onChange={handleSetField.bind(this, 'reference2_city')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 2 State <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference2_state} onChange={handleSetField.bind(this, 'reference2_state')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 2 Zip <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference2_zip} onChange={handleSetField.bind(this, 'reference2_zip')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 2 Phone <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference2_phone} onChange={handleSetField.bind(this, 'reference2_phone')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 2 Fax:</div>
                        <input type="text" value={props.fields.reference2_fax} onChange={handleSetField.bind(this, 'reference2_fax')} />
                    </div>
                    <hr />
                    <div className="field">
                        <div className="label">Reference 3 Name <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference3_name} onChange={handleSetField.bind(this, 'reference3_name')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 3 Address <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference3_address} onChange={handleSetField.bind(this, 'reference3_address')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 3 City <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference3_city} onChange={handleSetField.bind(this, 'reference3_city')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 3 State <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference3_state} onChange={handleSetField.bind(this, 'reference3_state')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 3 Zip <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference3_zip} onChange={handleSetField.bind(this, 'reference3_zip')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 3 Phone <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.reference3_phone} onChange={handleSetField.bind(this, 'reference3_phone')} />
                    </div>
                    <div className="field">
                        <div className="label">Reference 3 Fax:</div>
                        <input type="text" value={props.fields.reference3_fax} onChange={handleSetField.bind(this, 'reference3_fax')} />
                    </div>
                </div>
                <h3 className="terms-conditions-title">Credit Terms & Conditions</h3>
                <div className="conditions">
                    <p>
                        For the purpose of establishing and maintaining credit, the statements and information provided in and with this application are full,
                        true, and correct. Applicant authorizes OvernightColor to make inquiry into, to request, and to receive any information concerning character,
                        general reputation, financial or credit status from creditors or financial institutions which OvernightColor deems relevant for the
                        granting and collection of the proposed indebtedness and the Applicant authorizes any creditor or financial institution to divulge such
                        information. Applicant understands that OvernightColor will rely on the accuracy of any information set forth in and with this application
                        and all information obtained in determining whether to extend credit. Applicant agrees to pay all charges within 30 days from invoice date.
                        Applicant understands and agrees that payment in accordance with agreed upon terms is not contingent on Applicant's receipt of payment
                        from any other party for goods or services provided by OvernightColor. Applicant agrees to pay all costs of collection, including reasonable
                        attorney fees, in the event Applicant fails to pay any charges when due.
                    </p>
                    <p>
                        OvernightColor reserves the right to not extend credit to the Applicant or to withdraw credit at any time at OvernightColor's sole discretion.
                        Applicant wishes to apply for credit with OvernightColor in accordance with these terms and conditions which have been read, understood and
                        accepted. The undersigned is an officer or owner of Applicant and is authorized to represent and bind Applicant with respect to these matters.
                    </p>
                </div>
                <div className="contact-form mt-4">
                    <div className="field">
                        <div className="label">Agree to Terms? <span className="required">*</span>:</div>
                        <div className="select-field">
                            <select value={props.fields.agree} onChange={handleSetField.bind(this, 'agree')}>
                                <option value="">Please Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Electronic Signature (Type Name) <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.electronic_signature} onChange={handleSetField.bind(this, 'electronic_signature')} />
                    </div>
                    <div className="field">
                        <div className="label">Job Title <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.job_title} onChange={handleSetField.bind(this, 'job_title')} />
                    </div>
                </div>
                <div className="button-container">
                    <button onClick={props.sendContact} disabled={props.sending}>
                        {props.sending ? <i className="fas fa-circle-notch fa-spin" /> : 'Send'}
                    </button>
                </div>
            </div>
        </div >
    );
}

export default Form;
