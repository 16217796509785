import React from 'react';
import { Dialog } from './../../common/dialog';

const ArtworksWarningModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideArtworksWarningModal();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
    ];
    return (
        <Dialog
            name="frontend-artworks-warning-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="frontend-artworks-warning-dialog"
        >
            <div className="frontend-artworks-warning-modal-container">
                <h1>Important Information</h1>
                <p className="notice">
                    Dear customer you have pending artwork to upload for the Jobs # ({props.missingArtworksJobs.join(', ')}).
                    Please upload your artwork by clicking on this icon
                    <i className="fa-solid fa-file-image"></i>
                </p>
            </div>
        </Dialog >
    );
});

export default ArtworksWarningModal;
