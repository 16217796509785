import React from 'react';

const FlyersTemplates = React.memo((props) => {
    const templates = [
        'flyer_11inx17in_front',
        'flyer_11inx25.5in_front',
        'flyer_5.5inx8.5in_h-front',
        'flyer_8.5inx11in_front',
        'flyer_8.5inx14in_front',
        'flyer_9inx12in_front',
    ];

    return templates.map((template) => {
        return (
            <tr>
                <td>{template}</td>
                <td className="actions">
                    <a href={'https://overnightcolor.s3.amazonaws.com/templates/' + template + '.pdf'} target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-file-arrow-down"></i> Download
                    </a>
                </td>
            </tr>
        );
    });
});

export default FlyersTemplates;
