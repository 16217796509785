import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import store from './store';
import ReactNotification from 'react-notifications-component';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'react-notifications-component/dist/theme.css';
import './index.scss';
import Account from './components/Account';
import ActivateAccount from './components/ActivateAccount';
import AdminCareers from './components/AdminCareers';
import AdminConfiguration from './components/AdminConfiguration';
import AdminCoupons from './components/AdminCoupons';
import AdminCustomers from './components/AdminCustomers';
import AdminFaqs from './components/AdminFaqs';
import AdminJobs from './components/AdminJobs';
import AdminLogin from './components/AdminLogin';
import AdminOrders from './components/AdminOrders';
import AdminPoints from './components/AdminPoints';
import AdminPrizes from './components/AdminPrizes';
import AdminShoppingCartItems from './components/AdminShoppingCartItems';
import AdminTestimonials from './components/AdminTestimonials';
import AdminUsers from './components/AdminUsers';
import Careers from './components/Careers';
import CertificationAndAwards from './components/CertificationAndAwards';
import Checkout from './components/Checkout';
import Company from './components/Company';
import Contact from './components/Contact';
import CreditApplication from './components/CreditApplication';
import CustomDesignServices from './components/CustomDesignServices';
import CustomQuote from './components/CustomQuote';
import EnvironmentalStatement from './components/EnvironmentalStatement';
import Faqs from './components/Faqs';
import FileUpload from './components/FileUpload';
import ForgotPassword from './components/ForgotPassword';
import Landing from './components/Landing';
import Login from './components/Login';
import LoyaltyPoints from './components/LoyaltyPoints';
import MeetOwners from './components/MeetOwners';
import MyAddress from './components/MyAddress';
import MyAddressBook from './components/MyAddressBook';
import MyInformation from './components/MyInformation';
import MyJobs from './components/MyJobs';
import MyOrderDetails from './components/MyOrderDetails';
import MyOrders from './components/MyOrders';
import OurHistory from './components/OurHistory';
import Overview from './components/Overview';
import Partners from './components/Partners';
import Privacy from './components/Privacy';
import Product from './components/Product';
import ProfileAndVision from './components/ProfileAndVision';
import ResetPassword from './components/ResetPassword';
import ShoppingCart from './components/ShoppingCart';
import SignUp from './components/SignUp';
import Terms from './components/Terms';

require('dotenv').config();

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

ReactDOM.render(
    <Provider store={store}>
        <ReactNotification />
        <Router>
            <Route path="/account" exact component={Account} />
            <Route path="/activate-account/:id/:token" exact component={ActivateAccount} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/" exact component={Landing} />
            <Route path="/login" exact component={Login} />
            <Route path="/:type/address/:action" exact component={MyAddress} />
            <Route path="/:type/address/:action/:id" exact component={MyAddress} />
            <Route path="/my-address-book" exact component={MyAddressBook} />
            <Route path="/my-information" exact component={MyInformation} />
            <Route path="/my-jobs" exact component={MyJobs} />
            <Route path="/my-orders/details/:id" exact component={MyOrderDetails} />
            <Route path="/my-orders/details/:id/:showArtworks" exact component={MyOrderDetails} />
            <Route path="/my-orders" exact component={MyOrders} />
            <Route path="/loyalty-points" exact component={LoyaltyPoints} />
            <Route path="/product/:name" exact component={Product} />
            <Route path="/product/:name/:printingType" exact component={Product} />
            <Route path="/product/:name/edit/:id" exact component={Product} />
            <Route path="/product/:name/edit/:id/:printingType" exact component={Product} />
            <Route path="/reset-password/:id/:token" exact component={ResetPassword} />
            <Route path="/sign-up" exact component={SignUp} />
            <Route path="/shopping-cart" exact component={ShoppingCart} />
            <Route path="/checkout" exact component={Checkout} />
            <Route path="/company/online-printer" exact component={Company} />
            {/* <Route path="/company/environmental-statement" exact component={Company} /> */}
            <Route path="/company/terms-conditions" exact component={Terms} />
            <Route path="/company/privacy-policy" exact component={Privacy} />
            <Route path="/company/contact-us" exact component={Contact} />
            <Route path="/company/custom-quote" exact component={CustomQuote} />
            <Route path="/company/overview" exact component={Overview} />
            <Route path="/company/meet-the-owners" exact component={MeetOwners} />
            <Route path="/company/profile-and-vision" exact component={ProfileAndVision} />
            <Route path="/company/our-history" exact component={OurHistory} />
            <Route path="/company/certification-and-awards" exact component={CertificationAndAwards} />
            <Route path="/company/faqs" exact component={Faqs} />
            <Route path="/company/careers" exact component={Careers} />
            <Route path="/company/custom-design-services" exact component={CustomDesignServices} />
            <Route path="/company/partners" exact component={Partners} />
            <Route path="/company/environmental-statement" exact component={EnvironmentalStatement} />
            <Route path="/tools/file-upload" exact component={FileUpload} />
            <Route path="/tools/credit-application" exact component={CreditApplication} />

            <Route path="/admin/configuration" exact component={AdminConfiguration} />
            <Route path="/admin/coupons" exact component={AdminCoupons} />
            <Route path="/admin/coupons/:action" exact component={AdminCoupons} />
            <Route path="/admin/coupons/:action/:id" exact component={AdminCoupons} />
            <Route path="/admin/customer/:customerId/reward-points" exact component={AdminPoints} />
            <Route path="/admin/customer/:customerId/reward-points/:action" exact component={AdminPoints} />
            <Route path="/admin/customer/:customerId/reward-points/:action/:id" exact component={AdminPoints} />
            <Route path="/admin/prizes" exact component={AdminPrizes} />
            <Route path="/admin/prizes/:action" exact component={AdminPrizes} />
            <Route path="/admin/prizes/:action/:id" exact component={AdminPrizes} />
            <Route path="/admin/customers" exact component={AdminCustomers} />
            <Route path="/admin/customers/:action" exact component={AdminCustomers} />
            <Route path="/admin/customers/:action/:id" exact component={AdminCustomers} />
            <Route path="/admin/jobs" exact component={AdminJobs} />
            <Route path="/admin/jobs/:action" exact component={AdminJobs} />
            <Route path="/admin/jobs/:action/:id" exact component={AdminJobs} />
            <Route path="/admin/login" exact component={AdminLogin} />
            <Route path="/admin" exact component={AdminJobs} />
            <Route path="/admin/customer-orders/:customerId" exact component={AdminJobs} />
            <Route path="/admin/orders" exact component={AdminOrders} />
            <Route path="/admin/orders/:action" exact component={AdminOrders} />
            <Route path="/admin/orders/:action/:id" exact component={AdminOrders} />
            <Route path="/admin/testimonials" exact component={AdminTestimonials} />
            <Route path="/admin/testimonials/:action" exact component={AdminTestimonials} />
            <Route path="/admin/testimonials/:action/:id" exact component={AdminTestimonials} />
            <Route path="/admin/users" exact component={AdminUsers} />
            <Route path="/admin/users/:action" exact component={AdminUsers} />
            <Route path="/admin/users/:action/:id" exact component={AdminUsers} />
            <Route path="/admin/shopping-cart-items" exact component={AdminShoppingCartItems} />
            <Route path="/admin/shopping-cart-items/:action" exact component={AdminShoppingCartItems} />
            <Route path="/admin/shopping-cart-items/:action/:id" exact component={AdminShoppingCartItems} />
            <Route path="/admin/faqs" exact component={AdminFaqs} />
            <Route path="/admin/faqs/:action" exact component={AdminFaqs} />
            <Route path="/admin/faqs/:action/:id" exact component={AdminFaqs} />
            <Route path="/admin/careers" exact component={AdminCareers} />
            <Route path="/admin/careers/:action" exact component={AdminCareers} />
            <Route path="/admin/careers/:action/:id" exact component={AdminCareers} />
        </Router>
    </Provider>,
    document.getElementById('root')
);
