// import axios from 'axios';
import * as types from './EnvironmentalStatement.types';
// import { validateAuthInResponse } from './../../utils';
//import * as selectors from './Landing.selectors';

// const apiUrl = process.env.REACT_APP_API_URL;

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}