import axios from 'axios';
import * as types from './AdminLogin.types';
import * as selectors from './AdminLogin.selectors';
import { showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const login = (history) => (dispatch, getState) => {
    const form = selectors.getForm(getState());

    if (form.email === '' || form.password === '') {
        showNotification('Complete the information', 'Complete all the required fields of the form', 'info');
        return;
    }

    dispatch({ type: types.LOGIN, payload: null });
    axios.post(apiUrl.replace('/v1', '') + '/oauth2/token',
        {
            grant_type: 'password',
            client_id: 'testclient',  // TODO: Add this to environment variables
            client_secret: 'testpass',  // TODO: Add this to environment variables
            username: form.email,
            password: form.password,
            loginType: 'ac-admin',
        })
        .then(response => {
            dispatch({
                type: types.LOGIN_SUCCESS,
                payload: response.data
            });
            dispatch(setTokens(response.data));

            history.push('/admin/jobs');
        })
        .catch((error) => {
            dispatch({ type: types.LOGIN_ERROR, payload: null });
            showNotification('Incorrect Credentials', 'The email or password are incorrect', 'info');
        });
}

export const setField = (name, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}

export const setTokens = (data) => dispatch => {
    localStorage.setItem('admin_user', JSON.stringify(data.user));
    localStorage.setItem('admin_access_token', data.access_token);
    localStorage.setItem('admin_refresh_token', data.refresh_token);
    dispatch({
        type: types.SET_TOKENS,
        payload: data
    });
}

export const clearTokens = () => dispatch => {
    localStorage.removeItem('admin_user');
    localStorage.removeItem('admin_access_token');
    localStorage.removeItem('admin_refresh_token');

    dispatch({
        type: types.CLEAR_TOKENS,
        payload: null
    });
}

export const logout = (history = null) => (dispatch) => {
    if (history != null) {
        history.push('/login');
    }

    dispatch(clearTokens());
}

export const adminLogout = (history = null) => (dispatch) => {
    if (history != null) {
        history.push('/admin/login');
    }

    dispatch(clearTokens());
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}