import React from 'react';

const FoldedCardsTemplates = React.memo((props) => {
    const templates = [
        '12x11_foldpostcard',
        '12x8_foldpostcard',
        '12x9_foldpostcard',
        '6x8.5_foldpostcard',
        '7x10_foldpostcard',
        '8.5x11_foldpostcard_outside',
    ];

    return templates.map((template) => {
        return (
            <tr>
                <td>{template}</td>
                <td className="actions">
                    <a href={'https://overnightcolor.s3.amazonaws.com/templates/' + template + '.pdf'} target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-file-arrow-down"></i> Download
                    </a>
                </td>
            </tr>
        );
    });
});

export default FoldedCardsTemplates;
