import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BillingForm from './BillingForm';
import ShippingForm from './ShippingForm';
import MyAccountMenu from './../MyAccountMenu';
import { stringifyEqualityCheck } from './../../utils';
import * as selectors from './MyAddress.selectors';
import * as actions from './MyAddress.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import './MyAddress.scss';

const MyAddress = (props) => {
    /* State to props */
    const address = useSelector(selectors.getAddress, stringifyEqualityCheck);
    const fetchingAddress = useSelector(selectors.getFetchingAddress);
    const id = useSelector(selectors.getId);
    const type = useSelector(selectors.getType);
    const action = useSelector(selectors.getAction);
    const savingAddress = useSelector(selectors.getSavingAddress);
    const fetchingInformation = useSelector(selectors.getFetchingInformation);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const file = useSelector(selectors.getFile, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchAddress = useCallback(() => dispatch(actions.fetchAddress()), [dispatch]);
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const setId = useCallback((value) => dispatch(actions.setId(value)), [dispatch]);
    const setType = useCallback((value) => dispatch(actions.setType(value)), [dispatch]);
    const setAction = useCallback((value) => dispatch(actions.setAction(value)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const saveAddress = useCallback(() => dispatch(actions.saveAddress()), [dispatch]);
    const setFile = useCallback((file) => dispatch(actions.setFile(file)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);
        setType(props.match.params.type);
        setAction(props.match.params.action);
        fetchInformation();

        if (props.match.params.action === 'edit') {
            setId(props.match.params.id);
            fetchAddress();
        }

        return () => {
            resetState();
        };
    }, [fetchAddress, setHistory, resetState, setId, setType, setAction, fetchInformation,
        props.history, props.match.params.id, props.match.params.type, props.match.params.action]);

    let form = null;
    if (props.match.params.type === 'billing') {
        form = (
            <BillingForm
                history={props.history}
                address={address}
                id={id}
                type={type}
                action={action}
                fetchingAddress={fetchingAddress}
                fetchingInformation={fetchingInformation}
                information={information}
                savingAddress={savingAddress}
                file={file}
                fetchAddress={fetchAddress}
                setField={setField}
                saveAddress={saveAddress}
                setFile={setFile}
            />
        );
    } else {
        form = (
            <ShippingForm
                history={props.history}
                address={address}
                id={id}
                type={type}
                action={action}
                fetchingAddress={fetchingAddress}
                fetchingInformation={fetchingInformation}
                information={information}
                savingAddress={savingAddress}
                fetchAddress={fetchAddress}
                setField={setField}
                saveAddress={saveAddress}
            />
        );
    }

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>My Address - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="my-address-container">
                <div className="content-container">
                    <MyAccountMenu
                        history={props.history}
                    />
                    {form}
                </div>
            </div>
        </Layout>
    );
};

export default MyAddress;
