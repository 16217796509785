import { createSelector } from 'reselect';

export const getComponentState = state => state.shoppingCart;

export const getItems = createSelector(
    getComponentState,
    (state) => state.items
);
export const getFetchingItems = createSelector(
    getComponentState,
    (state) => state.fetchingItems
);
export const getContinuingToCheckout = createSelector(
    getComponentState,
    (state) => state.continuingToCheckout
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getCalculatingPrice = createSelector(
    getComponentState,
    (state) => state.calculatingPrice
);
export const getCalculatingPriceIndex = createSelector(
    getComponentState,
    (state) => state.calculatingPriceIndex
);
export const getShippingAddresses = createSelector(
    getComponentState,
    (state) => state.shippingAddresses
);
export const getFetchingShippingAddresses = createSelector(
    getComponentState,
    (state) => state.fetchingShippingAddresses
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getSelectedItem = createSelector(
    getComponentState,
    (state) => state.selectedItem
);
export const getSelectedAddress = createSelector(
    getComponentState,
    (state) => state.selectedAddress
);
export const getSavingShippingAddress = createSelector(
    getComponentState,
    (state) => state.savingShippingAddress
);
