import axios from 'axios';
import * as types from './ResetPassword.types';
import * as selectors from './ResetPassword.selectors';
import * as loginActions from './../AdminLogin/AdminLogin.actions';
import { showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const resetPassword = () => (dispatch, getState) => {
    const id = selectors.getId(getState());
    const token = selectors.getToken(getState());
    const form = selectors.getForm(getState());

    if (form.password === '' || form.confirm_password === '') {
        return showNotification('Complete the information', 'Complete all the required fields of the form', 'info');
    }
    if (form.password.length < 6 || form.confirm_password.length < 6) {
        return showNotification('Complete the information', 'The passwords must have at least 6 characters', 'info');
    }
    if (form.password !== form.confirm_password) {
        return showNotification('Complete the information', 'The passwords do not match', 'info');
    }

    dispatch({ type: types.RESET_PASSWORD, payload: null });
    axios.post(apiUrl + '/abs-order/reset-password', { id, token, password: form.password },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.RESET_PASSWORD_SUCCESS, payload: response.data });
            showNotification('Password Reset Successfully', 'Your password has been reset successfully', 'success');
        })
        .catch((error) => {
            dispatch({ type: types.RESET_PASSWORD_ERROR, payload: null });
            if (error.response && error.response.status === 401) {
                showNotification('Unauthorized', 'You are not authorized to see this page', 'warning');
            } else if (error.response && error.response.status === 409) {
                showNotification('Invalid data', 'The Email or Username already exist', 'info');
            } else if (error.response && error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const setId = (value) => dispatch => {
    dispatch({
        type: types.SET_ID,
        payload: value
    });
}

export const setToken = (value) => dispatch => {
    dispatch({
        type: types.SET_TOKEN,
        payload: value
    });
}

export const setField = (name, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}