import React from 'react';

const TableTentsTemplates = React.memo((props) => {
    const templates = [
        'table_tent4.25x5',
        'table_tent4.25x6',
        'table_tent5.5x5.5ai',
    ];

    return templates.map((template) => {
        return (
            <tr>
                <td>{template}</td>
                <td className="actions">
                    <a href={'https://overnightcolor.s3.amazonaws.com/templates/' + template + '.pdf'} target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-file-arrow-down"></i> Download
                    </a>
                </td>
            </tr>
        );
    });
});

export default TableTentsTemplates;
