import React from 'react';

const Contact = (props) => {
    const handleSetField = (field, event) => {
        props.setField(field, event.target.value)
    };

    const handleEcoFriendly = () => {
        props.history.push('/company/environmental-statement');
    };

    const handleRequestQuote = () => {
        props.history.push('/company/custom-quote');
    };

    return (
        <div id="contact-form" className="contact-container content-container">
            <h3>
                Let's get started! Please fill out the form below with your information and project details so we can better understand your needs and how we can help bring your vision to life.
            </h3>
            <div className="contact-form">
                <div className="field">
                    <div className="label">First Name <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.first_name} onChange={handleSetField.bind(this, 'first_name')} placeholder="First Name" />
                </div>
                <div className="field">
                    <div className="label">Last Name <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.last_name} onChange={handleSetField.bind(this, 'last_name')} placeholder="Last Name" />
                </div>
                <div className="field">
                    <div className="label">Email <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.email} onChange={handleSetField.bind(this, 'email')} placeholder="Email" />
                </div>
                <div className="field">
                    <div className="label">Phone <span className="required">*</span>:</div>
                    <input type="text" value={props.fields.phone} onChange={handleSetField.bind(this, 'phone')} placeholder="Phone" />
                </div>
                <div className="comments-field">
                    <div className="label">Project Description <span className="required">*</span>:</div>
                    <textarea value={props.fields.message} onChange={handleSetField.bind(this, 'message')} placeholder="Project Description" />
                </div>
            </div>
            <div className="button-container">
                <button onClick={props.sendContact} disabled={props.sending}>
                    {props.sending ? <i className="fas fa-circle-notch fa-spin" /> : 'Send'}
                </button>
            </div>
            <div className="address">
                <div>OvernightColor. 11101 Ella Blvd.</div>
                <div>Houston, Texas 77067</div>
                <div>Phone: 832-608-6400 / Fax: 832-608-6420</div>
            </div>
            <div className="messages-container">
                <div className="message-main-container eco-friendly">
                    <div className="message-container">
                        <div className="title">ECO-FRIENDLY PRINTING</div>
                        <div className="message">
                            OvernightColor.com has taken the initiative and grown into an environmentally friendly company by taking significant
                            steps in our production of printed products.
                        </div>
                        <button onClick={handleEcoFriendly}>READ MORE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/eco-friendly.png" alt="Eco Friendly" />
                    </div>
                </div>
                <div className="message-main-container request-quote">
                    <div className="message-container">
                        <div className="title">REQUEST PRINT QUOTE</div>
                        <div className="message">
                            Request a quote for any print project by using our user-friendly online form. Submit the details of your print request
                            and we will provide you with an instant quote.
                        </div>
                        <button onClick={handleRequestQuote}>REQUEST A QUOTE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/request-quote.png" alt="Request Quote" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
