import React from 'react';

const Form = (props) => {
    const setFieldHandler = event => {
        props.setField(event.target.name, event.target.value);
    }

    const keyPressHandler = (event) => {
        if (event.key === 'Enter') {
            props.send();
        }
    }

    const handleSignUp = () => {
        props.send();
    }

    let container = (
        <div className="register-container">
            <h1>Reset your Password</h1>
            <div className="fields">
                <div className="field">
                    <div className="icon"><i className="fa-solid fa-envelope"></i></div>
                    <input type="text" name="email" value={props.form.email} onChange={setFieldHandler} onKeyPress={keyPressHandler} placeholder="Email" />
                </div>
            </div>
            <button type="button" onClick={handleSignUp} disabled={props.sending}>{props.sending ? <i className="fas fa-circle-notch fa-spin"></i> : 'Reset Password'}</button>
        </div>
    )
    if (props.sent) {
        container = (
            <div className="signed-up-container">
                An email has been sent to your email address with instructions to restore your password.
            </div>
        );
    }

    return (
        <div className="form-container">
            <div className="logo-container">
                <img src="https://overnightcolor.s3.amazonaws.com/css/logo.png" alt="OvernightColor" />
            </div>
            {container}
        </div>
    );
}

export default Form;
