import React from 'react';

const Information2 = (props) => {
    const handleEcoFriendly = () => {
        props.history.push('/company/environmental-statement');
    };

    const handleRequestQuote = () => {
        props.history.push('/company/custom-quote');
    };

    return (
        <div className="information2-container content-container">
            <div className="info-container">
                <div className="image">
                    <img src="https://overnightcolor.s3.amazonaws.com/css/komori-inks.jpg" alt="Komori Inks" />
                </div>
                <div className="description">
                    <p>
                        We offer a user-friendly online printing website with the industries' most powerful and flexible instant quote tool.
                        Our online printing automated and digital workflow guarantees color management from Proof to Plate to Press (3Ps).
                        Proofs are instantly generated and sent to our customer, at no extra cost, and online orders can be tracked in real-time
                        via your secure login account on our online printing portal. It's that easy!
                    </p>
                    <p>
                        We know how critical it is for your businesses to receive printing services and high-quality printed materials quickly.
                        That is why we are the only printing company that offers 48 hour turnaround or less on printed products - Guaranteed! If
                        your proof is approved by 3:00 PM CMT, your order is guaranteed to go to print that evening and ship from our facility within
                        48 hours or less. Some restrictions may apply to very large quantities and some specialty products.
                    </p>
                    <p>
                        Our customers are our top priority and we strive to deliver 100% customer satisfaction. Whatever your needs are, from a simple
                        full-color business cards to a 412 page perfect bound catalog, we can handle your project every step of the way. Starting from
                        your first click to final delivery of your products, you will be treated with a high level of professionalism and attention to
                        detail that no printing company can match.
                    </p>
                    <p>
                        We have built our reputation by delivering quality printing that businesses need for over 30 years, with our commitment to Quality,
                        Service and Cost effective pricing plus a 100% guarantee to back up our statement of turnaround. If you haven't contemplated
                        ordering your printing online, we hope you'll consider us for your next printing project. You can buy with confidence as your
                        orders are backed by satisfaction and on-time shipping guarantees. Give us a call - we're here to help! Our goal is to make
                        printing easy to order and affordable to everyone. On behalf of our entire staff at OvernightColor.com, thank you for your business
                        and continued support and for allowing us to be the leading online business-to-business printing portal in the nation. We look
                        forward to serving you for many years to come.
                    </p>
                </div>
            </div>
            <div className="messages-container">
                <div className="message-main-container eco-friendly">
                    <div className="message-container">
                        <div className="title">ECO-FRIENDLY PRINTING</div>
                        <div className="message">
                            OvernightColor.com has taken the initiative and grown into an environmentally friendly company by taking significant
                            steps in our production of printed products.
                        </div>
                        <button onClick={handleEcoFriendly}>READ MORE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/eco-friendly.png" alt="Eco Friendly" />
                    </div>
                </div>
                <div className="message-main-container request-quote">
                    <div className="message-container">
                        <div className="title">REQUEST PRINT QUOTE</div>
                        <div className="message">
                            Request a quote for any print project by using our user-friendly online form. Submit the details of your print request
                            and we will provide you with an instant quote.
                        </div>
                        <button onClick={handleRequestQuote}>REQUEST A QUOTE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/request-quote.png" alt="Request Quote" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Information2;
