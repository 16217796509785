import React from 'react';

const Pagination = (props) => {
    const pageClickHandler = (page) => {
        props.setActivePage(page);
    };

    let pages = [];
    if (props.pagination.totalPages > 7) {
        let centerPages = [];

        let middleStart = props.pagination.activePage - 2;
        if (middleStart < 1) {
            middleStart = 1;
        }

        let middleEnd = props.pagination.activePage + 2;
        if (middleEnd > props.pagination.totalPages) {
            middleEnd = props.pagination.totalPages;
        }

        for (let i = middleStart - 1; i < middleEnd; i++) {
            let activeClass = '';
            if (i === props.pagination.activePage - 1) {
                activeClass = 'active';
            }

            centerPages.push(i + 1);
            pages.push(<div key={'pagination-page-' + i} className={'pagination-page ' + activeClass} onClick={pageClickHandler.bind(this, i + 1)}>{i + 1}</div>);
        }

        if (!centerPages.includes(1)) {
            if(centerPages[0] !== 2) {
                pages.unshift(<div key={'pagination-page-dots-1'} className="pagination-page pagination-dots">...</div>);
            }
            
            pages.unshift(<div key={'pagination-page-0'} className="pagination-page" onClick={pageClickHandler.bind(this, 1)}>1</div>);
        }

        if (!centerPages.includes(props.pagination.totalPages)) {
            if(centerPages[centerPages.length - 1] !== props.pagination.totalPages - 1) {
                pages.push(<div key={'pagination-page-dots-2'} className="pagination-page pagination-dots">...</div>);
            }

            pages.push(<div key={'pagination-page-' + (props.pagination.totalPages - 1)} className="pagination-page"
                onClick={pageClickHandler.bind(this, props.pagination.totalPages)}>{props.pagination.totalPages}</div>);
        }
    } else {
        for (let i = 0; i < props.pagination.totalPages; i++) {
            let activeClass = '';
            if (i === props.pagination.activePage - 1) {
                activeClass = 'active';
            }

            pages.push(<div key={'pagination-page-' + i} className={'pagination-page ' + activeClass} onClick={pageClickHandler.bind(this, i + 1)}>{i + 1}</div>);
        }
    }

    const prevClickHandler = () => {
        if (props.pagination.activePage > 1) {
            props.setActivePage(props.pagination.activePage - 1);
        }
    };

    const nextClickHandler = () => {
        if (props.pagination.activePage < props.pagination.totalPages) {
            props.setActivePage(props.pagination.activePage + 1);
        }
    };

    let showingStart = props.pagination.activePage * props.pagination.elementsPerPage - props.pagination.elementsPerPage + 1;
    if (parseInt(props.pagination.totalElements) === 0) {
        showingStart = 0;
    }

    let showingEnd = props.pagination.activePage * props.pagination.elementsPerPage;
    if (showingEnd > props.pagination.totalElements) {
        showingEnd = props.pagination.totalElements;
    }

    return (
        <div className="pagination-container">
            <div className="pagination-prev-page" onClick={prevClickHandler}><i className="fas fa-chevron-left"></i></div>
            {pages}
            <div className="pagination-next-page" onClick={nextClickHandler}><i className="fas fa-chevron-right"></i></div>
            <div className="pagination-summary">Showing {showingStart}-{showingEnd} of {props.pagination.totalElements} items</div>
        </div>
    );
}

export default Pagination;
