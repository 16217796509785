import React from 'react';
import Pagination from './../../Pagination';
// import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
// import { Tooltip } from 'react-tippy';
// import 'react-tippy/dist/tippy.css';

const Table = (props) => {
    if (props.actionType !== 'list') {
        return null;
    }

    const renderElements = () => {
        if (props.fetchingElements) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.items.length === 0) {
            return (
                <tr>
                    <td colSpan="10">No results</td>
                </tr>
            );
        }

        return props.items.map((item) => {
            return (
                <tr>
                    <td className="date-column desktop-column">{moment(item.date).format('MM/DD/YYYY')}</td>
                    <td className="product-column main-column">
                        <div className="desktop-column">{item.product}</div>
                        <div className="mobile-container">
                            <div className="mobile-item">
                                <div className="label">Product:</div>
                                <div className="value">{item.product}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Job Title:</div>
                                <div className="value">{item.job_title}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Order #:</div>
                                <div className="value">{item.order_number}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Job #:</div>
                                <div className="value">{item.job_number}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Description:</div>
                                <div className="value">{item.description}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Points:</div>
                                <div className="value">{numeral(item.points).format('0,0')}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Balance:</div>
                                <div className="value">{numeral(item.balance).format('0,0')}</div>
                            </div>
                        </div>
                    </td>
                    <td className="job-title-column desktop-column">{item.job_title}</td>
                    <td className="order-number-column desktop-column">{item.order_number}</td>
                    <td className="job-number-column desktop-column">{item.job_number}</td>
                    <td className="description-column desktop-column">{item.description}</td>
                    <td className="points-column desktop-column">{numeral(item.points).format('0,0')}</td>
                    <td className="balance-column desktop-column">{numeral(item.balance).format('0,0')}</td>
                </tr>
            );
        });
    };

    const handleSort = (column, type) => {
        props.setSort(column, type, props.type);
    };

    const renderHeader = (label, field) => {
        let sortType = 'asc';
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === field) {
            if (props.sort.type === 'desc') {
                sortIcon = <i className="fas fa-sort-down" />;
                sortType = 'asc';
            } else {
                sortIcon = <i className="fas fa-sort-up" />;
                sortType = 'desc';
            }
        }

        return <div className="sort-item" onClick={handleSort.bind(this, field, sortType)}>{label} {sortIcon}</div>
    };

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr className="header-row">
                        <th className="date-column desktop-column">{renderHeader('Date', 'date')}</th>
                        <th className="product-column desktop-column">{renderHeader('Product', 'product')}</th>
                        <th className="job-title-column desktop-column">{renderHeader('Job Title', 'job_title')}</th>
                        <th className="order-number-column desktop-column">{renderHeader('Order #', 'order_number')}</th>
                        <th className="job-number-column desktop-column">{renderHeader('Job #', 'job_number')}</th>
                        <th className="description-column desktop-column">{renderHeader('Description', 'description')}</th>
                        <th className="points-column desktop-column">{renderHeader('Points', 'points')}</th>
                        <th className="balance-column desktop-column">{renderHeader('Balance', 'balance')}</th>
                    </tr>
                </thead>
                <tbody>
                    {renderElements()}
                </tbody>
            </table>
            <Pagination
                pagination={props.pagination}
                setActivePage={props.setActivePage}
            />
        </div>
    );
}

export default Table;
