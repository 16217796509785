import React, { Fragment } from 'react';

const RackCardsForm = (props) => {
    return (
        <Fragment>
            <div className="features-container">
                <div className="title">Specs</div>
                <div className="features">
                    <div className="field">
                        <div className="label">Quantity:</div>
                        <div className="select-field">
                            <select value={props.fields.quantity} onChange={props.handleChangeField.bind(this, 'quantity')} disabled={props.calculatingPrice}>
                                <option className="digital-option" value="DIGITAL" disabled>DIGITAL</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="1000">1,000</option>
                                <option value="1500">1,500</option>
                                <option value="2000">2,000</option>
                                <option value="2500">2,500</option>
                                <option value="3000">3,000</option>
                                <option className="offset-option" value="OFFSET" disabled>OFFSET</option>
                                <option value="3500">3,500</option>
                                <option value="4000">4,000</option>
                                <option value="4500">4,500</option>
                                <option value="5000">5,000</option>
                                <option value="5500">5,500</option>
                                <option value="6000">6,000</option>
                                <option value="6500">6,500</option>
                                <option value="7000">7,000</option>
                                <option value="7500">7,500</option>
                                <option value="8000">8,000</option>
                                <option value="8500">8,500</option>
                                <option value="9000">9,000</option>
                                <option value="9500">9,500</option>
                                <option value="10000">10,000</option>
                                <option value="12500">12,500</option>
                                <option value="15000">15,000</option>
                                <option value="17500">17,500</option>
                                <option value="20000">20,000</option>
                                <option value="22500">22,500</option>
                                <option value="25000">25,000</option>
                                <option value="27500">27,500</option>
                                <option value="30000">30,000</option>
                                <option value="32500">32,500</option>
                                <option value="35000">35,000</option>
                                <option value="37500">37,500</option>
                                <option value="40000">40,000</option>
                                <option value="42500">42,500</option>
                                <option value="45000">45,000</option>
                                <option value="47500">47,500</option>
                                <option value="50000">50,000</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Printing Ink:</div>
                        <div className="select-field">
                            <select value={props.fields.ink} onChange={props.handleChangeField.bind(this, 'ink')} disabled={props.calculatingPrice}>
                                <option value="4/0">4/0 - Color Front Only</option>
                                <option value="4/4">4/4 - Color Both Side</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Size:</div>
                        <div className="select-field">
                            <select value={props.fields.size} onChange={props.handleChangeField.bind(this, 'size')} disabled={props.calculatingPrice}>
                                <option value="4.8x15.5">4.25x5</option>
                                <option value="6x16.5">5.5x5.5</option>
                                <option value="4.8x17.5">6x4.5</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Paper:</div>
                        <div className="select-field">
                            <select value={props.fields.paper_stock} onChange={props.handleChangeField.bind(this, 'paper_stock')} disabled={props.calculatingPrice}>
                                <option value="100-glossy-cover">100# Glossy, Cover</option>
                                <option value="14pt-glossy-cover-1side">14pt. Glossy, Cover 1 side</option>
                                <option value="14pt-glossy-cover-2sides">14pt. Glossy, Cover 2 sides</option>
                                <option value="100-uncoated-cover">100# Uncoated, Cover</option>
                                <option value="120-uncoated-cover">120# Uncoated, Cover</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-container">
                <div className="title">Finishing</div>
                <div className="features">
                    <div className="field">
                        <div className="label">Coating Front:</div>
                        <div className="select-field">
                            <select value={props.fields.coating_front} onChange={props.handleChangeField.bind(this, 'coating_front')} disabled={props.calculatingPrice}>
                                <option value="0">No Coating</option>
                                <option value="1">Aqueous Coating</option>
                                <option value="2">High Gloss UV Coating</option>
                                <option value="3">Matte UV Coating</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Coating Back:</div>
                        <div className="select-field">
                            <select value={props.fields.coating_back} onChange={props.handleChangeField.bind(this, 'coating_back')} disabled={props.calculatingPrice}>
                                <option value="0">No Coating</option>
                                <option value="1">Aqueous Coating</option>
                                <option value="2">High Gloss UV Coating</option>
                                <option value="3">Matte UV Coating</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Hole Drilling:</div>
                        <div className="select-field">
                            <select value={props.fields.hole_drilling} onChange={props.handleChangeField.bind(this, 'hole_drilling')} disabled={props.calculatingPrice}>
                                <option value="0">No Drilling</option>
                                <option value="1">Single 3/16 inches Hole</option>
                                <option value="2">Single 1/4 inches Hole</option>
                                <option value="3">Single 5/16 inches Hole</option>
                                <option value="4">Three 3/16 inches Hole</option>
                                <option value="5">Three 5/16 inches Hole</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Drilling Location:</div>
                        <div className="select-field">
                            <select value={props.fields.drilling_location} onChange={props.handleChangeField.bind(this, 'drilling_location')} disabled={props.calculatingPrice}>
                                <option value="0">No Drilling</option>
                                <option value="1">Top Left</option>
                                <option value="2">Top Right</option>
                                <option value="3">Top Center</option>
                                <option value="4">Left Side</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Round Corner:</div>
                        <div className="select-field">
                            <select value={props.fields.round_corner} onChange={props.handleChangeField.bind(this, 'round_corner')} disabled={props.calculatingPrice}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Perforating:</div>
                        <div className="select-field">
                            <select value={props.fields.perforating} onChange={props.handleChangeField.bind(this, 'perforating')} disabled={props.calculatingPrice}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Shrink Wrapping:</div>
                        <div className="select-field">
                            <select value={props.fields.shrink_wrapping} onChange={props.handleChangeField.bind(this, 'shrink_wrapping')} disabled={props.calculatingPrice}>
                                <option value="0">No Shrink Wrap</option>
                                <option value="25">Bundles in 25</option>
                                <option value="50">Bundles in 50</option>
                                <option value="100">Bundles in 100</option>
                                <option value="250">Bundles in 250</option>
                            </select>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default RackCardsForm;
