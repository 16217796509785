export const FETCH_BILLING_ADDRESSES = 'MY_ADDRESS_BOOK/FETCH_BILLING_ADDRESSES';
export const FETCH_BILLING_ADDRESSES_SUCCESS = 'MY_ADDRESS_BOOK/FETCH_BILLING_ADDRESSES_SUCCESS';
export const FETCH_BILLING_ADDRESSES_ERROR = 'MY_ADDRESS_BOOK/FETCH_BILLING_ADDRESSES_ERROR';
export const FETCH_SHIPPING_ADDRESSES = 'MY_ADDRESS_BOOK/FETCH_SHIPPING_ADDRESSES';
export const FETCH_SHIPPING_ADDRESSES_SUCCESS = 'MY_ADDRESS_BOOK/FETCH_SHIPPING_ADDRESSES_SUCCESS';
export const FETCH_SHIPPING_ADDRESSES_ERROR = 'MY_ADDRESS_BOOK/FETCH_SHIPPING_ADDRESSES_ERROR';
export const SET_AS_DEFAULT = 'MY_ADDRESS/SET_AS_DEFAULT';
export const SET_AS_DEFAULT_SUCCESS = 'MY_ADDRESS/SET_AS_DEFAULT_SUCCESS';
export const SET_AS_DEFAULT_ERROR = 'MY_ADDRESS/SET_AS_DEFAULT_ERROR';
export const DELETE_ADDRESS = 'MY_ADDRESS/DELETE_ADDRESS';
export const DELETE_ADDRESS_SUCCESS = 'MY_ADDRESS/DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_ERROR = 'MY_ADDRESS/DELETE_ADDRESS_ERROR';
export const SET_ACTIVE_PAGE = 'MY_ADDRESS_BOOK/SET_ACTIVE_PAGE';
export const SET_SORT = 'MY_ADDRESS_BOOK/SET_SORT';
export const SET_HISTORY = 'MY_ADDRESS_BOOK/SET_HISTORY';
export const RESET_STATE = 'MY_ADDRESS_BOOK/RESET_STATE';