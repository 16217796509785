import React from 'react';
import { Link } from 'react-router-dom';

const Information = (props) => {
    if (props.product == null) {
        return null;
    }

    return (
        <div className="information-container">
            <div className="main-product-title">{props.product.product}</div>
            <div className="image">
                <img src={props.product.image_url} alt="Postcards" />
            </div>
            <div className="product-title">
                {props.product.title}
            </div>
            <div className="product-description">
                {props.product.description}
            </div>
            <div className="custom-estimate">
                <div className="title">CUSTOM ESTIMATE:</div>
                <div className="description">
                    For custom orders or quantities that are not listed for your chosen product,
                    please <Link to="/company/custom-quote">click here</Link> for a custom estimate.
                </div>
            </div>
            <div className="templates-button">
                <button onClick={props.showTemplatesModal}>Download Templates</button>
            </div>
        </div>
    );
}

export default Information;
