export const FETCH_ELEMENTS = 'ADMIN_ORDERS/FETCH_ELEMENTS';
export const FETCH_ELEMENTS_SUCCESS = 'ADMIN_ORDERS/FETCH_ELEMENTS_SUCCESS';
export const FETCH_ELEMENTS_ERROR = 'ADMIN_ORDERS/FETCH_ELEMENTS_ERROR';
export const FETCH_ELEMENT = 'ADMIN_ORDERS/FETCH_ELEMENT';
export const FETCH_ELEMENT_SUCCESS = 'ADMIN_ORDERS/FETCH_ELEMENT_SUCCESS';
export const FETCH_ELEMENT_ERROR = 'ADMIN_ORDERS/FETCH_ELEMENT_ERROR';
export const SAVE_ELEMENT = 'ADMIN_ORDERS/SAVE_ELEMENT';
export const SAVE_ELEMENT_SUCCESS = 'ADMIN_ORDERS/SAVE_ELEMENT_SUCCESS';
export const SAVE_ELEMENT_ERROR = 'ADMIN_ORDERS/SAVE_ELEMENT_ERROR';
export const DELETE_ITEM = 'ADMIN_ORDERS/DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'ADMIN_ORDERS/DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_ERROR = 'ADMIN_ORDERS/DELETE_ITEM_ERROR';
export const CREATE_SC_TICKET = 'ADMIN_ORDERS/CREATE_SC_TICKET';
export const CREATE_SC_TICKET_SUCCESS = 'ADMIN_ORDERS/CREATE_SC_TICKET_SUCCESS';
export const CREATE_SC_TICKET_ERROR = 'ADMIN_ORDERS/CREATE_SC_TICKET_ERROR';
export const FETCH_INFORMATION = 'ADMIN_ORDERS/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'ADMIN_ORDERS/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'ADMIN_ORDERS/FETCH_INFORMATION_ERROR';
export const CHANGE_JOB_STATUS = 'ADMIN_ORDERS/CHANGE_JOB_STATUS';
export const CHANGE_JOB_STATUS_SUCCESS = 'ADMIN_ORDERS/CHANGE_JOB_STATUS_SUCCESS';
export const CHANGE_JOB_STATUS_ERROR = 'ADMIN_ORDERS/CHANGE_JOB_STATUS_ERROR';
export const SET_FIELD = 'ADMIN_ORDERS/SET_FIELD';
export const SET_ACTION_TYPE = 'ADMIN_ORDERS/SET_ACTION_TYPE';
export const SET_ACTIVE_PAGE = 'ADMIN_ORDERS/SET_ACTIVE_PAGE';
export const SET_SORT = 'ADMIN_ORDERS/SET_SORT';
export const SET_SEARCH = 'ADMIN_ORDERS/SET_SEARCH';
export const SET_SEARCH_TYPE = 'ADMIN_ORDERS/SET_SEARCH_TYPE';
export const SET_CUSTOMER_ID = 'ADMIN_ORDERS/SET_CUSTOMER_ID';
export const SET_ORDER_ID = 'ADMIN_ORDERS/SET_ORDER_ID';
export const SET_HISTORY = 'ADMIN_ORDERS/SET_HISTORY';
export const SET_ID_MODAL = 'ADMIN_ORDERS/SET_ID_MODAL';
export const RESET_STATE = 'ADMIN_ORDERS/RESET_STATE';