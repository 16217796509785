import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './AdminConfiguration.scss';
import Configuration from './Configuration';
import AdminLayout from './../AdminLayout';
import * as actions from './AdminConfiguration.actions';
import * as selectors from './AdminConfiguration.selectors';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';

const AdminConfiguration = (props) => {
    /* State to props */
    const item = useSelector(selectors.getItem, stringifyEqualityCheck);
    const fetchingElement = useSelector(selectors.getFetchingElement);
    const saving = useSelector(selectors.getSaving);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchElement = useCallback((id) => dispatch(actions.fetchElement(id)), [dispatch]);
    const saveElement = useCallback(() => dispatch(actions.saveElement()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchElement(1);

        return () => {
            resetState();
        };
    }, [fetchElement, resetState]);

    return (
        <AdminLayout history={props.history}>
            <Helmet>
                <title>Admin Configuration - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="admin-configuration-container">
                <h1>Configuration</h1>
                <Configuration
                    item={item}
                    saving={saving}
                    fetchingElement={fetchingElement}
                    setField={setField}
                    saveElement={saveElement}
                />
            </div>
        </AdminLayout>
    );
};

export default AdminConfiguration;
