import React, { useCallback, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Information from './Information';
import * as actions from './Account.actions';
import * as selectors from './Account.selectors';
import * as loginSelectors from './../AdminLogin/AdminLogin.selectors';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import './Account.scss';

const Account = (props) => {
    /* State to props */
    const element = useSelector(selectors.getElement, stringifyEqualityCheck);
    const fetchingElement = useSelector(selectors.getFetchingElement);
    const savingAccount = useSelector(selectors.getSavingAccount);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchElement = useCallback(() => dispatch(actions.fetchElement()), [dispatch]);
    const saveAccount = useCallback(() => dispatch(actions.saveAccount()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchElement();

        return () => {
            resetState();
        };
    }, [fetchElement, resetState]);

    let container = (
        <Fragment>
            <Information
                user={user}
                element={element}
                fields={fields}
                savingAccount={savingAccount}
                saveAccount={saveAccount}
                setField={setField}
            />
        </Fragment>
    );
    if (fetchingElement) {
        container = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    }

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>Account - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="section-container">
                <div className="account-container">
                    <h1>My Account</h1>
                    {container}
                </div>
            </div>
        </Layout>
    );
};

export default Account;
