import React from 'react';

const Products = (props) => {
    const handleEcoFriendly = () => {
        props.history.push('/company/environmental-statement');
    };

    const handleRequestQuote = () => {
        props.history.push('/company/custom-quote');
    };

    return (
        <div className="information-container content-container">
            <div className="info-container">
                <h1>ABOUT US</h1>
                <div className="main-information">
                    <p>
                        With more than 30 years of printing experience as a cornerstone of our business, OvernightColor.com has been trusted by 
                        Fortune 500 Companies worldwide for all their printed collateral. Our 30,000 square-foot Houston printing facility and 
                        state-of-the art printing technologies delivers award-winning quality and service to all our clients in the Houston area 
                        and around the globe.
                    </p>
                    <p>
                        OvernightColor.com's primary business model is to exceed our client's expectations each and every time. We do not promise 
                        what is not possible but we do consistently deliver what is promised. We measure our success by seeing all our customers 
                        return to us each year for their printing needs. OvernightColor.com also prides itself on expanding its list of new customers 
                        which come by both 'word of mouth' and referrals from other satisfied customers. We would be delighted to have you come 
                        visit and tour our facility to see for yourself what it's like to be part of the OvernightColor.com printing family.
                    </p>
                    <p>
                        OvernightColor.com prides itself on the underlining commitment to all our customers - Top Quality, Top Service and Exceptional 
                        Value. It's this commitment that separates OvernightColor.com from all other printing companies in Houston.
                    </p>
                    <p>
                        We look forward to serving all your printing needs for many years to come.
                    </p>
                </div>
            </div>
            <div className="messages-container">
                <div className="message-main-container eco-friendly">
                    <div className="message-container">
                        <div className="title">ECO-FRIENDLY PRINTING</div>
                        <div className="message">
                            OvernightColor.com has taken the initiative and grown into an environmentally friendly company by taking significant
                            steps in our production of printed products.
                        </div>
                        <button onClick={handleEcoFriendly}>READ MORE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/eco-friendly.png" alt="Eco Friendly" />
                    </div>
                </div>
                <div className="message-main-container request-quote">
                    <div className="message-container">
                        <div className="title">REQUEST PRINT QUOTE</div>
                        <div className="message">
                            Request a quote for any print project by using our user-friendly online form. Submit the details of your print request
                            and we will provide you with an instant quote.
                        </div>
                        <button onClick={handleRequestQuote}>REQUEST A QUOTE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/request-quote.png" alt="Request Quote" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
