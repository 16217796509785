import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Banner from './Banner';
import Products from './Products';
import Satisfaction from './Satisfaction';
import Testimonials from './Testimonials';
import './Landing.scss';
import * as selectors from './Landing.selectors';
import * as actions from './Landing.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';

const Landing = (props) => {
    /* State to props */
    const activeBlurProduct = useSelector(selectors.getActiveBlurProduct);
    const information = useSelector(selectors.getInformation);
    const fetchingInformation = useSelector(selectors.getFetchingInformation);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const setActiveBlurProduct = useCallback((value) => dispatch(actions.setActiveBlurProduct(value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');
        fetchInformation();

        return () => {
            resetState();
        };
    }, [fetchInformation, resetState]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="landing-container">
                <Banner />
                <Products
                    information={information}
                    fetchingInformation={fetchingInformation}
                    activeBlurProduct={activeBlurProduct}
                    history={props.history}
                    setActiveBlurProduct={setActiveBlurProduct}
                />
                <Satisfaction />
                <Testimonials
                    information={information}
                    fetchingInformation={fetchingInformation}
                />
            </div>
        </Layout>
    );
};

export default Landing;
