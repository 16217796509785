import React from 'react';
import { Link } from 'react-router-dom';

const Form = (props) => {
    if (props.actionType === 'list') {
        return null;
    }

    if (props.fetchingElement) {
        return (
            <div className="form-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        if (field === 'discount' && value !== '') {
            value /= 100;
        }

        props.setField(field, value);
    };

    return (
        <div className="form-container">
            <div className="fields">
                <div className="field">
                    <div className="label">Points <span className="required">*</span>:</div>
                    <input type="number" value={props.item.points} onChange={handleSetField.bind(this, 'points')} placeholder="Points (it could be negative)" />
                </div>
                <div className="field">
                    <div className="label">Description <span className="required">*</span>:</div>
                    <input type="text" value={props.item.description} onChange={handleSetField.bind(this, 'description')} placeholder="Description" />
                </div>
            </div>
            <div className="buttons-container">
                <div className="button-container">
                    <Link to={'/admin/customer/' + props.customerId + '/reward-points'}>
                        <button className="cancel-button">Cancel</button>
                    </Link>
                </div>
                <div className="button-container">
                    <button className="save-button admin-button" onClick={props.saveElement} disabled={props.saving}>
                        {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Form;
