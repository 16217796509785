import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { empty } from './../../../utils';

const Form = (props) => {
    if (props.actionType === 'list') {
        return null;
    }

    if (props.fetchingElement) {
        return (
            <div className="form-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        if (field === 'discount' && value !== '') {
            value /= 100;
        }

        props.setField(field, value);
    };

    const handleSetDateField = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setField(name, value);
    };

    return (
        <div className="form-container">
            <div className="fields">
                <div className="field">
                    <div className="label">Name <span className="required">*</span>:</div>
                    <input type="text" value={props.item.name} onChange={handleSetField.bind(this, 'name')} placeholder="Name" />
                </div>
                <div className="field">
                    <div className="label">Discount (%) <span className="required">*</span>:</div>
                    <input type="text" value={empty(props.item.discount) ? '' : props.item.discount * 100} onChange={handleSetField.bind(this, 'discount')} placeholder="Discount (%)" />
                </div>
                <div className="field">
                    <div className="label">Start Date <span className="required">*</span>:</div>
                    <DatePicker
                        selected={props.item.start_date == null ? null : new Date(props.item.start_date + ' 12:00:00')}
                        onChange={handleSetDateField.bind(this, 'start_date')}
                    />
                </div>
                <div className="field">
                    <div className="label">Finish Date <span className="required">*</span>:</div>
                    <DatePicker
                        selected={props.item.finish_date == null ? null : new Date(props.item.finish_date + ' 12:00:00')}
                        onChange={handleSetDateField.bind(this, 'finish_date')}
                    />
                </div>
                <div className="field">
                    <div className="label">Active <span className="required">*</span>:</div>
                    <select value={props.item.active} onChange={handleSetField.bind(this, 'active')}>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div className="buttons-container">
                <div className="button-container">
                    <Link to="/admin/coupons">
                        <button className="cancel-button">Cancel</button>
                    </Link>
                </div>
                <div className="button-container">
                    <button className="save-button admin-button" onClick={props.saveElement} disabled={props.saving}>
                        {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Form;
