import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Banner from './Banner';
import Banner2 from './Banner2';
import Information from './Information';
import Information2 from './Information2';
import './ProfileAndVision.scss';
import * as actions from './ProfileAndVision.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';

const ProfileAndVision = (props) => {
    /* Dispatch to props */
    const dispatch = useDispatch();
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        return () => {
            resetState();
        };
    }, [resetState]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>Profile and Vision - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="profile-and-vision-container">
                <Banner />
                <Information
                    history={props.history}
                />
                <Banner2 />
                <Information2
                    history={props.history}
                />
            </div>
        </Layout>
    );
};

export default ProfileAndVision;
