import React from 'react';

const Configuration = (props) => {
    if (props.fetchingElement) {
        return (
            <div className="configuration-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    return (
        <div className="configuration-container">
            <div className="fields">
                <div className="field">
                    <div className="label">Show Instant Quote <span className="required">*</span>:</div>
                    <select value={props.item.show_instant_quote} onChange={handleSetField.bind(this, 'show_instant_quote')} >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div className="buttons-container">
                <div className="button-container">
                    <button className="save-button admin-button" onClick={props.saveElement} disabled={props.saving}>
                        {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Configuration;
