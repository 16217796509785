import { createSelector } from 'reselect';

export const getComponentState = state => state.adminCoupons;

export const getActionType = createSelector(
    getComponentState,
    (state) => state.actionType
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getItems = createSelector(
    getComponentState,
    (state) => state.items
);
export const getItem = createSelector(
    getComponentState,
    (state) => state.item
);
export const getFetchingElements = createSelector(
    getComponentState,
    (state) => state.fetchingElements
);
export const getFetchingElement = createSelector(
    getComponentState,
    (state) => state.fetchingElement
);
export const getSaving = createSelector(
    getComponentState,
    (state) => state.saving
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getSearch = createSelector(
    getComponentState,
    (state) => state.search
);
export const getSearchType = createSelector(
    getComponentState,
    (state) => state.searchType
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getDeleting = createSelector(
    getComponentState,
    (state) => state.deleting
);
export const getIdModal = createSelector(
    getComponentState,
    (state) => state.idModal
);
export const getFile = createSelector(
    getComponentState,
    (state) => state.file
);