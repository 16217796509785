import React from 'react';
import { store } from 'react-notifications-component';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as loginActions from './components/Login/Login.actions';
import * as adminLoginActions from './components/AdminLogin/AdminLogin.actions';
import moment from 'moment-timezone';

export const showNotification = (title, message, type, duration = 4000) => {
    store.addNotification({
        title,
        message,
        type,
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: duration,
            onScreen: true,
            showIcon: false,
        },
    });
}

export const showDataSavedNotification = () => {
    showNotification(
        'Data Saved!',
        'Your data has been saved successfully!',
        'success'
    );
}

export const showDataDeletedNotification = () => {
    showNotification(
        'Element removed!',
        'The element has been removed successfully!',
        'success'
    );
}

export const showErrorNotification = () => {
    showNotification(
        'Error',
        'An error has occurred',
        'danger'
    );
}

export const showCompleteDataNotification = () => {
    showNotification(
        'Complete the information',
        'Complete all the required fields',
        'info'
    );
}

export const confirmModal = (deleteCallback, title = null, description = null) => {
    if (title === null) {
        title = 'Do you wan to remove this item?';
    }

    if (description === null) {
        description = 'This action cannot be undone.';
    }

    confirmAlert({
        customUI: ({ onClose }) => {
            const acceptHandler = () => {
                deleteCallback();
                onClose();
            }
            return (
                <div className="confirm-alert-container">
                    <h1>{title}</h1>
                    <p>{description}</p>
                    <div className="buttons">
                        <button onClick={onClose}>Cancel</button>
                        <button onClick={acceptHandler}>Accept</button>
                    </div>
                </div>
            );
        }
    });
}

export const dateToTimezone = (date, format) => {
    if (date == null) {
        return moment().tz('America/Chicago').format(format);
    } else {
        return moment.tz(date, process.env.REACT_APP_TIMEZONE).tz('America/Chicago').format(format);
    }
}

export const calculateTimeFromNow = date => {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let difference = moment().tz('America/Chicago').unix() - moment.tz(date, process.env.REACT_APP_TIMEZONE).unix();
    if (difference > 0) {
        days = Math.floor(difference / 86400);
    }

    difference -= days * 86400;
    if (difference > 0) {
        hours = Math.floor(difference / 3600);
    }

    difference -= hours * 3600;
    if (difference > 0) {
        minutes = Math.floor(difference / 60);
    }

    return { days, hours, minutes };
}

export const calculateTimeFromDates = (startDate, endDate) => {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let difference = moment.tz(endDate, process.env.REACT_APP_TIMEZONE).unix() - moment.tz(startDate, process.env.REACT_APP_TIMEZONE).unix();
    if (difference > 0) {
        days = Math.floor(difference / 86400);
    }

    difference -= days * 86400;
    if (difference > 0) {
        hours = Math.floor(difference / 3600);
    }

    difference -= hours * 3600;
    if (difference > 0) {
        minutes = Math.floor(difference / 60);
    }

    return { days, hours, minutes };
}

export const stringifyEqualityCheck = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
}

export const empty = (field) => {
    return field === undefined || field == null || field === ''; 
}

export const emptyOrZero = (field) => {
    return field === undefined || field == null || field === '' || parseInt(field) === 0; 
}

export const validateAuthInResponse = (error, type) => dispatch => {
    console.log(error)
    if (error.response && error.response.status === 403) {
        dispatch(loginActions.logout());
    } else if (error.response && error.response.status === 401) {
        dispatch({ type, payload: null });
        showNotification('Unauthorized', 'You are not authorized to see this page.', 'warning');
    } else if (error.response && error.response.status === 404) {
        dispatch({ type, payload: null });
        showNotification('Not Found', 'This page does not exist.', 'warning');
    } else {
        dispatch({ type, payload: null });
        showNotification('Error', 'An error has occurred!', 'danger');
    }
}

export const adminValidateAuthInResponse = (error, type) => dispatch => {
    if (error.response && error.response.status === 403) {
        dispatch(adminLoginActions.adminLogout());
    } else if (error.response && error.response.status === 401) {
        dispatch({ type, payload: null });
        showNotification('Unauthorized', 'You are not authorized to see this page.', 'warning');
    } else if (error.response && error.response.status === 404) {
        dispatch({ type, payload: null });
        showNotification('Not Found', 'This page does not exist.', 'warning');
    } else {
        dispatch({ type, payload: null });
        showNotification('Error', 'An error has occurred!', 'danger');
    }
}