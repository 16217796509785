import Immutable from 'immutable';
import * as types from './ShoppingCart.types';

const initialState = {
    items: [],
    fetchingItems: false,
    continuingToCheckout: false,
    history: null,
    calculatingPrice: false,
    calculatingPriceIndex: null,
    shippingAddresses: [],
    fetchingShippingAddresses: false,
    fields: {
        first_name: '',
        last_name: '',
        company: '',
        address1: '',
        address2: '',
        zip: '',
        country_id: '',
        state: '',
        city: '',
        phone: '',
        fax: '',
        cellphone: '',
    },
    selectedItem: null,
    selectedAddress: null,
    savingShippingAddress: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ITEMS:
            return state.setIn(['fetchingItems'], true).toJS();

        case types.FETCH_ITEMS_SUCCESS:
            return state.setIn(['fetchingItems'], false)
                .setIn(['items'], action.payload)
                .toJS();

        case types.FETCH_ITEMS_ERROR:
            return state.setIn(['fetchingItems'], false).toJS();

        case types.SAVE_CHECKOUT:
            return state.setIn(['continuingToCheckout'], true)
                .updateIn(['items'], (items) => {
                    const newItems = items.toJS();
                    newItems[action.payload.index].checkout = action.payload.value;
                    return newItems;
                }).toJS();

        case types.SAVE_CHECKOUT_SUCCESS:
            return state.setIn(['continuingToCheckout'], false).toJS();

        case types.SAVE_CHECKOUT_ERROR:
            return state.setIn(['continuingToCheckout'], false).toJS();

        case types.CALCULATE_PRICE:
            return state.setIn(['calculatingPrice'], true)
                .setIn(['calculatingPriceIndex'], action.payload.index)
                .updateIn(['items'], (items) => {
                    const newItems = items.toJS();

                    let newOptions = JSON.parse(newItems[action.payload.index].options);
                    newOptions.quantity = action.payload.value;

                    newItems[action.payload.index].options = JSON.stringify(newOptions);
                    return newItems;
                }).toJS();

        case types.CALCULATE_PRICE_SUCCESS:
            return state.setIn(['calculatingPrice'], false)
                .setIn(['calculatingPriceIndex'], null)
                .updateIn(['items'], (items) => {
                    const newItems = items.toJS();
                    newItems[action.payload.index].pricing = JSON.stringify(action.payload.pricing);
                    return newItems;
                }).toJS();

        case types.CALCULATE_PRICE_ERROR:
            return state.setIn(['calculatingPrice'], false)
                .setIn(['calculatingPriceIndex'], null)
                .toJS();

        case types.FETCH_SHIPPING_ADDRESSES:
            return state.setIn(['fetchingShippingAddresses'], true).toJS();

        case types.FETCH_SHIPPING_ADDRESSES_SUCCESS:
            return state.setIn(['fetchingShippingAddresses'], false)
                .setIn(['shippingAddresses'], action.payload)
                .toJS();

        case types.FETCH_SHIPPING_ADDRESSES_ERROR:
            return state.setIn(['fetchingShippingAddresses'], false).toJS();

        case types.SAVE_SHIPPING_ADDRESS:
            return state.setIn(['savingShippingAddress'], true).toJS();

        case types.SAVE_SHIPPING_ADDRESS_SUCCESS:
            return state.setIn(['savingShippingAddress'], false)
                .setIn(['selectedItem'], null)
                .setIn(['selectedAddress'], null)
                .setIn(['fields'], {
                    first_name: '',
                    last_name: '',
                    company: '',
                    address1: '',
                    address2: '',
                    zip: '',
                    country_id: '',
                    state: '',
                    city: '',
                    phone: '',
                    fax: '',
                    cellphone: '',
                })
                .updateIn(['items'], (items) => {
                    const newItems = items.toJS();
                    newItems[action.payload.index].shippingAddress = action.payload.address;
                    newItems[action.payload.index].shipping_address_id = action.payload.address.id;
                    return newItems;
                }).toJS();

        case types.SAVE_SHIPPING_ADDRESS_ERROR:
            return state.setIn(['savingShippingAddress'], false).toJS();

        case types.SET_SELECTED_ITEM:
            return state.setIn(['selectedItem'], action.payload).toJS();

        case types.SET_SELECTED_ADDRESS:
            return state.setIn(['selectedAddress'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.SET_FIELDS:
            return state.setIn(['fields'], action.payload).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}