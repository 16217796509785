import axios from 'axios';
import * as types from './Landing.types';
import { validateAuthInResponse } from './../../utils';
//import * as selectors from './Landing.selectors';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchInformation = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/order/landing-information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const setActiveBlurProduct = (value) => dispatch => {
    dispatch({
        type: types.SET_ACTIVE_BLUR_PRODUCT,
        payload: value,
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}