import { createSelector } from 'reselect';

export const getComponentState = state => state.myAddress;

export const getId = createSelector(
    getComponentState,
    (state) => state.id
);
export const getType = createSelector(
    getComponentState,
    (state) => state.type
);
export const getAction = createSelector(
    getComponentState,
    (state) => state.action
);
export const getAddress = createSelector(
    getComponentState,
    (state) => state.address
);
export const getFetchingAddress = createSelector(
    getComponentState,
    (state) => state.fetchingAddress
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getSavingAddress = createSelector(
    getComponentState,
    (state) => state.savingAddress
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getFile = createSelector(
    getComponentState,
    (state) => state.file
);