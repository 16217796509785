export const FETCH_ELEMENTS = 'ADMIN_CUSTOMERS/FETCH_ELEMENTS';
export const FETCH_ELEMENTS_SUCCESS = 'ADMIN_CUSTOMERS/FETCH_ELEMENTS_SUCCESS';
export const FETCH_ELEMENTS_ERROR = 'ADMIN_CUSTOMERS/FETCH_ELEMENTS_ERROR';
export const FETCH_ELEMENT = 'ADMIN_CUSTOMERS/FETCH_ELEMENT';
export const FETCH_ELEMENT_SUCCESS = 'ADMIN_CUSTOMERS/FETCH_ELEMENT_SUCCESS';
export const FETCH_ELEMENT_ERROR = 'ADMIN_CUSTOMERS/FETCH_ELEMENT_ERROR';
export const SAVE_ELEMENT = 'ADMIN_CUSTOMERS/SAVE_ELEMENT';
export const SAVE_ELEMENT_SUCCESS = 'ADMIN_CUSTOMERS/SAVE_ELEMENT_SUCCESS';
export const SAVE_ELEMENT_ERROR = 'ADMIN_CUSTOMERS/SAVE_ELEMENT_ERROR';
export const DELETE_ITEM = 'ADMIN_CUSTOMERS/DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'ADMIN_CUSTOMERS/DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_ERROR = 'ADMIN_CUSTOMERS/DELETE_ITEM_ERROR';
export const SEND_RESET_PASSWORD = 'ADMIN_CUSTOMERS/SEND_RESET_PASSWORD';
export const SEND_RESET_PASSWORD_SUCCESS = 'ADMIN_CUSTOMERS/SEND_RESET_PASSWORD_SUCCESS';
export const SEND_RESET_PASSWORD_ERROR = 'ADMIN_CUSTOMERS/SEND_RESET_PASSWORD_ERROR';
export const FETCH_INFORMATION = 'ADMIN_CUSTOMERS/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'ADMIN_CUSTOMERS/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'ADMIN_CUSTOMERS/FETCH_INFORMATION_ERROR';
export const SET_FIELD = 'ADMIN_CUSTOMERS/SET_FIELD';
export const SET_ACTION_TYPE = 'ADMIN_CUSTOMERS/SET_ACTION_TYPE';
export const SET_ACTIVE_PAGE = 'ADMIN_CUSTOMERS/SET_ACTIVE_PAGE';
export const SET_SORT = 'ADMIN_CUSTOMERS/SET_SORT';
export const SET_SEARCH = 'ADMIN_CUSTOMERS/SET_SEARCH';
export const SET_SEARCH_TYPE = 'ADMIN_CUSTOMERS/SET_SEARCH_TYPE';
export const SET_HISTORY = 'ADMIN_CUSTOMERS/SET_HISTORY';
export const SET_ID_MODAL = 'ADMIN_CUSTOMERS/SET_ID_MODAL';
export const RESET_STATE = 'ADMIN_CUSTOMERS/RESET_STATE';