import React, { Fragment, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Information from './Information';
import Form from './Form';
import Summary from './Summary';
import RelatedProducts from './RelatedProducts';
import NoAddressModal from './NoAddressModal';
import TemplatesModal from './TemplatesModal';
import { empty, stringifyEqualityCheck } from './../../utils';
import * as selectors from './Product.selectors';
import * as actions from './Product.actions';
import * as loginSelectors from './../Login/Login.selectors';
import * as layoutSelectors from './../Layout/Layout.selectors';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import './Product.scss';
import { Link } from 'react-router-dom';
import * as dialog from './../common/dialog';

const Product = (props) => {
    /* State to props */
    const product = useSelector(selectors.getProduct, stringifyEqualityCheck);
    const fetchingProduct = useSelector(selectors.getFetchingProduct);
    const name = useSelector(selectors.getName);
    const price = useSelector(selectors.getPrice, stringifyEqualityCheck);
    const calculatingPrice = useSelector(selectors.getCalculatingPrice);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const viewTurnaround = useSelector(selectors.getViewTurnaround);
    const viewRetail = useSelector(selectors.getViewRetail);
    const addingToCart = useSelector(selectors.getAddingToCart);
    const fetchingItem = useSelector(selectors.getFetchingItem);
    const isEdit = useSelector(selectors.getIsEdit);
    const saving = useSelector(selectors.getSaving);
    const retailMarkup = useSelector(selectors.getRetailMarkup);
    const retailCustomerName = useSelector(selectors.getRetailCustomerName);
    const downloadingRetail = useSelector(selectors.getDownloadingRetail);
    const relatedProducts = useSelector(selectors.getRelatedProducts, stringifyEqualityCheck);
    const printingType = useSelector(selectors.getPrintingType);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);
    const customer = useSelector(layoutSelectors.getCustomer, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchProduct = useCallback((callCalculatePrice, callSetFields, printingType) => dispatch(actions.fetchProduct(callCalculatePrice, callSetFields, printingType)), [dispatch]);
    const fetchItem = useCallback((id) => dispatch(actions.fetchItem(id)), [dispatch]);
    const calculatePrice = useCallback(() => dispatch(actions.calculatePrice()), [dispatch]);
    const setName = useCallback((value) => dispatch(actions.setName(value)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const setViewTurnaround = useCallback((value) => dispatch(actions.setViewTurnaround(value)), [dispatch]);
    const setViewRetail = useCallback(() => dispatch(actions.setViewRetail()), [dispatch]);
    const addToCart = useCallback(() => dispatch(actions.addToCart()), [dispatch]);
    const save = useCallback(() => dispatch(actions.save()), [dispatch]);
    const setIsEdit = useCallback((value) => dispatch(actions.setIsEdit(value)), [dispatch]);
    const setRetailMarkup = useCallback((value) => dispatch(actions.setRetailMarkup(value)), [dispatch]);
    const setRetailCustomerName = useCallback((value) => dispatch(actions.setRetailCustomerName(value)), [dispatch]);
    const downloadRetail = useCallback(() => dispatch(actions.downloadRetail()), [dispatch]);
    const setPrintingType = useCallback((value) => dispatch(actions.setPrintingType(value)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const showNoAddressModal = useCallback(() => dispatch(dialog.actions.showDialog('frontend-product-no-address-modal')), [dispatch]);
    const hideNoAddressModal = useCallback(() => dispatch(dialog.actions.hideDialog('frontend-product-no-address-modal')), [dispatch]);
    const showTemplatesModal = useCallback(() => dispatch(dialog.actions.showDialog('frontend-product-templates-modal')), [dispatch]);
    const hideTemplatesModal = useCallback(() => dispatch(dialog.actions.hideDialog('frontend-product-templates-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);
        setName(props.match.params.name);

        if (!empty(props.match.params.printingType)) {
            setPrintingType(props.match.params.printingType);
        } else {
            setPrintingType('press');
        }

        if (!empty(props.match.params.id)) {
            fetchItem(props.match.params.id);
            setIsEdit(true);
        } else {
            fetchProduct(empty(props.match.params.id), true, props.match.params.printingType);
        }

        return () => {
            resetState();
            hideNoAddressModal();
            hideTemplatesModal();
        };
    }, [setHistory, resetState, setName, fetchProduct, fetchItem, setIsEdit, hideNoAddressModal, hideTemplatesModal, setPrintingType,
        props.history, props.match.params.name, props.match.params.id, props.match.params.printingType]);

    const breadcrumbs = [];
    breadcrumbs.push(<Link to="/">Home</Link>);
    breadcrumbs.push(<span className="divider">/</span>);

    if (product != null) {
        breadcrumbs.push(<Link to={'/product/' + product.code} className="product">{product.product}</Link>);
    }

    let container = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading...</div>;
    if (product != null && !fetchingItem) {
        container = (
            <Fragment>
                <div className="breadcrumbs">{breadcrumbs}</div>
                <div className="product-information-container">
                    <Information
                        product={product}
                        showTemplatesModal={showTemplatesModal}
                    />
                    <Form
                        code={props.match.params.name}
                        product={product}
                        user={user}
                        fetchingProduct={fetchingProduct}
                        name={name}
                        price={price}
                        calculatingPrice={calculatingPrice}
                        fields={fields}
                        viewTurnaround={viewTurnaround}
                        customer={customer}
                        printingType={printingType}
                        calculatePrice={calculatePrice}
                        setField={setField}
                        setViewTurnaround={setViewTurnaround}
                    />
                    <Summary
                        price={price}
                        calculatingPrice={calculatingPrice}
                        addingToCart={addingToCart}
                        isEdit={isEdit}
                        saving={saving}
                        user={user}
                        customer={customer}
                        viewRetail={viewRetail}
                        retailMarkup={retailMarkup}
                        retailCustomerName={retailCustomerName}
                        downloadingRetail={downloadingRetail}
                        fields={fields}
                        history={props.history}
                        addToCart={addToCart}
                        save={save}
                        showNoAddressModal={showNoAddressModal}
                        setViewRetail={setViewRetail}
                        setRetailMarkup={setRetailMarkup}
                        setRetailCustomerName={setRetailCustomerName}
                        downloadRetail={downloadRetail}
                    />
                </div>
                <RelatedProducts
                    relatedProducts={relatedProducts}
                    history={props.history}
                />
            </Fragment>
        );
    }

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>Product - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="product-container">
                <div className="content-container">
                    {container}
                </div>
                <NoAddressModal
                    hideNoAddressModal={hideNoAddressModal}
                />
                <TemplatesModal
                    code={props.match.params.name}
                    hideTemplatesModal={hideTemplatesModal}
                />
            </div>
        </Layout>
    );
};

export default Product;
