import React from 'react';
import Pagination from './../../Pagination';
import { Tooltip } from 'react-tippy';
import numeral from 'numeral';
import { showNotification } from './../../../utils';

const Table = (props) => {
    const handleGetItNow = (id, points) => {
        if (props.information.points < points) {
            return showNotification('Not enough points', 'We\'re Sorry! You do not have enough points for this prize.', 'info');
        }

        props.setIdModal(id);
        props.showGetPrizeModal()
    };

    const renderElements = () => {
        if (props.fetchingPrizes) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.prizes.length === 0) {
            return (
                <tr>
                    <td colSpan="10">No results</td>
                </tr>
            );
        }

        return props.prizes.map((prize) => {
            let getIcon = (
                <div className="action-icon">
                    <Tooltip title="Get It Now!" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fa-solid fa-trophy" onClick={handleGetItNow.bind(this, prize.id, prize.points)}></i>
                    </Tooltip>
                </div>
            );

            return (
                <tr>
                    <td className="id-column desktop-column"><img className="prize-image" src={prize.file_url} alt="Prize" /></td>
                    <td className="customer-column main-column">
                        <div className="desktop-value">{prize.name}</div>
                        <div className="mobile-container">
                            <div className="mobile-item">
                                <div className="label">Image:</div>
                                <div className="value"><img className="prize-image" src={prize.file_url} alt="Prize" /></div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Name:</div>
                                <div className="value">{prize.name}</div>
                            </div>
                            <div className="mobile-item">
                                <div className="label">Points:</div>
                                <div className="value">{numeral(prize.points).format('0,0')}</div>
                            </div>
                            <div className="mobile-actions">
                                <button onClick={handleGetItNow.bind(this, prize.id, prize.points)}>Get It Now!</button>
                            </div>
                        </div>
                    </td>
                    <td className="points-column desktop-column">{numeral(prize.points).format('0,0')}</td>
                    <td className="actions-column desktop-column">
                        <div className="actions-container">
                            {getIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    // const handleSort = (column, type) => {
    //     props.setSort(column, type, props.type);
    // };

    const renderHeader = (label, field) => {
        return <div className="sort-item">{label}</div>;

        // TODO: Enable sort

        // let sortType = 'asc';
        // let sortIcon = <i className="fas fa-sort" />;
        // if (props.sort.column === field) {
        //     if (props.sort.type === 'desc') {
        //         sortIcon = <i className="fas fa-sort-down" />;
        //         sortType = 'asc';
        //     } else {
        //         sortIcon = <i className="fas fa-sort-up" />;
        //         sortType = 'desc';
        //     }
        // }

        // return <div className="sort-item" onClick={handleSort.bind(this, field, sortType)}>{label} {sortIcon}</div>;
    };

    return (
        <div className="table-container">
            <div className="title-container">
                <div className="title-description">
                    <div className="title">Loyalty Points</div>
                    <div className="description">
                        Thank you for your continued business. As a token of our gratitude, we assembled a list of incredibly nice prizes that are
                        redeemable with your valuable loyalty points. Please choose from the items below based on the loyalty points acquired to date.
                    </div>
                </div>
                <div className="your-points">
                    <div className="title">Total of Rewards Points Obtained</div>
                    <div className="points">
                        <i className="fa-solid fa-star"></i>
                        <div className="number">{numeral(props.information.points).format('0,0')}</div>
                        <div className="word">Pts.</div>
                    </div>
                </div>
            </div>
            <table>
                <thead>
                    <tr className="header-row">
                        <th className="id-column desktop-column">{renderHeader('Image', 'file_url')}</th>
                        <th className="customer-column desktop-column">{renderHeader('Name', 'name')}</th>
                        <th className="points-column desktop-column">{renderHeader('Points', 'points')}</th>
                        <th className="actions-column desktop-column"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderElements()}
                </tbody>
            </table>
            <Pagination
                pagination={props.pagination}
                setActivePage={props.setActivePage}
            />
        </div>
    );
}

export default Table;
