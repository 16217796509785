import React from 'react';
import { Dialog } from './../../common/dialog';

const GetPrizeModal = React.memo((props) => {
    if (props.idModal == null) {
        return null;
    }

    const hideHandler = () => {
        props.setIdModal(null);
        props.hideGetPrizeModal();
    };

    const handleGetPrize = () => {
        props.getPrize();
    };

    let getButtonLabel = 'Get It Now!';
    if (props.gettingPrize) {
        getButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let name = null;
    for (const prize of props.prizes) {
        if (parseInt(prize.id) === parseInt(props.idModal)) {
            name = prize.name;
            break;
        }
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
        <button key="save-button" className="btn btn-light btn-sm save-button" onClick={handleGetPrize} disabled={props.gettingPrize}>{getButtonLabel}</button>,
    ];
    return (
        <Dialog
            name="get-prize-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="get-prize-dialog"
        >
            <div className="get-prize-modal-container">
                <h1>Get Prize</h1>
                <p>Confirm that you want to exchange the prize <strong>{name}</strong></p>
            </div>
        </Dialog >
    );
});

export default GetPrizeModal;
