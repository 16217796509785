import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Details from './Details';
import MyAccountMenu from './../MyAccountMenu';
import ArtworksModal from './ArtworksModal';
import { empty, stringifyEqualityCheck } from './../../utils';
import * as selectors from './MyOrderDetails.selectors';
import * as actions from './MyOrderDetails.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import * as dialog from './../common/dialog';
import './MyOrderDetails.scss';

const MyOrderDetails = (props) => {
    /* State to props */
    const order = useSelector(selectors.getOrder, stringifyEqualityCheck);
    const fetchingOrder = useSelector(selectors.getFetchingOrder);
    const idModal = useSelector(selectors.getIdModal);
    const artworks = useSelector(selectors.getArtworks, stringifyEqualityCheck);
    const uploadingArtworks = useSelector(selectors.getUploadingArtworks);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchOrder = useCallback((id) => dispatch(actions.fetchOrder(id)), [dispatch]);
    const setIdModal = useCallback((id) => dispatch(actions.setIdModal(id)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const addArtwork = useCallback((file) => dispatch(actions.addArtwork(file)), [dispatch]);
    const removeArtwork = useCallback((index) => dispatch(actions.removeArtwork(index)), [dispatch]);
    const clearArtworks = useCallback((index) => dispatch(actions.clearArtworks(index)), [dispatch]);
    const uploadArtworks = useCallback(() => dispatch(actions.uploadArtworks()), [dispatch]);
    const setArtworks = useCallback((artworks) => dispatch(actions.setArtworks(artworks)), [dispatch]);
    const deleteArtwork = useCallback((id) => dispatch(actions.deleteArtwork(id)), [dispatch]);
    const showArtworksModal = useCallback(() => dispatch(dialog.actions.showDialog('frontend-my-orders-artworks-modal')), [dispatch]);
    const hideArtworksModal = useCallback(() => dispatch(dialog.actions.hideDialog('frontend-my-orders-artworks-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);
        fetchOrder(props.match.params.id);

        if (!empty(props.match.params.showArtworks)) {
            // showArtworksModal();
        }

        return () => {
            resetState();
        };
    }, [fetchOrder, setHistory, resetState, showArtworksModal, props.history, props.match.params.id, props.match.params.showArtworks]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>My Order Details - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="my-order-details-container">
                <div className="content-container">
                    <MyAccountMenu
                        history={props.history}
                    />
                    <Details
                        order={order}
                        fetchingOrder={fetchingOrder}
                        setIdModal={setIdModal}
                        showArtworksModal={showArtworksModal}
                        setArtworks={setArtworks}
                    />
                </div>
                <ArtworksModal
                    idModal={idModal}
                    order={order}
                    artworks={artworks}
                    uploadingArtworks={uploadingArtworks}
                    hideArtworksModal={hideArtworksModal}
                    setIdModal={setIdModal}
                    addArtwork={addArtwork}
                    removeArtwork={removeArtwork}
                    clearArtworks={clearArtworks}
                    uploadArtworks={uploadArtworks}
                    deleteArtwork={deleteArtwork}
                />
            </div>
        </Layout>
    );
};

export default MyOrderDetails;
