import axios from 'axios';
import * as types from './AdminConfiguration.types';
import * as selectors from './AdminConfiguration.selectors';
import { adminValidateAuthInResponse, showNotification, empty } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchElement = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ELEMENT, payload: null });
    axios.get(apiUrl + '/configurations/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENT_SUCCESS, payload: response.data });
            dispatch(setField('tax', response.data.tax * 100));
            dispatch(setField('shipping_percentage', response.data.shipping_percentage * 100));
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_ELEMENT_ERROR));
        });
}

export const saveElement = () => (dispatch, getState) => {
    const item = selectors.getItem(getState());

    if (!validateSaveFields(item)) {
        return;
    }

    dispatch({ type: types.SAVE_ELEMENT, payload: null });
    axios({
        method: 'put',
        url: apiUrl + '/configurations/' + item.id,
        data: item,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_ELEMENT_SUCCESS, payload: response.data });
        showNotification('Item Saved', 'The item has been saved successfully', 'success');
    }).catch((error) => {
        dispatch(adminValidateAuthInResponse(error, types.SAVE_ELEMENT_ERROR));
    });
}

export const validateSaveFields = (fields) => {
    if (empty(fields.tax)) {
        showNotification('Complete Information', 'Enter the Tax', 'info');
        return false;
    }
    if (empty(fields.shipping_percentage)) {
        showNotification('Complete Information', 'Enter the Shipping Percentage', 'info');
        return false;
    }
    // if (empty(fields.minimum_length)) {
    //     showNotification('Complete Information', 'Enter the Minimum Length', 'info');
    //     return false;
    // }
    // if (empty(fields.maximum_length)) {
    //     showNotification('Complete Information', 'Enter the Maximum Length', 'info');
    //     return false;
    // }
    // if (empty(fields.minimum_height)) {
    //     showNotification('Complete Information', 'Enter the Minimum Height', 'info');
    //     return false;
    // }
    // if (empty(fields.maximum_height)) {
    //     showNotification('Complete Information', 'Enter the Maximum Height', 'info');
    //     return false;
    // }
    // if (empty(fields.minimum_thickness)) {
    //     showNotification('Complete Information', 'Enter the Minimum Thickness', 'info');
    //     return false;
    // }
    // if (empty(fields.maximum_thickness)) {
    //     showNotification('Complete Information', 'Enter the Maximum Thickness', 'info');
    //     return false;
    // }
    // if (empty(fields.maximum_weight1)) {
    //     showNotification('Complete Information', 'Enter the Maximum Weight 1-5,000 pieces', 'info');
    //     return false;
    // }
    // if (empty(fields.maximum_weight2)) {
    //     showNotification('Complete Information', 'Enter the Maximum Weight 5,001-1,000,000 pieces', 'info');
    //     return false;
    // }

    return true;
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setHistory = (value) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: value,
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}