import { createSelector } from 'reselect';

export const getComponentState = state => state.resetPassword;

export const getResetting = createSelector(
    getComponentState,
    (state) => state.resetting
);
export const getForm = createSelector(
    getComponentState,
    (state) => state.form
);
export const getReset = createSelector(
    getComponentState,
    (state) => state.reset
);
export const getId = createSelector(
    getComponentState,
    (state) => state.id
);
export const getToken = createSelector(
    getComponentState,
    (state) => state.token
);
export const getUserType = createSelector(
    getComponentState,
    (state) => state.userType
);