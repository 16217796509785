import React from 'react';
import { Link } from 'react-router-dom';

const Form = (props) => {
    const setFieldHandler = event => {
        props.setField(event.target.name, event.target.value);
    }

    const keyPressHandler = (event) => {
        if (event.key === 'Enter') {
            props.login(props.history);
        }
    }

    const handleSignIn = () => {
        props.login(props.history);
    }

    // const forgotPasswordHandler = (event) => {
    //     props.history.push('/forgot-password');
    // }

    return (
        <div className="login-form-container">
            <div className="form-container">
                <div className="logo-container">
                    <Link to ="/">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/logo.png" alt="OvernightColor" />
                    </Link>
                </div>
                <div className="title">Log in to your account</div>
                <div className="fields">
                    <div className="field">
                        <div className="label">Email Address</div>
                        <input type="text" name="email" value={props.form.email} onChange={setFieldHandler} onKeyPress={keyPressHandler} />
                    </div>
                    <div className="field">
                        <div className="label">Password</div>
                        <input type="password" name="password" value={props.form.password} onChange={setFieldHandler} onKeyPress={keyPressHandler} />
                    </div>
                    <div className="text-center">
                        <button type="button" onClick={handleSignIn} disabled={props.isLogging}>{props.isLogging ? <i className="fas fa-circle-notch fa-spin"></i> : 'ENTER'}</button>
                    </div>
                </div>
                <div className="sign-up">
                    DON'T HAVE AN ACCOUNT? <Link to="/sign-up">SIGN UP</Link>
                </div>
                <div className="recaptcha-label">
                    {/* This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply. */}
                </div>
            </div>
            <div className="support">
                <div className="phone">832-608-6400</div>
                <div className="email">info@overnightcolor.com</div>
            </div>
            <div className="copyright-container">
                <div className="copyright">COPYRIGHT © 2024 OVERNIGHTCOLOR.COM®, LLC ALL RIGHTS RESERVED</div>
                <div className="menu">
                    <div className="menu-items">
                        <Link to="/company/terms-conditions">TERMS</Link>
                        <div className="divider">|</div>
                        <Link to="/company/privacy-policy">PRIVACY</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;
