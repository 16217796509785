import React, { useCallback, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './AdminPoints.scss';
import Table from './Table';
import Form from './Form';
import DeleteModal from './DeleteModal';
import AdminLayout from './../AdminLayout';
import * as actions from './AdminPoints.actions';
import * as selectors from './AdminPoints.selectors';
import { Helmet } from 'react-helmet';
import { empty, stringifyEqualityCheck } from './../../utils';
import { Link } from 'react-router-dom';
import * as dialog from './../common/dialog';

const AdminPoints = (props) => {
    /* State to props */
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const actionType = useSelector(selectors.getActionType);
    const items = useSelector(selectors.getItems, stringifyEqualityCheck);
    const item = useSelector(selectors.getItem, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const search = useSelector(selectors.getSearch);
    const searchType = useSelector(selectors.getSearchType);
    const fetchingElements = useSelector(selectors.getFetchingElements);
    const fetchingElement = useSelector(selectors.getFetchingElement);
    const saving = useSelector(selectors.getSaving);
    const deleting = useSelector(selectors.getDeleting);
    const idModal = useSelector(selectors.getIdModal);
    const customerId = useSelector(selectors.getCustomerId);
    const file = useSelector(selectors.getFile, stringifyEqualityCheck);
    const fetchingCustomer = useSelector(selectors.getFetchingCustomer);
    const customer = useSelector(selectors.getCustomer, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setActionType = useCallback((value) => dispatch(actions.setActionType(value)), [dispatch]);
    const setActivePage = useCallback((value) => dispatch(actions.setActivePage(value)), [dispatch]);
    const fetchElements = useCallback(() => dispatch(actions.fetchElements()), [dispatch]);
    const fetchElement = useCallback((id) => dispatch(actions.fetchElement(id)), [dispatch]);
    const saveElement = useCallback(() => dispatch(actions.saveElement()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const setHistory = useCallback((value) => dispatch(actions.setHistory(value)), [dispatch]);
    const setSort = useCallback((column, type, orderType) => dispatch(actions.setSort(column, type, orderType)), [dispatch]);
    const setSearch = useCallback((value) => dispatch(actions.setSearch(value)), [dispatch]);
    const setSearchType = useCallback((value) => dispatch(actions.setSearchType(value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setIdModal = useCallback((id) => dispatch(actions.setIdModal(id)), [dispatch]);
    const deleteItem = useCallback(() => dispatch(actions.deleteItem()), [dispatch]);
    const setFile = useCallback((file) => dispatch(actions.setFile(file)), [dispatch]);
    const setCustomerId = useCallback((id) => dispatch(actions.setCustomerId(id)), [dispatch]);
    const fetchCustomer = useCallback((id) => dispatch(actions.fetchCustomer(id)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        setHistory(props.history);
        setCustomerId(props.match.params.customerId);
        fetchCustomer(props.match.params.customerId);
        
        if (props.match.params.action) {
            setActionType(props.match.params.action);

            if (props.match.params.action === 'edit') {
                fetchElement(props.match.params.id);
            }
        } else {
            setActionType('list');
            fetchElements();
        }

        return () => {
            resetState();
        };
    }, [setActionType, resetState, fetchElements, fetchElement, setHistory, setCustomerId, fetchCustomer,
        props.history, props.match.params.action, props.match.params.id, props.match.params.customerId]);

    const handleSearch = () => {
        setActivePage(1);
        fetchElements();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setActivePage(1);
            fetchElements();
        }
    };

    const handleSetSearch = (event) => {
        setSearch(event.target.value);
    };

    let customerTitle = '';
    if (customer != null) {
        const customerName = [];
        if (!empty(customer.first_name)) {
            customerName.push(customer.first_name);
        }
        if (!empty(customer.last_name)) {
            customerName.push(customer.last_name);
        }
        customerTitle = ' (Customer: ' + customerName.join(' ') + ')';
    }

    let titleContainer = (
        <Fragment>
            <h1>Reward Points <span className="customer-name">{customerTitle}</span></h1>
            <div className="header-actions-container">
                <div className="search-field">
                    <input type="text" value={search} onChange={handleSetSearch} onKeyPress={handleKeyPress} />
                    <button className="search-button admin-button" onClick={handleSearch}>Search</button>
                </div>
                <div className="new-button-container">
                    <Link to={'/admin/customer/' + customerId + '/reward-points/new'}><button className="admin-button">Add New</button></Link>
                </div>
            </div>
        </Fragment>
    );
    if (props.match.params.action === 'new') {
        titleContainer = (
            <h1>New Points Transaction</h1>
        );
    } else if (props.match.params.action === 'edit') {
        titleContainer = (
            <h1>Edit Points Transaction</h1>
        );
    }

    return (
        <AdminLayout history={props.history}>
            <Helmet>
                <title>Admin Reward Points - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="admin-reward-points-container">
                {titleContainer}
                <Table
                    pagination={pagination}
                    actionType={actionType}
                    sort={sort}
                    search={search}
                    searchType={searchType}
                    fetchingElements={fetchingElements}
                    items={items}
                    customerId={customerId}
                    customer={customer}
                    fetchingCustomer={fetchingCustomer}
                    setActivePage={setActivePage}
                    setSort={setSort}
                    setSearch={setSearch}
                    setSearchType={setSearchType}
                    showDeleteModal={showDeleteModal}
                    setIdModal={setIdModal}
                />
                <Form
                    actionType={actionType}
                    item={item}
                    saving={saving}
                    fetchingElement={fetchingElement}
                    file={file}
                    customerId={customerId}
                    setField={setField}
                    saveElement={saveElement}
                    setFile={setFile}
                />
            </div>
            <DeleteModal
                idModal={idModal}
                deleting={deleting}
                deleteItem={deleteItem}
                hideDeleteModal={hideDeleteModal}
                setIdModal={setIdModal}
            />
        </AdminLayout>
    );
};

export default AdminPoints;
