import Immutable from 'immutable';
import * as types from './Partners.types';

const initialState = {

}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}