import Immutable from 'immutable';
import * as types from './MyAddress.types';

const initialState = {
    id: null,
    type: null,
    action: null,
    address: {
        use_as_shipping: 0,
    },
    fetchingAddress: false,
    savingAddress: false,
    history: null,
    fetchingInformation: false,
    information: {
        industries: [],
        roles: [],
    },
    file: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ADDRESS:
            return state.setIn(['fetchingAddress'], true).toJS();

        case types.FETCH_ADDRESS_SUCCESS:
            return state.setIn(['fetchingAddress'], false)
                .setIn(['address'], action.payload)
                .toJS();

        case types.FETCH_ADDRESS_ERROR:
            return state.setIn(['fetchingAddress'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'industries'], action.payload.industries)
                .setIn(['information', 'roles'], action.payload.roles)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS();

        case types.SAVE_ADDRESS:
            return state.setIn(['savingAddress'], true).toJS();

        case types.SAVE_ADDRESS_SUCCESS:
            return state.setIn(['savingAddress'], false).toJS();

        case types.SAVE_ADDRESS_ERROR:
            return state.setIn(['savingAddress'], false).toJS();

        case types.SET_ID:
            return state.setIn(['id'], action.payload).toJS();

        case types.SET_TYPE:
            return state.setIn(['type'], action.payload).toJS();

        case types.SET_ACTION:
            return state.setIn(['action'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['address', action.payload.field], action.payload.value).toJS();

        case types.SET_FILE:
            return state.setIn(['file'], action.payload).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}