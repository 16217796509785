import { createSelector } from 'reselect';

export const getComponentState = state => state.adminConfiguration;

export const getItem = createSelector(
    getComponentState,
    (state) => state.item
);
export const getFetchingElement = createSelector(
    getComponentState,
    (state) => state.fetchingElement
);
export const getSaving = createSelector(
    getComponentState,
    (state) => state.saving
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);