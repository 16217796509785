import React, { Fragment } from 'react';
import { empty } from './../../../utils';
import { Dialog } from './../../common/dialog';

const BillingAddressModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideBillingAddressModal();
    };

    const handleAddAddress = (event) => {
        event.preventDefault();
        props.setAddressFields({
            first_name: '',
            last_name: '',
            company: '',
            website: '',
            industry_id: '',
            role_id: '',
            address1: '',
            address2: '',
            zip: '',
            country_id: '',
            state: '',
            city: '',
            phone: '',
            fax: '',
            cellphone: '',
            is_reseller: '',
            tax_id: '',
        });
    };

    const handleSetSelectedAddress = (event) => {
        props.setSelectedAddress(event.target.value);

        for (const address of props.billingAddresses) {
            if (parseInt(event.target.value) === parseInt(address.id)) {
                props.setAddressFields(address);
                break;
            }
        }
    };

    const handleSetField = (field, event) => {
        props.setAddressField(field, event.target.value);
    };

    let fieldsDisabled = false;
    let defaultField = (
        <div className="field">
            <div className="label">Make Default Address <span className="required">*</span>:</div>
            <select value={props.addressFields.make_default} onChange={handleSetField.bind(this, 'make_default')} disabled={fieldsDisabled}>
                <option value="">Select option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
            </select>
        </div>
    );
    if (!empty(props.addressFields.id)) {
        fieldsDisabled = true;
        defaultField = null;
    }

    let taxIdField = null;
    if (props.addressFields && parseInt(props.addressFields.is_reseller)) {
        taxIdField = (
            <div className="field">
                <div className="label">Tax ID <span className="required">*</span>:</div>
                <input type="text" value={props.addressFields.tax_id} onChange={handleSetField.bind(this, 'tax_id')} disabled={fieldsDisabled} />
            </div>
        );
    }

    let content = (
        <Fragment>
            <h1>Change Billing Address</h1>
            <div className="select-address">
                Select an address
                <div className="select-field">
                    <select value={props.selectedAddress} onChange={handleSetSelectedAddress}>
                        {props.billingAddresses.map((address) => <option value={address.id}>
                            {address.first_name} {address.last_name} - {address.city}, {address.state} {address.zip}
                        </option>)}
                    </select>
                    <i className="fa-solid fa-angle-down"></i>
                </div>
                or <a href="/" onClick={handleAddAddress}>Add a New Address</a>
            </div>
            <div className="form">
                <div className="field">
                    <div className="label">First Name <span className="required">*</span>:</div>
                    <input type="text" value={props.addressFields.first_name} onChange={handleSetField.bind(this, 'first_name')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Last Name <span className="required">*</span>:</div>
                    <input type="text" value={props.addressFields.last_name} onChange={handleSetField.bind(this, 'last_name')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Company <span className="required">*</span>:</div>
                    <input type="text" value={props.addressFields.company} onChange={handleSetField.bind(this, 'company')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Website:</div>
                    <input type="text" value={props.addressFields.website} onChange={handleSetField.bind(this, 'website')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Industry:</div>
                    <select value={props.addressFields.industry_id} onChange={handleSetField.bind(this, 'industry_id')} disabled={fieldsDisabled}>
                        <option value="">Select option</option>
                        {props.information.industries.map((industry) => <option value={industry.id}>{industry.name}</option>)}
                    </select>
                </div>
                <div className="field">
                    <div className="label">Role:</div>
                    <select value={props.addressFields.role_id} onChange={handleSetField.bind(this, 'role_id')} disabled={fieldsDisabled}>
                        <option value="">Select option</option>
                        {props.information.roles.map((role) => <option value={role.id}>{role.name}</option>)}
                    </select>
                </div>
                <div className="field">
                    <div className="label">Address 1 <span className="required">*</span>:</div>
                    <input type="text" value={props.addressFields.address1} onChange={handleSetField.bind(this, 'address1')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Address 2:</div>
                    <input type="text" value={props.addressFields.address2} onChange={handleSetField.bind(this, 'address2')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Zip Code <span className="required">*</span>:</div>
                    <input type="text" value={props.addressFields.zip} onChange={handleSetField.bind(this, 'zip')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Country <span className="required">*</span>:</div>
                    <select value={props.addressFields.country_id} onChange={handleSetField.bind(this, 'country_id')} disabled={fieldsDisabled}>
                        <option value="">Select option</option>
                        <option value="230">United States</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">State <span className="required">*</span>:</div>
                    <input type="text" value={props.addressFields.state} onChange={handleSetField.bind(this, 'state')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">City <span className="required">*</span>:</div>
                    <input type="text" value={props.addressFields.city} onChange={handleSetField.bind(this, 'city')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Phone <span className="required">*</span>:</div>
                    <input type="text" value={props.addressFields.phone} onChange={handleSetField.bind(this, 'phone')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Fax:</div>
                    <input type="text" value={props.addressFields.fax} onChange={handleSetField.bind(this, 'fax')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Cellphone:</div>
                    <input type="text" value={props.addressFields.cellphone} onChange={handleSetField.bind(this, 'cellphone')} disabled={fieldsDisabled} />
                </div>
                <div className="field">
                    <div className="label">Reseller <span className="required">*</span>:</div>
                    <select value={props.addressFields.is_reseller} onChange={handleSetField.bind(this, 'is_reseller')} disabled={fieldsDisabled}>
                        <option value="">Select option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                {taxIdField}
                {defaultField}
            </div>
        </Fragment>
    );
    if (props.fetchingBillingAddresses) {
        content = <Fragment><i className="fas fa-circle-notch fa-spin" /> Loading data...</Fragment>;
    }

    let saveAddressLabel = 'Save';
    if (props.savingBillingAddress) {
        saveAddressLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
        <button key="save-button" className="btn btn-light btn-sm save-button" onClick={props.saveBillingAddress} disabled={props.savingBillingAddress}>{saveAddressLabel}</button>,
    ];
    return (
        <Dialog
            name="frontend-checkout-billing-address-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="frontend-checkout-billing-address-dialog"
        >
            <div className="frontend-checkout-billing-address-modal-container">
                {content}
            </div>
        </Dialog >
    );
});

export default BillingAddressModal;
