import React, { useCallback, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './AdminOrders.scss';
import Table from './Table';
import Details from './Details';
import DeleteModal from './DeleteModal';
import AdminLayout from './../AdminLayout';
import ArtworksModal from './ArtworksModal';
import CreateScTicketModal from './CreateScTicketModal';
import * as actions from './AdminOrders.actions';
import * as selectors from './AdminOrders.selectors';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from './../common/dialog';

const AdminOrders = (props) => {
    /* State to props */
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const actionType = useSelector(selectors.getActionType);
    const items = useSelector(selectors.getItems, stringifyEqualityCheck);
    const item = useSelector(selectors.getItem, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const search = useSelector(selectors.getSearch);
    const searchType = useSelector(selectors.getSearchType);
    const fetchingElements = useSelector(selectors.getFetchingElements);
    const fetchingElement = useSelector(selectors.getFetchingElement);
    const saving = useSelector(selectors.getSaving);
    const deleting = useSelector(selectors.getDeleting);
    const idModal = useSelector(selectors.getIdModal);
    const creatingScTicket = useSelector(selectors.getCreatingScTicket);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const fetchingInformation = useSelector(selectors.getFetchingInformation);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setActionType = useCallback((value) => dispatch(actions.setActionType(value)), [dispatch]);
    const setActivePage = useCallback((value) => dispatch(actions.setActivePage(value)), [dispatch]);
    const fetchElements = useCallback(() => dispatch(actions.fetchElements()), [dispatch]);
    const fetchElement = useCallback((id) => dispatch(actions.fetchElement(id)), [dispatch]);
    const saveElement = useCallback(() => dispatch(actions.saveElement()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const setHistory = useCallback((value) => dispatch(actions.setHistory(value)), [dispatch]);
    const setSort = useCallback((column, type, orderType) => dispatch(actions.setSort(column, type, orderType)), [dispatch]);
    const setSearch = useCallback((value) => dispatch(actions.setSearch(value)), [dispatch]);
    const setSearchType = useCallback((value) => dispatch(actions.setSearchType(value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setIdModal = useCallback((id) => dispatch(actions.setIdModal(id)), [dispatch]);
    const deleteItem = useCallback(() => dispatch(actions.deleteItem()), [dispatch]);
    const createScTicket = useCallback((id) => dispatch(actions.createScTicket(id)), [dispatch]);
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const changeJobStatus = useCallback((id, jobStatusId, index) => dispatch(actions.changeJobStatus(id, jobStatusId, index)), [dispatch]);
    const setCustomerId = useCallback((id) => dispatch(actions.setCustomerId(id)), [dispatch]);
    const setOrderId = useCallback((id) => dispatch(actions.setOrderId(id)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showArtworksModal = useCallback(() => dispatch(dialog.actions.showDialog('artworks-modal')), [dispatch]);
    const hideArtworksModal = useCallback(() => dispatch(dialog.actions.hideDialog('artworks-modal')), [dispatch]);
    const showCreateScTicketModal = useCallback(() => dispatch(dialog.actions.showDialog('create-sc-ticket-modal')), [dispatch]);
    const hideCreateScTicketModal = useCallback(() => dispatch(dialog.actions.hideDialog('create-sc-ticket-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        setHistory(props.history);
        fetchInformation();

        if (props.match.params.customerId) {
            setCustomerId(props.match.params.customerId);
        } else {
            setCustomerId(null);
        }

        if (props.match.params.action) {
            setActionType(props.match.params.action);

            if (props.match.params.action === 'details') {
                fetchElement(props.match.params.id);
                setOrderId(props.match.params.id);
            }
        } else {
            setActionType('list');
            fetchElements();
        }

        return () => {
            resetState();
        };
    }, [setActionType, resetState, fetchElements, fetchElement, setHistory, fetchInformation, setCustomerId, setOrderId,
        props.history, props.match.params.action, props.match.params.id, props.match.params.customerId]);

    const handleSearch = () => {
        setActivePage(1);
        fetchElements();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setActivePage(1);
            fetchElements();
        }
    };

    const handleSetSearch = (event) => {
        setSearch(event.target.value);
    };

    let titleContainer = (
        <Fragment>
            <h1>Orders</h1>
            <div className="header-actions-container">
                <div className="search-field">
                    <input type="text" value={search} onChange={handleSetSearch} onKeyPress={handleKeyPress} />
                    <button className="search-button admin-button" onClick={handleSearch}>Search</button>
                </div>
            </div>
        </Fragment>
    );
    if (props.match.params.action === 'details') {
        titleContainer = <h1>Order Details</h1>;
    }

    return (
        <AdminLayout history={props.history}>
            <Helmet>
                <title>Admin Orders - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="admin-orders-container">
                {titleContainer}
                <Table
                    pagination={pagination}
                    actionType={actionType}
                    sort={sort}
                    search={search}
                    searchType={searchType}
                    fetchingElements={fetchingElements}
                    items={items}
                    setActivePage={setActivePage}
                    setSort={setSort}
                    setSearch={setSearch}
                    setSearchType={setSearchType}
                    showDeleteModal={showDeleteModal}
                    setIdModal={setIdModal}
                />
                <Details
                    actionType={actionType}
                    item={item}
                    saving={saving}
                    fetchingElement={fetchingElement}
                    information={information}
                    fetchingInformation={fetchingInformation}
                    setField={setField}
                    saveElement={saveElement}
                    setIdModal={setIdModal}
                    deleteItem={deleteItem}
                    showArtworksModal={showArtworksModal}
                    showCreateScTicketModal={showCreateScTicketModal}
                    changeJobStatus={changeJobStatus}
                />
            </div>
            <DeleteModal
                idModal={idModal}
                deleting={deleting}
                deleteItem={deleteItem}
                hideDeleteModal={hideDeleteModal}
                setIdModal={setIdModal}
            />
            <ArtworksModal
                idModal={idModal}
                item={item}
                hideArtworksModal={hideArtworksModal}
                setIdModal={setIdModal}
            />
            <CreateScTicketModal
                idModal={idModal}
                creatingScTicket={creatingScTicket}
                setIdModal={setIdModal}
                createScTicket={createScTicket}
                hideCreateScTicketModal={hideCreateScTicketModal}
            />
        </AdminLayout>
    );
};

export default AdminOrders;
