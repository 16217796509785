import React from 'react';

const Description = (props) => {
    return (
        <div className="description-container content-container">
            <h2>
                Looking to create something awesome?
            </h2>
            <div className="description">
                Our experienced creative team is here to help! Whether you need a quick update or a custom design, we can turn your ideas into reality.
                Let's work together to bring your vision to life!
            </div>
            <div className="services">
                <div className="service">
                    <div className="icon-container logo-design">
                        <div className="image">
                            <img src="https://overnightcolor.s3.amazonaws.com/css/custom-service1.png" alt="Custom Service" />
                        </div>
                        <div className="title">Logo <br />design</div>
                    </div>
                    <div className="description-container">
                        <p>
                            A logo is a symbol or emblem that represents your brand. It should be simple, memorable, and easily recognizable, making it a
                            key element of your brand identity.
                        </p>
                        <a href="#contact-form">ORDER YOURS <i className="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
                <div className="service">
                    <div className="icon-container business-card-design">
                        <div className="image">
                            <img src="https://overnightcolor.s3.amazonaws.com/css/custom-service2.png" alt="Custom Service" />
                        </div>
                        <div className="title">Business Cards <br />design</div>
                    </div>
                    <div className="description-container">
                        <p>
                            A business card is a small but powerful marketing tool that introduces your brand and contact information to potential clients.
                            A well-designed business card should be visually appealing, easy to read, and reflective of your brand's personality and values.
                        </p>
                        <a href="#contact-form">ORDER YOURS <i className="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
                <div className="service">
                    <div className="icon-container brochure-design">
                        <div className="image">
                            <img src="https://overnightcolor.s3.amazonaws.com/css/custom-service3.png" alt="Custom Service" />
                        </div>
                        <div className="title">Brochure <br />design</div>
                    </div>
                    <div className="description-container">
                        <p>
                            Printed marketing material that showcases your business, product or service. It can introduce new offerings, promote events or highlight
                            your company's capabilities. A well-designed brochure should be visually engaging, informative and easy to navigate.
                        </p>
                        <a href="#contact-form">ORDER YOURS <i className="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
                <div className="service">
                    <div className="icon-container booklet-design">
                        <div className="image">
                            <img src="https://overnightcolor.s3.amazonaws.com/css/custom-service4.png" alt="Custom Service" />
                        </div>
                        <div className="title">Booklet <br />design</div>
                    </div>
                    <div className="description-container">
                        <p>
                            A printed piece with multiple pages, bound together with staples or a perfect binding. Can be used for catalogs, event programs or handbooks.
                            A well-designed booklet should be visually engaging, easy to read and organized to make sense of the content.
                        </p>
                        <a href="#contact-form">ORDER YOURS <i className="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
                <div className="service">
                    <div className="icon-container postcard-design">
                        <div className="image">
                            <img src="https://overnightcolor.s3.amazonaws.com/css/custom-service5.png" alt="Custom Service" />
                        </div>
                        <div className="title">Postcard <br />design</div>
                    </div>
                    <div className="description-container">
                        <p>
                            A postcard is a printed piece of cardstock that can be mailed without an envelope. Postcards can be used for a variety of purposes, such as
                            promoting an event, offering a special promotion or just to say hello. A well-designed postcard should be visually appealing, easy to read,
                            and have a clear call to action.
                        </p>
                        <a href="#contact-form">ORDER YOURS <i className="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
                <div className="service">
                    <div className="icon-container flyer-design">
                        <div className="image">
                            <img src="https://overnightcolor.s3.amazonaws.com/css/custom-service6.png" alt="Custom Service" />
                        </div>
                        <div className="title">Flyer <br />design</div>
                    </div>
                    <div className="description-container">
                        <p>
                            A flyer is a printed marketing piece that is typically distributed by hand or posted in public places. It can be used for a variety of
                            purposes, such as promoting an event, advertising a product or service, or raising awareness for a cause. A well-designed flyer should
                            be eye-catching, informative, and easy to read.
                        </p>
                        <a href="#contact-form">ORDER YOURS <i className="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div className="many-more">
                And many more!
            </div>
        </div>
    );
}

export default Description;
