import axios from 'axios';
import * as types from './ShoppingCart.types';
import * as selectors from './ShoppingCart.selectors';
import { validateAuthInResponse, showNotification, empty } from './../../utils';
import * as dialog from './../common/dialog';
import * as layoutActions from './../Layout/Layout.actions';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchItems = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ITEMS, payload: null });
    axios.get(apiUrl + '/shopping-cart-items',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ITEMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ITEMS_ERROR));
        });
}

export const fetchShippingAddresses = () => (dispatch, getState) => {
    const selectedAddress = selectors.getSelectedAddress(getState());

    dispatch({ type: types.FETCH_SHIPPING_ADDRESSES, payload: null });
    axios.get(apiUrl + '/customer/shipping-addresses',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_SHIPPING_ADDRESSES_SUCCESS, payload: response.data });

            for (const address of response.data) {
                if (parseInt(selectedAddress) === parseInt(address.id)) {
                    dispatch(setFields(address));
                    break;
                }
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_SHIPPING_ADDRESSES_ERROR));
        });
}

export const saveCheckout = (index, id, value) => (dispatch, getState) => {
    dispatch({ type: types.SAVE_CHECKOUT, payload: { index, value } });
    axios.post(apiUrl + '/shopping-cart-item/save-checkout', { id, value },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_CHECKOUT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_CHECKOUT_ERROR));
        });
}

export const saveShippingAddress = () => (dispatch, getState) => {
    const item = selectors.getSelectedItem(getState());
    const fields = selectors.getFields(getState());
    const items = selectors.getItems(getState());

    if (!validateAddress(fields)) {
        return;
    }

    let index = -1;
    for (let i = 0; i < items.length; i++) {
        if (parseInt(items[i].id) === parseInt(item.id)) {
            index = i;
            break;
        }
    }

    dispatch({ type: types.SAVE_SHIPPING_ADDRESS, payload: null });
    axios.post(apiUrl + '/shopping-cart-item/save-shipping-address', { id: item.id, fields },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_SHIPPING_ADDRESS_SUCCESS, payload: { index, address: response.data } });
            dispatch(calculatePrice(index, item.id, item.quantity));
            dispatch(dialog.actions.hideDialog('frontend-cart-shipping-address-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_SHIPPING_ADDRESS_ERROR));
        });
}

export const validateAddress = (fields) => {
    if (empty(fields.first_name)) {
        showNotification('Complete Information', 'Enter the First Name', 'info');
        return false;
    }
    if (empty(fields.last_name)) {
        showNotification('Complete Information', 'Enter the Last Name', 'info');
        return false;
    }
    if (empty(fields.company)) {
        showNotification('Complete Information', 'Enter the Company', 'info');
        return false;
    }
    if (empty(fields.zip)) {
        showNotification('Complete Information', 'Enter the Zip Code', 'info');
        return false;
    }
    if (empty(fields.country_id)) {
        showNotification('Complete Information', 'Select the Country', 'info');
        return false;
    }
    if (empty(fields.state)) {
        showNotification('Complete Information', 'Enter the State', 'info');
        return false;
    }
    if (empty(fields.city)) {
        showNotification('Complete Information', 'Enter the City', 'info');
        return false;
    }
    if (empty(fields.phone)) {
        showNotification('Complete Information', 'Enter the Phone', 'info');
        return false;
    }
    if (empty(fields.id) && empty(fields.make_default)) {
        showNotification('Complete Information', 'Select if this will be the Default Address or not', 'info');
        return false;
    }

    return true;
}

export const deleteItem = (id) => (dispatch, getState) => {
    dispatch({ type: types.DELETE_ITEM, payload: null });
    axios.delete(apiUrl + '/shopping-cart-items/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.DELETE_ITEM_SUCCESS, payload: response.data });
            showNotification('Item Deleted', 'The item has been deleted succesfully', 'success');
            dispatch(fetchItems());
            dispatch(layoutActions.checkCartItems());
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DELETE_ITEM_ERROR));
        });
}

export const calculatePrice = (index, id, value) => (dispatch, getState) => {
    dispatch({ type: types.CALCULATE_PRICE, payload: { index, id, value } });
    axios.post(apiUrl + '/shopping-cart-item/calculate-price', { index, id, value },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.CALCULATE_PRICE_SUCCESS, payload: { index, pricing: response.data } });

            if (parseFloat(response.data.shipping) === -1) {
                return showNotification('Invalid Shipping Method', 'The Shipping Method is not available for your address, please select another one.', 'info', 6000);
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.CALCULATE_PRICE_ERROR));
        });
}

export const setSelectedItem = (item) => dispatch => {
    dispatch({
        type: types.SET_SELECTED_ITEM,
        payload: item,
    });
}

export const setSelectedAddress = (value) => dispatch => {
    dispatch({
        type: types.SET_SELECTED_ADDRESS,
        payload: value,
    });
}

export const setFields = (value) => dispatch => {
    dispatch({
        type: types.SET_FIELDS,
        payload: value,
    });
}

export const setField = (field, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setHistory = (history) => dispatch => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}