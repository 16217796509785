import React from 'react';

const Products = (props) => {
    const handleEcoFriendly = () => {
        props.history.push('/company/environmental-statement');
    };

    const handleRequestQuote = () => {
        props.history.push('/company/custom-quote');
    };

    return (
        <div className="information-container content-container">
            <div className="owners">
                <div className="owner hugh">
                    <div className="picture">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/hugh.jpg" alt="Hugh Nguyen" />
                    </div>
                    <div className="name">Hugh Nguyen</div>
                    <div className="position">CEO</div>
                    <div className="description">
                        Originally from Vietnam, Hugh is a transplant from Pittsburg but has called Houston home for over 35 years. Hugh was
                        submerged in the printing and reprographic industry at the young age of 14. Working in his parents' small reprographic
                        company, Hugh learned the ropes from sweeping the press room to operating a press, to Vice President of Sales and then
                        President of the company. Hugh and his brothers grew the business into the largest Asian owned printing company in Houston.
                        Hugh and his wife Christy launched OvernightColor in 2001, providing industry-leading print technology and turn-key solutions
                        for their clients.
                    </div>
                </div>
                <div className="owner christy">
                    <div className="picture">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/christy.jpg" alt="Christy Nguyen" />
                    </div>
                    <div className="name">Christy Nguyen</div>
                    <div className="position">Business Development</div>
                    <div className="description">
                        Born in Oklahoma City but raised in Houston, Texas for much of her life, Christy has been exposed to the printing industry
                        since 1995. A graduate of Sam Houston State University, she brought her accounting and business management background and
                        co-founded OvernightColor in 2001. She instilled the four pillars for business success: management, marketing, operations,
                        and finance. Christy provides a true perception of financial and fiscal responsibility along with a strategy to win more
                        opportunities and stay ahead of the competition through a customer-centric approach. Christy also assists with generating
                        new sales leads, negotiating client pricing, and forecast sales revenue, all to support one of the most important business
                        development manager responsibilities - helping organizations maximize their profits.
                    </div>
                </div>
            </div>
            <div className="messages-container">
                <div className="message-main-container eco-friendly">
                    <div className="message-container">
                        <div className="title">ECO-FRIENDLY PRINTING</div>
                        <div className="message">
                            OvernightColor.com has taken the initiative and grown into an environmentally friendly company by taking significant
                            steps in our production of printed products.
                        </div>
                        <button onClick={handleEcoFriendly}>READ MORE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/eco-friendly.png" alt="Eco Friendly" />
                    </div>
                </div>
                <div className="message-main-container request-quote">
                    <div className="message-container">
                        <div className="title">REQUEST PRINT QUOTE</div>
                        <div className="message">
                            Request a quote for any print project by using our user-friendly online form. Submit the details of your print request
                            and we will provide you with an instant quote.
                        </div>
                        <button onClick={handleRequestQuote}>REQUEST A QUOTE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/request-quote.png" alt="Request Quote" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
