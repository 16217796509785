import Immutable from 'immutable';
import * as types from './CreditApplication.types';

const initialState = {
    fields: {
        company_name: '',
        trade_name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        fax: '',
        website: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        contact_address: '',
        contact_city: '',
        contact_state: '',
        contact_zip: '',
        business_entity: '',
        fed_id: '',
        business_type: '',
        estimated_annual_print: '',
        tax_exempt: '',
        sales_tax_rate: '',
        county: '',
        bank_name: '',
        account_number: '',
        bank_officer_name: '',
        bank_phone_number: '',
        reference1_name: '',
        reference1_address: '',
        reference1_city: '',
        reference1_state: '',
        reference1_zip: '',
        reference1_phone: '',
        reference1_fax: '',
        reference2_name: '',
        reference2_address: '',
        reference2_city: '',
        reference2_state: '',
        reference2_zip: '',
        reference2_phone: '',
        reference2_fax: '',
        reference3_name: '',
        reference3_address: '',
        reference3_city: '',
        reference3_state: '',
        reference3_zip: '',
        reference3_phone: '',
        reference3_fax: '',
        agree: '',
        electronic_signature: '',
        job_title: '',
    },
    sending: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SEND_CONTACT:
            return state.setIn(['sending'], true).toJS();

        case types.SEND_CONTACT_SUCCESS:
            return state.setIn(['sending'], false)
                .setIn(['fields'], {
                    company_name: '',
                    trade_name: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    phone: '',
                    fax: '',
                    website: '',
                    contact_name: '',
                    contact_phone: '',
                    contact_email: '',
                    contact_address: '',
                    contact_city: '',
                    contact_state: '',
                    contact_zip: '',
                    business_entity: '',
                    fed_id: '',
                    business_type: '',
                    estimated_annual_print: '',
                    tax_exempt: '',
                    sales_tax_rate: '',
                    county: '',
                    bank_name: '',
                    account_number: '',
                    bank_officer_name: '',
                    bank_phone_number: '',
                    reference1_name: '',
                    reference1_address: '',
                    reference1_city: '',
                    reference1_state: '',
                    reference1_zip: '',
                    reference1_phone: '',
                    reference1_fax: '',
                    reference2_name: '',
                    reference2_address: '',
                    reference2_city: '',
                    reference2_state: '',
                    reference2_zip: '',
                    reference2_phone: '',
                    reference2_fax: '',
                    reference3_name: '',
                    reference3_address: '',
                    reference3_city: '',
                    reference3_state: '',
                    reference3_zip: '',
                    reference3_phone: '',
                    reference3_fax: '',
                    agree: '',
                    electronic_signature: '',
                    job_title: '',
                }).toJS();

        case types.SEND_CONTACT_ERROR:
            return state.setIn(['sending'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}