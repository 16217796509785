import Immutable from 'immutable';
import * as types from './MyAddressBook.types';

const initialState = {
    billingAddresses: [],
    shippingAddresses: [],
    fetchingBillingAddresses: false,
    fetchingShippingAddresses: false,
    history: null,
    pagination: {
        billing: {
            totalElements: 0,
            activePage: 1,
            totalPages: 0,
            elementsPerPage: 15,
        },
        shipping: {
            totalElements: 0,
            activePage: 1,
            totalPages: 0,
            elementsPerPage: 15,
        },
    },
    sort: {
        billing: {
            column: 'id',
            type: 'desc',
        },
        shipping: {
            column: 'id',
            type: 'desc',
        },
    },
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_BILLING_ADDRESSES:
            return state.setIn(['fetchingBillingAddresses'], true).toJS();

        case types.FETCH_BILLING_ADDRESSES_SUCCESS:
            return state.setIn(['fetchingBillingAddresses'], false)
                .setIn(['billingAddresses'], action.payload.elements)
                .setIn(['pagination', 'billing', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'billing', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'billing', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'billing', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_BILLING_ADDRESSES_ERROR:
            return state.setIn(['fetchingBillingAddresses'], false).toJS();

        case types.FETCH_SHIPPING_ADDRESSES:
            return state.setIn(['fetchingShippingAddresses'], true).toJS();

        case types.FETCH_SHIPPING_ADDRESSES_SUCCESS:
            return state.setIn(['fetchingShippingAddresses'], false)
                .setIn(['shippingAddresses'], action.payload.elements)
                .setIn(['pagination', 'shipping', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'shipping', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'shipping', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'shipping', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_SHIPPING_ADDRESSES_ERROR:
            return state.setIn(['fetchingShippingAddresses'], false).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', action.payload.type, 'activePage'], action.payload.value).toJS();

        case types.SET_SORT:
            return state.setIn(['sort', action.payload.type, 'type'], action.payload.sortType)
                .setIn(['sort', action.payload.type, 'column'], action.payload.sortColumn)
                .toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}