import Immutable from 'immutable';
import * as types from './AdminConfiguration.types';

const initialState = {
    actionType: 'list',
    item: {},
    fetchingElement: false,
    saving: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ELEMENT:
            return state.setIn(['fetchingElement'], true).toJS();

        case types.FETCH_ELEMENT_SUCCESS:
            return state.setIn(['fetchingElement'], false)
                .setIn(['item'], action.payload)
                .toJS();

        case types.FETCH_ELEMENT_ERROR:
            return state.setIn(['fetchingElement'], false).toJS();

        case types.SAVE_ELEMENT:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_ELEMENT_SUCCESS:
            return state.setIn(['saving'], false).toJS();

        case types.SAVE_ELEMENT_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['item', action.payload.field], action.payload.value).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}