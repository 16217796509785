import React from 'react';

const Information = (props) => {
    return (
        <div className="information-container content-container">
            <div className="info-container">
                <h1>COMPANY PROFILE AND VISION</h1>
                <div className="main-information">
                    <p>
                        OvernightColor.com was founded on the basic principles of honesty and a passion for customer service. Over time, we have 
                        built a concrete reputation in the Houston printing market while continuing to grow and expand our capabilities and services 
                        to large and small businesses in Houston, Texas. Today, we harness decades of experience, a pool of dedicated print 
                        professionals and state-of-the art printing technology that provides unparalleled quality and services to all our customers.
                    </p>
                    <p>
                        With over 30 years of printing experience, OvernightColor.com is trusted by Fortune 500 Companies worldwide for all their 
                        printed collaterals. We have been saving companies, large and small, thousands of dollars on printed products each year 
                        in our own hometown of Houston, Texas. Now, we are excited to offer our extended printing services along with our experience 
                        to the world with our easy to use online printing portal. It features our signature easy-to-use website and complimentary 
                        proofing service which requires no upfront payment plus a point reward system for each dollar that you spend.
                    </p>
                    <p>
                        Powered by a brand new fully automated Komori 10 color perfecting 40'' printing press with coater and a completely digital 
                        workflow, we can offer a faster turnaround with minimal human error at a lower overhead cost. This allows us to provide all 
                        our customers with consistently high-quality printing at the lowest possible prices.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Information;
