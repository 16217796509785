import React from 'react';

const Description = (props) => {
    return (
        <div className="description-container content-container">
            <h2>
                Join the OvernightColor Team: Seeking Top Talent for Superior Printing Products and Customer Satisfaction
            </h2>
            <div className="description">
                <p>
                    OvernightColor only looks for employment candidates that excel in their craft. OvernightColor is committed to total customer 
                    satisfaction and the delivery of superior printing products to our customers.
                </p>
                <p>
                    We are always on the search for the best industry professionals and sales people to join our ever growing family. If you have 
                    what it takes to join the OvernightColor team, we would love to speak to you.
                </p>
                <p>
                    If you are interested in a position with us, please email your resume to hugh@absolutecolor.net or fax resume to 832-608-6420.
                </p>
            </div>
        </div>
    );
}

export default Description;
