import { createSelector } from 'reselect';

export const getComponentState = state => state.login;

export const getToken = createSelector(
    getComponentState,
    (state) => state.token
);
export const getIsLogging = createSelector(
    getComponentState,
    (state) => state.isLogging
);
export const getForm = createSelector(
    getComponentState,
    (state) => state.form
);
export const getIsAuthenticated = createSelector(
    getComponentState,
    (state) => state.isAuthenticated
);
export const getUser = createSelector(
    getComponentState,
    (state) => state.user
);