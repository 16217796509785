import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Banner from './Banner';
import Description from './Description';
import Items from './Items';
import Contact from './Contact';
import './Faqs.scss';
import * as selectors from './Faqs.selectors';
import * as actions from './Faqs.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';

const Faqs = (props) => {
    /* State to props */
    const items = useSelector(selectors.getItems, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const setShowItem = useCallback((index) => dispatch(actions.setShowItem(index)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');
        fetchInformation();

        return () => {
            resetState();
        };
    }, [fetchInformation, resetState]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>FAQs - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="faqs-container">
                <Banner />
                <Description />
                <Items
                    items={items}
                    setShowItem={setShowItem}
                />
                <Contact
                    history={props.history}
                />
            </div>
        </Layout>
    );
};

export default Faqs;
