import Immutable from 'immutable';
import * as types from './Landing.types';

const initialState = {
    activeBlurProduct: 1,
    information: {
        bestSellers: [],
        newProducts: [],
        recommendedProducts: [],
        testimonials: [],
    },
    fetchingInformation: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'bestSellers'], action.payload.bestSellers)
                .setIn(['information', 'newProducts'], action.payload.newProducts)
                .setIn(['information', 'recommendedProducts'], action.payload.recommendedProducts)
                .setIn(['information', 'testimonials'], action.payload.testimonials)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS()

        case types.SET_ACTIVE_BLUR_PRODUCT:
            return state.setIn(['activeBlurProduct'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}