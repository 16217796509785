import Immutable from 'immutable';
import * as types from './ActivateAccount.types';

const initialState = {
    activating: false,
    activated: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.ACTIVATE:
            return state.setIn(['activating'], true).toJS();

        case types.ACTIVATE_SUCCESS:
            return state.setIn(['activating'], false)
                .setIn(['activated'], true)
                .toJS();

        case types.ACTIVATE_ERROR:
            return state.setIn(['activating'], false).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}