export const FETCH_ITEMS = 'CHECKOUT/FETCH_ITEMS';
export const FETCH_ITEMS_SUCCESS = 'CHECKOUT/FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_ERROR = 'CHECKOUT/FETCH_ITEMS_ERROR';
export const PLACE_ORDER = 'CHECKOUT/PLACE_ORDER';
export const PLACE_ORDER_SUCCESS = 'CHECKOUT/PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_ERROR = 'CHECKOUT/PLACE_ORDER_ERROR';
export const FETCH_INFORMATION = 'CHECKOUT/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'CHECKOUT/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'CHECKOUT/FETCH_INFORMATION_ERROR';
export const FETCH_BILLING_ADDRESSES = 'CHECKOUT/FETCH_BILLING_ADDRESSES';
export const FETCH_BILLING_ADDRESSES_SUCCESS = 'CHECKOUT/FETCH_BILLING_ADDRESSES_SUCCESS';
export const FETCH_BILLING_ADDRESSES_ERROR = 'CHECKOUT/FETCH_BILLING_ADDRESSES_ERROR';
export const SAVE_BILLING_ADDRESS = 'CHECKOUT/SAVE_BILLING_ADDRESS';
export const SAVE_BILLING_ADDRESS_SUCCESS = 'CHECKOUT/SAVE_BILLING_ADDRESS_SUCCESS';
export const SAVE_BILLING_ADDRESS_ERROR = 'CHECKOUT/SAVE_BILLING_ADDRESS_ERROR';
export const VALIDATE_COUPON = 'CHECKOUT/VALIDATE_COUPON';
export const VALIDATE_COUPON_SUCCESS = 'CHECKOUT/VALIDATE_COUPON_SUCCESS';
export const VALIDATE_COUPON_ERROR = 'CHECKOUT/VALIDATE_COUPON_ERROR';
export const SET_SELECTED_ADDRESS = 'CHECKOUT/SET_SELECTED_ADDRESS';
export const SET_ADDRESS_FIELDS = 'CHECKOUT/SET_ADDRESS_FIELDS';
export const SET_ADDRESS_FIELD = 'CHECKOUT/SET_ADDRESS_FIELD';
export const SET_FIELD = 'CHECKOUT/SET_FIELD';
export const SET_PAYMENT_METHOD = 'CHECKOUT/SET_PAYMENT_METHOD';
export const SET_ORDER_ID = 'CHECKOUT/SET_ORDER_ID';
export const SET_HISTORY = 'CHECKOUT/SET_HISTORY';
export const RESET_STATE = 'CHECKOUT/RESET_STATE';