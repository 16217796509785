import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Banner from './Banner';
import Form from './Form';
import './CustomQuote.scss';
import * as selectors from './CustomQuote.selectors';
import * as actions from './CustomQuote.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';

const CustomQuote = (props) => {
    /* State to props */
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const sending = useSelector(selectors.getSending);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const sendCustomQuote = useCallback(() => dispatch(actions.sendCustomQuote()), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        return () => {
            resetState();
        };
    }, [resetState]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>Custom Quote - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="custom-quote-container">
                <Banner />
                <Form
                    fields={fields}
                    sending={sending}
                    setField={setField}
                    sendCustomQuote={sendCustomQuote}
                />
            </div>
        </Layout>
    );
};

export default CustomQuote;
