import axios from 'axios';
import * as types from './MyAddress.types';
import * as selectors from './MyAddress.selectors';
import { validateAuthInResponse, showNotification, empty } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchAddress = () => (dispatch, getState) => {
    const id = selectors.getId(getState());
    const type = selectors.getType(getState());

    dispatch({ type: types.FETCH_ADDRESS, payload: null });
    axios.get(apiUrl + '/' + type + '-addresses/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ADDRESS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ADDRESS_ERROR));
        });
}

export const fetchInformation = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/billing-address/information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const saveAddress = () => (dispatch, getState) => {
    const address = selectors.getAddress(getState());
    const type = selectors.getType(getState());
    // const action = selectors.getAction(getState());
    const history = selectors.getHistory(getState());
    const file = selectors.getFile(getState());

    if (!validateAddress(address, type, file)) {
        return;
    }

    let endpoint = apiUrl + '/' + type + '-addresses';
    let method = 'post';
    if (address.id && address.id != null) {
        endpoint = apiUrl + '/' + type + '-address/update-post?id=' + address.id;
        method = 'post';
    }

    var formData = new FormData();
    for (const key in address) {
        if (address[key] != null) {
            formData.append(key, address[key]);
        }
    }
    formData.append('file', file);

    dispatch({ type: types.SAVE_ADDRESS, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: formData,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_ADDRESS_SUCCESS, payload: response.data });
        showNotification('Address Saved', 'The address has been saved successfully', 'success');
        history.push('/my-address-book');
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_ADDRESS_ERROR));
    });
}

export const validateAddress = (address, type, file) => {
    if (empty(address.first_name)) {
        showNotification('Complete Information', 'Enter the First Name', 'info');
        return false;
    }
    if (empty(address.last_name)) {
        showNotification('Complete Information', 'Enter the Last Name', 'info');
        return false;
    }
    if (empty(address.company)) {
        showNotification('Complete Information', 'Enter the Company', 'info');
        return false;
    }
    if (type === 'billing' && empty(address.is_reseller)) {
        showNotification('Complete Information', 'Select if is a Reseller', 'info');
        return false;
    }
    if (type === 'billing' && parseInt(address.is_reseller) && empty(address.tax_id)) {
        showNotification('Complete Information', 'Enter the Tax ID', 'info');
        return false;
    }
    if (type === 'billing' && parseInt(address.is_reseller) && file == null && empty(address.reseller_certificate_file_url)) {
        showNotification('Complete Information', 'Select the Reseller Certificate', 'info');
        return false;
    }
    if (empty(address.zip)) {
        showNotification('Complete Information', 'Enter the Zip Code', 'info');
        return false;
    }
    if (empty(address.country_id)) {
        showNotification('Complete Information', 'Select the Country', 'info');
        return false;
    }
    if (empty(address.state)) {
        showNotification('Complete Information', 'Enter the State', 'info');
        return false;
    }
    if (empty(address.city)) {
        showNotification('Complete Information', 'Enter the City', 'info');
        return false;
    }
    if (empty(address.phone)) {
        showNotification('Complete Information', 'Enter the Phone', 'info');
        return false;
    }

    return true;
}

export const setId = (value) => dispatch => {
    dispatch({
        type: types.SET_ID,
        payload: value,
    });
}

export const setType = (value) => dispatch => {
    dispatch({
        type: types.SET_TYPE,
        payload: value,
    });
}

export const setAction = (value) => dispatch => {
    dispatch({
        type: types.SET_ACTION,
        payload: value,
    });
}

export const setField = (field, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setFile = (file) => dispatch => {
    dispatch({
        type: types.SET_FILE,
        payload: file,
    });
}

export const setHistory = (history) => dispatch => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}