import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Table = (props) => {
    const handleDelete = (id, event) => {
        event.preventDefault();
        props.deleteItem(id);
    };

    const handleChangeQuantity = (index, id, event) => {
        props.calculatePrice(index, id, event.target.value);
    };

    const handleShowShippingAddressModal = (item, event) => {
        event.preventDefault();
        props.setSelectedItem(item);
        props.setSelectedAddress(item.shipping_address_id);
        props.fetchShippingAddresses();
        props.showShippingAddressModal();
    };

    const renderPrice = (content, isCalculating) => {
        if (isCalculating) {
            return <i className="fas fa-circle-notch fa-spin" />;
        }

        return content;
    };

    const renderQuantities = (item, index, fields) => {
        if (item.printing_type === 'digital') {
            return (
                <select value={fields.quantity} onChange={handleChangeQuantity.bind(this, index, item.id)}>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                    <option value="250">250</option>
                    <option value="300">300</option>
                    <option value="400">400</option>
                    <option value="500">500</option>
                </select>
            );
        }

        if (['booklets', 'catalogs', 'newsletters'].includes(item.product_code)) {
            return (
                <select value={fields.quantity} onChange={handleChangeQuantity.bind(this, index, item.id)}>
                    <option value="250">250</option>
                    <option value="500">500</option>
                    <option value="1000">1,000</option>
                    <option value="1500">1,500</option>
                    <option value="2000">2,000</option>
                    <option value="2500">2,500</option>
                    <option value="3000">3,000</option>
                    <option value="3500">3,500</option>
                    <option value="4000">4,000</option>
                    <option value="4500">4,500</option>
                    <option value="5000">5,000</option>
                    <option value="5500">5,500</option>
                    <option value="6000">6,000</option>
                    <option value="6500">6,500</option>
                    <option value="7000">7,000</option>
                    <option value="7500">7,500</option>
                    <option value="8000">8,000</option>
                    <option value="8500">8,500</option>
                    <option value="9000">9,000</option>
                    <option value="9500">9,500</option>
                    <option value="10000">10,000</option>
                    <option value="12500">12,500</option>
                    <option value="15000">15,000</option>
                </select>
            );
        }

        return (
            <select value={fields.quantity} onChange={handleChangeQuantity.bind(this, index, item.id)}>
                <option value="250">250</option>
                <option value="500">500</option>
                <option value="1000">1,000</option>
                <option value="1500">1,500</option>
                <option value="2000">2,000</option>
                <option value="2500">2,500</option>
                <option value="3000">3,000</option>
                <option value="3500">3,500</option>
                <option value="4000">4,000</option>
                <option value="4500">4,500</option>
                <option value="5000">5,000</option>
                <option value="5500">5,500</option>
                <option value="6000">6,000</option>
                <option value="6500">6,500</option>
                <option value="7000">7,000</option>
                <option value="7500">7,500</option>
                <option value="8000">8,000</option>
                <option value="8500">8,500</option>
                <option value="9000">9,000</option>
                <option value="9500">9,500</option>
                <option value="10000">10,000</option>
                <option value="12500">12,500</option>
                <option value="15000">15,000</option>
                <option value="17500">17,500</option>
                <option value="20000">20,000</option>
                <option value="22500">22,500</option>
                <option value="25000">25,000</option>
                <option value="27500">27,500</option>
                <option value="30000">30,000</option>
                <option value="32500">32,500</option>
                <option value="35000">35,000</option>
                <option value="37500">37,500</option>
                <option value="40000">40,000</option>
                <option value="42500">42,500</option>
                <option value="45000">45,000</option>
                <option value="47500">47,500</option>
                <option value="50000">50,000</option>
            </select>
        );
    }

    const renderItems = () => {
        if (props.fetchingItems) {
            return <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading...</div>;
        }

        if (props.items.length === 0) {
            return <div className="cart-empty">Cart empty.</div>;
        }

        return props.items.map((item, index) => {
            const fields = JSON.parse(item.options);
            const pricing = JSON.parse(item.pricing);

            const tax = pricing.printing * 0.0825;

            let total = pricing.printing + tax;
            if (pricing.shipping > 0) {
                total += pricing.shipping;
            }

            let checkbox = <i className="fa-regular fa-square" onClick={props.saveCheckout.bind(this, index, item.id, 1)}></i>;
            if (item.checkout) {
                checkbox = <i className="fa-solid fa-square-check" onClick={props.saveCheckout.bind(this, index, item.id, 0)}></i>;
            }

            let isCalculating = false;
            if (props.calculatingPriceIndex === index && props.calculatingPrice) {
                isCalculating = true;
            }

            let shippingHtml = numeral(pricing.shipping).format('$0,0.00');
            if (pricing.shipping < 0) {
                shippingHtml = 'N/A';
            }

            return (
                <div className="item">
                    <div className="information">
                        <div className="checkbox">
                            {checkbox}
                        </div>
                        <div className="image">
                            <img src={item.product_image_url} alt="Product" />
                        </div>
                        <div className="product">
                            <div className="job-name">
                                {checkbox} {fields.project_name}
                            </div>
                            <div className="date">{moment().format('MMMM Do YYYY')}</div>
                            <div className="product-name">{item.product_name}</div>
                            <div className="shipping-address">
                                Shipping Address: <br />
                                <div className="address-row">{item.shippingAddress.first_name} {item.shippingAddress.last_name}</div>
                                <div className="address-row">{item.shippingAddress.address1} {item.shippingAddress.address2}</div>
                                <div className="address-row">{item.shippingAddress.city}, {item.shippingAddress.state} {item.shippingAddress.zip}</div>
                            </div>
                        </div>
                        <div className="pricing">
                            <div className="quantity">
                                Quantity:
                                <div className="select-field">
                                    {renderQuantities(item, index, fields)}
                                    <i className="fa-solid fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="pricing-row">
                                <div className="label">Printing</div>
                                <div className="price">
                                    <div>{renderPrice(numeral(pricing.printing).format('$0,0.00'), isCalculating)}</div>
                                    <div className="each">(Each: {renderPrice(numeral(pricing.printing / fields.quantity).format('$0,0.00[00]'), isCalculating)})</div>
                                </div>
                            </div>
                            <div className="pricing-row">
                                <div className="label">Shipping</div>
                                <div className="price">{renderPrice(shippingHtml, isCalculating)}</div>
                            </div>
                            <div className="pricing-row">
                                <div className="label">Tax</div>
                                <div className="price">{renderPrice(numeral(tax).format('$0,0.00'), isCalculating)}</div>
                            </div>
                            <div className="pricing-row">
                                <div className="label">Total</div>
                                <div className="price">{renderPrice(numeral(total).format('$0,0.00'), isCalculating)}</div>
                            </div>
                            <div className="pricing-row">
                                <div className="label">Points</div>
                                <div className="price">{renderPrice(numeral(Math.floor(pricing.printing)).format('0,0'), isCalculating)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="actions">
                        <div className="action">
                            <Link to={'/product/' + item.product_code + '/edit/' + item.id + '/' + item.printing_type}>Edit Job</Link>
                        </div>
                        <div className="divider">|</div>
                        <div className="action">
                            <a href="/" onClick={handleShowShippingAddressModal.bind(this, item)}>Change Shipping Address</a>
                        </div>
                        <div className="divider">|</div>
                        <div className="action">
                            <a href="/" onClick={handleDelete.bind(this, item.id)}>Delete Job</a>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="table-container">
            <div className="title">Shopping Cart</div>
            <div className="items">
                {renderItems()}
            </div>
        </div>
    );
}

export default Table;
