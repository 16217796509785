import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = (props) => {
    const path = props.history.location.pathname;

    let couponsMenu = null;
    let testimonialsMenu = null;
    let prizesMenu = null;
    let usersMenu = null;
    let customersMenu = null;
    let shoppingCartMenu = null;
    let faqsMenu = null;
    let careersMenu = null;

    if (parseInt(props.user.type) === 1) {  // Super Admin
        couponsMenu = (
            <div className={path.includes('/admin/coupons') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/coupons">
                    <i className="fa-solid fa-percent" /> Coupons
                </Link>
            </div>
        );
        testimonialsMenu = (
            <div className={path.includes('/admin/testimonials') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/testimonials">
                    <i className="fa-solid fa-comment" /> Testimonials
                </Link>
            </div>
        );
        prizesMenu = (
            <div className={path.includes('/admin/prizes') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/prizes">
                    <i className="fa-solid fa-gift" /> Prizes
                </Link>
            </div>
        );
        usersMenu = (
            <div className={path.includes('/admin/users') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/users">
                    <i className="fa-solid fa-cart-shopping"></i> Users
                </Link>
            </div>
        );
        customersMenu = (
            <div className={path.includes('/admin/customers') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/customers">
                    <i className="fa-solid fa-users" /> Customers
                </Link>
            </div>
        );
        shoppingCartMenu = (
            <div className={path.includes('/admin/shopping-cart-items') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/shopping-cart-items">
                    <i className="fa-solid fa-cart-shopping" /> Shopping Cart
                </Link>
            </div>
        );
        faqsMenu = (
            <div className={path.includes('/admin/faqs') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/faqs">
                    <i className="fa-solid fa-circle-question" /> FAQs
                </Link>
            </div>
        );
        careersMenu = (
            <div className={path.includes('/admin/careers') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/careers">
                    <i className="fa-solid fa-briefcase" /> Careers
                </Link>
            </div>
        );
    } else if (parseInt(props.user.type) === 2) {  // Admin
        customersMenu = (
            <div className={path.includes('/admin/customers') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/customers">
                    <i className="fa-solid fa-users" /> Customers
                </Link>
            </div>
        );
    }

    return (
        <div className="sidebar-container">
            <div className={path.includes('/admin/jobs') || path.includes('/admin/orders') || path.includes('/admin/customer-orders') || path === '/admin' ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/jobs">
                    <i className="fa-solid fa-clipboard"></i> Jobs
                </Link>
            </div>
            {shoppingCartMenu}
            {customersMenu}
            {couponsMenu}
            {testimonialsMenu}
            {prizesMenu}
            {faqsMenu}
            {careersMenu}
            {usersMenu}
        </div>
    );
}

export default Sidebar;
