import axios from 'axios';
import * as types from './AdminTestimonials.types';
import * as selectors from './AdminTestimonials.selectors';
import { adminValidateAuthInResponse, showNotification, empty } from './../../utils';
import * as dialog from './../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchElements = () => (dispatch, getState) => {
    const pagination = selectors.getPagination(getState());
    const sort = selectors.getSort(getState());
    const search = selectors.getSearch(getState());
    const searchType = selectors.getSearchType(getState());

    let params = [];
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);
    params.push('sort-column=' + sort.column);
    params.push('sort-type=' + sort.type);
    params.push('search=' + search);
    params.push('search-type=' + searchType);

    dispatch({ type: types.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrl + '/testimonial/admin-index?' + params.join('&'),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_ELEMENTS_ERROR));
        });
}

export const fetchElement = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ELEMENT, payload: null });
    axios.get(apiUrl + '/testimonials/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_ELEMENT_ERROR));
        });
}

export const saveElement = () => (dispatch, getState) => {
    const item = selectors.getItem(getState());
    const history = selectors.getHistory(getState());
    const file = selectors.getFile(getState());

    if (!validateSaveFields(item)) {
        return;
    }

    var formData = new FormData();
    for (const field in item) {
        if (item[field] == null) {
            formData.append(field, item[field]);
            formData.delete(field);
        } else if (Array.isArray(item[field])) {
            formData.append(field, JSON.stringify(item[field]));
        } else {
            formData.append(field, item[field]);
        }
    }
    if (file != null) {
        formData.append('file', file);
    }

    let endpoint = apiUrl + '/testimonials';
    if (item.id && item.id != null) {
        endpoint = apiUrl + '/testimonial/update-post?id=' + item.id;
    }

    dispatch({ type: types.SAVE_ELEMENT, payload: null });
    axios({
        method: 'post',
        url: endpoint,
        data: formData,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_ELEMENT_SUCCESS, payload: response.data });
        showNotification('Item Saved', 'The item has been saved successfully', 'success');
        history.push('/admin/testimonials')
    }).catch((error) => {
        dispatch(adminValidateAuthInResponse(error, types.SAVE_ELEMENT_ERROR));
    });
}

export const deleteItem = () => (dispatch, getState) => {
    const idModal = selectors.getIdModal(getState());

    dispatch({ type: types.DELETE_ITEM, payload: null });
    axios.delete(apiUrl + '/testimonials/' + idModal,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('admin_access_token')}` } })
        .then((response) => {
            dispatch({ type: types.DELETE_ITEM_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('delete-modal'));
            showNotification('Item Deleted', 'The item has been deleted successfully', 'success');
            dispatch(fetchElements());
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.DELETE_ITEM_ERROR));
        });
}

export const validateSaveFields = (fields) => {
    if (empty(fields.name)) {
        showNotification('Complete Information', 'Enter the Name', 'info');
        return false;
    }
    if (empty(fields.comment)) {
        showNotification('Complete Information', 'Enter the Comment', 'info');
        return false;
    }

    return true;
}

export const setActionType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_ACTION_TYPE,
        payload: value
    });
}

export const setActivePage = (value) => (dispatch, getState) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value,
        });
        resolve();
    }).then(() => {
        dispatch(fetchElements());
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: { column, type }
        });
        resolve();
    }).then(() => {
        dispatch(fetchElements());
    });
}

export const setSearch = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH,
        payload: value,
    });
}

export const setSearchType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH_TYPE,
        payload: value,
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setHistory = (value) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: value,
    });
}

export const setIdModal = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_MODAL,
        payload: id
    });
}

export const setFile = (file) => (dispatch) => {
    dispatch({
        type: types.SET_FILE,
        payload: file,
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}