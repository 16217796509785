import React from 'react';
import numeral from 'numeral';
import { empty } from './../../../utils';

const Summary = (props) => {
    let blurClass = '';
    let loginMessage = null;
    if (!props.user) {
        blurClass = 'blur';
        loginMessage = <div className="login-message">Please log in to see pricing.</div>;
    }

    const renderPrice = (price) => {
        if (props.calculatingPrice) {
            return <i className="fas fa-circle-notch fa-spin" />;
        }

        if (price >= 0) {
            return numeral(price).format('$0,0.00');
        }

        return 'N/A';
    };

    const renderEachPrice = (price) => {
        if (props.calculatingPrice) {
            return <i className="fas fa-circle-notch fa-spin" />;
        }

        return numeral(price).format('$0,0.00[00]');
    };

    const handleLogin = () => {
        props.history.push('/login');
    };

    const handleAddToCart = () => {
        if (props.customer == null) {
            return props.history.push('/login');
        }

        if (!props.customer.has_billing_address || !props.customer.has_shipping_address) {
            return props.showNoAddressModal();
        }

        props.addToCart();
    };

    const handleSetRetailMarkup = (event) => {
        props.setRetailMarkup(event.target.value);
    };

    const handleSetRetailCustomerName = (event) => {
        props.setRetailCustomerName(event.target.value);
    };

    let button = (
        <button onClick={handleAddToCart} disabled={props.addingToCart}>
            {props.addingToCart ? <i className="fas fa-circle-notch fa-spin" /> : 'ADD TO CART'}
        </button>
    );
    if (props.isEdit) {
        button = (
            <button onClick={props.save} disabled={props.saving}>
                {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'UPDATE CART'}
            </button>
        );
    }
    if (props.customer == null) {
        button = <button onClick={handleLogin}>LOGIN</button>;
    }

    let downloadRetail = (
        <div className="download-button">
            <button onClick={props.downloadRetail} disabled={props.downloadingRetail}>
                {props.downloadingRetail ? <i className="fas fa-circle-notch fa-spin" /> : 'Download PDF'}
            </button>
        </div>
    );
    if (props.customer == null) {
        downloadRetail = null;
    }

    let retailArrow = <i className="fa-solid fa-angle-down"></i>;
    let retailContent = null;
    if (props.viewRetail) {
        retailArrow = <i className="fa-solid fa-angle-up"></i>;
        retailContent = (
            <div className="retail-form">
                <div className="field">
                    <div className="label">Percent Markup:</div>
                    <input type="number" value={props.retailMarkup} onChange={handleSetRetailMarkup} />
                </div>
                <div className="field mt-2">
                    <div className="label">Customer Name:</div>
                    <input type="text" value={props.retailCustomerName} onChange={handleSetRetailCustomerName} />
                </div>
                <div className="pricing">
                    <div className="price-row">
                        <div className="label">Total Job Price:</div>
                        <div className={'value ' + blurClass}>{renderPrice(props.price.total)}</div>
                    </div>
                    <div className="price-row">
                        <div className="label">Net Resale:</div>
                        <div className={'value ' + blurClass}>{renderPrice(props.price.total * props.retailMarkup / 100)}</div>
                    </div>
                    <div className="price-row">
                        <div className="label">Resale Job Price:</div>
                        <div className={'value ' + blurClass}>{renderPrice(props.price.total + props.price.total * (props.retailMarkup / 100))}</div>
                    </div>
                </div>
                {downloadRetail}
                <div className="notice">The Total Job Price is an estimate. Calculated prices may vary.</div>
            </div>
        );
    }

    let printingPrice = props.price.printing;

    let proofRow = null;
    if (parseInt(props.fields.proof) === 2) {
        printingPrice -= 45;
        proofRow = (
            <div className="price-row">
                <div className="label">Next Day Overnight Proof </div>
                <div className="price">{renderPrice(45)}</div>
            </div>
        );
    }

    let samplesRow = null;
    if (props.fields.send_samples) {
        let samplesCost = 45;
        if (printingPrice < 95) {
            samplesCost = 25;
        }

        printingPrice -= samplesCost;
        samplesRow = (
            <div className="price-row">
                <div className="label">Client Samples</div>
                <div className="price">{renderPrice(samplesCost)}</div>
            </div>
        );
    }

    let eachPrice = 0;
    if (!empty(props.fields.quantity)) {
        eachPrice = printingPrice / props.fields.quantity;
    }

    return (
        <div className="summary-container">
            <div className="sticky-container">
                <div className="title">Order Summary</div>
                <div className="pricing-container">
                    <div className="price-row">
                        <div className="label">Product Base</div>
                        <div className="price">
                            <div className={blurClass}>{renderPrice(printingPrice)}</div>
                            <div className={'each-price ' + blurClass}>
                                (Each: {renderEachPrice(eachPrice)})
                            </div>
                        </div>
                    </div>
                    {samplesRow}
                    {proofRow}
                    <div className="price-row">
                        <div className="label">Shipping</div>
                        <div className={'price ' + blurClass}>{renderPrice(props.price.shipping)}</div>
                    </div>
                    <div className="price-row total-row">
                        <div className="label">Total</div>
                        <div className={'price ' + blurClass}>{renderPrice(props.price.total)}</div>
                    </div>
                </div>
                <div className="retail-container">
                    <div className="header" onClick={props.setViewRetail}>
                        <div>Reseller's Estimate</div>
                        {retailArrow}
                    </div>
                    {retailContent}
                </div>
                <div className="continue-button">
                    {button}
                </div>
                {loginMessage}
            </div>
        </div>
    );
}

export default Summary;
