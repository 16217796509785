import axios from 'axios';
import * as types from './CreditApplication.types';
import * as selectors from './CreditApplication.selectors';
import { empty, validateAuthInResponse, showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const sendContact = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());

    if (empty(fields.company_name)) {
        return showNotification('Complete Information', 'Enter the Company Name', 'info');
    }
    if (empty(fields.address)) {
        return showNotification('Complete Information', 'Enter the Address', 'info');
    }
    if (empty(fields.city)) {
        return showNotification('Complete Information', 'Enter the City', 'info');
    }
    if (empty(fields.city)) {
        return showNotification('Complete Information', 'Enter the City', 'info');
    }
    if (empty(fields.state)) {
        return showNotification('Complete Information', 'Enter the State', 'info');
    }
    if (empty(fields.zip)) {
        return showNotification('Complete Information', 'Enter the Zip', 'info');
    }
    if (empty(fields.phone)) {
        return showNotification('Complete Information', 'Enter the Phone', 'info');
    }
    if (empty(fields.contact_name)) {
        return showNotification('Complete Information', 'Enter the Contact Name', 'info');
    }
    if (empty(fields.contact_phone)) {
        return showNotification('Complete Information', 'Enter the Contact Phone', 'info');
    }
    if (empty(fields.contact_email)) {
        return showNotification('Complete Information', 'Enter the Contact Email', 'info');
    }
    if (empty(fields.contact_address)) {
        return showNotification('Complete Information', 'Enter the Contact Address', 'info');
    }
    if (empty(fields.contact_city)) {
        return showNotification('Complete Information', 'Enter the Contact City', 'info');
    }
    if (empty(fields.contact_state)) {
        return showNotification('Complete Information', 'Enter the Contact State', 'info');
    }
    if (empty(fields.contact_zip)) {
        return showNotification('Complete Information', 'Enter the Contact Zip', 'info');
    }
    if (empty(fields.business_entity)) {
        return showNotification('Complete Information', 'Enter the Business Entity', 'info');
    }
    if (empty(fields.fed_id)) {
        return showNotification('Complete Information', 'Enter the Fed ID # (SSN for Individual)', 'info');
    }
    if (empty(fields.business_type)) {
        return showNotification('Complete Information', 'Enter the Type of Business', 'info');
    }
    if (empty(fields.estimated_annual_print)) {
        return showNotification('Complete Information', 'Enter the Est. Annual Print Purchases ($)', 'info');
    }
    if (empty(fields.tax_exempt)) {
        return showNotification('Complete Information', 'Enter the Is the Company Tax Exempt?', 'info');
    }
    if (empty(fields.sales_tax_rate)) {
        return showNotification('Complete Information', 'Enter the Applicable Sales Tax Rate (%)', 'info');
    }
    if (empty(fields.county)) {
        return showNotification('Complete Information', 'Enter the County', 'info');
    }
    if (empty(fields.bank_name)) {
        return showNotification('Complete Information', 'Enter the Bank Name', 'info');
    }
    if (empty(fields.account_number)) {
        return showNotification('Complete Information', 'Enter the Account Number', 'info');
    }
    if (empty(fields.bank_officer_name)) {
        return showNotification('Complete Information', 'Enter the Bank Officer Name', 'info');
    }
    if (empty(fields.bank_phone_number)) {
        return showNotification('Complete Information', 'Enter the Bank Phone Number', 'info');
    }
    if (empty(fields.reference1_name)) {
        return showNotification('Complete Information', 'Enter the Reference 1 Name', 'info');
    }
    if (empty(fields.reference1_address)) {
        return showNotification('Complete Information', 'Enter the Reference 1 Address', 'info');
    }
    if (empty(fields.reference1_city)) {
        return showNotification('Complete Information', 'Enter the Reference 1 City', 'info');
    }
    if (empty(fields.reference1_state)) {
        return showNotification('Complete Information', 'Enter the Reference 1 State', 'info');
    }
    if (empty(fields.reference1_zip)) {
        return showNotification('Complete Information', 'Enter the Reference 1 Zip', 'info');
    }
    if (empty(fields.reference1_phone)) {
        return showNotification('Complete Information', 'Enter the Reference 1 Phone', 'info');
    }
    if (empty(fields.reference2_name)) {
        return showNotification('Complete Information', 'Enter the Reference 2 Name', 'info');
    }
    if (empty(fields.reference2_address)) {
        return showNotification('Complete Information', 'Enter the Reference 2 Address', 'info');
    }
    if (empty(fields.reference2_city)) {
        return showNotification('Complete Information', 'Enter the Reference 2 City', 'info');
    }
    if (empty(fields.reference2_state)) {
        return showNotification('Complete Information', 'Enter the Reference 2 State', 'info');
    }
    if (empty(fields.reference2_zip)) {
        return showNotification('Complete Information', 'Enter the Reference 2 Zip', 'info');
    }
    if (empty(fields.reference2_phone)) {
        return showNotification('Complete Information', 'Enter the Reference 2 Phone', 'info');
    }
    if (empty(fields.reference3_name)) {
        return showNotification('Complete Information', 'Enter the Reference 3 Name', 'info');
    }
    if (empty(fields.reference3_address)) {
        return showNotification('Complete Information', 'Enter the Reference 3 Address', 'info');
    }
    if (empty(fields.reference3_city)) {
        return showNotification('Complete Information', 'Enter the Reference 3 City', 'info');
    }
    if (empty(fields.reference3_state)) {
        return showNotification('Complete Information', 'Enter the Reference 3 State', 'info');
    }
    if (empty(fields.reference3_zip)) {
        return showNotification('Complete Information', 'Enter the Reference 3 Zip', 'info');
    }
    if (empty(fields.reference3_phone)) {
        return showNotification('Complete Information', 'Enter the Reference 3 Phone', 'info');
    }
    if (empty(fields.agree) || parseInt(fields.agree) !== 1) {
        return showNotification('Complete Information', 'Agree with the Terms and Conditions', 'info');
    }
    if (empty(fields.electronic_signature)) {
        return showNotification('Complete Information', 'Enter the Electronic Signature', 'info');
    }
    if (empty(fields.job_title)) {
        return showNotification('Complete Information', 'Enter the Job Title', 'info');
    }

    dispatch({ type: types.SEND_CONTACT, payload: null });
    axios.post(apiUrl + '/user/send-credit-application', fields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SEND_CONTACT_SUCCESS, payload: response.data });
            showNotification('Message Sent', 'Your message has been sent successfully, our Customer Service will contact you very soon', 'success', 8000);
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SEND_CONTACT_ERROR));
        });
}

export const setField = (field, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}