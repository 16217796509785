import { createSelector } from 'reselect';

export const getComponentState = state => state.landing;

export const getActiveBlurProduct = createSelector(
    getComponentState,
    (state) => state.activeBlurProduct
);
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);