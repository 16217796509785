import axios from 'axios';
import * as types from './ForgotPassword.types';
import * as selectors from './ForgotPassword.selectors';
import * as loginActions from './../AdminLogin/AdminLogin.actions';
import { showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const send = () => (dispatch, getState) => {
    const form = selectors.getForm(getState());
    if (form.email === '') {
        return showNotification('Complete the information', 'Enter the Email Address', 'info');
    }

    dispatch({ type: types.SEND_EMAIL, payload: null });
    axios.post(apiUrl + '/abs-order/send-restore-password', form,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SEND_EMAIL_SUCCESS, payload: response.data });
            showNotification('Sign Up Successfully', 'Your account has been created successfully', 'success');
        })
        .catch((error) => {
            dispatch({ type: types.SEND_EMAIL_ERROR, payload: null });
            if (error.response && error.response.status === 401) {
                showNotification('Unauthorized', 'You are not authorized to see this page', 'warning');
            } else if (error.response && error.response.status === 409) {
                showNotification('Invalid data', 'The Email or Username already exist', 'info');
            } else if (error.response && error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const setField = (name, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}