export const FETCH_PRODUCT = 'PRODUCT/FETCH_PRODUCT';
export const FETCH_PRODUCT_SUCCESS = 'PRODUCT/FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_ERROR = 'PRODUCT/FETCH_PRODUCT_ERROR';
export const FETCH_ITEM = 'PRODUCT/FETCH_ITEM';
export const FETCH_ITEM_SUCCESS = 'PRODUCT/FETCH_ITEM_SUCCESS';
export const FETCH_ITEM_ERROR = 'PRODUCT/FETCH_ITEM_ERROR';
export const CALCULATE_PRICE = 'PRODUCT/CALCULATE_PRICE';
export const CALCULATE_PRICE_SUCCESS = 'PRODUCT/CALCULATE_PRICE_SUCCESS';
export const CALCULATE_PRICE_ERROR = 'PRODUCT/CALCULATE_PRICE_ERROR';
export const ADD_TO_CART = 'PRODUCT/ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'PRODUCT/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'PRODUCT/ADD_TO_CART_ERROR';
export const SAVE = 'PRODUCT/SAVE';
export const SAVE_SUCCESS = 'PRODUCT/SAVE_SUCCESS';
export const SAVE_ERROR = 'PRODUCT/SAVE_ERROR';
export const DOWNLOAD_RETAIL = 'PRODUCT/DOWNLOAD_RETAIL';
export const DOWNLOAD_RETAIL_SUCCESS = 'PRODUCT/DOWNLOAD_RETAIL_SUCCESS';
export const DOWNLOAD_RETAIL_ERROR = 'PRODUCT/DOWNLOAD_RETAIL_ERROR';
export const SET_NAME = 'PRODUCT/SET_NAME';
export const SET_FIELD = 'PRODUCT/SET_FIELD';
export const SET_VIEW_TURNAROUND = 'PRODUCT/SET_VIEW_TURNAROUND';
export const SET_VIEW_RETAIL = 'PRODUCT/SET_VIEW_RETAIL';
export const SET_RETAIL_MARKUP = 'PRODUCT/SET_RETAIL_MARKUP';
export const SET_RETAIL_CUSTOMER_NAME = 'PRODUCT/SET_RETAIL_CUSTOMER_NAME';
export const SET_IS_EDIT = 'PRODUCT/SET_IS_EDIT';
export const SET_FIELDS = 'PRODUCT/SET_FIELDS';
export const SET_PRINTING_TYPE = 'PRODUCT/SET_PRINTING_TYPE';
export const SET_HISTORY = 'PRODUCT/SET_HISTORY';
export const RESET_STATE = 'PRODUCT/RESET_STATE';