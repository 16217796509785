import React from 'react';

const Products = (props) => {
    const handleEcoFriendly = () => {
        props.history.push('/company/environmental-statement');
    };

    const handleRequestQuote = () => {
        props.history.push('/company/custom-quote');
    };

    return (
        <div className="information-container content-container">
            <div className="info-container">
                <h1>
                    OvernightColor.com has taken the initiative and grown into an environmentally friendly company by taking significant
                    steps in our production of printed products. OvernightColor.com even goes the extra mile to offer paper products that
                    are recycled from certified sustainable production systems.
                </h1>
                <div className="main-information">
                    <div className="description">
                        <p>
                            OvernightColor.com believes in using recycled paper products containing recovered fibers, which include pre or post
                            consumer sources, or both when possible. As a result, there are significant environmental advantages of producing
                            recycled paper over non-recycled paper. OvernightColor.com uses and recommends to our customers to choose FSC® certified
                            paper. The advantages create an eco-friendly footprint by reducing the impact on forest resources, less pollution,
                            lower consumption of water and energy, and minimizing solid waste.
                        </p>
                        <p>
                            OvernightColor.com also incorporates the use of eco-friendly soy-based inks and solvents in our production which emit
                            very low traces of volatile organic compounds (VOCs). VOCs are the fumes emitted into the air from inks, solvents and
                            cleaners as they are used or while they dry. Our VOC levels are one of the lowest in the world and much lower than the
                            required amounts set by the AQMD. In comparison, petroleum based inks release a much greater amount of VOCs than soy
                            inks. Soy inks are extremely beneficial during the paper recycling process since removing soy ink stains from paper is
                            much easier than other artificial inks. Soy inks are easily washable and cause less damage to the paper fibers. As a result,
                            the recycled paper is much brighter and fit for reuse. Furthermore, the waste produced by soy inks does not pose any threat
                            to the environment and is easily treated using modern methods. Soy inks are popular for their vibrant colors and are becoming
                            an increasingly popular alternative in the printing industry.
                        </p>
                        <p>
                            OvernightColor.com uses unique Aqueous and UV coatings, yet another critical step in producing environmentally friendly 
                            print products. The coatings consist of very low to zero traces of volatile organic compounds (VOC) reducing harmful 
                            components that damage the atmosphere and the surrounding environments. Impregnated washing systems are used for press 
                            rollers and blankets which consist of minimal traces of VOCs. Fountain solutions are free-of or use reduced VOC solutions. 
                            OvernightColor.com is constantly looking out for safer and more environmentally friendly press room products. We recycle all 
                            solutions and remove all hazardous waste from our facilities to prevent it from getting into the natural environment. 
                            OvernightColor.com production facilities are alcohol-free environments using only alcohol substitutes. Continued development 
                            with press room products assures only the safest solutions will be used in our facilities.
                        </p>
                        <p>
                            In our continued commitment to the environment, OvernightColor.com takes every opportunity to offer customers environmentally 
                            conscious and friendly solutions by expanding our recycled paper selection while still retaining a high degree of quality. 
                            We always strive to be a good steward of our planet because the future of our environment depends on the choices we make today. 
                            We are thrilled to provide you with the choice to use recycled papers and other green products and encourage you to take advantage 
                            of these eco-conscious choices. Together we can make a difference.
                        </p>
                    </div>
                    <div className="icons">
                        <div>
                            <img src="https://overnightcolor.s3.amazonaws.com/css/environmental-statement-image1.jpg" alt="Environmental Statement" />
                        </div>
                        <div>
                            <img src="https://overnightcolor.s3.amazonaws.com/css/environmental-statement-image2.jpg" alt="Environmental Statement" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="messages-container">
                <div className="message-main-container eco-friendly">
                    <div className="message-container">
                        <div className="title">ECO-FRIENDLY PRINTING</div>
                        <div className="message">
                            OvernightColor.com has taken the initiative and grown into an environmentally friendly company by taking significant
                            steps in our production of printed products.
                        </div>
                        <button onClick={handleEcoFriendly}>READ MORE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/eco-friendly.png" alt="Eco Friendly" />
                    </div>
                </div>
                <div className="message-main-container request-quote">
                    <div className="message-container">
                        <div className="title">REQUEST PRINT QUOTE</div>
                        <div className="message">
                            Request a quote for any print project by using our user-friendly online form. Submit the details of your print request
                            and we will provide you with an instant quote.
                        </div>
                        <button onClick={handleRequestQuote}>REQUEST A QUOTE</button>
                    </div>
                    <div className="image">
                        <img src="https://overnightcolor.s3.amazonaws.com/css/request-quote.png" alt="Request Quote" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
