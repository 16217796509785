export const FETCH_ITEMS = 'SHOPPING_CART/FETCH_ITEMS';
export const FETCH_ITEMS_SUCCESS = 'SHOPPING_CART/FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_ERROR = 'SHOPPING_CART/FETCH_ITEMS_ERROR';
export const SAVE_CHECKOUT = 'SHOPPING_CART/SAVE_CHECKOUT';
export const SAVE_CHECKOUT_SUCCESS = 'SHOPPING_CART/SAVE_CHECKOUT_SUCCESS';
export const SAVE_CHECKOUT_ERROR = 'SHOPPING_CART/SAVE_CHECKOUT_ERROR';
export const DELETE_ITEM = 'SHOPPING_CART/DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'SHOPPING_CART/DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_ERROR = 'SHOPPING_CART/DELETE_ITEM_ERROR';
export const CALCULATE_PRICE = 'SHOPPING_CART/CALCULATE_PRICE';
export const CALCULATE_PRICE_SUCCESS = 'SHOPPING_CART/CALCULATE_PRICE_SUCCESS';
export const CALCULATE_PRICE_ERROR = 'SHOPPING_CART/CALCULATE_PRICE_ERROR';
export const FETCH_SHIPPING_ADDRESSES = 'SHOPPING_CART/FETCH_SHIPPING_ADDRESSES';
export const FETCH_SHIPPING_ADDRESSES_SUCCESS = 'SHOPPING_CART/FETCH_SHIPPING_ADDRESSES_SUCCESS';
export const FETCH_SHIPPING_ADDRESSES_ERROR = 'SHOPPING_CART/FETCH_SHIPPING_ADDRESSES_ERROR';
export const SAVE_SHIPPING_ADDRESS = 'SHOPPING_CART/SAVE_SHIPPING_ADDRESS';
export const SAVE_SHIPPING_ADDRESS_SUCCESS = 'SHOPPING_CART/SAVE_SHIPPING_ADDRESS_SUCCESS';
export const SAVE_SHIPPING_ADDRESS_ERROR = 'SHOPPING_CART/SAVE_SHIPPING_ADDRESS_ERROR';
export const SET_ITEM_QUANTITY = 'SHOPPING_CART/SET_ITEM_QUANTITY';
export const SET_SELECTED_ITEM = 'SHOPPING_CART/SET_SELECTED_ITEM';
export const SET_SELECTED_ADDRESS = 'SHOPPING_CART/SET_SELECTED_ADDRESS';
export const SET_FIELDS = 'SHOPPING_CART/SET_FIELDS';
export const SET_FIELD = 'SHOPPING_CART/SET_FIELD';
export const SET_HISTORY = 'SHOPPING_CART/SET_HISTORY';
export const RESET_STATE = 'SHOPPING_CART/RESET_STATE';