import React, { Fragment } from 'react';
// import { Link } from 'react-router-dom';

const Products = (props) => {
    const handleShopNow = (product) => {
        props.history.push('/product/' + product.code);
    };

    const renderRecommendedProducts = () => {
        if (props.fetchingInformation || props.information.recommendedProducts.length === 0) {
            return (
                <div className="products">
                    <div className="column1">
                        <div className="product-card loading">
                            <div className="label loading"></div>
                        </div>
                    </div>
                    <div className="column2">
                        <div className="product-card loading">
                            <div className="label loading"></div>
                        </div>
                        <div className="product-card loading">
                            <div className="label loading"></div>
                        </div>
                        <div className="product-card loading">
                            <div className="label loading"></div>
                        </div>
                        <div className="product-card loading">
                            <div className="label loading"></div>
                        </div>
                    </div>
                </div>
            );
        }

        const recommendedProducts = props.information.recommendedProducts;
        return (
            <Fragment>
                <div className="products">
                    <div className="column1">
                        <div className="product-card" style={{ backgroundImage: 'url("' + recommendedProducts[0].image_url + '")' }} onClick={handleShopNow.bind(this, recommendedProducts[0])}>
                            <div className="label">{recommendedProducts[0].product}</div>
                        </div>
                    </div>
                    <div className="column2">
                        <div className="product-card" style={{ backgroundImage: 'url("' + recommendedProducts[1].image_url + '")' }} onClick={handleShopNow.bind(this, recommendedProducts[1])}>
                            <div className="label">{recommendedProducts[1].product}</div>
                        </div>
                        <div className="product-card" style={{ backgroundImage: 'url("' + recommendedProducts[2].image_url + '")' }} onClick={handleShopNow.bind(this, recommendedProducts[2])}>
                            <div className="label">{recommendedProducts[2].product}</div>
                        </div>
                        <div className="product-card" style={{ backgroundImage: 'url("' + recommendedProducts[3].image_url + '")' }} onClick={handleShopNow.bind(this, recommendedProducts[3])}>
                            <div className="label">{recommendedProducts[3].product}</div>
                        </div>
                        <div className="product-card" style={{ backgroundImage: 'url("' + recommendedProducts[4].image_url + '")' }} onClick={handleShopNow.bind(this, recommendedProducts[4])}>
                            <div className="label">{recommendedProducts[4].product}</div>
                        </div>
                    </div>
                </div>
                <div className="mobile-products">
                    <div className="product-card" style={{ backgroundImage: 'url("' + recommendedProducts[0].image_url + '")' }} onClick={handleShopNow.bind(this, recommendedProducts[0])}>
                        <div className="label">{recommendedProducts[0].product}</div>
                    </div>
                    <div className="product-card" style={{ backgroundImage: 'url("' + recommendedProducts[1].image_url + '")' }} onClick={handleShopNow.bind(this, recommendedProducts[1])}>
                        <div className="label">{recommendedProducts[1].product}</div>
                    </div>
                    <div className="product-card" style={{ backgroundImage: 'url("' + recommendedProducts[2].image_url + '")' }} onClick={handleShopNow.bind(this, recommendedProducts[2])}>
                        <div className="label">{recommendedProducts[2].product}</div>
                    </div>
                    <div className="product-card" style={{ backgroundImage: 'url("' + recommendedProducts[3].image_url + '")' }} onClick={handleShopNow.bind(this, recommendedProducts[3])}>
                        <div className="label">{recommendedProducts[3].product}</div>
                    </div>
                    <div className="product-card" style={{ backgroundImage: 'url("' + recommendedProducts[4].image_url + '")' }} onClick={handleShopNow.bind(this, recommendedProducts[4])}>
                        <div className="label">{recommendedProducts[4].product}</div>
                    </div>
                </div>
            </Fragment>
        );
    };

    const renderBestSellers = () => {
        if (props.fetchingInformation || props.information.bestSellers.length === 0) {
            return (
                <div className="quick-products">
                    <div className="quick-product-container">
                        <div className="quick-product loading"></div>
                        <button>SHOP NOW!</button>
                        <div className="title"></div>
                    </div>
                    <div className="quick-product-container">
                        <div className="quick-product loading"></div>
                        <button>SHOP NOW!</button>
                        <div className="title"></div>
                    </div>
                    <div className="quick-product-container">
                        <div className="quick-product loading"></div>
                        <button>SHOP NOW!</button>
                        <div className="title"></div>
                    </div>
                    <div className="quick-product-container">
                        <div className="quick-product loading"></div>
                        <button>SHOP NOW!</button>
                        <div className="title"></div>
                    </div>
                </div>
            );
        }

        const bestSellers = props.information.bestSellers;
        return (
            <div className="quick-products">
                <div className="quick-product-container" onClick={handleShopNow.bind(this, bestSellers[0])}>
                    <div className="quick-product" style={{ backgroundImage: 'url("' + bestSellers[0].image_url + '")' }}></div>
                    <button onClick={handleShopNow.bind(this, bestSellers[0])}>SHOP NOW!</button>
                    <div className="title">{bestSellers[0].product}</div>
                </div>
                <div className="quick-product-container" onClick={handleShopNow.bind(this, bestSellers[1])}>
                    <div className="quick-product" style={{ backgroundImage: 'url("' + bestSellers[1].image_url + '")' }}></div>
                    <button onClick={handleShopNow.bind(this, bestSellers[1])}>SHOP NOW!</button>
                    <div className="title">{bestSellers[1].product}</div>
                </div>
                <div className="quick-product-container" onClick={handleShopNow.bind(this, bestSellers[2])}>
                    <div className="quick-product" style={{ backgroundImage: 'url("' + bestSellers[2].image_url + '")' }}></div>
                    <button onClick={handleShopNow.bind(this, bestSellers[2])}>SHOP NOW!</button>
                    <div className="title">{bestSellers[2].product}</div>
                </div>
                <div className="quick-product-container" onClick={handleShopNow.bind(this, bestSellers[3])}>
                    <div className="quick-product" style={{ backgroundImage: 'url("' + bestSellers[3].image_url + '")' }}></div>
                    <button onClick={handleShopNow.bind(this, bestSellers[3])}>SHOP NOW!</button>
                    <div className="title">{bestSellers[3].product}</div>
                </div>
            </div>
        );
    };

    const renderNewProducts = () => {
        if (props.fetchingInformation || props.information.newProducts.length === 0) {
            return (
                <div className="quick-products">
                    <div className="quick-product-container">
                        <div className="quick-product loading"></div>
                        <button>SHOP NOW!</button>
                        <div className="title"></div>
                    </div>
                    <div className="quick-product-container">
                        <div className="quick-product loading"></div>
                        <button>SHOP NOW!</button>
                        <div className="title"></div>
                    </div>
                    <div className="quick-product-container">
                        <div className="quick-product loading"></div>
                        <button>SHOP NOW!</button>
                        <div className="title"></div>
                    </div>
                    <div className="quick-product-container">
                        <div className="quick-product loading"></div>
                        <button>SHOP NOW!</button>
                        <div className="title"></div>
                    </div>
                </div>
            );
        }

        const newProducts = props.information.newProducts;
        return (
            <div className="quick-products">
                <div className="quick-product-container" onClick={handleShopNow.bind(this, newProducts[0])}>
                    <div className="quick-product" style={{ backgroundImage: 'url("' + newProducts[0].image_url + '")' }}></div>
                    <button onClick={handleShopNow.bind(this, newProducts[0])}>SHOP NOW!</button>
                    <div className="title">{newProducts[0].product}</div>
                </div>
                <div className="quick-product-container" onClick={handleShopNow.bind(this, newProducts[1])}>
                    <div className="quick-product" style={{ backgroundImage: 'url("' + newProducts[1].image_url + '")' }}></div>
                    <button onClick={handleShopNow.bind(this, newProducts[1])}>SHOP NOW!</button>
                    <div className="title">{newProducts[1].product}</div>
                </div>
                <div className="quick-product-container" onClick={handleShopNow.bind(this, newProducts[2])}>
                    <div className="quick-product" style={{ backgroundImage: 'url("' + newProducts[2].image_url + '")' }}></div>
                    <button onClick={handleShopNow.bind(this, newProducts[2])}>SHOP NOW!</button>
                    <div className="title">{newProducts[2].product}</div>
                </div>
                <div className="quick-product-container" onClick={handleShopNow.bind(this, newProducts[3])}>
                    <div className="quick-product" style={{ backgroundImage: 'url("' + newProducts[3].image_url + '")' }}></div>
                    <button onClick={handleShopNow.bind(this, newProducts[3])}>SHOP NOW!</button>
                    <div className="title">{newProducts[3].product}</div>
                </div>
            </div>
        );
    };

    return (
        <div className="products-container content-container">
            <h1>Recommended for this season</h1>
            {renderRecommendedProducts()}
            <h1>Best Sellers</h1>
            {renderBestSellers()}
            <h1>New & Updated Products</h1>
            {renderNewProducts()}
            {/* <h1>Best Deals</h1>
            <div className="quick-products">
                <div className="quick-product-container">
                    <div className="quick-product" style={{ backgroundImage: 'url("https://overnightcolor.s3.amazonaws.com/css/best-deal-test1.png")' }}></div>
                    <button>SHOP NOW!</button>
                    <div className="title">Calendars</div>
                </div>
                <div className="quick-product-container">
                    <div className="quick-product" style={{ backgroundImage: 'url("https://overnightcolor.s3.amazonaws.com/css/best-deal-test2.png")' }}></div>
                    <button>SHOP NOW!</button>
                    <div className="title">Apparel</div>
                </div>
                <div className="quick-product-container">
                    <div className="quick-product" style={{ backgroundImage: 'url("https://overnightcolor.s3.amazonaws.com/css/best-deal-test3.png")' }}></div>
                    <button>SHOP NOW!</button>
                    <div className="title">Product Labels</div>
                </div>
                <div className="quick-product-container">
                    <div className="quick-product" style={{ backgroundImage: 'url("https://overnightcolor.s3.amazonaws.com/css/best-deal-test4.png")' }}></div>
                    <button>SHOP NOW!</button>
                    <div className="title">Sample Bottles</div>
                </div>
            </div> */}
        </div>
    );
}

export default Products;
