import axios from 'axios';
import * as types from './Login.types';
import * as selectors from './Login.selectors';
import { showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const login = (history, email = null, password = null) => (dispatch, getState) => {
    const form = selectors.getForm(getState());

    let emailValue = '';
    let passwordValue = '';
    if (email != null) {
        emailValue = email;
        passwordValue = password;
    } else {
        if (form.email === '' || form.password === '') {
            showNotification('Complete the information', 'Complete all the required fields of the form', 'info');
            return;
        }

        emailValue = form.email;
        passwordValue = form.password;
    }

    dispatch({ type: types.LOGIN, payload: null });
    axios.post(apiUrl.replace('/v1', '') + '/oauth2/token',
        {
            grant_type: 'password',
            client_id: 'testclient',  // TODO: Add this to environment variables
            client_secret: 'testpass',  // TODO: Add this to environment variables
            username: emailValue,
            password: passwordValue,
            loginType: 'ac-frontend',
        })
        .then(response => {
            dispatch({
                type: types.LOGIN_SUCCESS,
                payload: response.data
            });
            dispatch(setTokens(response.data));

            history.push('/my-jobs');
        })
        .catch((error) => {
            dispatch({ type: types.LOGIN_ERROR, payload: null });
            showNotification('Incorrect Credentials', 'The email or password are incorrect', 'info');
        });
}

export const setField = (name, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}

export const setTokens = (data) => dispatch => {
    localStorage.setItem('user', JSON.stringify(data.user));
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    dispatch({
        type: types.SET_TOKENS,
        payload: data
    });
}

export const clearTokens = () => dispatch => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    dispatch({
        type: types.CLEAR_TOKENS,
        payload: null
    });
}

export const logout = (history = null) => (dispatch) => {
    if (history != null) {
        history.push('/');
    }

    dispatch(clearTokens());
}

export const adminLogout = (history = null) => (dispatch) => {
    if (history != null) {
        history.push('/admin/login');
    }

    dispatch(clearTokens());
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}