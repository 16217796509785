import { createSelector } from 'reselect';

export const getComponentState = state => state.account;

export const getElement = createSelector(
    getComponentState,
    (state) => state.element
);
export const getFetchingElement = createSelector(
    getComponentState,
    (state) => state.fetchingElement
);
export const getSavingAccount = createSelector(
    getComponentState,
    (state) => state.savingAccount
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);