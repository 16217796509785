import Immutable from 'immutable';
import * as types from './Faqs.types';

const initialState = {
    items: [],
    fetchingInformation: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['items'], action.payload)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS()

        case types.SET_SHOW_ITEM:
            return state.updateIn(['items'], (items) => {
                const newItems = items.toJS();
                newItems[action.payload.index].show = !newItems[action.payload.index].show;
                return newItems;
            }).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}