import axios from 'axios';
import * as types from './Layout.types';
import * as selectors from './Layout.selectors';
import { validateAuthInResponse } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCustomer = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_CUSTOMER, payload: null });
    axios.get(apiUrl + '/customers/' + id + '?expand=defaultBillingAddress,defaultShippingAddress',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_CUSTOMER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_CUSTOMER_ERROR));
        });
}

export const checkCartItems = () => (dispatch) => {
    dispatch({ type: types.CHECK_CART_ITEMS, payload: null });
    axios.get(apiUrl + '/shopping-cart-item/check-items',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.CHECK_CART_ITEMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.CHECK_CART_ITEMS_ERROR));
        });
}

export const setShowSidebarMenu = () => (dispatch, getState) => {
    const showSidebarMenu = selectors.getShowSidebarMenu(getState());

    if (!showSidebarMenu) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }

    dispatch({
        type: types.SET_SHOW_SIDEBAR_MENU,
        payload: !showSidebarMenu,
    })
}

export const setShowAllProducts = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SHOW_ALL_PRODUCTS,
        payload: value,
    })
}
export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}